import React, { useState } from 'react'
import { useAppChain } from 'src/hooks/useUserStore'
import { numberInputReg } from 'src/utils/input'
import { ClaimBlock } from 'src/views/stakeUSDP/components/ClaimBlock'

import { SUSDP_ADDRESS } from '../../../../constants/identifiers'
import {
  submitStakeUSDPDeposit,
  submitStakeUSDPWithdraw,
} from '../../../../contracts/stakeusdp/contractFunctions'
import { approveToken } from '../../../../hooks/useApprovals'
import useSubject from '../../../../hooks/useSubject'
import { StakeStoreTokens } from '../../../../store/stakeStore'
import { userStore } from '../../../../store/userStore'
import { MainBlock, MainBlockActionBtns, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { TokenInput } from '../../../../uiKit/TokenInput'
import { UIButton } from '../../../../uiKit/UiButton/styled'
import { UiTabs } from '../../../../uiKit/UiTabs'
import $BN from '../../../../utils/BigNumber'
import { getAtomicAmountFromDisplayAmount } from '../../../../utils/utils'
import { useWeb3Controller } from '../../../../providers/Web3CtxProvider'

const TABS = [
  {
    name: 'Stake',
    id: 'stake',
  },
  {
    name: 'Unstake',
    id: 'unstake',
  },
]

export const StakeBlock: React.FC<{ tokens: StakeStoreTokens }> = ({ tokens }) => {
  const userAddress = useSubject(userStore.address)
  const { connect } = useWeb3Controller()

  const [currentTab, setCurrentTab] = useState({
    name: 'Stake',
    id: 'stake',
  })
  const [value, setValue] = useState('0')
  const { usdpAddress } = useAppChain().config

  async function mainAction(actionName) {
    if (!userAddress) return connect()

    const signer = userStore.signer.getValue()

    const approvalNeeded =
      actionName === 'deposit' &&
      $BN(tokens[usdpAddress].allowance).lt(getAtomicAmountFromDisplayAmount(value, usdpAddress))

    if (approvalNeeded) approveToken(signer, usdpAddress, SUSDP_ADDRESS)

    if (actionName === 'deposit') {
      submitStakeUSDPDeposit(getAtomicAmountFromDisplayAmount(value, usdpAddress), signer)
    } else {
      submitStakeUSDPWithdraw(getAtomicAmountFromDisplayAmount(value, SUSDP_ADDRESS), signer)
    }
  }

  return (
    <>
      <MainBlock pad="small">
        <UiTabs tabs={TABS} currentTab={currentTab} onTabClick={setCurrentTab} />
        <MainBlockTitle>{currentTab.name}</MainBlockTitle>
        <TokenInput
          value={value}
          tokenAddress={currentTab.id === 'stake' ? usdpAddress : SUSDP_ADDRESS}
          onInput={(e) => setValue(numberInputReg(e.target.value))}
          onMax={(e) => setValue(e)}
          maxValue={
            currentTab.id === 'stake' ? tokens[usdpAddress].balance : tokens[SUSDP_ADDRESS].balance
          }
        />
        <MainBlockActionBtns>
          {!userAddress ? (
            <UIButton onClick={() => connect()}>Connect</UIButton>
          ) : currentTab.id === 'stake' ? (
            <UIButton onClick={() => mainAction('deposit')}>
              {!userAddress
                ? 'Connect'
                : !$BN(tokens[usdpAddress].allowance).gt(value) && currentTab.id === 'stake'
                ? 'Approve'
                : 'Stake'}
            </UIButton>
          ) : (
            <>
              <UIButton onClick={() => mainAction('withdraw')}>Unstake</UIButton>
            </>
          )}
        </MainBlockActionBtns>
      </MainBlock>
      <MainBlock pad="small">
        <ClaimBlock tokens={tokens} />
      </MainBlock>
    </>
  )
}
