import React, { useEffect, useState } from 'react'
import { ModalSpinner } from 'src/components/Modals/TransactionModal/components/ModalSpinner'
import useSubject from 'src/hooks/useSubject'
import { useSetModal } from 'src/providers/ModalsProvider'
import { getTxByHash, transactionsStore } from 'src/store/transactionsStore'
import { TxRecord } from 'src/types/transactions'
import { Modal } from 'src/uiKit/Modal'

import { ModalMessage, ModalTransactionContainer, StatusIcon } from './styled'

type Props = {
  message?: string
  txHash?: string
  txError?: number
}

export const TransactionModal: React.FC<Props> = ({ txHash, message, txError }) => {
  const [tx, setTx] = useState<TxRecord | null>(null)
  const setModal = useSetModal()
  const transactions = useSubject(transactionsStore.transactions)

  useEffect(() => {
    if (!txHash) return
    const txFromStore = getTxByHash(txHash)
    if (!txFromStore) {
      setTx(null)
      return
    }
    setTx(txFromStore)
  }, [txHash, transactions])

  function getTransactionIcon() {
    if (txError) return <StatusIcon isCancelled />
    if (!tx) return <ModalSpinner />
    if (tx.status === 0) return <StatusIcon isCancelled />
    if (tx.status === 2) return <StatusIcon />
  }

  function getTransactionTitle() {
    if (txError === 4001) return 'User denied transaction'
    if (txError) return 'It seems this transaction will fail'
    if (!tx) return 'Waiting For Confirmation'
    if (tx.status === 0) return 'It seems this transaction will fail'
    if (tx.status === 1) return 'Transaction Confirmed'
    if (tx.status === 2) return 'Transaction Pending'
  }

  return (
    <Modal onCloseModal={() => setModal(null)} forTransaction>
      <ModalTransactionContainer>
        {getTransactionIcon()}
        <ModalMessage>{getTransactionTitle()}</ModalMessage>
        {message}
      </ModalTransactionContainer>
    </Modal>
  )
}
