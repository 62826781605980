import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StakeBlock } from 'src/views/stake/components/StakingBlock'

import { DUCK_ADDRESS, QDUCK_ADDRESS } from '../../constants/identifiers'
import useSubject from '../../hooks/useSubject'
import { stakeStore } from '../../store/stakeStore'
import { MainBlock, MainBlockTitle, MainWrap } from '../../uiKit/MainBlock'
import { TokenName } from '../../uiKit/TokenInput/styled'
import { StyledUiTableRow, StyledUiTableRowCell } from '../../uiKit/UiTable/UiTableRow/styled'
import { getTokenSymbolByAddress } from '../../utils/token'
import { getDisplayAmountFromAtomicAmount } from '../../utils/utils'

export const StakePage: React.FC = () => {
  const tokens = useSubject(stakeStore.tokens)

  useEffect(() => {
    stakeStore.init()
    return stakeStore.destroy
  }, [])

  return (
    <MainWrap>
      <MainBlock pad="small">
        <span style={{ fontSize: '3rem' }}>
          DUCK staking V2 is live at <Link to="/stakingv2">duck.limo/stakingv2</Link>
        </span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style={{ fontSize: '3rem' }}>
          <span>Staking APR (based on the last distribution period):</span>
          <b>
            {'   '}
            {stakeStore.apr.getValue()}
          </b>
        </div>
      </MainBlock>
      <MainBlock pad="small">
        <StakeBlock tokens={tokens} />
      </MainBlock>
      <MainBlock pad="small">
        <MainBlockTitle>Your balances</MainBlockTitle>
        <StyledUiTableRow>
          <StyledUiTableRowCell>
            <TokenName>{getTokenSymbolByAddress(DUCK_ADDRESS)}</TokenName>
          </StyledUiTableRowCell>
          <StyledUiTableRowCell>
            {getDisplayAmountFromAtomicAmount(tokens[DUCK_ADDRESS].balance, DUCK_ADDRESS)}
          </StyledUiTableRowCell>
          <StyledUiTableRowCell />
        </StyledUiTableRow>
        <StyledUiTableRow>
          <StyledUiTableRowCell>
            <TokenName>{getTokenSymbolByAddress(QDUCK_ADDRESS)}</TokenName>
          </StyledUiTableRowCell>
          <StyledUiTableRowCell>
            {getDisplayAmountFromAtomicAmount(tokens[QDUCK_ADDRESS].balance, QDUCK_ADDRESS)}
          </StyledUiTableRowCell>
          <StyledUiTableRowCell />
        </StyledUiTableRow>
      </MainBlock>
    </MainWrap>
  )
}
