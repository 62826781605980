import React from 'react'
import { ModalSettings, useModal } from 'src/providers/ModalsProvider'

import { ModalConnect } from './ModalConnect'
import { TransactionModal } from './TransactionModal/TransactionModal'

const renderModal = (modal: ModalSettings) => {
  switch (modal.key) {
    case 'connect_wallet':
      return <ModalConnect />
    case 'transaction':
      return (
        <TransactionModal message={modal.message} txHash={modal.txHash} txError={modal.txError} />
      )
    default:
      return null
  }
}

export const Modals = () => {
  const modal = useModal()
  return modal ? renderModal(modal) : null
}
