import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/OracleRegistry.json'

export function getOracleRegistryContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.oracleRegistry, abi, provider)
}

export function getOracleRegistryWETH() {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.WETH().catch((e) => {
    console.error('Failed to call WETH in oracleRegistry:', e)
  })
}

export function getOracleRegistryGetKeydonixOracleTypes() {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.getKeydonixOracleTypes().catch((e) => {
    console.error('Failed to call getKeydonixOracleTypes in oracleRegistry:', e)
  })
}

export function getOracleRegistryGetOracles() {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.getOracles().catch((e) => {
    console.error('Failed to call getOracles in oracleRegistry:', e)
  })
}

export function getOracleRegistryKeydonixOracleTypes(uint256Input1) {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.keydonixOracleTypes(uint256Input1).catch((e) => {
    console.error('Failed to call keydonixOracleTypes in oracleRegistry:', e)
  })
}

export function getOracleRegistryMaxOracleType() {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.maxOracleType().catch((e) => {
    console.error('Failed to call maxOracleType in oracleRegistry:', e)
  })
}

export function getOracleRegistryOracleByAsset(asset) {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.oracleByAsset(asset).catch((e) => {
    console.error('Failed to call oracleByAsset in oracleRegistry:', e)
  })
}

export function getOracleRegistryOracleByType(uint256Input1) {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.oracleByType(uint256Input1).catch((e) => {
    console.error('Failed to call oracleByType in oracleRegistry:', e)
  })
}

export function getOracleRegistryOracleTypeByAsset(addressInput1) {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.oracleTypeByAsset(addressInput1).catch((e) => {
    console.error('Failed to call oracleTypeByAsset in oracleRegistry:', e)
  })
}

export function getOracleRegistryOracleTypeByOracle(addressInput1) {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.oracleTypeByOracle(addressInput1).catch((e) => {
    console.error('Failed to call oracleTypeByOracle in oracleRegistry:', e)
  })
}

export function submitOracleRegistrySetKeydonixOracleTypes(
  _keydonixOracleTypes,
  signer,
  options = {},
) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'setKeydonixOracleTypes', [_keydonixOracleTypes, { ...options }])
}

export function submitOracleRegistrySetOracle(oracleType, oracle, signer, options = {}) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'setOracle', [oracleType, oracle, { ...options }])
}

export function submitOracleRegistrySetOracleTypeForAsset(asset, oracleType, signer, options = {}) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'setOracleTypeForAsset', [asset, oracleType, { ...options }])
}

export function submitOracleRegistrySetOracleTypeForAssets(
  assets,
  oracleType,
  signer,
  options = {},
) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'setOracleTypeForAssets', [assets, oracleType, { ...options }])
}

export function submitOracleRegistryUnsetOracle(oracleType, signer, options = {}) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'unsetOracle', [oracleType, { ...options }])
}

export function submitOracleRegistryUnsetOracleForAsset(asset, signer, options = {}) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'unsetOracleForAsset', [asset, { ...options }])
}

export function submitOracleRegistryUnsetOracleForAssets(assets, signer, options = {}) {
  const contract = getOracleRegistryContract(signer)
  return sendTransaction(contract, 'unsetOracleForAssets', [assets, { ...options }])
}

export function getOracleRegistryVaultParameters() {
  const contract = getOracleRegistryContract(getChain().ethersProvider)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in oracleRegistry:', e)
  })
}
