import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getIsConnected } from 'src/hooks/useUserStore'
import { useSetModal } from 'src/providers/ModalsProvider'
import { shortenHash } from 'src/utils/ui'

import GnoLogoSrc from '../../assets/images/gno-logo.svg'
import BackIconGno from '../../assets/images/icons/ic-back-gno.svg'
import BackIcon from '../../assets/images/icons/ic-back.svg'
import { IS_GNO } from '../../constants/constants'
import useSubject from '../../hooks/useSubject'
import { userStore } from '../../store/userStore'
import { isMobile } from '../../utils/isMobile'
import { NetworkAttention } from '../NetworkAttention'
import { TransactionStatus } from '../TransactionStatus'
import { ChainSelector } from './ChainSelector'
import { Menu } from './Menu'
import {
  GnoLogo,
  HeaderBackBtn,
  HeaderBtnsContainer,
  HeaderConnectBtn,
  HeaderContainer,
  HeaderMenuBtn,
  HeaderWalletAddress,
  UiHeader,
} from './styled'
import { useWeb3Controller } from '../../providers/Web3CtxProvider'

export const Header: React.FC = () => {
  const userAddress = useSubject<string>(userStore.address)
  const { connect, disconnect } = useWeb3Controller()
  const isConnected = getIsConnected(userAddress)
  const location = useLocation()
  const setModal = useSetModal()
  const { chainUrlRepresentation } = useParams()

  const [showMenu, setShowMenu] = useState(false)

  function handleConnectBtnClick() {
    const isTrust = window.web3?.currentProvider.isTrust
    const isMetaMask = window.web3?.currentProvider.isMetaMask
    if (!isConnected) {
      if (isMobile() && (isTrust || isMetaMask)) {
        connect()
      } else {
        setModal({ key: 'connect_wallet' })
      }
    } else disconnect()
  }

  function onCloseMenu() {
    setShowMenu(false)
  }

  useEffect(() => {
    onCloseMenu()
    window.scrollTo(0, 0)
  }, [location])

  return (
    <HeaderContainer>
      <UiHeader>
        <NetworkAttention />
        {location.pathname.split('/').length > 3 ? (
          <Link to={`/${chainUrlRepresentation}`}>
            <HeaderBackBtn src={IS_GNO ? BackIconGno : BackIcon} />
          </Link>
        ) : IS_GNO ? (
          <GnoLogo src={GnoLogoSrc} />
        ) : null}
        <HeaderBtnsContainer>
          {!IS_GNO && <ChainSelector />}
          {isConnected && <HeaderWalletAddress>{shortenHash(userAddress)}</HeaderWalletAddress>}
          <TransactionStatus />
          <HeaderConnectBtn type="button" onClick={handleConnectBtnClick}>
            {isConnected ? 'Disconnect' : 'Connect wallet'}
          </HeaderConnectBtn>
          <HeaderMenuBtn onClick={() => setShowMenu(true)} />
        </HeaderBtnsContainer>
        {showMenu && <Menu onCloseMenu={onCloseMenu} />}
      </UiHeader>
    </HeaderContainer>
  )
}
