import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/Gauge3CRV.json'

export function getGauge3crvContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.gauge3crv, abi, provider)
}

export function submitGauge3crvDecimals(signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'decimals', [{ ...options }])
}

export function submitGauge3crvUser_checkpoint(addr, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'user_checkpoint', [addr, { ...options }])
}

export function getGauge3crvClaimable_tokens(addr) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.claimable_tokens(addr, { gasLimit: 1e6 }).catch((e) => {
    console.error('Failed to call claimable_tokens in gauge3crv:', e)
  })
}

export function submitGauge3crvClaim_tokens(signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'claim_tokens', [{ ...options }])
}

export function submitGauge3crvSet_approve_deposit(addr, can_deposit, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'set_approve_deposit', [addr, can_deposit, { ...options }])
}

export function submitGauge3crvDeposit(_value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'deposit', [_value, { ...options }])
}

export function submitGauge3crvWithdraw(_value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'withdraw', [_value, { ...options }])
}

export function getGauge3crvAllowance(_owner, _spender) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.allowance(_owner, _spender).catch((e) => {
    console.error('Failed to call allowance in gauge3crv:', e)
  })
}

export function submitGauge3crvTransfer(_to, _value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'transfer', [_to, _value, { ...options }])
}

export function submitGauge3crvTransferFrom(_from, _to, _value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'transferFrom', [_from, _to, _value, { ...options }])
}

export function submitGauge3crvApprove(_spender, _value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'approve', [_spender, _value, { ...options }])
}

export function submitGauge3crvIncreaseAllowance(_spender, _added_value, signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'increaseAllowance', [_spender, _added_value, { ...options }])
}

export function submitGauge3crvDecreaseAllowance(
  _spender,
  _subtracted_value,
  signer,
  options = {},
) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'decreaseAllowance', [
    _spender,
    _subtracted_value,
    { ...options },
  ])
}

export function submitGauge3crvMinter(signer, options = {}) {
  const contract = getGauge3crvContract(signer)
  return sendTransaction(contract, 'minter', [{ ...options }])
}

export function getGauge3crvCrv_token() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.crv_token().catch((e) => {
    console.error('Failed to call crv_token in gauge3crv:', e)
  })
}

export function getGauge3crvLp_token() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.lp_token().catch((e) => {
    console.error('Failed to call lp_token in gauge3crv:', e)
  })
}

export function getGauge3crvGauge() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.gauge().catch((e) => {
    console.error('Failed to call gauge in gauge3crv:', e)
  })
}

export function getGauge3crvBalanceOf(arg0) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.balanceOf(arg0).catch((e) => {
    console.error('Failed to call balanceOf in gauge3crv:', e)
  })
}

export function getGauge3crvDepositedBalanceOf(arg0) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.depositedBalanceOf(arg0).catch((e) => {
    console.error('Failed to call depositedBalanceOf in gauge3crv:', e)
  })
}

export function getGauge3crvTotalSupply() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in gauge3crv:', e)
  })
}

export function getGauge3crvName() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.name().catch((e) => {
    console.error('Failed to call name in gauge3crv:', e)
  })
}

export function getGauge3crvSymbol() {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in gauge3crv:', e)
  })
}

export function getGauge3crvApproved_to_deposit(arg0, arg1) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.approved_to_deposit(arg0, arg1).catch((e) => {
    console.error('Failed to call approved_to_deposit in gauge3crv:', e)
  })
}

export function getGauge3crvClaimable_crv(arg0) {
  const contract = getGauge3crvContract(getChain().ethersProvider)
  return contract.claimable_crv(arg0).catch((e) => {
    console.error('Failed to call claimable_crv in gauge3crv:', e)
  })
}
