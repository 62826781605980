import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { Header } from '../../components/Header'
import { IS_GNO } from '../../constants/constants'
import { userStore } from '../../store/userStore'
import { getChainConfigByUrlRepresentation } from '../../utils/chain'

export const ChainUrlRepresentation: React.FC = () => {
  const { chainUrlRepresentation } = useParams()

  useEffect(() => {
    const chainConfig = getChainConfigByUrlRepresentation(
      chainUrlRepresentation || (IS_GNO ? 'gnosis' : 'eth'),
    )
    userStore.setAppChainId(chainConfig.id)
  }, [chainUrlRepresentation])

  return (
    <div>
      <Header />
      <Outlet />
    </div>
  )
}
