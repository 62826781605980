import styled from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../uiKit/theme'

export const HeaderMenuBtn = styled.div`
  position: relative;
  background: center / 4rem no-repeat url('/images/icons/ic-header-info.svg'),
    rgba(255, 255, 255, 1);
  width: 8rem;
  height: 6.1rem;
  border-radius: ${borderRadius.normal};
  cursor: pointer;
  margin-left: auto;
  :hover {
    background-image: url('/images/icons/ic-header-info-white.svg');
    background-color: ${color.purple};
  }
  @media screen and (min-width: ${breakpoint.tablet}) {
    order: initial;
    margin-right: 0;
  }
`

export const HeaderBackBtn = styled.img`
  width: 10rem;
  display: block;
  background: ${color.grayBg};
  margin-right: 2rem;
  border-radius: ${borderRadius.normal};
  cursor: pointer;
`

export const HeaderBtnsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media screen and (min-width: ${breakpoint.tablet}) {
    margin-left: auto;
    flex-grow: 0;
  }
`

export const HeaderConnectBtn = styled.button`
  display: block;
  background: ${color.purple};
  color: ${color.white};
  padding: 1.5rem 2rem;
  box-shadow: none;
  border: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 0.8rem;
`

export const HeaderWalletAddress = styled.button`
  display: block;
  background: ${color.grayBg};
  padding: 1.5rem 2rem;
  box-shadow: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 0.8rem;
  border: 0.1em solid rgba(37, 37, 37, 0.1);
  color: #4e4e4e;
`

export const HeaderContainer = styled.div`
  padding-bottom: 5rem;
`

export const UiHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding: 0 2.5rem;
  }
`

export const GnoLogo = styled.img`
  width: 12rem;
  margin-right: 2rem;
  @media (min-width: ${breakpoint.tablet}) {
    width: 26rem;
  }
`
