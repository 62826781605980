import { BehaviorSubject } from 'rxjs'

export const collateralsFilterStore = {
  currentFilterByType: new BehaviorSubject(''),
  currentFilterBySearch: new BehaviorSubject(''),

  setFilter(val: string): void {
    collateralsFilterStore.currentFilterByType.next(val)
  },
  setSearch(val: string): void {
    collateralsFilterStore.currentFilterBySearch.next(val)
  },
}
