import { BehaviorSubject } from 'rxjs'
import { NetworkConfig } from 'src/constants/network'
import { CollateralProps } from 'src/types/collaterals'

import { DUCK_ADDRESS, QDUCK_ADDRESS } from '../constants/identifiers'
import { getErc20TokenAllowance } from '../contracts/erc20token/contractFunctions'
import {
  trackErc20TokenApproval,
  trackErc20TokenTransfer,
} from '../contracts/erc20token/eventListeners'
import { getStakeGetExchangeRate } from '../contracts/stake/contractFunctions'
import { getBalance } from '../utils/balances'
import { getChainConfig, userStore } from './userStore'

export interface StakeStoreTokens {
  [tokenAddress: string]: CollateralProps & {
    balance?: string
    allowance?: string
    contractBalance?: string
    claimable?: string
  }
}

export const stakeStore = {
  exchangeRate: new BehaviorSubject<string>('1000000000000000000'),
  tokens: new BehaviorSubject<StakeStoreTokens>({
    [DUCK_ADDRESS]: {
      symbol: 'DUCK',
      decimals: 18,
      balance: '0',
    },
    [QDUCK_ADDRESS]: {
      symbol: 'qDUCK',
      decimals: 18,
      balance: '0',
    },
  }),
  storeSubscriptions: [],
  chainSubscriptions: [],
  apr: new BehaviorSubject<string>(''),
  initialized: false,

  async init(): Promise<void> {
    try {
      const rate = await getStakeGetExchangeRate()
      if (rate) stakeStore.exchangeRate.next(rate.toString())
    } catch (e) {
      console.log(e)
    }

    const chainConfig = getChainConfig()
    stakeStore.storeSubscriptions.push(
      userStore.address.subscribe((address) => stakeStore.fetchTokensParams(address, chainConfig)),
    )
  },

  async fetchTokensParams(address: string, chainConfig: NetworkConfig): Promise<void> {
    await stakeStore._getDUCKBalance(chainConfig)
    if (!stakeStore.initialized) {
      await stakeStore.initializeAPR()
    }
    if (!address) {
      stakeStore._resetTokensParams()
    } else {
      await stakeStore._getTokensBalances(address)
      await stakeStore._getTokensAllowance(address, chainConfig)
    }
  },

  async _getDUCKBalance({ contracts }: NetworkConfig): Promise<void> {
    const contractBalance = (await getBalance(contracts.stake, DUCK_ADDRESS)).toString()

    stakeStore.tokens.next({
      [DUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[DUCK_ADDRESS],
        contractBalance,
      },
      [QDUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[QDUCK_ADDRESS],
      },
    })

    stakeStore.chainSubscriptions.push(
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const balance = (await getBalance(contracts.stake, DUCK_ADDRESS)).toString()
            stakeStore._setTokenParam(DUCK_ADDRESS, balance, 'contractBalance')
          },
          contract: DUCK_ADDRESS,
          src: contracts.stake,
          dst: '',
        },
        contracts.stake,
      ),
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const balance = (await getBalance(contracts.stake, DUCK_ADDRESS)).toString()
            stakeStore._setTokenParam(DUCK_ADDRESS, balance, 'contractBalance')
          },
          contract: DUCK_ADDRESS,
          src: '',
          dst: contracts.stake,
        },
        contracts.stake,
      ),
    )
  },

  async _getTokensBalances(wallet: string): Promise<void> {
    const balances = await Promise.all(
      Object.keys(stakeStore.tokens.getValue()).map(async (tokenAddress) => {
        stakeStore.chainSubscriptions.push(
          await trackErc20TokenTransfer(
            {
              callback: async () => {
                const balance = (await getBalance(wallet, tokenAddress)).toString()
                stakeStore._setTokenParam(tokenAddress, balance, 'balance')
              },
              contract: tokenAddress,
              src: wallet,
              dst: '',
            },
            wallet,
          ),
          await trackErc20TokenTransfer(
            {
              callback: async () => {
                const balance = (await getBalance(wallet, tokenAddress)).toString()
                stakeStore._setTokenParam(tokenAddress, balance, 'balance')
              },
              contract: tokenAddress,
              src: '',
              dst: wallet,
            },
            wallet,
          ),
        )
        return (await getBalance(wallet, tokenAddress)).toString()
      }),
    )
    stakeStore.tokens.next({
      [DUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[DUCK_ADDRESS],
        balance: balances[0],
      },
      [QDUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[QDUCK_ADDRESS],
        balance: balances[1],
      },
    })
  },

  async initializeAPR(): Promise<void> {
    stakeStore.apr.next('0%')
  },

  async _getTokensAllowance(wallet: string, { contracts }: NetworkConfig): Promise<void> {
    const allowances = await Promise.all(
      Object.keys(stakeStore.tokens.getValue()).map(async (tokenAddress) => {
        stakeStore.chainSubscriptions.push(
          await trackErc20TokenApproval(
            {
              callback: async ([event]) => {
                if (!event) return
                const tokenAllowance = BigInt(event.data).toString()
                stakeStore._setTokenParam(tokenAddress, tokenAllowance, 'allowance')
              },
              src: wallet,
              contract: tokenAddress,
              guy: contracts.stake,
            },
            wallet,
          ),
        )
        return getErc20TokenAllowance(tokenAddress, wallet, QDUCK_ADDRESS)
      }),
    )
    stakeStore.tokens.next({
      [DUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[DUCK_ADDRESS],
        allowance: allowances[0].toString(),
      },
      [QDUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[QDUCK_ADDRESS],
        allowance: allowances[1].toString(),
      },
    })
  },

  _setTokenParam(
    tokenAddress: string,
    value: string,
    param: 'balance' | 'allowance' | 'contractBalance',
  ): void {
    stakeStore.tokens.next({
      ...stakeStore.tokens.getValue(),
      [tokenAddress.toLowerCase()]: {
        ...stakeStore.tokens.getValue()[tokenAddress.toLowerCase()],
        [param]: value,
      },
    })
  },

  _resetTokensParams(): void {
    stakeStore.tokens.next({
      [DUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[DUCK_ADDRESS],
        balance: '0',
        allowance: '0',
      },
      [QDUCK_ADDRESS]: {
        ...stakeStore.tokens.getValue()[QDUCK_ADDRESS],
        balance: '0',
        allowance: '0',
      },
    })
  },

  destroy(): void {
    stakeStore._resetTokensParams()
    stakeStore.storeSubscriptions.forEach((subscription): void => {
      subscription.unsubscribe()
    })
    stakeStore.chainSubscriptions.forEach((sub): void => {
      sub.unsubscribe()
    })
  },
}
