import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/BearingAssetOracle.json'

export function getBearingAssetOracleContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.bearingAssetOracle, abi, provider)
}

export function getBearingAssetOracleAssetToUsd(bearing, amount) {
  const contract = getBearingAssetOracleContract(getChain().ethersProvider)
  return contract.assetToUsd(bearing, amount).catch((e) => {
    console.error('Failed to call assetToUsd in bearingAssetOracle:', e)
  })
}

export function getBearingAssetOracleBearingToUnderlying(bearing, amount) {
  const contract = getBearingAssetOracleContract(getChain().ethersProvider)
  return contract.bearingToUnderlying(bearing, amount).catch((e) => {
    console.error('Failed to call bearingToUnderlying in bearingAssetOracle:', e)
  })
}

export function getBearingAssetOracleOracleRegistry() {
  const contract = getBearingAssetOracleContract(getChain().ethersProvider)
  return contract.oracleRegistry().catch((e) => {
    console.error('Failed to call oracleRegistry in bearingAssetOracle:', e)
  })
}

export function submitBearingAssetOracleSetUnderlying(bearing, underlying, signer, options = {}) {
  const contract = getBearingAssetOracleContract(signer)
  return sendTransaction(contract, 'setUnderlying', [bearing, underlying, { ...options }])
}

export function getBearingAssetOracleVaultParameters() {
  const contract = getBearingAssetOracleContract(getChain().ethersProvider)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in bearingAssetOracle:', e)
  })
}
