import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/VeDUCKDistribution.json'

export function getVeDUCKDistributionContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.veDUCKDistribution, abi, provider)
}

export function submitVeDUCKDistributionCheckpoint_token(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'checkpoint_token', [{ ...options }])
}

export function getVeDUCKDistributionVe_for_at(_user, _timestamp) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.ve_for_at(_user, _timestamp).catch((e) => {
    console.error('Failed to call ve_for_at in veDUCKDistribution:', e)
  })
}

export function submitVeDUCKDistributionCheckpoint_total_supply(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'checkpoint_total_supply', [{ ...options }])
}

export function getVeDUCKDistributionClaimable(_addr) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.claimable(_addr).catch((e) => {
    console.error('Failed to call claimable in veDUCKDistribution:', e)
  })
}

export function submitVeDUCKDistributionClaim(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'claim', [{ ...options }])
}

export function submitVeDUCKDistributionClaim_many(_receivers, signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'claim_many', [_receivers, { ...options }])
}

export function submitVeDUCKDistributionBurn(_coin, signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'burn', [_coin, { ...options }])
}

export function submitVeDUCKDistributionCommit_admin(_addr, signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'commit_admin', [_addr, { ...options }])
}

export function submitVeDUCKDistributionApply_admin(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'apply_admin', [{ ...options }])
}

export function submitVeDUCKDistributionToggle_allow_checkpoint_token(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'toggle_allow_checkpoint_token', [{ ...options }])
}

export function submitVeDUCKDistributionKill_me(signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'kill_me', [{ ...options }])
}

export function submitVeDUCKDistributionRecover_balance(_coin, signer, options = {}) {
  const contract = getVeDUCKDistributionContract(signer)
  return sendTransaction(contract, 'recover_balance', [_coin, { ...options }])
}

export function getVeDUCKDistributionStart_time() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.start_time().catch((e) => {
    console.error('Failed to call start_time in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionTime_cursor() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.time_cursor().catch((e) => {
    console.error('Failed to call time_cursor in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionTime_cursor_of(arg0) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.time_cursor_of(arg0).catch((e) => {
    console.error('Failed to call time_cursor_of in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionUser_epoch_of(arg0) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.user_epoch_of(arg0).catch((e) => {
    console.error('Failed to call user_epoch_of in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionLast_token_time() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.last_token_time().catch((e) => {
    console.error('Failed to call last_token_time in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionTokens_per_week(arg0) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.tokens_per_week(arg0).catch((e) => {
    console.error('Failed to call tokens_per_week in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionVoting_escrow() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.voting_escrow().catch((e) => {
    console.error('Failed to call voting_escrow in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionToken() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.token().catch((e) => {
    console.error('Failed to call token in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionTotal_received() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.total_received().catch((e) => {
    console.error('Failed to call total_received in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionToken_last_balance() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.token_last_balance().catch((e) => {
    console.error('Failed to call token_last_balance in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionVe_supply(arg0) {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.ve_supply(arg0).catch((e) => {
    console.error('Failed to call ve_supply in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionAdmin() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.admin().catch((e) => {
    console.error('Failed to call admin in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionFuture_admin() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.future_admin().catch((e) => {
    console.error('Failed to call future_admin in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionCan_checkpoint_token() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.can_checkpoint_token().catch((e) => {
    console.error('Failed to call can_checkpoint_token in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionEmergency_return() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.emergency_return().catch((e) => {
    console.error('Failed to call emergency_return in veDUCKDistribution:', e)
  })
}

export function getVeDUCKDistributionIs_killed() {
  const contract = getVeDUCKDistributionContract(getChain().ethersProvider)
  return contract.is_killed().catch((e) => {
    console.error('Failed to call is_killed in veDUCKDistribution:', e)
  })
}
