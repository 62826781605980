import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/LiquidationAuction.json'

export function getLiquidationAuctionContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.liquidationAuction, abi, provider)
}

export function getLiquidationAuctionDENOMINATOR_1E2() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.DENOMINATOR_1E2().catch((e) => {
    console.error('Failed to call DENOMINATOR_1E2 in LiquidationAuction:', e)
  })
}

export function getLiquidationAuctionWRAPPED_TO_UNDERLYING_ORACLE_TYPE() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.WRAPPED_TO_UNDERLYING_ORACLE_TYPE().catch((e) => {
    console.error('Failed to call WRAPPED_TO_UNDERLYING_ORACLE_TYPE in LiquidationAuction:', e)
  })
}

export function submitLiquidationAuctionBuyout(asset, owner, signer, options = {}) {
  const contract = getLiquidationAuctionContract(signer)
  return sendTransaction(contract, 'buyout', [asset, owner, { ...options }])
}

export function getLiquidationAuctionCdpRegistry() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.cdpRegistry().catch((e) => {
    console.error('Failed to call cdpRegistry in LiquidationAuction:', e)
  })
}

export function getLiquidationAuctionForceTransferAssetStore() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.forceTransferAssetStore().catch((e) => {
    console.error('Failed to call forceTransferAssetStore in LiquidationAuction:', e)
  })
}

export function getLiquidationAuctionVault() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.vault().catch((e) => {
    console.error('Failed to call vault in LiquidationAuction:', e)
  })
}

export function getLiquidationAuctionVaultManagerParameters() {
  const contract = getLiquidationAuctionContract(getChain().ethersProvider)
  return contract.vaultManagerParameters().catch((e) => {
    console.error('Failed to call vaultManagerParameters in LiquidationAuction:', e)
  })
}
