import React from 'react'
import { SpinnerContainer } from 'src/components/Modals/TransactionModal/components/ModalSpinner/styled'

export const ModalSpinner = () => (
  <SpinnerContainer>
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4747 2.52525C47.4747 1.13059 48.6053 0 50 0C77.6142 0 100 22.3858 100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 41.0931 2.33115 32.7231 6.41844 25.4748C7.10348 24.2599 8.64362 23.8305 9.85844 24.5155C11.0733 25.2005 11.5028 26.7407 10.8177 27.9555C7.14621 34.4665 5.0505 41.9847 5.0505 50C5.0505 74.8249 25.1751 94.9495 50 94.9495C74.8249 94.9495 94.9495 74.8249 94.9495 50C94.9495 25.1751 74.8249 5.0505 50 5.0505C48.6053 5.0505 47.4747 3.91991 47.4747 2.52525Z"
        fill="#EB52A1"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </SpinnerContainer>
)
