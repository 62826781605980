import React, { useState } from 'react'
import { useAppChain } from 'src/hooks/useUserStore'
import { lockingStore } from 'src/store/lockingStore'
import { safeWait } from 'src/store/transactionsStore'

import { submitVeDUCKDistributionClaim } from '../../../../contracts/veduckdistribution/contractFunctions'
import useSubject from '../../../../hooks/useSubject'
import { userStore } from '../../../../store/userStore'
import { MainBlockActionBtns } from '../../../../uiKit/MainBlock'
import { UIButton } from '../../../../uiKit/UiButton/styled'
import $BN from '../../../../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount } from '../../../../utils/utils'
import { Param, ParamName, ParamValue } from '../../../asset/components/CollateralParameters/styled'

export const ClaimBlock: React.FC<{ claimableUSDP: string }> = ({ claimableUSDP }) => {
  const signer = useSubject(userStore.signer)
  const [loading, setLoading] = useState(false)
  const { usdpAddress } = useAppChain().config

  return (
    <>
      <Param>
        <ParamName>Earned:</ParamName>
        <ParamValue>
          {getDisplayAmountFromAtomicAmount(claimableUSDP, usdpAddress, true)} USDP
        </ParamValue>
      </Param>
      <MainBlockActionBtns>
        <UIButton
          disabled={$BN(claimableUSDP).lte(0) || loading}
          onClick={() => {
            setLoading(true)
            safeWait(submitVeDUCKDistributionClaim(signer))
              .then(() => lockingStore.fetchTokensParams(userStore.address.getValue()))
              .then(() => setLoading(false))
          }}
        >
          Claim
        </UIButton>
      </MainBlockActionBtns>
    </>
  )
}
