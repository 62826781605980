import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppChain } from 'src/hooks/useUserStore'

import { NETWORK_LIST } from '../../../constants/network'
import useSubject from '../../../hooks/useSubject'
import { collateralsStore } from '../../../store/collateralsStore'
import Spinner from '../../../uiKit/Spinner'
import {
  ChainSelectorContainer,
  ChainSelectorCurrent,
  ChainSelectorOption,
  ChainSelectorOptions,
} from './styled'

export const ChainSelector: React.FC = () => {
  const navigate = useNavigate()
  const collateralsLoading = useSubject<boolean>(collateralsStore.loading)
  const appChain = useAppChain()
  const [showOptions, setShowOptions] = useState(false)

  function documentClickListener() {
    setShowOptions(false)
  }

  useEffect(() => {
    if (!showOptions) return

    document.addEventListener('click', documentClickListener)
    return () => document.removeEventListener('click', documentClickListener)
  }, [showOptions])

  return (
    <ChainSelectorContainer onClick={(e) => e.nativeEvent.stopImmediatePropagation()}>
      <ChainSelectorCurrent
        showOptions={showOptions}
        onClick={() => !collateralsLoading && setShowOptions(!showOptions)}
      >
        {appChain.config.name}
        {collateralsLoading && <Spinner />}
      </ChainSelectorCurrent>
      {showOptions && (
        <ChainSelectorOptions>
          {NETWORK_LIST.filter((chain) => chain.id !== appChain.id).map((chain) => (
            <ChainSelectorOption
              key={chain.id}
              onClick={() => {
                setShowOptions(false)
                // If there is no link to another UP domain in the chain config,
                // switch it dynamically within the current page.
                if (!chain.unitProtocolLink) {
                  navigate(`/${chain.urlRepresentation}`)
                }
              }}
            >
              {chain.name}
            </ChainSelectorOption>
          ))}
        </ChainSelectorOptions>
      )}
    </ChainSelectorContainer>
  )
}
