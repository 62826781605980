import React from 'react'
import styled from 'styled-components'

import { IS_GNO } from '../../../constants/constants'
import { protocolStableSymbol } from '../../../constants/usdp'
import { useAppChain } from '../../../hooks/useUserStore'
import { isGnosis } from '../../../store/userStore'
import { Modal } from '../../../uiKit/Modal'
import { color } from '../../../uiKit/theme'
import { ChainSelector } from '../ChainSelector'
import menu from './menu.json'
import {
  MenuAbout,
  MenuCol,
  MenuContainer,
  MenuExternalLink,
  MenuNavItem,
  MenuTtl,
  SocialLink,
  SocialLinksContainer,
} from './styled'

const Divider = styled.div`
  height: 0.1rem;
  width: 100%;
  margin: 4rem 0 5rem;
  background: ${color.white};
`

export const Menu: React.FC<{ onCloseMenu: () => void }> = ({ onCloseMenu }) => {
  const appChain = useAppChain()
  const externalLinks2 = menu.externalLinks2.filter(
    (el) => !el.chain || el.chain.includes(appChain.id),
  )

  return (
    <Modal onCloseModal={onCloseMenu} forMenu>
      <MenuContainer>
        {!IS_GNO && <ChainSelector />}
        <MenuCol>
          <MenuTtl>Menu</MenuTtl>
          {menu.mainNav
            .filter((el) => !el.chain || el.chain === appChain.id)
            .map((el) => (
              <MenuNavItem key={el.name} to={el.link}>
                {el.name}
              </MenuNavItem>
            ))}
          {menu.externalLinks
            .filter((el) => !el.chain || el.chain.includes(appChain.id))
            .map((el) => (
              <MenuExternalLink
                key={el.name}
                href={el.link[appChain.id] || el.link.default}
                target="_blank"
                rel="noopener noreferrer"
              >
                {el.name.replace('{CurrentStableSymbol}', protocolStableSymbol)}
              </MenuExternalLink>
            ))}
          {externalLinks2.length ? <Divider /> : null}
          {menu.externalLinks2
            .filter((el) => !el.chain || el.chain.includes(appChain.id))
            .map((el) => (
              <MenuExternalLink
                key={el.name}
                href={el.link[appChain.id] || el.link.default}
                target="_blank"
                rel="noopener noreferrer"
              >
                {el.name.replace('{CurrentStableSymbol}', protocolStableSymbol)}
              </MenuExternalLink>
            ))}
        </MenuCol>
        <MenuCol>
          <MenuTtl>About us</MenuTtl>
          {IS_GNO || isGnosis() ? (
            <MenuAbout>
              Unit Protocol lets you use a huge selection of tokens as collateral to borrow
              liquidity in the form of USG stablecoin.
              <br />
              <br />
              Unit Protocol offers optimized solutions which let users obtain liquidity from a large
              variety of cryptoassets (including LP tokens). We have 160+ different tokens available
              which you can use as collaterals to mint USG stable.
              <br />
              <br />
              What you do with the USG you borrow is entirely up to you. Borrow more tokens, stake
              in your favourite projects, cash out – it&apos;s your call. There&apos;s no shortage
              of opportunities to make your crypto do more!
              <br />
              <br />
              The protocol is currently on several blockchains including Ethereum, BSC and Fantom,
              with a view to launching on more networks soon.
            </MenuAbout>
          ) : (
            <MenuAbout>
              Unit Protocol lets you use a huge selection of tokens as collateral to borrow
              liquidity in the form of USDP stablecoin.
              <br />
              <br />
              Unit Protocol offers optimized solutions which let users obtain liquidity from a large
              variety of cryptoassets (including LP tokens). We have 160+ different tokens available
              which you can use as collaterals to mint USDP stable.
              <br />
              <br />
              What you do with the USDP you borrow is entirely up to you. Borrow more tokens, stake
              in your favourite projects, cash out – it&apos;s your call. There&apos;s no shortage
              of opportunities to make your crypto do more!
              <br />
              <br />
              The protocol is currently on several blockchains including Ethereum, BSC and Fantom,
              with a view to launching on more networks soon.
            </MenuAbout>
          )}
        </MenuCol>
        <MenuCol>
          <MenuTtl>Follow</MenuTtl>
          <SocialLinksContainer>
            {!IS_GNO && (
              <SocialLink
                href="https://www.coingecko.com/en/coins/unit-protocol-duck"
                target="_blank"
                rel="noopener noreferrer"
              >
                coingecko
              </SocialLink>
            )}
            {!IS_GNO && (
              <SocialLink href="https://t.me/unit_en" target="_blank" rel="noopener noreferrer">
                telegram
              </SocialLink>
            )}
            {!IS_GNO && (
              <SocialLink
                href="https://discord.gg/t6rWn85fu7"
                target="_blank"
                rel="noopener noreferrer"
              >
                discord
              </SocialLink>
            )}
            {!IS_GNO && (
              <SocialLink
                href="https://twitter.com/unitprotocol"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter
              </SocialLink>
            )}
            <SocialLink
              href="https://github.com/unitprotocol/"
              target="_blank"
              rel="noopener noreferrer"
            >
              github
            </SocialLink>
          </SocialLinksContainer>
        </MenuCol>
      </MenuContainer>
    </Modal>
  )
}
