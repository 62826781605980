import styled from 'styled-components'

import { borderRadius, breakpoint, color } from '../../../../../uiKit/theme'

export const CollateralsListSearchContainer = styled.div`
  position: relative;
  flex-grow: 1;
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-bottom: 2.5rem;
    flex-grow: 0;
  }
`

export const StyledCollateralsListSearch = styled.input`
  padding: 2rem 7rem 2rem 4rem;
  border: none;
  background: ${color.white};
  font-size: 2rem;
  border-radius: ${borderRadius.normal};
  font-weight: bold;
  width: 100%;
  &:focus {
    box-shadow: none;
  }
  ::placeholder {
    color: #808080;
  }
`

export const CollateralsListSearchIcon = styled.img`
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
`
