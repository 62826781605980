import abi from 'src/contracts/abis/AggregatorInterface.json'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackChainlinkAggregatorAnswerUpdated = ({ callback, current, roundId, contract }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'AnswerUpdated',
    params: { current, roundId, contract },
    topic: '0x0559884fd3a460db3073b7fc896cc77986f16e378210ded43186175bf646fc5f',
    namespace: 'chainlinkAggregator',
  })

export const trackChainlinkAggregatorNewRound = ({ callback, roundId, startedBy, contract }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'NewRound',
    params: { roundId, startedBy, contract },
    topic: '0x0109fc6f55cf40689f02fbaad7af7fe7bbac8a3d2186600afc7d3e10cac60271',
    namespace: 'chainlinkAggregator',
  })

export const trackChainlinkAggregatorOwnershipTransferRequested = ({
  callback,
  from,
  to,
  contract,
}) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'OwnershipTransferRequested',
    params: { from, to, contract },
    topic: '0xed8889f560326eb138920d842192f0eb3dd22b4f139c87a2c57538e05bae1278',
    namespace: 'chainlinkAggregator',
  })

export const trackChainlinkAggregatorOwnershipTransferred = ({ callback, from, to, contract }) =>
  eventTracker.trackEvent({
    callback,
    contract,
    abi,
    name: 'OwnershipTransferred',
    params: { from, to, contract },
    topic: '0x8be0079c531659141344cd1fd0a4f28419497f9722a3daafe3b4186f6b6457e0',
    namespace: 'chainlinkAggregator',
  })
