import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppChain } from 'src/hooks/useUserStore'
import { StakeBlock } from 'src/views/stakeUSDP/components/StakingBlock'

import { SUSDP_ADDRESS } from '../../constants/identifiers'
import useSubject from '../../hooks/useSubject'
import { stakeUSDPStore } from '../../store/stakeUSDPStore'
import { MainBlock, MainBlockTitle, MainWrap } from '../../uiKit/MainBlock'
import { TokenName } from '../../uiKit/TokenInput/styled'
import { StyledUiTableRow, StyledUiTableRowCell } from '../../uiKit/UiTable/UiTableRow/styled'
import { getTokenSymbolByAddress } from '../../utils/token'
import { getDisplayAmountFromAtomicAmount } from '../../utils/utils'

export const StakeUSDP: React.FC = () => {
  const tokens = useSubject(stakeUSDPStore.tokens)

  useEffect(() => {
    stakeUSDPStore.init()
    return stakeUSDPStore.destroy
  }, [])

  const { usdpAddress } = useAppChain().config

  return (
    <MainWrap>
      <MainBlock pad="small">
        <span style={{ fontSize: '3rem' }}>
          DUCK staking V2 is live at <Link to="/stakingv2">duck.limo/stakingv2</Link>
        </span>
      </MainBlock>
      <StakeBlock tokens={tokens} />
      <MainBlock pad="small">
        <MainBlockTitle>Your balances</MainBlockTitle>
        <StyledUiTableRow>
          <StyledUiTableRowCell>
            <TokenName>{getTokenSymbolByAddress(usdpAddress)}</TokenName>
          </StyledUiTableRowCell>
          <StyledUiTableRowCell>
            {getDisplayAmountFromAtomicAmount(tokens[usdpAddress].balance, usdpAddress)}
          </StyledUiTableRowCell>
          <StyledUiTableRowCell />
        </StyledUiTableRow>
        <StyledUiTableRow>
          <StyledUiTableRowCell>
            <TokenName>{getTokenSymbolByAddress(SUSDP_ADDRESS)}</TokenName>
          </StyledUiTableRowCell>
          <StyledUiTableRowCell>
            {getDisplayAmountFromAtomicAmount(tokens[SUSDP_ADDRESS].balance, SUSDP_ADDRESS)}
          </StyledUiTableRowCell>
          <StyledUiTableRowCell />
        </StyledUiTableRow>
      </MainBlock>
    </MainWrap>
  )
}
