import { ZERO_ADDRESS } from 'src/constants/constants'
import { userStore } from 'src/store/userStore'
import { ChainVendor, isChainSupported } from 'src/utils/chainVendor'

import useSubject from './useSubject'

export const useChainId = (): number => useSubject<number>(userStore.chainId)
export const useAppChain = (): ChainVendor => useSubject<ChainVendor>(userStore.appChain)

/** Returns true when the network selected in the web app matches the network in metamask (or another wallet) */
export const useIsSameNetworks = (): boolean => useChainId() === useAppChain().id

/** Returns true when the network selected in the metamask (or another wallet) supported by the app */
export const useIsSupportedNetwork = (): boolean => isChainSupported(useChainId())

export const getIsConnected = (address: string): boolean => address && address !== ZERO_ADDRESS

export const useIsConnected = (): boolean => getIsConnected(useSubject<string>(userStore.address))
