import styled from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../../../uiKit/theme'

export const FastFiltersContainer = styled.div`
  margin-bottom: 2.5rem;
  display: none;
  @media screen and (min-width: ${breakpoint.tablet}) {
    display: flex;
  }
`

export const FastFiltersItem = styled.div`
  background: ${color.white};
  border-radius: ${borderRadius.normal};
  width: calc(50% - 0.5rem);
  font-size: 3rem;
  ${fontFamily.maru};
  padding: 1.75rem 2rem;
  cursor: pointer;
  :not(:last-child) {
    margin-right: 1rem;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    width: 43.5rem;
  }
`
