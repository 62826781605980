import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/CDPManagerFallback.json'

export function getCdpManagerFallbackContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.cdpManagerFallback, abi, provider)
}

export function getCdpManagerFallbackDENOMINATOR_1E5() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.DENOMINATOR_1E5().catch((e) => {
    console.error('Failed to call DENOMINATOR_1E5 in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackQ112() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.Q112().catch((e) => {
    console.error('Failed to call Q112 in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackCdpRegistry() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.cdpRegistry().catch((e) => {
    console.error('Failed to call cdpRegistry in cdpManagerFallback:', e)
  })
}

export function submitCdpManagerFallbackExit(
  asset,
  assetAmount,
  usdpAmount,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'exit', [
    asset,
    assetAmount,
    usdpAmount,
    proofData,
    { ...options },
  ])
}

export function submitCdpManagerFallbackExit_targetRepayment(
  asset,
  assetAmount,
  repayment,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'exit_targetRepayment', [
    asset,
    assetAmount,
    repayment,
    proofData,
    { ...options },
  ])
}

export function getCdpManagerFallbackGetCollateralUsdValue_q112(asset, owner, proofData) {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.getCollateralUsdValue_q112(asset, owner, proofData).catch((e) => {
    console.error('Failed to call getCollateralUsdValue_q112 in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackIsLiquidatablePosition(asset, owner, proofData) {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.isLiquidatablePosition(asset, owner, proofData).catch((e) => {
    console.error('Failed to call isLiquidatablePosition in cdpManagerFallback:', e)
  })
}

export function submitCdpManagerFallbackJoin(
  asset,
  assetAmount,
  usdpAmount,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'join', [
    asset,
    assetAmount,
    usdpAmount,
    proofData,
    { ...options },
  ])
}

export function getCdpManagerFallbackLiquidationPrice_q112(asset, owner) {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.liquidationPrice_q112(asset, owner).catch((e) => {
    console.error('Failed to call liquidationPrice_q112 in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackOracleRegistry() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.oracleRegistry().catch((e) => {
    console.error('Failed to call oracleRegistry in cdpManagerFallback:', e)
  })
}

export function submitCdpManagerFallbackTriggerLiquidation(
  asset,
  owner,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'triggerLiquidation', [asset, owner, proofData, { ...options }])
}

export function submitCdpManagerFallbackUnwrapAndExit(
  wrappedAsset,
  assetAmount,
  usdpAmount,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'unwrapAndExit', [
    wrappedAsset,
    assetAmount,
    usdpAmount,
    proofData,
    { ...options },
  ])
}

export function submitCdpManagerFallbackUnwrapAndExitTargetRepayment(
  wrappedAsset,
  assetAmount,
  repayment,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'unwrapAndExitTargetRepayment', [
    wrappedAsset,
    assetAmount,
    repayment,
    proofData,
    { ...options },
  ])
}

export function getCdpManagerFallbackUsdp() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.usdp().catch((e) => {
    console.error('Failed to call usdp in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackUtilizationRatio(asset, owner, proofData) {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.utilizationRatio(asset, owner, proofData).catch((e) => {
    console.error('Failed to call utilizationRatio in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackVault() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.vault().catch((e) => {
    console.error('Failed to call vault in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackVaultManagerBorrowFeeParameters() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.vaultManagerBorrowFeeParameters().catch((e) => {
    console.error('Failed to call vaultManagerBorrowFeeParameters in cdpManagerFallback:', e)
  })
}

export function getCdpManagerFallbackVaultManagerParameters() {
  const contract = getCdpManagerFallbackContract(getChain().ethersProvider)
  return contract.vaultManagerParameters().catch((e) => {
    console.error('Failed to call vaultManagerParameters in cdpManagerFallback:', e)
  })
}

export function submitCdpManagerFallbackWrapAndJoin(
  wrappedAsset,
  assetAmount,
  usdpAmount,
  proofData,
  signer,
  options = {},
) {
  const contract = getCdpManagerFallbackContract(signer)
  return sendTransaction(contract, 'wrapAndJoin', [
    wrappedAsset,
    assetAmount,
    usdpAmount,
    proofData,
    { ...options },
  ])
}
