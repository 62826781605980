import styled from 'styled-components'

import { Attention } from '../components/Attention'
import { borderRadius, breakpoint, color, fontFamily } from './theme'

export const MainBlock = styled.div<{ pad?: 'small' }>`
  background: ${color.white};
  border-radius: ${borderRadius.normal};
  margin-bottom: 1.2rem;
  padding: 4rem 2rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding: 3rem 6rem;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    padding: ${(p) => (p.pad === 'small' ? '5rem' : '5rem 15rem')};
  }
  ${Attention} {
    font-size: 1.8rem;
    :first-child {
      margin-top: -2.5rem;
    }
    :last-child {
      margin-bottom: 3rem;
    }
  }
`

export const MainBlockTitle = styled.h3`
  color: ${color.black};
  display: flex;
  align-items: center;
  font-size: 3rem;
  ${fontFamily.maru};
  letter-spacing: -0.01em;
  margin: 0 0 1.75em;
  width: 100%;
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 4rem;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    font-size: 4.3rem;
  }
`

export const MainBlockActionBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const MainWrap = styled.div<{ large?: boolean }>`
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoint.desktop}) {
    width: ${(p) => (p.large ? '120rem' : '90rem')};
  }
`
