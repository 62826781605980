import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/UniswapV3Oracle.json'

export function getUniswapV3OracleContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function getUniswapV3OracleAssetToUsd(contractAddress, baseAsset, amount) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.assetToUsd(baseAsset, amount).catch((e) => {
    console.error('Failed to call assetToUsd in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleDefaultPoolFee(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.defaultPoolFee().catch((e) => {
    console.error('Failed to call defaultPoolFee in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleDefaultQuoteAsset(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.defaultQuoteAsset().catch((e) => {
    console.error('Failed to call defaultQuoteAsset in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleDefaultTWAPPeriod(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.defaultTWAPPeriod().catch((e) => {
    console.error('Failed to call defaultTWAPPeriod in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleFactory(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.factory().catch((e) => {
    console.error('Failed to call factory in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleGetOracle(contractAddress, asset) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.getOracle(asset).catch((e) => {
    console.error('Failed to call getOracle in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleOracleRegistry(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.oracleRegistry().catch((e) => {
    console.error('Failed to call oracleRegistry in uniswapV3Oracle:', e)
  })
}

export function getUniswapV3OracleQuoteParams(contractAddress, addressInput1) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.quoteParams(addressInput1).catch((e) => {
    console.error('Failed to call quoteParams in uniswapV3Oracle:', e)
  })
}

export function submitUniswapV3OracleSetDefaultQuoteAsset(
  contractAddress,
  quoteAsset,
  signer,
  options = {},
) {
  const contract = getUniswapV3OracleContract(signer, contractAddress)
  return sendTransaction(contract, 'setDefaultQuoteAsset', [quoteAsset, { ...options }])
}

export function submitUniswapV3OracleSetDefaultTWAPPeriod(
  contractAddress,
  twapPeriod,
  signer,
  options = {},
) {
  const contract = getUniswapV3OracleContract(signer, contractAddress)
  return sendTransaction(contract, 'setDefaultTWAPPeriod', [twapPeriod, { ...options }])
}

export function submitUniswapV3OracleSetQuoteParams(
  contractAddress,
  baseAsset,
  quoteP,
  signer,
  options = {},
) {
  const contract = getUniswapV3OracleContract(signer, contractAddress)
  return sendTransaction(contract, 'setQuoteParams', [baseAsset, quoteP, { ...options }])
}

export function getUniswapV3OracleVaultParameters(contractAddress) {
  const contract = getUniswapV3OracleContract(getChain().ethersProvider, contractAddress)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in uniswapV3Oracle:', e)
  })
}
