import { Web3Provider } from '@ethersproject/providers'
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import debug from 'debug'
import { isWalletLocked } from 'src/utils/wallet'
import { EventEmitter } from 'src/utils/EventEmitter'
import { NotNullValues } from 'src/types/objectHelpers'
import { userStore } from '../store/userStore'
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider'
import EthereumProvider from '@walletconnect/ethereum-provider'
import { NETWORK_LIST } from '../constants/network'

const log = debug('utils:web3Controller')

const ethereumProviderOptions: EthereumProviderOptions = {
  projectId: '0f23acfb130ecdad88eae4f65292c9d8',
  chains: NETWORK_LIST.map((network) => network.id),
  showQrModal: true,
  qrModalOptions: {
    explorerExcludedWalletIds: 'ALL',
  },
  events: ['connect', 'disconnect'],
  methods: [
    'eth_sendTransaction',
    'eth_signTransaction',
    'eth_sign',
    'personal_sign',
    'eth_signTypedData',
  ],
}

export type Web3ControllerState = {
  walletProvider: null | Web3Provider
  userAddress: null | string
  isConnecting: boolean
  chainId: null | number
  version: number
}

export type ConnectedWeb3ControllerState = NotNullValues<Web3ControllerState>

export type Web3Controller = ReturnType<typeof getWeb3Controller>

/**
 * This controller is responsible for connecting/disconnecting wallet account,
 * storing active account address and (un)subscribing to wallet events.
 */
export const getWeb3Controller = (initialState: Web3ControllerState, web3Modal: Web3Modal) => {
  let state = initialState
  let unsubscribe = () => {}
  let walletconnectProvider: EthereumProvider

  const emitter = new EventEmitter<Web3ControllerState>()

  const getState = () => state
  const setState = (newState: Partial<Web3ControllerState>) => {
    state = { ...state, ...newState, version: state.version + 1 }
    emitter.emit(state)
  }

  const init = async () => {
    walletconnectProvider = await EthereumProvider.init(ethereumProviderOptions)

    if (walletconnectProvider && walletconnectProvider.session) {
      return connect('walletconnect')
    }

    if (web3Modal.cachedProvider && !isWalletLocked()) {
      return connect()
    }
  }

  const connect = async (walletId?: string) => {
    userStore.setLoading(true)
    let newWalletProvider: Web3Provider
    try {
      log('connect start')
      setState({ isConnecting: true })

      if (walletId === 'walletconnect') {
        if (!walletconnectProvider.session) await walletconnectProvider.connect()
        newWalletProvider = new ethers.providers.Web3Provider(walletconnectProvider)
      } else {
        const instance = await (walletId ? web3Modal.connectTo(walletId) : web3Modal.connect())
        newWalletProvider = new ethers.providers.Web3Provider(instance)
      }

      const [accounts, network, signer] = await Promise.all([
        newWalletProvider.listAccounts(),
        newWalletProvider.getNetwork(),
        newWalletProvider.getSigner(),
      ])
      log('connect', accounts, network)

      subscribe(newWalletProvider)
      setState({
        isConnecting: false,
        chainId: network.chainId,
        userAddress: accounts[0] || '',
        walletProvider: newWalletProvider,
      })
      userStore.setAddress(accounts[0])
      userStore.setSigner(signer)
      userStore.setChainId(network.chainId)
      userStore.setLoading(false)
    } catch (err) {
      console.error('components:Web3CtxProvider caught error:', err)
      setState({ isConnecting: false })
      disconnect()
    }
  }

  const subscribe = (provider: Web3Provider) => {
    log('subscribe')

    unsubscribe()

    const onChainChange = (chainId: number) => {
      log('chainChanged', chainId)
      connect()
    }
    const onAccountsChange = (accounts: string[]) => {
      log('accountChanged', accounts)
      if (accounts.length > 0) {
        connect()
      } else {
        disconnect()
      }
    }

    const extProvider = provider.provider as any

    extProvider.on('close', disconnect)
    extProvider.on('disconnect', disconnect)
    extProvider.on('accountsChanged', onAccountsChange)
    extProvider.on('chainChanged', onChainChange)

    unsubscribe = () => {
      log('unsubscribe')
      extProvider.removeListener('close', disconnect)
      extProvider.removeListener('disconnect', disconnect)
      extProvider.removeListener('accountsChanged', onAccountsChange)
      extProvider.removeListener('chainChanged', onChainChange)
    }
  }

  const disconnect = () => {
    log('disconnect')

    unsubscribe()
    web3Modal.clearCachedProvider()
    const extProvider = state.walletProvider?.provider as any
    // may work only in walletconnect
    extProvider?.close?.()
    extProvider?.disconnect?.()
    setState({ ...initialState, userAddress: '' })
    userStore.clearAll()
  }

  return {
    getState,
    init,
    connect,
    disconnect,
    onUpdate: emitter.listen,
  }
}

export const isWalletConnected = (
  state: Web3ControllerState,
): state is ConnectedWeb3ControllerState =>
  Boolean(state.userAddress && state.chainId && state.walletProvider)
