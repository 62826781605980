import React from 'react'

import { collateralsFilterStore } from '../../../../store/collateralsFilterStore'
import { FastFiltersContainer, FastFiltersItem } from './styled'

const FILTERS = [
  {
    label: 'Your portfolio',
    type: 'With balance',
  },
  {
    label: 'Open positions',
    type: 'Opened',
  },
]

export const FastFilters: React.FC = () => {
  function onFilterClick(filter) {
    collateralsFilterStore.setFilter(filter.type)
  }

  return (
    <FastFiltersContainer>
      {FILTERS.map((filter) => (
        <FastFiltersItem key={filter.type} onClick={() => onFilterClick(filter)}>
          {filter.label}
        </FastFiltersItem>
      ))}
    </FastFiltersContainer>
  )
}
