import { BehaviorSubject } from 'rxjs'
import { getChainConfig } from 'src/store/userStore'

import { getErc20TokenBalanceOf } from '../contracts/erc20token/contractFunctions'
import { trackErc20TokenTransfer } from '../contracts/erc20token/eventListeners'

export const usdpStore = {
  balance: new BehaviorSubject<string>('0'),

  init(owner: string): void {
    fetchAndTrackUsdpBalance(owner)
  },
}

function fetchAndTrackUsdpBalance(owner: string): void {
  const { usdpAddress } = getChainConfig()
  getErc20TokenBalanceOf(usdpAddress, owner).then((res) => usdpStore.balance.next(res.toString()))
  initializeBalanceTrackingForUsdp(owner, usdpAddress)
}

function initializeBalanceTrackingForUsdp(wallet: string, usdpAddress: string): void {
  trackErc20TokenTransfer(
    {
      callback: async (): Promise<void> => {
        const balance = await getErc20TokenBalanceOf(usdpAddress, wallet)
        usdpStore.balance.next(balance.toString())
      },
      contract: usdpAddress,
      src: wallet,
      dst: '',
    },
    wallet,
  )
  trackErc20TokenTransfer(
    {
      callback: async (): Promise<void> => {
        const balance = await getErc20TokenBalanceOf(usdpAddress, wallet)
        usdpStore.balance.next(balance.toString())
      },
      contract: usdpAddress,
      src: '',
      dst: wallet,
    },
    wallet,
  )
}
