import styled from 'styled-components'

export const StyledUiTableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  :not(:last-child) {
    margin-bottom: 5rem;
  }
`

export const StyledUiTableRowCell = styled.div`
  flex: 1 50px;
  font-size: 3rem;
  :first-child {
    flex: 1 30%;
  }
`
