import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useIsConnected } from 'src/hooks/useUserStore'
import { cdpStore } from 'src/store/cdpStore'
import { CDPList } from 'src/types/cdp'
import { StoreCollaterals } from 'src/types/collaterals'

import { ClaimRewards } from '../../components/CDPActions/ClaimRewards'
import { WrapUnwrap } from '../../components/CDPActions/WrapUnwrap'
import { InfoBlock } from '../../components/InfoBlock'
import { SHIBA_WITHOUT_REWARDS } from '../../constants/identifiers'
import { protocolStableSymbol } from '../../constants/usdp'
import { subscriptionsForCollateral } from '../../controllers/StorageSubscriptions'
import useSubject from '../../hooks/useSubject'
import { collateralsStore } from '../../store/collateralsStore'
import currentCollateralStore from '../../store/currentCollateralStore'
import { MainBlock, MainBlockTitle, MainWrap } from '../../uiKit/MainBlock'
import { CollateralParameters } from './components/CollateralParameters'
import { CurrentCDP } from './components/CurrentCDP'
import { DepositAndBorrow } from './components/DepositAndBorrow'
import { UserBalances } from './components/UserBalances'
import { WithdrawAndRepay } from './components/WithdrawAndRepay'

export const AssetPage: React.FC = () => {
  const { assetName } = useParams<{ assetName: string }>()

  const shibFirstText = `
      Lock Shibaswap Liquidity Provider (SSLP) tokens as collateral to borrow ${protocolStableSymbol} and earn <a
        href='https://etherscan.io/address/0x9813037ee2218799597d83d4a5b6f3b6778218d9'
        target='_blank'
        rel='noreferrer'
      >BONE</a> token rewards.
      Learn more in our <a
        href='https://docs.duck.limo/understanding-usdp/liquid-farming-shibaswap-liquidity-provider-tokens-as-collateral'
        target='_blank'
        rel='noreferrer'
      >Docs</a>
    `

  const shibSecondText = `
      <b>NOTICE:</b> At the moment, 33% of earned rewards are claimable by the user at any point in time,
      but the remaining 67% will be locked in the 
      <a
        href='https://etherscan.io/address/0xa404f66b9278c4ab8428225014266b4b239bcdc7'
        target='_blank'
        rel='noreferrer'
      >Bone Locker</a> contract for 6 months. When this period ends, the locked rewards become claimable.
      <br />
      <br />
      10% of the BONE token rewards are allocated to the Unit Protocol treasury.
    `

  const collaterals = useSubject<StoreCollaterals>(collateralsStore.collaterals)
  const cdps = useSubject<CDPList>(cdpStore.cdpList)
  const collateralsStoreLoading = useSubject<boolean>(collateralsStore.loading)
  const isConnected = useIsConnected()
  const currentCollateralAddress = useSubject<string>(currentCollateralStore.address)

  const collateral = collaterals[currentCollateralAddress]
  const currentCdp = cdps[currentCollateralAddress]

  useEffect(() => {
    if (!collateralsStoreLoading) {
      const assetAddress = collateralsStore.getTokenAddressBySymbol(assetName)
      currentCollateralStore.setCurrentAsset(assetAddress)
    }
    return () => currentCollateralStore.setCurrentAsset(null)
  }, [assetName, collateralsStoreLoading])

  // subscriptions of collateral
  useEffect(() => {
    let subscriptions = [] as Subscription[]
    if (!collateralsStoreLoading && collateral) {
      subscriptions = subscriptionsForCollateral()
    }
    return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
  }, [collateral, collateralsStoreLoading])

  if (collateralsStoreLoading) {
    return (
      <MainWrap large>
        <MainBlock>
          <p style={{ fontSize: '3rem' }}>Loading parameters for {assetName.toUpperCase()}...</p>
        </MainBlock>
      </MainWrap>
    )
  }

  if (!collateral) {
    return (
      <MainWrap large>
        <MainBlock>
          <p style={{ fontSize: '3rem' }}>{assetName.toUpperCase()} not presented</p>
        </MainBlock>
      </MainWrap>
    )
  }

  const showRewards = !SHIBA_WITHOUT_REWARDS.includes(collateral.address) && collateral.underlying

  const showInfo = collateral.type === 'shiba' && showRewards

  return (
    <MainWrap large>
      <MainBlockTitle style={{ justifyContent: 'center' }}>
        {collateral.name || collateral.symbol}
      </MainBlockTitle>
      {showInfo && <InfoBlock content={shibFirstText} />}
      <CollateralParameters collateral={collateral} cdp={currentCdp} />
      <DepositAndBorrow collateral={collateral} cdp={currentCdp} />
      {collateral.underlying && <WrapUnwrap />}
      {isConnected && <UserBalances collateral={collateral} />}
      {isConnected && <CurrentCDP collateral={collateral} cdp={currentCdp} />}
      {showRewards && (
        <>
          {showInfo && <InfoBlock content={shibSecondText} />}
          <ClaimRewards collateral={collateral} />
        </>
      )}
      <WithdrawAndRepay collateral={collateral} cdp={currentCdp} />
    </MainWrap>
  )
}
