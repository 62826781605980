import React from 'react'

import $BN from '../utils/BigNumber'

export const LiquidationPrice: React.FC<{ liquidationPrice: string }> = ({ liquidationPrice }) => {
  if ($BN(liquidationPrice).lte(0)) {
    return <span>-</span>
  }
  return <span>${liquidationPrice}</span>
}
