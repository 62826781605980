import BigNumber from 'bignumber.js'
import React, { useEffect, useMemo, useState } from 'react'
import { searchTokenPropsInAllStores } from 'src/utils/token'

import $BN from '../../utils/BigNumber'
import {
  getAtomicAmountFromDisplayAmount,
  getDisplayAmountFromAtomicAmount,
} from '../../utils/utils'
import {
  FooterMessage,
  StyledTokenInput,
  TokenBalance,
  TokenInputContainer,
  TokenInputErrorMessage,
  TokenInputFooter,
  TokenInputHeader,
  TokenName,
} from './styled'

interface TokenInputProps {
  value: string
  tokenAddress: string
  onInput: (e: any) => void
  onMax?: (amount: string) => void
  maxBtn?: boolean
  skipCheck?: boolean
  maxValue?: BigNumber.Value
  errorMessage?: string
  footerMessage?: string | React.ReactNode
}

export const TokenInput: React.FC<TokenInputProps> = ({
  value,
  tokenAddress,
  onInput,
  onMax,
  maxBtn = true,
  maxValue,
  errorMessage,
  skipCheck = false,
  footerMessage = '',
}) => {
  const [inputError, setInputError] = useState('')

  useEffect(() => {
    if (!skipCheck && $BN(maxValue).lt(getAtomicAmountFromDisplayAmount(value, tokenAddress)))
      setInputError(errorMessage || 'Insufficient balance')
    else setInputError('')
  }, [maxValue, value, skipCheck, tokenAddress])

  const symbol = useMemo(() => {
    return searchTokenPropsInAllStores(tokenAddress).symbol
  }, [])

  return (
    <TokenInputContainer>
      <TokenInputHeader>
        <div>
          <TokenName>{symbol}</TokenName>
          {maxBtn && (
            <TokenBalance
              onClick={() => {
                onMax(getDisplayAmountFromAtomicAmount(maxValue.toString(), tokenAddress, false))
              }}
            >
              Max ({getDisplayAmountFromAtomicAmount(maxValue.toString(), tokenAddress)})
            </TokenBalance>
          )}
        </div>
        <FooterMessage>{footerMessage}</FooterMessage>
      </TokenInputHeader>
      <StyledTokenInput
        onInput={onInput}
        onChange={onInput}
        value={value}
        error={inputError && maxBtn}
      />
      <TokenInputFooter>
        {inputError && maxBtn && <TokenInputErrorMessage>{inputError}</TokenInputErrorMessage>}
      </TokenInputFooter>
    </TokenInputContainer>
  )
}
