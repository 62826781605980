import debug from 'debug'
import { BehaviorSubject } from 'rxjs'

import { coingecko } from '../api/coingecko'
import { DUCK_ADDRESS } from '../constants/identifiers'
import {
  getErc20TokenAllowance,
  getErc20TokenTotalSupply,
} from '../contracts/erc20token/contractFunctions'
import {
  trackErc20TokenApproval,
  trackErc20TokenTransfer,
} from '../contracts/erc20token/eventListeners'
import { getVeDuckLocked } from '../contracts/veduck/contractFunctions'
import {
  getVeDUCKDistributionClaimable,
  getVeDUCKDistributionLast_token_time,
  getVeDUCKDistributionTokens_per_week,
} from '../contracts/veduckdistribution/contractFunctions'
import { getBalance } from '../utils/balances'
import $BN from '../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount, getThursdayUTCTimestamp } from '../utils/utils'
import { StakeStoreTokens } from './stakeStore'
import { getChainConfig, userStore } from './userStore'

export interface LockStats {
  totalLocked: string
  totalSupply: string
  totalMinted: string
  lockEnd: number
  locked: string
}

export interface APRStats {
  apr: string
  feeAPYAverage: string
  weeklyVolume: string
  userFee: string
  feePerVeCRV: string
  distroTime: string
}

const INITIAL_TOKENS_STATE = {
  [DUCK_ADDRESS]: {
    symbol: 'DUCK',
    decimals: 18,
    logoUrl: `${process.env.PUBLIC_URL}/images/duck_logo.png`,
    balance: '0',
  },
  [getChainConfig().contracts.veDuck]: {
    symbol: 'veDUCK',
    decimals: 18,
    logoUrl: `${process.env.PUBLIC_URL}/images/duck_logo.png`,
    balance: '0',
  },
}

const log = debug('store:lockingStore')

export const lockingStore = {
  tokens: new BehaviorSubject<StakeStoreTokens>(INITIAL_TOKENS_STATE),
  claimableUSDP: new BehaviorSubject<string>('0'),
  stats: new BehaviorSubject<LockStats>({
    totalLocked: '0',
    totalSupply: '0',
    totalMinted: '0',
    lockEnd: 0,
    locked: '0',
  }),
  aprStats: new BehaviorSubject<APRStats>({
    apr: 'Loading...',
    feeAPYAverage: 'Loading...',
    feePerVeCRV: 'Loading...',
    userFee: 'Loading...',
    weeklyVolume: 'Loading...',
    distroTime: 'Loading...',
  }),
  distroTime: new BehaviorSubject<string>('Loading...'),
  storeSubscriptions: [],
  chainSubscriptions: [],
  initialized: false,
  chainConfig: getChainConfig(),

  async init(): Promise<void> {
    lockingStore.chainConfig = getChainConfig()
    lockingStore.storeSubscriptions.push(
      userStore.address.subscribe(lockingStore.fetchTokensParams),
    )
  },

  async getLockingParams(wallet: string, full = true): Promise<void> {
    let params = []
    params.push(
      getBalance(lockingStore.chainConfig.contracts.veDuck, DUCK_ADDRESS),
      getErc20TokenTotalSupply(DUCK_ADDRESS),
      getErc20TokenTotalSupply(lockingStore.chainConfig.contracts.veDuck),
    )
    if (full) {
      params.push(getVeDuckLocked(wallet))
    }
    params = await Promise.all(params)

    const [totalLocked, totalSupply, totalMinted, locked] = params

    log('locking params: ', params)

    lockingStore.stats.next({
      totalLocked: totalLocked.toString(),
      totalSupply: totalSupply.toString(),
      totalMinted: totalMinted.toString(),
      locked: locked ? locked[0].toString() : 0,
      lockEnd: Number(locked ? locked[1].toString() : 0),
    })
  },

  async _getTokensBalances(wallet: string): Promise<void> {
    const balances = await Promise.all(
      Object.keys(lockingStore.tokens.getValue()).map(async (tokenAddress) => {
        lockingStore.chainSubscriptions.push(
          await trackErc20TokenTransfer(
            {
              callback: async () => {
                const balance = (await getBalance(wallet, tokenAddress)).toString()
                await lockingStore.getLockingParams(wallet)
                lockingStore._setTokenParam(tokenAddress, balance, 'balance')
              },
              contract: tokenAddress,
              src: wallet,
              dst: '',
            },
            wallet,
          ),
          await trackErc20TokenTransfer(
            {
              callback: async () => {
                const balance = (await getBalance(wallet, tokenAddress)).toString()
                await lockingStore.getLockingParams(wallet)
                lockingStore._setTokenParam(tokenAddress, balance, 'balance')
              },
              contract: tokenAddress,
              src: '',
              dst: wallet,
            },
            wallet,
          ),
        )
        return (await getBalance(wallet, tokenAddress)).toString()
      }),
    )
    lockingStore.tokens.next({
      [DUCK_ADDRESS]: {
        ...lockingStore.tokens.getValue()[DUCK_ADDRESS],
        balance: balances[0],
      },
      [lockingStore.chainConfig.contracts.veDuck]: {
        ...lockingStore.tokens.getValue()[lockingStore.chainConfig.contracts.veDuck],
        balance: balances[1],
      },
    })
  },

  async fetchTokensParams(wallet: string): Promise<void> {
    await lockingStore.getLockingParams(wallet, !!wallet)
    if (!wallet) {
      lockingStore._resetTokensParams()
    } else {
      lockingStore.claimableUSDP.next((await getVeDUCKDistributionClaimable(wallet)).toString())
      await lockingStore.getLockingParams(wallet)
      await lockingStore._getTokensBalances(wallet)
      await lockingStore._getLockerAllowance(wallet)
    }
    lockingStore.calculateAPR()
  },

  async calculateAPR(): Promise<void> {
    const totalVeDUCK = lockingStore.stats.getValue().totalMinted
    const userVeDUCKBalance =
      lockingStore.tokens.getValue()[lockingStore.chainConfig.contracts.veDuck].balance
    // const lockedDUCK = lockingStore.stats.getValue().totalLocked
    // const totalDUCKSupply = lockingStore.stats.getValue().totalSupply
    const DUCKPrice = (await coingecko.getDuckPrice()).data['unit-protocol-duck'].usd

    // contract weekly fees
    const week = 604800
    let t = getThursdayUTCTimestamp()
    const weeklyFeesTable = []
    let total = '0'
    let fourWeekAverage = '0'
    let weeksAvg = 1
    let distroTime = '0'
    for (let i = 1; i < 5; i++) {
      const thisWeekFees = await getVeDUCKDistributionTokens_per_week(t)

      console.log('thisWeekFees', thisWeekFees)

      if ($BN(thisWeekFees.toString()).gte(0)) {
        total = $BN(thisWeekFees).plus(total).toString()

        const thisWeek = {
          date: new Date(t * 1000).toDateString(),
          fees: `$${getDisplayAmountFromAtomicAmount(
            thisWeekFees.toString(),
            lockingStore.chainConfig.contracts.veDuck,
          )}`,
          rawFees: getDisplayAmountFromAtomicAmount(
            thisWeekFees.toString(),
            lockingStore.chainConfig.contracts.veDuck,
          ),
        }
        if (i > 0 && weeksAvg < 5) {
          fourWeekAverage = $BN(thisWeekFees.toString()).div(1e18).plus(fourWeekAverage).toString()
          weeksAvg++
        }
        weeklyFeesTable.push(thisWeek)
      } else if (i > 8) {
        i = 9 // end loop
      }
      t -= week
    }

    const currentTs = Math.floor(new Date().getTime() / 1000)

    if (currentTs > t) {
      let nextdistroTime = await getVeDUCKDistributionLast_token_time()
      nextdistroTime = +nextdistroTime + 6 * 86400
      if (nextdistroTime > currentTs) {
        const d = new Date(nextdistroTime * 1000)
        distroTime = d.toDateString()
      }
    }

    const weeklyFees = weeklyFeesTable[1].rawFees
    // for future
    // const totalFees = $BN(weeklyFees).div(7)
    const feeAPYAverage = $BN(fourWeekAverage)
      .times(365)
      .times(1e20)
      .div(4)
      .div(7)
      .div(totalVeDUCK)
      .div(DUCKPrice)
      .toFixed(2)
    const weeklyVolume = $BN(weeklyFees).times(100).div(0.02).toFixed(2)
    const userFee = $BN(weeklyFees)
      .times(userVeDUCKBalance)
      .div(7)
      .div(totalVeDUCK)
      .div(DUCKPrice)
      .toFixed(2)
    const feePerVeCRV = $BN(weeklyFees).times(52).div(totalVeDUCK).div(1e18).toFixed(2)
    const feeAPY = $BN(weeklyFees).times(52).times(1e20).div(DUCKPrice).div(totalVeDUCK).toFixed(2)
    lockingStore.aprStats.next({
      apr: `${feeAPY.toString()}%`,
      feeAPYAverage,
      feePerVeCRV,
      userFee,
      weeklyVolume,
      distroTime,
    })
    lockingStore.distroTime.next(distroTime)
  },

  async _getLockerAllowance(wallet: string): Promise<void> {
    lockingStore.chainSubscriptions.push(
      await trackErc20TokenApproval(
        {
          callback: async ([event]) => {
            if (!event) return
            const tokenAllowance = BigInt(event.data).toString()
            lockingStore._setTokenParam(DUCK_ADDRESS, tokenAllowance, 'allowance')
          },
          src: wallet,
          contract: DUCK_ADDRESS,
          guy: lockingStore.chainConfig.contracts.veDuck,
        },
        wallet,
      ),
    )
    const allowance = await getErc20TokenAllowance(
      DUCK_ADDRESS,
      wallet,
      lockingStore.chainConfig.contracts.veDuck,
    )
    lockingStore.tokens.next({
      ...lockingStore.tokens.getValue(),
      [DUCK_ADDRESS]: {
        ...lockingStore.tokens.getValue()[DUCK_ADDRESS],
        allowance: allowance.toString(),
      },
    })
  },

  _setTokenParam(
    tokenAddress: string,
    value: string,
    param: 'balance' | 'allowance' | 'contractBalance',
  ): void {
    lockingStore.tokens.next({
      ...lockingStore.tokens.getValue(),
      [tokenAddress.toLowerCase()]: {
        ...lockingStore.tokens.getValue()[tokenAddress.toLowerCase()],
        [param]: value,
      },
    })
  },

  _resetTokensParams(): void {
    lockingStore.tokens.next(INITIAL_TOKENS_STATE)
  },

  destroy(): void {
    lockingStore._resetTokensParams()
    lockingStore.storeSubscriptions.forEach((subscription): void => {
      subscription.unsubscribe()
    })
    lockingStore.chainSubscriptions.forEach((sub): void => {
      sub.unsubscribe()
    })
  },
}
