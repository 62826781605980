import { color, fontFamily } from 'src/uiKit/theme'
import styled from 'styled-components'

export const ModalTransactionContainer = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: 1rem;
  user-select: none;
  margin-bottom: 3rem;
`

export const ModalMessage = styled.span`
  font-size: 3.1rem;
  font-weight: 700;
`

export const ModalText = styled.span`
  font-size: 2rem;
  opacity: 0.4;
`

export const CloseButton = styled.button`
  display: block;
  background: ${color.purple};
  color: ${color.white};
  margin-top: 1rem;
  padding: 1.8rem 5rem;
  box-shadow: none;
  border: none;
  border-radius: 1.25rem;
  ${fontFamily.maru}
  font-weight: 900;
  font-size: 2.5rem;
  cursor: pointer;
`

export const StatusIcon = styled.div<{ isCancelled?: boolean }>`
  margin-bottom: 0.5rem;
  width: 12.5rem;
  height: 12.5rem;
  background: ${(p) =>
    p.isCancelled
      ? `center no-repeat url('/images/icons/ic-close.svg'), center / contain no-repeat url('/images/icons/ic-circle.svg')`
      : `center no-repeat url('/images/icons/ic-arrow.svg'), center / contain no-repeat url('/images/icons/ic-circle.svg')`};
`
