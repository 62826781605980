import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../../uiKit/theme'
import { ChainSelectorContainer } from '../ChainSelector/styled'

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: ${breakpoint.desktop}) {
    flex-direction: row;
  }
  ${ChainSelectorContainer} {
    align-self: flex-start;
    display: block;
    @media screen and (min-width: ${breakpoint.tablet}) {
      display: none;
    }
  }
`

export const MenuCol = styled.div`
  width: 100%;
  margin-top: 4rem;
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-top: 0;
    width: auto;
    max-width: 40%;
  }
`

export const MenuTtl = styled.span`
  ${fontFamily.maru};
  display: block;
  font-size: 4.5rem;
  margin-bottom: 0.5em;
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 5.5rem;
  }
`

const MenuLinkItemsCss = css`
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: ${color.white};
  margin-bottom: 0.5em;
  font-size: 2.5rem;
  :hover {
    text-decoration: underline;
  }
`

export const MenuNavItem = styled(Link)`
  ${MenuLinkItemsCss};
`

export const MenuExternalLink = styled.a`
  ${MenuLinkItemsCss};
`

export const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  @media screen and (min-width: ${breakpoint.desktop}) {
    flex-direction: column;
  }
`

export const SocialLink = styled.a`
  display: inline-block;
  text-decoration: none;
  color: ${color.white};
  cursor: pointer;
  font-size: 2rem;
  padding: 0.8em 1em;
  border: 0.2rem solid ${color.white};
  border-radius: ${borderRadius.large};
  margin-right: 1.5rem;
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-right: 0;
  }
  &:not(:last-child) {
    margin-bottom: 0.4em;
  }
  &:hover {
    background: ${color.white};
    color: ${color.purple};
  }
`

export const MenuAbout = styled.p``
