import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/BoneLocker.json'

export function getBoneLockerContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function submitBoneLockerClaimAll(contractAddress, r, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'claimAll', [r, { ...options }])
}

export function submitBoneLockerClaimAllForUser(contractAddress, r, user, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'claimAllForUser', [r, user, { ...options }])
}

export function getBoneLockerDevLockingPeriod(contractAddress) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.devLockingPeriod().catch((e) => {
    console.error('Failed to call devLockingPeriod in boneLocker:', e)
  })
}

export function submitBoneLockerEmergencyWithdrawOwner(contractAddress, _to, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'emergencyWithdrawOwner', [_to, { ...options }])
}

export function getBoneLockerGetClaimableAmount(contractAddress, _user) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.getClaimableAmount(_user).catch((e) => {
    console.error('Failed to call getClaimableAmount in boneLocker:', e)
  })
}

export function getBoneLockerGetLeftRightCounters(contractAddress, _user) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.getLeftRightCounters(_user).catch((e) => {
    console.error('Failed to call getLeftRightCounters in boneLocker:', e)
  })
}

export function getBoneLockerLatestCounterByUser(contractAddress, addressInput1) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.latestCounterByUser(addressInput1).catch((e) => {
    console.error('Failed to call latestCounterByUser in boneLocker:', e)
  })
}

export function submitBoneLockerLock(
  contractAddress,
  _holder,
  _amount,
  _isDev,
  signer,
  options = {},
) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'lock', [_holder, _amount, _isDev, { ...options }])
}

export function getBoneLockerLockInfoByUser(contractAddress, addressInput1, uint256Input2) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.lockInfoByUser(addressInput1, uint256Input2).catch((e) => {
    console.error('Failed to call lockInfoByUser in boneLocker:', e)
  })
}

export function getBoneLockerLockingPeriod(contractAddress) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.lockingPeriod().catch((e) => {
    console.error('Failed to call lockingPeriod in boneLocker:', e)
  })
}

export function getBoneLockerOwner(contractAddress) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.owner().catch((e) => {
    console.error('Failed to call owner in boneLocker:', e)
  })
}

export function submitBoneLockerRenounceOwnership(contractAddress, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'renounceOwnership', [{ ...options }])
}

export function submitBoneLockerSetEmergencyAddr(contractAddress, _newAddr, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'setEmergencyAddr', [_newAddr, { ...options }])
}

export function submitBoneLockerSetEmergencyFlag(
  contractAddress,
  _emergencyFlag,
  signer,
  options = {},
) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'setEmergencyFlag', [_emergencyFlag, { ...options }])
}

export function submitBoneLockerSetLockingPeriod(
  contractAddress,
  _newLockingPeriod,
  _newDevLockingPeriod,
  signer,
  options = {},
) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'setLockingPeriod', [
    _newLockingPeriod,
    _newDevLockingPeriod,
    { ...options },
  ])
}

export function submitBoneLockerTransferOwnership(contractAddress, newOwner, signer, options = {}) {
  const contract = getBoneLockerContract(signer, contractAddress)
  return sendTransaction(contract, 'transferOwnership', [newOwner, { ...options }])
}

export function getBoneLockerUnclaimedTokensByUser(contractAddress, addressInput1) {
  const contract = getBoneLockerContract(getChain().ethersProvider, contractAddress)
  return contract.unclaimedTokensByUser(addressInput1).catch((e) => {
    console.error('Failed to call unclaimedTokensByUser in boneLocker:', e)
  })
}
