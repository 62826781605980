import Web3 from 'web3'

import $BN from '../utils/BigNumber'

export const HARDHAT_COLLATERALS = JSON.parse(process.env.REACT_APP_HARDHAT_COLLATERALS || '[]')

export const Q112 = BigInt('0x10000000000000000000000000000')

export const websocketOptions = {
  // Enable auto reconnection
  reconnect: {
    auto: true,
    delay: 1000, // ms
    maxAttempts: 100,
    onTimeout: false,
  },
}

export const DEFAULT_COLLATERAL_MOCK = {
  symbol: 'N/A',
  decimals: 18,
  logoUrl:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
}

export const MAX_UINT = $BN(2).pow(256).minus(1).toString()

export const LIQUIDATION_TRIGGERED_TOPICS = [
  Web3.utils.sha3('LiquidationTriggered(address,address)'),
]
export const COLLATERAL_ADDED_TOPICS = [Web3.utils.sha3('CollateralAdded(address)')]

export const ZERO_ADDRESS = `0x${'0'.repeat(40)}`

export const GAUGED_3CRV = '0x4bfb2fa13097e5312b19585042fdbf3562dc8676'
export const CRV = '0xd533a949740bb3306d119cc777fa900ba034cd52'
export const TRI_POOL_TOKEN = '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490'

export const SUSHI_FACTORY = '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac'.toLowerCase()
export const SHIBA_FACTORY = '0x115934131916c8b277dd010ee02de363c09d037c'.toLowerCase()
export const UNI_FACTORY = '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f'.toLowerCase()

export const IS_GNO = JSON.parse(process.env.REACT_APP_IS_GNO || 'false')

export const UNIT_APP = 'usdp'
