import styled from 'styled-components'

export const PeriodLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
`

export const PeriodLink = styled.div`
  font-size: 2rem;
  margin: 1rem;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`
