import bn from 'bignumber.js'
import { ethers } from 'ethers'
import { NETWORK_LIST, NetworkConfig } from 'src/constants/network'
import Web3 from 'web3'

import { getChainConfig } from '../store/userStore'

Web3.providers.WebsocketProvider.prototype.sendAsync =
  Web3.providers.WebsocketProvider.prototype.send
bn.config({ EXPONENTIAL_AT: 1e9 }) // prevents strings from being rendered in exponential notation

export const getEthersProvider = (network: NetworkConfig): never | ethers.providers.Provider => {
  if (network.ethWebsocketUrl)
    return new ethers.providers.WebSocketProvider(network.ethWebsocketUrl)
  return new ethers.providers.JsonRpcProvider(network.rpcUrls[0])
}

const makeChainExplorerGetter = (hash: string): string => {
  const { blockExplorerUrls } = getChainConfig()
  return `${blockExplorerUrls[0]}/tx/${hash}`
}

export type ChainVendor = {
  id: number
  config: NetworkConfig
  wsProvider: Web3
  ethersProvider: ethers.providers.Provider
  getAddress: (address: string) => string
  getLinkToTxExplorer: (hash: string) => string
}

export const getNetworkConfig = (id: number): NetworkConfig =>
  NETWORK_LIST.find((chain) => chain.id === id)

export const getChainVendor = (chainId: number): null | ChainVendor => {
  const network = getNetworkConfig(chainId)

  if (!network) return null

  return {
    id: network.id,
    config: network,
    wsProvider: new Web3(
      new Web3.providers.WebsocketProvider(network.ethWebsocketUrl, {
        // Enable auto reconnection
        reconnect: {
          auto: true,
          delay: 1000, // ms
          maxAttempts: 100,
          onTimeout: false,
        },
      }),
    ),
    ethersProvider: getEthersProvider(network),
    // TODO: not needed?
    getAddress: (address) => (network.mockAddress === address ? network.wethAddress : address),
    getLinkToTxExplorer: makeChainExplorerGetter,
  }
}

export const isChainSupported = (chainId: number): boolean => Boolean(getNetworkConfig(chainId))
