import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/CDPManager.json'

export function getCdpManagerContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.cdpManager, abi, provider)
}

export function getCdpManagerDENOMINATOR_1E5() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.DENOMINATOR_1E5().catch((e) => {
    console.error('Failed to call DENOMINATOR_1E5 in cdpManager:', e)
  })
}

export function getCdpManagerQ112() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.Q112().catch((e) => {
    console.error('Failed to call Q112 in cdpManager:', e)
  })
}

export function getCdpManagerWETH() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.WETH().catch((e) => {
    console.error('Failed to call WETH in cdpManager:', e)
  })
}

export function getCdpManagerCdpRegistry() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.cdpRegistry().catch((e) => {
    console.error('Failed to call cdpRegistry in cdpManager:', e)
  })
}

export function submitCdpManagerExit(asset, assetAmount, usdpAmount, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'exit', [asset, assetAmount, usdpAmount, { ...options }])
}

export function submitCdpManagerExit_Eth(ethAmount, usdpAmount, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'exit_Eth', [ethAmount, usdpAmount, { ...options }])
}

export function submitCdpManagerExit_Eth_targetRepayment(
  ethAmount,
  repayment,
  signer,
  options = {},
) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'exit_Eth_targetRepayment', [
    ethAmount,
    repayment,
    { ...options },
  ])
}

export function submitCdpManagerExit_targetRepayment(
  asset,
  assetAmount,
  repayment,
  signer,
  options = {},
) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'exit_targetRepayment', [
    asset,
    assetAmount,
    repayment,
    { ...options },
  ])
}

export function getCdpManagerGetCollateralUsdValue_q112(asset, owner) {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.getCollateralUsdValue_q112(asset, owner).catch((e) => {
    console.error('Failed to call getCollateralUsdValue_q112 in cdpManager:', e)
  })
}

export function getCdpManagerIsLiquidatablePosition(asset, owner) {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.isLiquidatablePosition(asset, owner).catch((e) => {
    console.error('Failed to call isLiquidatablePosition in cdpManager:', e)
  })
}

export function submitCdpManagerJoin(asset, assetAmount, usdpAmount, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'join', [asset, assetAmount, usdpAmount, { ...options }])
}

export function submitCdpManagerJoin_Eth(ethAmount, usdpAmount, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'join_Eth', [usdpAmount, { ...options, value: ethAmount }])
}

export function getCdpManagerLiquidationPrice_q112(asset, owner) {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.liquidationPrice_q112(asset, owner).catch((e) => {
    console.error('Failed to call liquidationPrice_q112 in cdpManager:', e)
  })
}

export function getCdpManagerOracleRegistry() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.oracleRegistry().catch((e) => {
    console.error('Failed to call oracleRegistry in cdpManager:', e)
  })
}

export function submitCdpManagerTriggerLiquidation(asset, owner, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'triggerLiquidation', [asset, owner, { ...options }])
}

export function submitCdpManagerUnwrapAndExit(
  wrappedAsset,
  assetAmount,
  usdpAmount,
  signer,
  options = {},
) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'unwrapAndExit', [
    wrappedAsset,
    assetAmount,
    usdpAmount,
    { ...options },
  ])
}

export function submitCdpManagerUnwrapAndExitTargetRepayment(
  wrappedAsset,
  assetAmount,
  repayment,
  signer,
  options = {},
) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'unwrapAndExitTargetRepayment', [
    wrappedAsset,
    assetAmount,
    repayment,
    { ...options },
  ])
}

export function getCdpManagerUsdp() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.usdp().catch((e) => {
    console.error('Failed to call usdp in cdpManager:', e)
  })
}

export function getCdpManagerUtilizationRatio(asset, owner) {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.utilizationRatio(asset, owner).catch((e) => {
    console.error('Failed to call utilizationRatio in cdpManager:', e)
  })
}

export function getCdpManagerVault() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.vault().catch((e) => {
    console.error('Failed to call vault in cdpManager:', e)
  })
}

export function getCdpManagerVaultManagerBorrowFeeParameters() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.vaultManagerBorrowFeeParameters().catch((e) => {
    console.error('Failed to call vaultManagerBorrowFeeParameters in cdpManager:', e)
  })
}

export function getCdpManagerVaultManagerParameters() {
  const contract = getCdpManagerContract(getChain().ethersProvider)
  return contract.vaultManagerParameters().catch((e) => {
    console.error('Failed to call vaultManagerParameters in cdpManager:', e)
  })
}

export function submitCdpManagerWrapAndJoin(
  wrappedAsset,
  assetAmount,
  usdpAmount,
  signer,
  options = {},
) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'wrapAndJoin', [
    wrappedAsset,
    assetAmount,
    usdpAmount,
    { ...options },
  ])
}

export function submitCdpManagerRepayAll(asset, withdraw, signer, options = {}) {
  const contract = getCdpManagerContract(signer)
  return sendTransaction(contract, 'repayAll', [asset, withdraw, { ...options }])
}
