import styled from 'styled-components'

export const StyledTableHead = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.8rem;
  font-size: 2rem;
`

export const TableCol = styled.div`
  color: rgba(0, 0, 0, 0.4);
  flex: 1 50px;
  :first-child {
    flex: 1 30%;
  }
`
