import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/IShibaSwapV2Factory.json'

export function getShibaSwapFactoryContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.shibaSwapFactory, abi, provider)
}

export function getShibaSwapFactoryAllPairs(uint256Input1) {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.allPairs(uint256Input1).catch((e) => {
    console.error('Failed to call allPairs in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryAllPairsLength() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.allPairsLength().catch((e) => {
    console.error('Failed to call allPairsLength in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryAlphaRegular() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.alphaRegular().catch((e) => {
    console.error('Failed to call alphaRegular in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryAlphaTopCoin() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.alphaTopCoin().catch((e) => {
    console.error('Failed to call alphaTopCoin in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryBetaRegular() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.betaRegular().catch((e) => {
    console.error('Failed to call betaRegular in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryBetaTopCoin() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.betaTopCoin().catch((e) => {
    console.error('Failed to call betaTopCoin in shibaSwapFactory:', e)
  })
}

export function submitShibaSwapFactoryCreatePair(tokenA, tokenB, signer, options = {}) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'createPair', [tokenA, tokenB, { ...options }])
}

export function getShibaSwapFactoryFeeTo() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.feeTo().catch((e) => {
    console.error('Failed to call feeTo in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryFeeToSetter() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.feeToSetter().catch((e) => {
    console.error('Failed to call feeToSetter in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryGetPair(addressInput1, addressInput2) {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.getPair(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call getPair in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryMigrator() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.migrator().catch((e) => {
    console.error('Failed to call migrator in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryPairCodeHash() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.pairCodeHash().catch((e) => {
    console.error('Failed to call pairCodeHash in shibaSwapFactory:', e)
  })
}

export function submitShibaSwapFactorySetFeeTo(_feeTo, signer, options = {}) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setFeeTo', [_feeTo, { ...options }])
}

export function submitShibaSwapFactorySetFeeToSetter(_feeToSetter, signer, options = {}) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setFeeToSetter', [_feeToSetter, { ...options }])
}

export function submitShibaSwapFactorySetMigrator(_migrator, signer, options = {}) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setMigrator', [_migrator, { ...options }])
}

export function submitShibaSwapFactorySetRegularCoinFee(
  _totalFee,
  _alpha,
  _beta,
  signer,
  options = {},
) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setRegularCoinFee', [_totalFee, _alpha, _beta, { ...options }])
}

export function submitShibaSwapFactorySetTopCoin(_coinAddress, _status, signer, options = {}) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setTopCoin', [_coinAddress, _status, { ...options }])
}

export function submitShibaSwapFactorySetTopCoinFee(
  _totalFee,
  _alpha,
  _beta,
  signer,
  options = {},
) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'setTopCoinFee', [_totalFee, _alpha, _beta, { ...options }])
}

export function getShibaSwapFactoryTopCoins(addressInput1) {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.topCoins(addressInput1).catch((e) => {
    console.error('Failed to call topCoins in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryTotalFeeRegular() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.totalFeeRegular().catch((e) => {
    console.error('Failed to call totalFeeRegular in shibaSwapFactory:', e)
  })
}

export function getShibaSwapFactoryTotalFeeTopCoin() {
  const contract = getShibaSwapFactoryContract(getChain().ethersProvider)
  return contract.totalFeeTopCoin().catch((e) => {
    console.error('Failed to call totalFeeTopCoin in shibaSwapFactory:', e)
  })
}

export function submitShibaSwapFactoryUpdatePairFee(
  token0,
  token1,
  _totalFee,
  _alpha,
  _beta,
  signer,
  options = {},
) {
  const contract = getShibaSwapFactoryContract(signer)
  return sendTransaction(contract, 'updatePairFee', [
    token0,
    token1,
    _totalFee,
    _alpha,
    _beta,
    { ...options },
  ])
}
