import styled from 'styled-components'

import { borderRadius, breakpoint, color } from '../../../../uiKit/theme'

export const ParamsCol = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: 50%;
  }
`

export const Param = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: 50%;
    display: inline-block;
    margin-bottom: 6.2rem;
  }
`

export const ParamInfo = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  border-radius: ${borderRadius.normal};
  color: ${color.black};
  padding: 2rem;
  font-size: 1.5rem;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  width: 40rem;
  line-height: 1.6em;
  @media screen and (min-width: ${breakpoint.phone}) {
    right: 0;
    left: auto;
  }
`

export const ParamInfoContainer = styled.div`
  position: relative;
  &:hover {
    ${ParamInfo} {
      opacity: 1;
      visibility: visible;
    }
  }
`

export const ParamName = styled.div`
  color: rgb(147, 145, 145);
  font-size: 2rem;
  display: flex;
  align-items: center;
`

export const ParamValue = styled.div<{ large?: boolean }>`
  color: ${color.black};
  font-size: ${(p) => (p.large ? '10.4rem' : '3rem')};
  margin-left: auto;
  @media screen and (min-width: ${breakpoint.tablet}) {
    margin-top: 2.2rem;
    margin-left: 0;
  }
`

export const ParamInfoIcon = styled.img`
  margin-left: 1rem;
  cursor: pointer;
`

export const BorrowLimit = styled.div`
  display: flex;
  width: 60%;
  border: 0.1rem solid ${color.gray};
  border-radius: ${borderRadius.normal};
  padding: 2.5rem;
  align-items: center;
  justify-content: space-between;
`

export const BorrowLimitIcon = styled.img`
  width: 10rem;
  height: auto;
  display: block;
`

export const BorrowLimitText = styled.div`
  color: ${color.gray};
  text-align: right;
  font-size: 1.8rem;
  width: 40%;
`
