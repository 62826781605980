import { IS_GNO } from '../constants/constants'
import { ChainId, NETWORK_LIST, NetworkConfig, urlRepresentationsList } from '../constants/network'

export const getChainConfigById = (chainId: ChainId): NetworkConfig =>
  NETWORK_LIST.find((chain) => chain.id === chainId)

export const getChainConfigByUrlRepresentation = (urlRepresentation: string): NetworkConfig =>
  NETWORK_LIST.find((chain) => chain.urlRepresentation === urlRepresentation) ||
  getChainConfigById(IS_GNO ? 100 : 1)

export const getChainRepresentationFromUrl = (): string => {
  const { pathname } = window.location
  const chainRepresentationFromUrl = pathname.split('/')[1]
  if (!urlRepresentationsList.includes(chainRepresentationFromUrl)) {
    return IS_GNO ? 'gnosis' : 'eth'
  }
  return chainRepresentationFromUrl
}
