import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/StakeUSDP.json'

export function getStakeUSDPContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.stakeUSDP, abi, provider)
}

export function getStakeUSDPDURATION() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.DURATION().catch((e) => {
    console.error('Failed to call DURATION in stakeUSDP:', e)
  })
}

export function submitStakeUSDPAddReward(reward, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'addReward', [reward, { ...options }])
}

export function getStakeUSDPAllowance(owner, spender) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.allowance(owner, spender).catch((e) => {
    console.error('Failed to call allowance in stakeUSDP:', e)
  })
}

export function submitStakeUSDPApprove(spender, amount, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'approve', [spender, amount, { ...options }])
}

export function getStakeUSDPBalanceOf(account) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.balanceOf(account).catch((e) => {
    console.error('Failed to call balanceOf in stakeUSDP:', e)
  })
}

export function submitStakeUSDPClaim(signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'claim', [{ ...options }])
}

export function submitStakeUSDPClaimAndDeposit(signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'claimAndDeposit', [{ ...options }])
}

export function getStakeUSDPCollectors(addressInput1) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.collectors(addressInput1).catch((e) => {
    console.error('Failed to call collectors in stakeUSDP:', e)
  })
}

export function getStakeUSDPDecimals() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in stakeUSDP:', e)
  })
}

export function submitStakeUSDPDecreaseAllowance(spender, subtractedValue, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'decreaseAllowance', [spender, subtractedValue, { ...options }])
}

export function submitStakeUSDPDeposit(amount, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'deposit', [amount, { ...options }])
}

export function getStakeUSDPEarned(user) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.earned(user).catch((e) => {
    console.error('Failed to call earned in stakeUSDP:', e)
  })
}

export function submitStakeUSDPExit(signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'exit', [{ ...options }])
}

export function submitStakeUSDPIncreaseAllowance(spender, addedValue, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'increaseAllowance', [spender, addedValue, { ...options }])
}

export function getStakeUSDPLastTimeRewardApplicable() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.lastTimeRewardApplicable().catch((e) => {
    console.error('Failed to call lastTimeRewardApplicable in stakeUSDP:', e)
  })
}

export function getStakeUSDPLastUpdateTime() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.lastUpdateTime().catch((e) => {
    console.error('Failed to call lastUpdateTime in stakeUSDP:', e)
  })
}

export function submitStakeUSDPMultiClaim(users, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'multiClaim', [users, { ...options }])
}

export function getStakeUSDPName() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.name().catch((e) => {
    console.error('Failed to call name in stakeUSDP:', e)
  })
}

export function getStakeUSDPOwner() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.owner().catch((e) => {
    console.error('Failed to call owner in stakeUSDP:', e)
  })
}

export function getStakeUSDPPeriodFinish() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.periodFinish().catch((e) => {
    console.error('Failed to call periodFinish in stakeUSDP:', e)
  })
}

export function submitStakeUSDPRenounceOwnership(signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'renounceOwnership', [{ ...options }])
}

export function getStakeUSDPRewardPerToken() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.rewardPerToken().catch((e) => {
    console.error('Failed to call rewardPerToken in stakeUSDP:', e)
  })
}

export function getStakeUSDPRewardPerTokenStored() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.rewardPerTokenStored().catch((e) => {
    console.error('Failed to call rewardPerTokenStored in stakeUSDP:', e)
  })
}

export function getStakeUSDPRewardRate() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.rewardRate().catch((e) => {
    console.error('Failed to call rewardRate in stakeUSDP:', e)
  })
}

export function getStakeUSDPRewards(addressInput1) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.rewards(addressInput1).catch((e) => {
    console.error('Failed to call rewards in stakeUSDP:', e)
  })
}

export function submitStakeUSDPSetCollector(collector, isCollector, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'setCollector', [collector, isCollector, { ...options }])
}

export function getStakeUSDPSymbol() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in stakeUSDP:', e)
  })
}

export function getStakeUSDPTotalSupply() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in stakeUSDP:', e)
  })
}

export function submitStakeUSDPTransfer(to, amount, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'transfer', [to, amount, { ...options }])
}

export function submitStakeUSDPTransferFrom(from, to, amount, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'transferFrom', [from, to, amount, { ...options }])
}

export function submitStakeUSDPTransferOwnership(newOwner, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'transferOwnership', [newOwner, { ...options }])
}

export function getStakeUSDPUsdp() {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.usdp().catch((e) => {
    console.error('Failed to call usdp in stakeUSDP:', e)
  })
}

export function getStakeUSDPUserRewardPerTokenPaid(addressInput1) {
  const contract = getStakeUSDPContract(getChain().ethersProvider)
  return contract.userRewardPerTokenPaid(addressInput1).catch((e) => {
    console.error('Failed to call userRewardPerTokenPaid in stakeUSDP:', e)
  })
}

export function submitStakeUSDPWithdraw(amount, signer, options = {}) {
  const contract = getStakeUSDPContract(signer)
  return sendTransaction(contract, 'withdraw', [amount, { ...options }])
}
