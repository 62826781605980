import BigNumber from 'bignumber.js'
import debug from 'debug'
import { BehaviorSubject } from 'rxjs'
import { CHAIN_ID } from 'src/constants/network'
import { ORACLE_BY_TYPE } from 'src/constants/oracles'
import { collateralsStore } from 'src/store/collateralsStore'

import { Q112 } from '../constants/constants'
import { TOKENS_WITH_UNIQUE_PRECISION } from '../constants/identifiers'
import { getBearingAssetOracleBearingToUnderlying } from '../contracts/bearingassetoracle/contractFunctions'
import { getOracleRegistryOracleTypeByAsset } from '../contracts/oracleregistry/contractFunctions'
import {
  getUniswapPairGetReserves,
  getUniswapPairToken0,
  getUniswapPairToken1,
} from '../contracts/uniswappair/contractFunctions'
import { getUniswapV3OracleAssetToUsd } from '../contracts/uniswapv3oracle/contractFunctions'
import { Collateral } from '../types/collaterals'
import { Price, PricesState } from '../types/oracles'
import $BN from '../utils/BigNumber'
import {
  getAssetAvgPriceInEth_Keydonix,
  getAssetPriceProof,
  getMainAssetPriceFastQ112,
  getPoolTokenPriceFastQ112,
} from '../utils/oracle'
import { getDisplayAmountFromAtomicAmount, sqrt } from '../utils/utils'
import { getLatestBlockNumber } from './blockStore'
import chainlinkAggregatorStore from './chainlinkAggregatorStore'
import currentCollateralStore from './currentCollateralStore'
import { getChain, getChainConfig, userStore } from './userStore'

const log = debug('store:oraclesStore')
const ONE = BigInt(1e18)

const oraclesStore = new BehaviorSubject<PricesState>({
  loading: false,
  loaded: false,
  prices: {},
})

async function getPriceForAssetInEth_Keydonix(assetAddress: string): Promise<string[]> {
  const { wethAddress } = getChainConfig()
  if (assetAddress === wethAddress)
    return [BigInt('0x10000000000000000000000000000').toString(), assetAddress]

  const token = collateralsStore.getCollateralProps(assetAddress)
  const poolTokens = token.underlying?.lpUnderlyings || token.lpUnderlyings
  let priceInEth
  let proofAddress

  if (poolTokens) {
    const token0Addr = await getUniswapPairToken0(assetAddress)
    const token1Addr = await getUniswapPairToken1(assetAddress)
    const [reserve0, reserve1] = await getUniswapPairGetReserves(assetAddress)
    let tokenReserve: bigint
    let ethReserve: bigint
    if (token0Addr.toLowerCase() === wethAddress) {
      proofAddress = token1Addr.toLowerCase()
      tokenReserve = BigInt(reserve1)
      ethReserve = BigInt(reserve0)
    } else if (token1Addr.toLowerCase() === wethAddress) {
      proofAddress = token0Addr.toLowerCase()
      tokenReserve = BigInt(reserve0)
      ethReserve = BigInt(reserve1)
    }
    log('getPriceForAssetInEth_Keydonix proofAddress: ', proofAddress)
    const underlyingToken =
      collateralsStore.getCollateralProps(proofAddress) ||
      getChainConfig().collaterals[proofAddress]

    log('getPriceForAssetInEth_Keydonix underlyingToken: ', underlyingToken)

    const oracleType =
      underlyingToken?.oracleType || Number(await getOracleRegistryOracleTypeByAsset(proofAddress))

    log('getPriceForAssetInEth_Keydonix oracleType: ', oracleType)

    const avgPriceInEth = await getAssetAvgPriceInEth_Keydonix(proofAddress, oracleType)
    const currPriceInEth = (ethReserve * Q112) / tokenReserve
    let ethReserveCalc: bigint

    if (currPriceInEth < avgPriceInEth) {
      const toSqrt =
        ethReserve *
        (ethReserve * BigInt(9) + (tokenReserve * BigInt(3988000) * avgPriceInEth) / Q112)
      const ethReserveChange = (sqrt(toSqrt) - ethReserve * BigInt(1997)) / BigInt(2000)

      ethReserveCalc = ethReserve + ethReserveChange
    } else {
      const a = tokenReserve * avgPriceInEth
      const b = (a * BigInt(9)) / Q112
      const c = ethReserve * BigInt(3988000)
      const sqRoot = sqrt((a / Q112) * (b + c))
      const d = (a * BigInt(3)) / Q112
      ethReserveCalc = ethReserve - (ethReserve - (d + sqRoot) / BigInt(2000))
    }
    const lpSupply = token.underlying?.totalSupply || token.totalSupply

    priceInEth = (ethReserveCalc * BigInt(2) * Q112) / BigInt(lpSupply)
  } else {
    proofAddress = assetAddress
    priceInEth = await getAssetAvgPriceInEth_Keydonix(assetAddress, token.oracleType)
  }
  return [priceInEth.toString(), proofAddress]
}

async function getMainAssetEthPriceFastMode(assetAddress: string): Promise<string> {
  if (assetAddress === getChainConfig().wethAddress)
    return BigInt('0x10000000000000000000000000000').toString()

  const fastPrice = await getMainAssetPriceFastQ112(assetAddress)
  return fastPrice.toString()
}

async function getPoolTokenEthPriceFastMode(assetAddress: string): Promise<string> {
  const fastPrice = await getPoolTokenPriceFastQ112(assetAddress)
  return fastPrice.toString()
}

async function getBearingAssetEthPriceFastMode(assetAddress: string): Promise<string> {
  const [underlying, bearingToUnderlyingRate] = await getBearingAssetOracleBearingToUnderlying(
    assetAddress,
    ONE,
  )
  const fastPrice = await getMainAssetPriceFastQ112(underlying)
  const fastBearingPrice = (fastPrice * BigInt(bearingToUnderlyingRate)) / ONE
  return fastBearingPrice.toString()
}

const updateSubject = (obj) => {
  oraclesStore.next({
    ...oraclesStore.getValue(),
    ...obj,
  })
}

async function fetchMainAssetUsdPriceFastMode(tokenAddress: string, ethUsdPrice): Promise<Price> {
  const priceInEthQ112 = await getMainAssetEthPriceFastMode(tokenAddress)
  const token = collateralsStore.getCollateralProps(tokenAddress)
  const precision = TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()]
    ? TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()].precision
    : 2

  const priceFormatted = $BN(priceInEthQ112)
    .times(ethUsdPrice)
    .div($BN(2).pow(112))
    .div($BN(10).pow(8))
    .div($BN(10).pow(18 - token.decimals))
    .dp(precision)
    .toString()

  return {
    address: tokenAddress,
    priceInEthQ112,
    priceFormatted,
    symbol: token.symbol,
    proof: undefined,
    full: false,
  }
}

async function fetchPoolTokenUsdPriceFastMode(
  tokenAddress: string,
  ethUsdPrice: string,
): Promise<Price> {
  const priceInEthQ112 = await getPoolTokenEthPriceFastMode(tokenAddress)
  const precision = TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()]
    ? TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()].precision
    : 2
  const priceFormatted = $BN(priceInEthQ112)
    .times(ethUsdPrice)
    .div($BN(2).pow(112))
    .div($BN(10).pow(8))
    .div($BN(10).pow(18 - collateralsStore.getCollateralProps(tokenAddress).decimals))
    .dp(precision)
    .toString()

  return {
    address: tokenAddress,
    priceInEthQ112,
    priceFormatted,
    symbol: collateralsStore.getCollateralProps(tokenAddress).symbol,
    proof: undefined,
    full: false,
  }
}

async function fetchBearingAssetUsdPriceFastMode(
  tokenAddress: string,
  ethUsdPrice: string,
): Promise<Price> {
  const priceInEthQ112 = await getBearingAssetEthPriceFastMode(tokenAddress)
  const precision = TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()]
    ? TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()].precision
    : 2

  const priceFormatted = $BN(priceInEthQ112)
    .times(ethUsdPrice)
    .div($BN(2).pow(112))
    .div($BN(10).pow(8))
    .div($BN(10).pow(18 - collateralsStore.getCollateralProps(tokenAddress).decimals))
    .dp(precision)
    .toString()

  return {
    address: tokenAddress,
    priceInEthQ112,
    priceFormatted,
    symbol: collateralsStore.getCollateralProps(tokenAddress).symbol,
    proof: undefined,
    full: false,
  }
}

async function fetchAssetUsdPriceFullMode(tokenAddress, ethUsdPrice): Promise<Price> {
  const collateral = collateralsStore.getCollateralProps(tokenAddress)
  const precision = TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()]
    ? TOKENS_WITH_UNIQUE_PRECISION[tokenAddress.toLowerCase()].precision
    : 4
  const oracleType = collateral.oracleType

  log('fetchAssetUsdPriceFullMode: ', {
    tokenAddress,
    ethUsdPrice,
    oracleType,
  })

  if (getChainConfig().wethAddress === tokenAddress) {
    const priceFormatted = $BN(Q112.toString())
      .times(ethUsdPrice)
      .div($BN(2).pow(112))
      .div($BN(10).pow(8))
      .div($BN(10).pow(18 - collateralsStore.getCollateralProps(tokenAddress).decimals))
      .dp(precision)
      .toString()
    return {
      address: tokenAddress,
      priceInEthQ112: Q112.toString(),
      priceFormatted,
      symbol: collateralsStore.getCollateralProps(tokenAddress).symbol,
      full: true,
    }
  }

  if (isKeydonixOracle(oracleType) && getChain().id !== CHAIN_ID.HARDHAT) {
    log('keydonix fetching START')

    const underlyingAddress = collateral.type === 'shiba' ? collateral.underlying.address : null

    log('underlyingAddress: ', underlyingAddress)

    const [priceInEthQ112, addressForProof] = await getPriceForAssetInEth_Keydonix(
      underlyingAddress || tokenAddress,
    )

    const priceFormatted = $BN(priceInEthQ112)
      .times(ethUsdPrice)
      .div($BN(2).pow(112))
      .div($BN(10).pow(8))
      .div($BN(10).pow(18 - collateralsStore.getCollateralProps(tokenAddress).decimals))
      .dp(precision)
      .toString()

    const [proof, blockNumber] = await getAssetPriceProof(addressForProof)

    log('keydonix fetching END')

    return {
      address: tokenAddress,
      priceInEthQ112,
      priceFormatted,
      symbol: collateralsStore.getCollateralProps(tokenAddress).symbol,
      proof: proof as string[],
      blockNumber: blockNumber.toString(),
      full: true,
    }
  }

  if (isOnchainOracle(oracleType)) {
    const existing = oraclesStore.getValue().prices[collateral.address]

    if (existing && existing.full) {
      return existing
    }

    const curve = isCurveOracle(oracleType)
    const decimals = collateral.decimals

    log('fetchAssetUsdPriceFullMode isOnchainOracle is curve', curve)
    log('fetchAssetUsdPriceFullMode isOnchainOracle oracleType', oracleType)
    log('fetchAssetUsdPriceFullMode isOnchainOracle token', collateral)

    const priceInUsdQ112 = $BN(
      (
        await getUniswapV3OracleAssetToUsd(
          ORACLE_BY_TYPE[getChainConfig().id][oracleType],
          collateral.address,
          $BN(10).pow(decimals).toString(),
        )
      ).toString(),
    )
      .div(1e18)
      .toFixed(0)

    log(
      'priceFormatted',
      $BN(priceInUsdQ112).times($BN(10).pow(decimals)).div(Q112.toString()).toString(),
    )

    const priceFormatted = getDisplayAmountFromAtomicAmount(
      $BN(priceInUsdQ112).times($BN(10).pow(decimals)).div(Q112.toString()).toString(),
      collateral.address,
    )

    return {
      address: collateral.address,
      priceInEthQ112: '',
      priceInUsdQ112,
      priceFormatted,
      symbol: collateral.symbol,
      full: true,
    }
  }
}

async function fetchAssetsUsdPricesFastMode(ethUsdPrice): Promise<void> {
  let price

  const address = currentCollateralStore.address.getValue()
  const collateral = collateralsStore.getCollateralProps(address)
  const poolTokens = collateral.underlying?.lpUnderlyings || collateral.lpUnderlyings
  const oracleType = collateral.oracleType

  if (poolTokens) {
    price = await fetchPoolTokenUsdPriceFastMode(address, ethUsdPrice)
  } else if (isBearingAssetOracleSimple(oracleType)) {
    price = await fetchBearingAssetUsdPriceFastMode(address, ethUsdPrice)
  } else {
    price = await fetchMainAssetUsdPriceFastMode(address, ethUsdPrice)
  }
  persistPriceData(price)
}

async function ensurePriceAndProof(
  assetAddress: string,
  blockNumberPromise: Promise<BigNumber.Value>,
  ethUsdPrice: BigNumber.Value,
): Promise<void> {
  const prices = oraclesStore.getValue().prices
  const target = prices[assetAddress]
  if (
    target &&
    target.proof &&
    $BN(await blockNumberPromise)
      .minus(target.blockNumber)
      .lt(240)
  ) {
    return
  }
  if (target) {
    delete target.proof
    delete target.blockNumber
    persistPriceData(target)
  }
  const priceData = await fetchAssetUsdPriceFullMode(assetAddress, ethUsdPrice)
  persistPriceData(priceData)
}

function persistPriceData(priceData: Price): void {
  log('persistPriceData: ', priceData)
  const prices = { ...oraclesStore.getValue().prices }
  prices[priceData.address] = priceData
  updateSubject({ prices })
}

async function fetchPriceFastMode(ethUsdPrice: string): Promise<void> {
  updateSubject({ loading: true })
  console.time('price fetching time')
  await fetchAssetsUsdPricesFastMode(ethUsdPrice)
  updateSubject({ loading: false })
  console.timeEnd('price fetching time')
}

export function collateralExist(): Collateral | undefined {
  const collateral = currentCollateralStore.address.getValue()
  return (
    chainlinkAggregatorStore.getValue().ethUsd &&
    chainlinkAggregatorStore.getValue().ethUsd.price &&
    collateral &&
    collateralsStore.getCollateralProps(collateral)
  )
}

export async function updateOraclesStore(): Promise<void> {
  const promises = []
  if (userStore.address.getValue())
    promises.push(
      ensureFreshPrice(
        currentCollateralStore.address.getValue(),
        chainlinkAggregatorStore.getValue().ethUsd.price,
      ),
    )
  else promises.push(fetchPriceFastMode(chainlinkAggregatorStore.getValue().ethUsd.price))
  await Promise.all(promises)
}

export async function ensureFreshPrice(
  assetAddress: string,
  ethUsdPrice: BigNumber.Value,
): Promise<void> {
  const blockNumberPromise = getLatestBlockNumber()
  await ensurePriceAndProof(assetAddress, blockNumberPromise, ethUsdPrice)
}

export function isKeydonixOracle(oracleType: number): boolean {
  return [1, 2, 13, 18, 19].includes(oracleType)
}

export function isOnchainOracle(oracleType: number): boolean {
  return [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 20].includes(oracleType)
}

export function isBearingAssetOracleSimple(oracleType: number): boolean {
  return [9].includes(oracleType)
}

export function isChainlinkOracle(oracleType: number): boolean {
  return [5].includes(oracleType)
}

export function isCurveOracle(oracleType: number): boolean {
  return [10].includes(oracleType)
}

export default oraclesStore
