import BigNumber from 'bignumber.js'

import { GAUGED_3CRV } from '../constants/constants'
import {
  submitGauge3crvDeposit,
  submitGauge3crvWithdraw,
} from '../contracts/gauge3crv/contractFunctions'
import { submitWrappedSSLPWithdraw } from '../contracts/wrappedsslp/contractFunctions'
import { userStore } from '../store/userStore'
import { Collateral } from '../types/collaterals'
import { getAtomicAmountFromDisplayAmount } from './utils'

export function getWrapUnwrapAction(
  collateral: Collateral,
  amountToUnwrap: BigNumber.Value,
  amountToWrap: BigNumber.Value,
): { wrapAction?: () => void; unwrapAction?: () => void } {
  let wrapAction: () => Promise<void>
  let unwrapAction: () => Promise<void>

  const user = userStore.address.getValue()
  const signer = userStore.signer.getValue()

  if (collateral.address === GAUGED_3CRV) {
    wrapAction = () =>
      submitGauge3crvDeposit(
        getAtomicAmountFromDisplayAmount(amountToWrap, collateral.underlying.address),
        signer,
      )
    unwrapAction = () =>
      submitGauge3crvWithdraw(
        getAtomicAmountFromDisplayAmount(amountToUnwrap, collateral.address),
        signer,
      )
  } else if (collateral.type === 'shiba') {
    unwrapAction = () =>
      submitWrappedSSLPWithdraw(
        collateral.address,
        user,
        getAtomicAmountFromDisplayAmount(amountToUnwrap, collateral.address),
        signer,
      )
  } else {
    console.warn(`collateral couldn't be wrapped / unwrapped: ${collateral.address}`)
  }

  return {
    wrapAction,
    unwrapAction,
  }
}
