import BigNumber from 'bignumber.js'
import { collateralsStore } from 'src/store/collateralsStore'
import { getChainConfig } from 'src/store/userStore'

import { Q112 } from '../constants/constants'
import { getCdpViewerGetCollateralParameters } from '../contracts/cdpviewer/contractFunctions'
import { getVaultContractFunctions } from '../contracts/vault'
import blockStore from '../store/blockStore'
import { cdpStore } from '../store/cdpStore'
import chainlinkAggregatorStore from '../store/chainlinkAggregatorStore'
import oraclesStore from '../store/oraclesStore'
import { CDP } from '../types/cdp'
import { EthUsd } from '../types/chainlinkAggregator'
import { Collateral } from '../types/collaterals'
import { Price, PricesList } from '../types/oracles'
import $BN from './BigNumber'
import { sdkPriceInEthToUsdQ112 } from './oracle'
import { formatNumber, getDisplayAmountFromAtomicAmount } from './utils'

export function getMaxUSDPMintable(
  collateral: Collateral,
  cdp: CDP,
  ethUsd: EthUsd,
  prices: PricesList,
  assetAddress: string,
  depositedAssetAmount: string,
  additionalAssetAmount: string,
  mintedDebtAmount: string,
  raw = false,
): string {
  const { td: tokenDebt, tdl: tokenDebtLimit } = cdp

  const usdLimitForPosition = getMaxUSDPMintableForPosition(
    collateral,
    cdp,
    ethUsd,
    prices,
    assetAddress,
    depositedAssetAmount,
    additionalAssetAmount,
    mintedDebtAmount,
    true,
  )

  let mintable

  if ($BN(tokenDebt).plus(usdLimitForPosition).gt(tokenDebtLimit)) {
    if (tokenDebt > tokenDebtLimit) return '0'
    mintable = $BN(tokenDebtLimit).minus(tokenDebt).toString()
  } else {
    mintable = usdLimitForPosition
  }

  if ($BN(mintable).lt(0)) {
    return '0'
  }

  mintable = $BN(mintable)
    .div($BN(1).minus($BN(cdp.bf).div(10000)))
    .toString()

  return raw
    ? mintable
    : getDisplayAmountFromAtomicAmount(mintable, getChainConfig().usdpAddress, false)
}

export function getMaxUSDPMintableForPosition(
  collateral: Collateral,
  cdp: CDP,
  ethUsd: EthUsd,
  prices: PricesList,
  assetAddress: string,
  depositedAssetAmount: string,
  additionalAssetAmount: string,
  mintedDebtAmount: string,
  raw = false,
): string {
  const { icr } = cdp

  if (!prices[assetAddress] || oraclesStore.getValue().loading) {
    return '0'
  }

  const totalAssetAmount = $BN(depositedAssetAmount).plus(additionalAssetAmount).toString()
  const mainUsdUtilizedQ112 = getAssetUsdQ112Price(
    totalAssetAmount,
    prices[assetAddress],
    ethUsd.price,
  )

  // USD limit of the position
  const usdLimit = $BN(mainUsdUtilizedQ112).times(icr).div(Q112.toString()).div(100)

  const usdLimitForPosition = usdLimit.minus(mintedDebtAmount).toString()

  return raw
    ? usdLimitForPosition
    : getDisplayAmountFromAtomicAmount(
        usdLimitForPosition.toString(),
        getChainConfig().usdpAddress,
        false,
      )
}

export function getAssetUsdQ112Price(
  amount: BigNumber.Value,
  priceObj: Price,
  ethUsdPrice: string,
): string {
  if (priceObj.priceInEthQ112) {
    return sdkPriceInEthToUsdQ112(amount, priceObj.priceInEthQ112, ethUsdPrice)
  }

  return $BN(priceObj.priceInUsdQ112)
    .times(amount)
    .times($BN(10).pow(18 - collateralsStore.getCollateralProps(priceObj.address).decimals))
    .toString()
}

export function getAssetUsdPriceFormatted(amount: BigNumber.Value, address: string): string {
  if (isUsdp(address)) {
    return formatNumber($BN(amount).div(1e18))
  }

  const ethUsdPrice = chainlinkAggregatorStore.getValue().ethUsd.price

  if (isEthOrWeth(address)) {
    return formatNumber($BN(sdkPriceInEthToUsdQ112(amount, 1, ethUsdPrice)).div(1e18))
  }

  const priceObj = oraclesStore.getValue().prices[address]

  if (!priceObj) return '0'

  const price = $BN(getAssetUsdQ112Price(amount, priceObj, ethUsdPrice))
    .div(Q112.toString())
    .div(1e16)

  return formatNumber($BN(price).div(100).toString())
}

export function usdpMinted(assetAddress: string): string {
  const cdp = cdpStore.getCdpByAddress(assetAddress)
  const { td } = cdp
  return td
}

export function getHealthFactor(
  collateral: Collateral,
  cdp: CDP,
  ethUsd: EthUsd,
  prices: PricesList,
  mainAssetAddress: string,
  isVaultLoading: boolean,
  timestamp: number,
): string {
  if (isVaultLoading || !timestamp || !prices[mainAssetAddress] || !ethUsd.price) return ''
  const { cdp_deposit, cdpTotalDebt, lr } = cdp

  if ($BN(cdpTotalDebt).eq(0)) return ''

  if (!prices[mainAssetAddress] || !lr) return ''

  let mainUsdValueQ112
  if (prices[mainAssetAddress].priceInEthQ112) {
    mainUsdValueQ112 = sdkPriceInEthToUsdQ112(
      cdp_deposit,
      prices[mainAssetAddress].priceInEthQ112,
      ethUsd.price,
    )
  } else {
    mainUsdValueQ112 = prices[mainAssetAddress].priceInUsdQ112
  }

  return $BN(mainUsdValueQ112).times(lr).div(cdpTotalDebt).div(Q112.toString()).toString()
}

export function getMaxMainWithdrawable(
  cdp: CDP,
  ethUsd: EthUsd,
  prices: PricesList,
  assetAddressInBlockchain: string,
  mainDeposit: string,
  mintedDebtAmount: string,
  repaymentAmount: string,
): string {
  if ($BN(mainDeposit).eq(0)) return '0'

  const { icr } = cdp

  if (!prices[assetAddressInBlockchain]) return ''

  const remainingDebt = $BN(mintedDebtAmount).minus(repaymentAmount)

  if (remainingDebt.lte(0)) return mainDeposit.toString()

  const mainUsdValueQ112 = getAssetUsdQ112Price(
    mainDeposit,
    prices[assetAddressInBlockchain],
    ethUsd.price,
  )

  const mainUsdValueCollateralizingQ112 = $BN(remainingDebt)
    .times(Q112.toString())
    .times(100)
    .div(icr)

  if ($BN(mainUsdValueCollateralizingQ112).gt(mainUsdValueQ112) || $BN(mainUsdValueQ112).eq(0)) {
    return '0'
  }

  let mainWithdrawable = $BN(mainUsdValueQ112)
    .minus(mainUsdValueCollateralizingQ112)
    .times(mainDeposit)
    .div(mainUsdValueQ112)
    .toString()

  if ($BN(mainWithdrawable).gte(mainDeposit)) {
    mainWithdrawable = mainDeposit
  }

  return mainWithdrawable
}

export function calculateFee(cdp: CDP, repayment: BigNumber.Value): string {
  const timePast = $BN(blockStore.getValue().timestamp).minus(cdp.cdp_lastUpdate).plus(3600)
  return $BN(repayment)
    .times(cdp.cdp_sf)
    .times(timePast)
    .div(365)
    .div(24)
    .div(60)
    .div(60)
    .div(1e5)
    .toFixed(0)
}

export async function getCdpWithoutLiquidationBlock(asset: string, owner: string): Promise<any> {
  const { getVaultLiquidationPrice } = getVaultContractFunctions()
  const [cdpDetails, liqPrice] = await Promise.all([
    getCdpViewerGetCollateralParameters(asset, owner),
    getVaultLiquidationPrice(asset, owner),
  ])

  const [, , , , dp, , , , , , [cdp_deposit, cdp_debt, , , , cdp_lf]] = cdpDetails

  return {
    asset,
    owner,
    deposit: BigInt(cdp_deposit),
    debt: BigInt(cdp_debt),
    devaluationPeriod: dp.toString(),
    liquidationPrice: BigInt(liqPrice),
    liquidationFee: cdp_lf.toString(),
  }
}

export async function getCdpForLiquidation(asset: string, owner: string): Promise<any> {
  const { getVaultgetVaultLiquidationPrice, getVaultLiquidationBlock } = getVaultContractFunctions()
  const [cdpDetails, liqPrice, liqBlock] = await Promise.all([
    getCdpViewerGetCollateralParameters(asset, owner),
    getVaultgetVaultLiquidationPrice(asset, owner),
    getVaultLiquidationBlock(asset, owner),
  ])

  const [, , , , dp, , , , , , [cdp_deposit, cdp_debt, , , , cdp_lf]] = cdpDetails

  return {
    asset,
    owner,
    deposit: BigInt(cdp_deposit),
    debt: BigInt(cdp_debt),
    devaluationPeriod: dp.toString(),
    liquidationPrice: BigInt(liqPrice),
    liquidationFee: cdp_lf.toString(),
    liquidationBlock: BigInt(liqBlock).toString(),
  }
}

function isEthOrWeth(address: string): boolean {
  const { mockAddress, wethAddress } = getChainConfig()
  return [mockAddress, wethAddress].includes(address.toLowerCase())
}

function isUsdp(address: string): boolean {
  const { usdpAddress } = getChainConfig()
  return usdpAddress === address.toLowerCase()
}
