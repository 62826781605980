import React from 'react'

export const NoCDPMessage: React.FC = () => (
  <div style={{ fontSize: '2rem' }}>
    <span>You haven&#39;t opened a CDP yet.&nbsp;</span>
    <a
      href="https://unit-protocol.gitbook.io/docs/understanding-usdp/how-to-mint-usdp"
      target="_blank"
      rel="noopener noreferrer"
    >
      How to...
    </a>
  </div>
)
