import styled from 'styled-components'

import { breakpoint } from '../../../uiKit/theme'

export const WrapUnwrapBody = styled.div<{
  reverse: boolean
}>`
  margin-top: 20px;
  align-items: stretch;
  display: flex;
  flex-direction: ${(p) => (p.reverse ? 'column-reverse' : 'column')};
  @media (min-width: ${breakpoint.tablet}) {
    align-items: flex-end;
    flex-direction: ${(p) => (p.reverse ? 'row-reverse' : 'row')};
  }
`

export const SwapButton = styled.div`
  display: flex;
  align-self: flex-end;
  transform: rotate(90deg);
  border-radius: 50%;
  padding: 1rem;
  margin: 0 1rem;
  :hover {
    background: rgb(255 218 254);
  }
  @media (min-width: ${breakpoint.tablet}) {
    transform: rotate(0deg);
    cursor: pointer;
    margin-bottom: 7rem;
    flex-shrink: 0;
  }
`
