import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'src/App'
import 'src/index.css'
import { collateralsStore } from 'src/store/collateralsStore'
import 'src/utils/injectStoreGetter'

import { ModalsProvider } from './providers/ModalsProvider'

collateralsStore.init()

const container = document.getElementById('root')

const root = createRoot(container)

const app = (
  <React.StrictMode>
    <ModalsProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ModalsProvider>
  </React.StrictMode>
)

root.render(app)
