import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/IUniswapV2Pair.json'

export function getUniswapPairContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function getUniswapPairDOMAIN_SEPARATOR(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.DOMAIN_SEPARATOR().catch((e) => {
    console.error('Failed to call DOMAIN_SEPARATOR in uniswapPair:', e)
  })
}

export function getUniswapPairMINIMUM_LIQUIDITY(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.MINIMUM_LIQUIDITY().catch((e) => {
    console.error('Failed to call MINIMUM_LIQUIDITY in uniswapPair:', e)
  })
}

export function getUniswapPairPERMIT_TYPEHASH(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.PERMIT_TYPEHASH().catch((e) => {
    console.error('Failed to call PERMIT_TYPEHASH in uniswapPair:', e)
  })
}

export function getUniswapPairAllowance(contractAddress, owner, spender) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.allowance(owner, spender).catch((e) => {
    console.error('Failed to call allowance in uniswapPair:', e)
  })
}

export function submitUniswapPairApprove(contractAddress, spender, value, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'approve', [spender, value, { ...options }])
}

export function getUniswapPairBalanceOf(contractAddress, owner) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.balanceOf(owner).catch((e) => {
    console.error('Failed to call balanceOf in uniswapPair:', e)
  })
}

export function submitUniswapPairBurn(contractAddress, to, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'burn', [to, { ...options }])
}

export function getUniswapPairDecimals(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in uniswapPair:', e)
  })
}

export function getUniswapPairFactory(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.factory().catch((e) => {
    console.error('Failed to call factory in uniswapPair:', e)
  })
}

export function getUniswapPairGetReserves(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.getReserves().catch((e) => {
    console.error('Failed to call getReserves in uniswapPair:', e)
  })
}

export function submitUniswapPairInitialize(
  contractAddress,
  addressInput1,
  addressInput2,
  signer,
  options = {},
) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'initialize', [addressInput1, addressInput2, { ...options }])
}

export function getUniswapPairKLast(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.kLast().catch((e) => {
    console.error('Failed to call kLast in uniswapPair:', e)
  })
}

export function submitUniswapPairMint(contractAddress, to, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'mint', [to, { ...options }])
}

export function getUniswapPairName(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.name().catch((e) => {
    console.error('Failed to call name in uniswapPair:', e)
  })
}

export function getUniswapPairNonces(contractAddress, owner) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.nonces(owner).catch((e) => {
    console.error('Failed to call nonces in uniswapPair:', e)
  })
}

export function submitUniswapPairPermit(
  contractAddress,
  owner,
  spender,
  value,
  deadline,
  v,
  r,
  s,
  signer,
  options = {},
) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'permit', [
    owner,
    spender,
    value,
    deadline,
    v,
    r,
    s,
    { ...options },
  ])
}

export function getUniswapPairPrice0CumulativeLast(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.price0CumulativeLast().catch((e) => {
    console.error('Failed to call price0CumulativeLast in uniswapPair:', e)
  })
}

export function getUniswapPairPrice1CumulativeLast(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.price1CumulativeLast().catch((e) => {
    console.error('Failed to call price1CumulativeLast in uniswapPair:', e)
  })
}

export function submitUniswapPairSkim(contractAddress, to, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'skim', [to, { ...options }])
}

export function submitUniswapPairSwap(
  contractAddress,
  amount0Out,
  amount1Out,
  to,
  data,
  signer,
  options = {},
) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'swap', [amount0Out, amount1Out, to, data, { ...options }])
}

export function getUniswapPairSymbol(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in uniswapPair:', e)
  })
}

export function submitUniswapPairSync(contractAddress, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'sync', [{ ...options }])
}

export function getUniswapPairToken0(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.token0().catch((e) => {
    console.error('Failed to call token0 in uniswapPair:', e)
  })
}

export function getUniswapPairToken1(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.token1().catch((e) => {
    console.error('Failed to call token1 in uniswapPair:', e)
  })
}

export function getUniswapPairTotalSupply(contractAddress) {
  const contract = getUniswapPairContract(getChain().ethersProvider, contractAddress)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in uniswapPair:', e)
  })
}

export function submitUniswapPairTransfer(contractAddress, to, value, signer, options = {}) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'transfer', [to, value, { ...options }])
}

export function submitUniswapPairTransferFrom(
  contractAddress,
  from,
  to,
  value,
  signer,
  options = {},
) {
  const contract = getUniswapPairContract(signer, contractAddress)
  return sendTransaction(contract, 'transferFrom', [from, to, value, { ...options }])
}
