import debug from 'debug'
import React, { useEffect, useState } from 'react'
import { useAppChain, useIsConnected } from 'src/hooks/useUserStore'
import { useSetModal } from 'src/providers/ModalsProvider'
import { safeWait } from 'src/store/transactionsStore'

import { protocolStableSymbol } from '../../constants/usdp'
import { submitLiquidationAuctionBuyout } from '../../contracts/liquidationauction/contractFunctions'
import { useUsdpApproval } from '../../hooks/useApprovals'
import useSubject from '../../hooks/useSubject'
import blockStore from '../../store/blockStore'
import { usdpStore } from '../../store/usdpStore'
import { isBsc, isFantom, isGnosis, isMainnet, userStore } from '../../store/userStore'
import { CDPLiquidationsState } from '../../types/cdp'
import { MainBlock, MainBlockActionBtns } from '../../uiKit/MainBlock'
import { UIButton } from '../../uiKit/UiButton/styled'
import $BN from '../../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount } from '../../utils/utils'
import {
  Param,
  ParamName,
  ParamValue,
} from '../../views/asset/components/CollateralParameters/styled'
import { StyledCurrentCDPParams } from '../../views/asset/components/CurrentCDP/CurrentCDPParams/styled'

const log = debug('components:LiquidationListItem')

const LiquidationListItem: React.FC<{ cdp: CDPLiquidationsState }> = ({ cdp }) => {
  const isConnected = useIsConnected()
  const signer = useSubject(userStore.signer)
  const blockState = useSubject(blockStore)
  const usdpBalance = useSubject(usdpStore.balance)
  const { usdpAddress } = useAppChain().config
  const setModal = useSetModal()

  const [repayment, setReapyment] = useState('0')
  const [assetToLiquidator, setAssetToLiquidator] = useState('0')
  const [blocksPast, setBlocksPast] = useState('0')

  const notEnoughBalance = $BN(repayment.toString()).gte(usdpBalance)
  const { deposit, debt, devaluationPeriod, liquidationPrice, liquidationFee } = cdp

  // blocks past for OLD contracts
  useEffect(() => {
    if (!isMainnet() && !isFantom() && !isGnosis() && !isBsc()) return
    log('setBlocksPast fired for OLD')
    const { liquidationBlock } = cdp
    setBlocksPast($BN(blockState.number).minus(liquidationBlock).toString())
  }, [blockState])

  // blocks past for NEW contracts
  // in this case liquidationBlock is a timestamp of liquidation
  useEffect(() => {
    if (isMainnet() || isFantom() || isBsc() || isGnosis()) return
    log('setBlocksPast fired for NEW')

    let dateNow = Math.round(new Date().getTime() / 1000)
    const { liquidationBlock } = cdp
    setBlocksPast($BN(dateNow).minus(liquidationBlock).toString())

    const interval = setInterval(() => {
      setBlocksPast($BN(dateNow).minus(liquidationBlock).toString())
      dateNow += 1
    }, 1000)

    return () => clearInterval(interval)
  }, [cdp.liquidationBlock])

  useEffect(() => {
    if ($BN(devaluationPeriod).gt(blocksPast)) {
      const valuation = $BN(devaluationPeriod).minus(blocksPast)
      const collateralPrice = $BN(liquidationPrice.toString())
        .times(valuation)
        .div(devaluationPeriod)
      const penalty = $BN(debt.toString()).times(liquidationFee.toString()).div(100)
      const debtWithPenalty = $BN(debt.toString()).plus(penalty)
      if (collateralPrice.gt(debtWithPenalty)) {
        setAssetToLiquidator(
          $BN(deposit.toString()).times(debtWithPenalty).div(collateralPrice).toString(),
        )
        setReapyment(debtWithPenalty.toString())
      } else {
        setReapyment(collateralPrice.toString())
        setAssetToLiquidator(deposit.toString())
      }
    } else {
      setAssetToLiquidator(deposit.toString())
    }
  }, [blocksPast])

  const approvals = useUsdpApproval(repayment.toString())

  async function liquidationAction() {
    setModal({ key: 'transaction' })
    // eslint-disable-next-line no-restricted-syntax
    for (const a of approvals) {
      if (!(await a())) {
        setModal(null)
        return
      }
    }
    await safeWait(
      submitLiquidationAuctionBuyout(
        cdp.asset,
        cdp.owner,
        signer,
        approvals.length ? { gasLimit: 200_000 } : {},
      ),
    )
    setModal(null)
  }

  return (
    <MainBlock pad="small">
      <StyledCurrentCDPParams inRow={2}>
        <Param>
          <ParamName>Collateral</ParamName>
          <ParamValue>
            {getDisplayAmountFromAtomicAmount(assetToLiquidator.toString(), cdp.asset)} {cdp.symbol}
          </ParamValue>
        </Param>
        <Param>
          <ParamName>Total price</ParamName>
          <ParamValue>
            {getDisplayAmountFromAtomicAmount(repayment.toString(), usdpAddress)} USDP
          </ParamValue>
        </Param>
        <Param>
          <ParamName>Buyout rate (per 1 {cdp.symbol}):</ParamName>
          <ParamValue>
            {`${$BN(repayment)
              .times(10000000)
              .div(assetToLiquidator.toString())
              .div($BN(10).pow(18 - cdp.decimals))
              .div(1e7)
              .toFixed(2)} ${protocolStableSymbol}`}
          </ParamValue>
        </Param>
        <Param>
          <ParamName>Depreciation</ParamName>
          <ParamValue>
            {blocksPast.toString()}/{devaluationPeriod.toString()}{' '}
            {isMainnet() || isGnosis() || isFantom() || isBsc() ? 'blocks past' : 'time past'}
          </ParamValue>
        </Param>
      </StyledCurrentCDPParams>
      <MainBlockActionBtns>
        <UIButton small onClick={liquidationAction} disabled={!isConnected || notEnoughBalance}>
          {isConnected
            ? notEnoughBalance
              ? `Not enough ${protocolStableSymbol}`
              : approvals.length
              ? 'Approve & Buyout'
              : 'Buyout'
            : 'Login to interact'}
        </UIButton>
      </MainBlockActionBtns>
    </MainBlock>
  )
}

export default LiquidationListItem
