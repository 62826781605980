import React from 'react'

import { StyledTableHead, TableCol } from './styled'

interface UiTableHeadProps {
  cols: { name: string; id: string }[]
}

export const UiTableHead: React.FC<UiTableHeadProps> = ({ cols }) => {
  return (
    <StyledTableHead>
      {cols.map((col) => (
        <TableCol key={col.id}>{col.name}</TableCol>
      ))}
    </StyledTableHead>
  )
}
