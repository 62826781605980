import { CollateralProps } from '../../types/collaterals'

export const gnoCollaterals: Record<string, CollateralProps> = {
  '0x9c58bacc331c9aa871afd802db6379a98e80cedb': {
    symbol: 'GNO',
    decimals: 18,
  },
  '0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1': {
    symbol: 'WETH',
    decimals: 18,
  },
  '0x8e5bbbb09ed1ebde8674cda39a0c169401db4252': {
    symbol: 'WBTC',
    decimals: 8,
  },
  '0xe2e73a1c69ecf83f464efce6a5be353a37ca09b2': {
    symbol: 'LINK',
    decimals: 18,
  },
  '0x712b3d230f3c1c19db860d80619288b1f0bdd0bd': {
    symbol: 'CRV',
    decimals: 18,
  },
  '0x4537e328bf7e4efa29d05caea260d7fe26af9d74': {
    symbol: 'UNI',
    decimals: 18,
  },
  '0x5fd896d248fbfa54d26855c267859eb1b4daee72': {
    symbol: 'MKR',
    decimals: 18,
  },
  '0x8e7ab03ca7d17996b097d5866bfaa1e251c35c6a': {
    symbol: 'DUCK',
    decimals: 18,
  },
  '0xfe7ed09c4956f7cdb54ec4ffcb9818db2d7025b8': {
    symbol: 'USDP',
    decimals: 18,
  },
}
