import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/ISushiSwapV2Factory.json'

export function getSushiSwapFactoryContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.sushiSwapFactory, abi, provider)
}

export function getSushiSwapFactoryAllPairs(uint256Input1) {
  const contract = getSushiSwapFactoryContract(getChain().ethersProvider)
  return contract.allPairs(uint256Input1).catch((e) => {
    console.error('Failed to call allPairs in sushiSwapFactory:', e)
  })
}

export function getSushiSwapFactoryAllPairsLength() {
  const contract = getSushiSwapFactoryContract(getChain().ethersProvider)
  return contract.allPairsLength().catch((e) => {
    console.error('Failed to call allPairsLength in sushiSwapFactory:', e)
  })
}

export function submitSushiSwapFactoryCreatePair(tokenA, tokenB, signer, options = {}) {
  const contract = getSushiSwapFactoryContract(signer)
  return sendTransaction(contract, 'createPair', [tokenA, tokenB, { ...options }])
}

export function getSushiSwapFactoryFeeTo() {
  const contract = getSushiSwapFactoryContract(getChain().ethersProvider)
  return contract.feeTo().catch((e) => {
    console.error('Failed to call feeTo in sushiSwapFactory:', e)
  })
}

export function getSushiSwapFactoryFeeToSetter() {
  const contract = getSushiSwapFactoryContract(getChain().ethersProvider)
  return contract.feeToSetter().catch((e) => {
    console.error('Failed to call feeToSetter in sushiSwapFactory:', e)
  })
}

export function getSushiSwapFactoryGetPair(tokenA, tokenB) {
  const contract = getSushiSwapFactoryContract(getChain().ethersProvider)
  return contract.getPair(tokenA, tokenB).catch((e) => {
    console.error('Failed to call getPair in sushiSwapFactory:', e)
  })
}
