import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/WrappedSSLP.json'

export function getWrappedSSLPContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function getWrappedSSLPAllowance(contractAddress, owner, spender) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.allowance(owner, spender).catch((e) => {
    console.error('Failed to call allowance in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPApprove(contractAddress, spender, amount, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'approve', [spender, amount, { ...options }])
}

export function submitWrappedSSLPApproveSslpToTopDog(contractAddress, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'approveSslpToTopDog', [{ ...options }])
}

export function getWrappedSSLPBalanceOf(contractAddress, account) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.balanceOf(account).catch((e) => {
    console.error('Failed to call balanceOf in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPBoneToken(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.boneToken().catch((e) => {
    console.error('Failed to call boneToken in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPCallBoneLocker(
  contractAddress,
  _boneLocker,
  _callData,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'callBoneLocker', [_boneLocker, _callData, { ...options }])
}

export function submitWrappedSSLPClaimReward(contractAddress, _user, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'claimReward', [_user, { ...options }])
}

export function submitWrappedSSLPClaimRewardFromBoneLocker(
  contractAddress,
  _boneLocker,
  _maxBoneLockerRewardsAtOneClaim,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'claimRewardFromBoneLocker', [
    _boneLocker,
    _maxBoneLockerRewardsAtOneClaim,
    { ...options },
  ])
}

export function getWrappedSSLPDecimals(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPDecreaseAllowance(
  contractAddress,
  spender,
  subtractedValue,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'decreaseAllowance', [spender, subtractedValue, { ...options }])
}

export function submitWrappedSSLPDeposit(contractAddress, _user, _amount, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'deposit', [_user, _amount, { ...options }])
}

export function submitWrappedSSLPEmergencyWithdraw(contractAddress, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'emergencyWithdraw', [{ ...options }])
}

export function getWrappedSSLPFeePercent(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.feePercent().catch((e) => {
    console.error('Failed to call feePercent in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPFeeReceiver(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.feeReceiver().catch((e) => {
    console.error('Failed to call feeReceiver in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPGetClaimableRewardFromBoneLocker(
  contractAddress,
  _user,
  _boneLocker,
) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.getClaimableRewardFromBoneLocker(_user, _boneLocker).catch((e) => {
    console.error('Failed to call getClaimableRewardFromBoneLocker in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPGetUnderlyingToken(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.getUnderlyingToken().catch((e) => {
    console.error('Failed to call getUnderlyingToken in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPIncreaseAllowance(
  contractAddress,
  spender,
  addedValue,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'increaseAllowance', [spender, addedValue, { ...options }])
}

export function getWrappedSSLPIsUnitProtocolWrappedAsset(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.isUnitProtocolWrappedAsset().catch((e) => {
    console.error('Failed to call isUnitProtocolWrappedAsset in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPMovePosition(
  contractAddress,
  _userFrom,
  _userTo,
  _amount,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'movePosition', [_userFrom, _userTo, _amount, { ...options }])
}

export function getWrappedSSLPName(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.name().catch((e) => {
    console.error('Failed to call name in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPPendingReward(contractAddress, _user) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.pendingReward(_user).catch((e) => {
    console.error('Failed to call pendingReward in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPReadBoneLocker(contractAddress, _user, _boneLocker, _callData) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.readBoneLocker(_user, _boneLocker, _callData).catch((e) => {
    console.error('Failed to call readBoneLocker in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPSetAllowedBoneLockerSelector(
  contractAddress,
  _boneLocker,
  _selector,
  _isAllowed,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'setAllowedBoneLockerSelector', [
    _boneLocker,
    _selector,
    _isAllowed,
    { ...options },
  ])
}

export function submitWrappedSSLPSetFee(contractAddress, _feePercent, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'setFee', [_feePercent, { ...options }])
}

export function submitWrappedSSLPSetFeeReceiver(
  contractAddress,
  _feeReceiver,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'setFeeReceiver', [_feeReceiver, { ...options }])
}

export function getWrappedSSLPSymbol(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPTopDog(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.topDog().catch((e) => {
    console.error('Failed to call topDog in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPTopDogPoolId(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.topDogPoolId().catch((e) => {
    console.error('Failed to call topDogPoolId in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPTotalSupply(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPTransfer(
  contractAddress,
  recipient,
  amount,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'transfer', [recipient, amount, { ...options }])
}

export function submitWrappedSSLPTransferFrom(
  contractAddress,
  sender,
  recipient,
  amount,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'transferFrom', [sender, recipient, amount, { ...options }])
}

export function getWrappedSSLPUserProxyImplementation(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.userProxyImplementation().catch((e) => {
    console.error('Failed to call userProxyImplementation in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPUsersProxies(contractAddress, addressInput1) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.usersProxies(addressInput1).catch((e) => {
    console.error('Failed to call usersProxies in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPVault(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.vault().catch((e) => {
    console.error('Failed to call vault in wrappedSSLP:', e)
  })
}

export function getWrappedSSLPVaultParameters(contractAddress) {
  const contract = getWrappedSSLPContract(getChain().ethersProvider, contractAddress)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in wrappedSSLP:', e)
  })
}

export function submitWrappedSSLPWithdraw(contractAddress, _user, _amount, signer, options = {}) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'withdraw', [_user, _amount, { ...options }])
}

export function submitWrappedSSLPWithdrawToken(
  contractAddress,
  _token,
  _amount,
  signer,
  options = {},
) {
  const contract = getWrappedSSLPContract(signer, contractAddress)
  return sendTransaction(contract, 'withdrawToken', [_token, _amount, { ...options }])
}
