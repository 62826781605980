export const numberInputReg = (val: string): string => {
  return val
    .replace(/[^0-9.,]/g, '')
    .replace(/,/g, '.')
    .replace(/(\..*?)\..*/g, '$1')
    .replace(/(,.*?),.*/g, '$1')
    .replace(/^0+/g, '0')
    .replace(/^0(?=\d)/, '')
    .replace(/^\./, '0.')
}
