import React, { useEffect } from 'react'

import SearchIcon from '../../../../../assets/images/icons/ic-search.svg'
import { collateralsFilterStore } from '../../../../../store/collateralsFilterStore'
import {
  CollateralsListSearchContainer,
  CollateralsListSearchIcon,
  StyledCollateralsListSearch,
} from './styled'

export const CollateralsListSearch: React.FC = () => {
  useEffect(() => {
    return () => collateralsFilterStore.setSearch('')
  }, [])
  return (
    <CollateralsListSearchContainer>
      <StyledCollateralsListSearch
        placeholder="Name / Address"
        onChange={(e) => collateralsFilterStore.setSearch(e.target.value)}
      />
      <CollateralsListSearchIcon src={SearchIcon} />
    </CollateralsListSearchContainer>
  )
}
