import { getChain, getChainConfig } from 'src/store/userStore'

import { getErc20TokenBalanceOf } from '../contracts/erc20token/contractFunctions'
import {
  trackErc20TokenDeposit,
  trackErc20TokenTransfer,
  trackErc20TokenWithdrawal,
} from '../contracts/erc20token/eventListeners'

export async function getBalance(walletAddress: string, tokenAddress: string): Promise<bigint> {
  return BigInt(await getErc20TokenBalanceOf(tokenAddress, walletAddress))
}

export async function fetchEthBalance(walletAddress: string): Promise<bigint> {
  return BigInt(await getChain().wsProvider.eth.getBalance(walletAddress))
}

export const initializeBalanceTrackingForToken = (
  wallet: string,
  tokenAddress: string,
  callback: (wallet, tokenAddress) => void,
  secondAddress = '',
): void => {
  trackErc20TokenTransfer(
    {
      callback: () => callback(wallet, tokenAddress),
      contract: tokenAddress,
      src: wallet,
      dst: secondAddress,
    },
    wallet,
  )
  trackErc20TokenTransfer(
    {
      callback: () => callback(wallet, tokenAddress),
      contract: tokenAddress,
      src: secondAddress,
      dst: wallet,
    },
    wallet,
  )
  // TODO: check why this exception exist
  if (tokenAddress === getChainConfig().wethAddress) {
    trackErc20TokenDeposit(
      {
        callback: () => callback(wallet, tokenAddress),
        contract: tokenAddress,
        dst: wallet,
      },
      wallet,
    )
    trackErc20TokenWithdrawal(
      {
        callback: () => callback(wallet, tokenAddress),
        contract: tokenAddress,
        src: wallet,
      },
      wallet,
    )
  }
}
