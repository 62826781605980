import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'
import debug from 'debug'
import { BehaviorSubject } from 'rxjs'
import { ZERO_ADDRESS } from 'src/constants/constants'
import { CHAIN_ID, NETWORK_LIST, NetworkConfig } from 'src/constants/network'
import { ChainVendor, getChainVendor } from 'src/utils/chainVendor'

import { getChainConfigByUrlRepresentation, getChainRepresentationFromUrl } from '../utils/chain'

const log = debug('store:userStore')

const chainRepresentationFromUrl = getChainRepresentationFromUrl()
const chainConfig = getChainConfigByUrlRepresentation(chainRepresentationFromUrl)

export const userStore = {
  address: new BehaviorSubject<string>(ZERO_ADDRESS),
  signer: new BehaviorSubject<JsonRpcSigner>(null),
  // chain that currently selected in the unit protocol UI
  appChain: new BehaviorSubject<ChainVendor>(getChainVendor(chainConfig.id)),
  // chain id that currently selected in the provider, e.g. Metamask
  chainId: new BehaviorSubject<number>(NETWORK_LIST[0].id),
  loading: new BehaviorSubject(true),
  prevAddress: null as null | string,
  prevAppChainId: null as null | number,

  setAddress(val: string): void {
    userStore.prevAddress = userStore.address.getValue()
    userStore.address.next(val)
  },

  setSigner(val: JsonRpcSigner): void {
    userStore.signer.next(val)
  },

  setAppChainId(id: number): void {
    if (id === userStore.appChain.getValue().id)
      return log('Ignoring attempt to set the same chain id:', id)

    const newChain = getChainVendor(id)
    if (newChain) {
      userStore.prevAppChainId = userStore.appChain.getValue().id
      log(`setAppChainId:`, newChain)
      userStore.appChain.next(newChain)
    } else {
      console.warn(
        `setAppChainId: chain with id ${id} not found. ETH network was set as default one`,
      )
    }
  },

  setChainId(id: number): void {
    this.chainId.next(id)
  },

  setLoading(val: boolean): void {
    this.loading.next(val)
  },

  clearAll(): void {
    log('clearAll')
    userStore.address.next(ZERO_ADDRESS)
    userStore.signer.next(null)
  },
}

export const getChain = (): ChainVendor => userStore.appChain.getValue()
export const getChainConfig = (): NetworkConfig => getChain().config
// TODO: need to remove, because we shouldn't rely on such details in code,
// they need to be hidden under abstraction instead.
export const isMainnet = (): boolean => getChain().id === CHAIN_ID.ETH
export const isBsc = (): boolean => getChain().id === CHAIN_ID.BSC
export const isFantom = (): boolean => getChain().id === CHAIN_ID.FTM
export const isGnosis = (): boolean => getChain().id === CHAIN_ID.GNO
