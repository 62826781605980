import styled from 'styled-components'

import { MainBlock } from '../../uiKit/MainBlock'
import { color } from '../../uiKit/theme'

export const InfoBlockStyled = styled(MainBlock)`
  font-size: 2.5rem;
  line-height: 1.6em;
  a {
    color: ${color.pink};
    font-weight: bold;
  }
`
