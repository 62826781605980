import React from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import MoreIcon from '../../../../assets/images/icons/ic-more.svg'
import { protocolStableSymbol } from '../../../../constants/usdp'
import useSubject from '../../../../hooks/useSubject'
import currentCollateralStore from '../../../../store/currentCollateralStore'
import { CDP } from '../../../../types/cdp'
import { Collateral } from '../../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { UiCheckbox } from '../../../../uiKit/UiCheckbox'
import $BN from '../../../../utils/BigNumber'
import { formatNumber, getDisplayAmountFromAtomicAmount } from '../../../../utils/utils'
import {
  Param,
  ParamInfo,
  ParamInfoContainer,
  ParamInfoIcon,
  ParamName,
  ParamsCol,
  ParamValue,
} from './styled'

export const CollateralParameters: React.FC<{
  collateral: Collateral
  cdp: CDP
}> = ({ collateral, cdp }) => {
  const useWETH = useSubject(currentCollateralStore.useWeth)

  const preparedCollateralParams = [
    {
      name: 'ICR',
      value: `${cdp.icr}%`,
      info: `ICR means Initial Collateral Ratio. The ICR tells you how much ${protocolStableSymbol} is available to borrow with this token. So, if the ICR is 40%, for example, it means that if you put in $1000, you can borrow up to $400 ${protocolStableSymbol}.\n`,
    },
    {
      name: 'Liquidation ratio',
      value: `${$BN(cdp.lr).gt(100) ? '-' : cdp.lr}%`,
      info: 'Liquidation ratio(LR) - debt/collateral ratio represents the limit after which the CDP can be liquidated by anyone. As an example: 50% means that if the debt/collateral ratio will be >50% the position can be triggered for liquidation. LR>ICR to create some safety reserve to avoid fast liquidation.',
    },
    {
      name: 'Stability Fee',
      value: `${Number(cdp.sf) / 1000}%`,
      info: `The stability fee tells you how much borrowing your ${protocolStableSymbol} is going to cost you per year.`,
    },
    {
      name: 'Liquidation fee',
      value: `${cdp.lf}%`,
      info: 'The liquidation fee tells you the percentage of your collateral that you stand to lose if it gets liquidated. Keep an eye on this.',
    },
    {
      name: 'Issuance fee',
      value: `${Number(cdp.bf) / 100}%`,
      info: `This is the percentage charged for issuing the ${protocolStableSymbol} each time. For example, if the issuance fee is 0.9% and you want to get 10 ${protocolStableSymbol}, you will have to issue (10 / 0.991) ${protocolStableSymbol}.`,
    },
  ]

  const { wethAddress, wCurrencySymbol, usdpAddress } = useAppChain().config

  return (
    <MainBlock pad="small">
      <MainBlockTitle>
        Collateral parameters
        {collateral.address === wethAddress && (
          <UiCheckbox
            label={`Use ${wCurrencySymbol}`}
            value={useWETH}
            onChange={() => currentCollateralStore.setUseWeth(!useWETH)}
            style={{ margin: '0.6rem 0 0 4rem', fontFamily: 'Circe Regular', fontWeight: 'normal' }}
          />
        )}
      </MainBlockTitle>
      <ParamsCol>
        <ParamName>Borrowable {protocolStableSymbol}</ParamName>
        {cdp.usdpMintable === '0' && <ParamValue>Borrowing limit reached</ParamValue>}
        <ParamValue large>
          {formatNumber(getDisplayAmountFromAtomicAmount(cdp.usdpMintable, usdpAddress))}
        </ParamValue>
      </ParamsCol>
      <ParamsCol>
        <>
          {preparedCollateralParams.map((param) => (
            <Param key={param.name}>
              <ParamName>
                {param.name}
                {param.info && (
                  <ParamInfoContainer>
                    <ParamInfoIcon src={MoreIcon} alt="more-icon" />
                    <ParamInfo>{param.info}</ParamInfo>
                  </ParamInfoContainer>
                )}
              </ParamName>
              <ParamValue>{param.value}</ParamValue>
            </Param>
          ))}
        </>
      </ParamsCol>
    </MainBlock>
  )
}
