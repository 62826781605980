import debug from 'debug'
import _ from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { CHAIN_ID } from 'src/constants/network'

import { LIQUIDATION_TRIGGERED_TOPICS } from '../constants/constants'
import { trackCdpManagerLiquidationTriggered } from '../contracts/cdpmanager/eventListeners'
import { trackCdpManagerFallbackLiquidationTriggered } from '../contracts/cdpmanagerfallback/eventListeners'
import { trackLiquidationAuctionBuyout } from '../contracts/liquidationauction/eventListeners'
import { getVaultLiquidationTs } from '../contracts/vault/new/contractFunctions'
import { getVaultLiquidationBlock } from '../contracts/vault/old/contractFunctions'
import { LiquidationsState } from '../types/liquidations'
import { getCdpForLiquidation, getCdpWithoutLiquidationBlock } from '../utils/CDP'
import { getLatestBlockNumber, initializeBlockStore } from './blockStore'
import { collateralsStore } from './collateralsStore'
import { getChain, getChainConfig, isBsc, isFantom, isGnosis, isMainnet } from './userStore'

const log = debug('store:liquidationsStore')

export const liquidationsStore = new BehaviorSubject<LiquidationsState>({
  loading: true,
  cdpMap: new Map(),
  assets: [],
})

const updateSubject = (obj) => {
  liquidationsStore.next({
    ...liquidationsStore.getValue(),
    ...obj,
  })
}

async function fetchLiquidations(selectedChain: number): Promise<void> {
  let promises = []

  log('selectedChain', selectedChain)

  const liquidationByBlock = isMainnet() || isFantom() || isBsc() || isGnosis()

  const fromBlock = (await getLatestBlockNumber()) - 9_900

  log('liquidationByBlock: ', liquidationByBlock)
  log('fromBlock: ', fromBlock)
  log('address: ', getChainConfig().contracts.cdpManager)

  promises.push(
    getChain().wsProvider.eth.getPastLogs({
      fromBlock,
      address: getChainConfig().contracts.cdpManager,
      topics: LIQUIDATION_TRIGGERED_TOPICS,
    }),
  )

  if (selectedChain === 1) {
    promises.push(
      getChain().wsProvider.eth.getPastLogs({
        fromBlock,
        address: getChainConfig().contracts.cdpManagerFallback,
        topics: LIQUIDATION_TRIGGERED_TOPICS,
      }),
    )
  }

  const liquidationsEvents = (await Promise.all(promises)).reduce((acc, curr) => [...acc, ...curr])

  log('liquidationsEvents', liquidationsEvents)

  const assetsAndOwners = liquidationsEvents.map((eventLog) => [
    `0x${eventLog.topics[1].substr(-40)}`,
    `0x${eventLog.topics[2].substr(-40)}`,
  ])

  log('assetsAndOwners', assetsAndOwners)

  promises = assetsAndOwners.map(([asset, owner]) =>
    liquidationByBlock
      ? getVaultLiquidationBlock(asset, owner)
      : getVaultLiquidationTs(asset, owner),
  )

  const liquidationBlocks = await Promise.all(promises)

  log('liquidationBlocks', liquidationBlocks)

  const liqudatablePositions = []

  liquidationBlocks.forEach((block, i) => {
    if (Number(block) > 0) {
      liqudatablePositions.push({
        asset: assetsAndOwners[i][0],
        owner: assetsAndOwners[i][1],
        liquidationBlock: Number(block),
      })
    }
  })

  promises = liqudatablePositions.map((l) => getCdpWithoutLiquidationBlock(l.asset, l.owner))

  const cdpList = await Promise.all(promises)

  const assets = []

  const cdpMap = new Map()
  for (let i = 0; i < cdpList.length; i++) {
    const cdp = cdpList[i]
    const { symbol, decimals } = collateralsStore.getCollateralProps(cdp.asset)
    cdpMap.set(cdp.asset + cdp.owner, {
      ...cdp,
      liquidationBlock: liqudatablePositions[i].liquidationBlock,
      symbol,
      decimals,
    })
    assets.push(cdp.asset)
  }

  updateSubject({ cdpMap, loading: false, assets })
}

function initializeLiquidationTracking(selectedChain: number): void {
  trackLiquidationAuctionBuyout({
    callback: ([event]) => {
      if (!event) return
      console.log('trackLiquidationAuctionBuyout', event)
      const cdpMap = _.cloneDeep(liquidationsStore.getValue().cdpMap)
      cdpMap.delete(`0x${event.topics[1].substr(-40)}0x${event.topics[2].substr(-40)}`)
      updateSubject({ cdpMap })
    },
    asset: '',
    owner: '',
    buyer: '',
  })
  trackCdpManagerLiquidationTriggered({
    callback: async ([event]) => {
      if (!event) return
      console.log('trackCdpManagerLiquidationTriggered', event)
      const cdpMap = _.cloneDeep(liquidationsStore.getValue().cdpMap)
      const asset = `0x${event.topics[1].substr(-40)}`
      const owner = `0x${event.topics[2].substr(-40)}`
      const key = `${asset}${owner}`
      const cdp = await getCdpForLiquidation(asset, owner)
      cdpMap.set(key, cdp)
      updateSubject({ cdpMap })
    },
    asset: '',
    owner: '',
  })

  if (selectedChain === CHAIN_ID.ETH) {
    trackCdpManagerFallbackLiquidationTriggered({
      callback: async ([event]) => {
        if (!event) return
        console.log('trackCdpManagerLiquidationTriggered', event)
        const cdpMap = _.cloneDeep(liquidationsStore.getValue().cdpMap)
        const asset = `0x${event.topics[1].substr(-40)}`
        const owner = `0x${event.topics[2].substr(-40)}`
        const key = `${asset}${owner}`
        const cdp = await getCdpForLiquidation(asset, owner)
        cdpMap.set(key, cdp)
        updateSubject({ cdpMap })
      },
      asset: '',
      owner: '',
    })
  }
}

export const initializeLiquidations = async (selectedChain: number): Promise<void> => {
  updateSubject({ loading: true })
  log('liquidations fetching START')
  await initializeBlockStore()
  await fetchLiquidations(selectedChain)
  initializeLiquidationTracking(selectedChain)
  updateSubject({ loading: false })
  log('liquidations fetching END')
}
