import { BehaviorSubject } from 'rxjs'

const infoModalStore = {
  show: new BehaviorSubject(false),
  info: new BehaviorSubject(''),

  setShowState(state: boolean): void {
    infoModalStore.show.next(state)
  },
  setInfo(text: string): void {
    infoModalStore.info.next(text)
  },
}

export default infoModalStore
