import debug from 'debug'
import { collateralsStore } from 'src/store/collateralsStore'

import {
  submitCdpManagerExit,
  submitCdpManagerExit_Eth,
  submitCdpManagerJoin,
  submitCdpManagerJoin_Eth,
  submitCdpManagerUnwrapAndExit,
  submitCdpManagerWrapAndJoin,
} from '../contracts/cdpmanager/contractFunctions'
import {
  submitCdpManagerFallbackExit,
  submitCdpManagerFallbackJoin,
  submitCdpManagerFallbackUnwrapAndExit,
  submitCdpManagerFallbackWrapAndJoin,
} from '../contracts/cdpmanagerfallback/contractFunctions'
import oraclesStore, { isKeydonixOracle } from '../store/oraclesStore'
import { getChainConfig, userStore } from '../store/userStore'
import $BN from '../utils/BigNumber'
import { getAtomicAmountFromDisplayAmount } from '../utils/utils'

const log = debug('transactions:deposit/withdraw')

export const depositAndMint = async (
  metamask: any,
  assetAddressInBlockchain: string,
  assetAmount: string,
  usdpAmount: string,
  skipGasEstimation = false,
  useWeth: boolean,
  wrapped = false,
): Promise<void> => {
  log('depositAndMint call START')
  const options: any = {}

  const { signer } = metamask

  const { usdpAddress, wethAddress } = getChainConfig()
  const infoAssetAddress = assetAddressInBlockchain

  const assetAmountAtomic = getAtomicAmountFromDisplayAmount(assetAmount, assetAddressInBlockchain)
  const usdpAmountAtomic = getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress)

  const useEth = assetAddressInBlockchain === wethAddress && !useWeth
  const oracleType = collateralsStore.getCollateralProps(infoAssetAddress).oracleType

  log('oracleType', oracleType)

  const keydonix = isKeydonixOracle(oracleType) && $BN(usdpAmountAtomic).gt(0)

  const proofs = keydonix ? [getProof(assetAddressInBlockchain)] : []

  let joinFunction

  if (keydonix)
    if (wrapped) joinFunction = submitCdpManagerFallbackWrapAndJoin
    else joinFunction = submitCdpManagerFallbackJoin
  else if (wrapped) joinFunction = submitCdpManagerWrapAndJoin
  else if (useEth) joinFunction = submitCdpManagerJoin_Eth
  else joinFunction = submitCdpManagerJoin

  if (skipGasEstimation) {
    options.gasLimit = keydonix ? 1_500_000 : 900_000
  }

  const args = [assetAmountAtomic, usdpAmountAtomic, ...proofs, signer, options]

  if (!useEth) args.unshift(assetAddressInBlockchain)

  log('arguments: ', ...args)
  log('depositAndMint call END')

  return joinFunction(...args)
}

export const repayAndWithdraw = (
  assetAddressInBlockchain: string,
  assetAmount: string,
  usdpAmount: string,
  useWeth: boolean,
  feeValue: string,
  repayAll: boolean,
  skipGasEstimation: boolean,
  wrapped = false,
) => {
  log('repayAndWithdraw call START')
  const options: any = {}

  const signer = userStore.signer.getValue()

  const { usdpAddress, wethAddress } = getChainConfig()
  const assetAmountAtomic = getAtomicAmountFromDisplayAmount(assetAmount, assetAddressInBlockchain)
  const usdpAmountAtomic = getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress)
  const usdpAmountAtomicExcludeFee = $BN(usdpAmountAtomic).minus(feeValue).toString()

  const infoAssetAddress = assetAddressInBlockchain
  const useEth = assetAddressInBlockchain === wethAddress && !useWeth

  log('oracleType: ', collateralsStore.getCollateralProps(infoAssetAddress).oracleType)

  const keydonix =
    isKeydonixOracle(collateralsStore.getCollateralProps(infoAssetAddress).oracleType) && !repayAll

  let contractFunction

  if (keydonix)
    if (wrapped) contractFunction = submitCdpManagerFallbackUnwrapAndExit
    else contractFunction = submitCdpManagerFallbackExit
  else if (useEth) contractFunction = submitCdpManagerExit_Eth
  else if (wrapped) contractFunction = submitCdpManagerUnwrapAndExit
  else contractFunction = submitCdpManagerExit

  log(contractFunction.name)

  const proofs = []

  if (keydonix) {
    proofs.push(getProof(assetAddressInBlockchain))
  }
  if (skipGasEstimation) {
    options.gasLimit = keydonix ? 1_500_000 : 900_000
  }

  const args = [
    assetAmountAtomic,
    repayAll ? $BN(usdpAmountAtomic).times(1.02).toFixed(0).toString() : usdpAmountAtomicExcludeFee,
    ...proofs,
    signer,
    options,
  ]
  if (!useEth) args.unshift(assetAddressInBlockchain)

  log('arguments: ', ...args)
  log('repayAndWithdraw call END')

  return contractFunction(...args)
}

function getProof(assetAddress: string) {
  return oraclesStore.getValue().prices[assetAddress].proof
}
