import debug from 'debug'
import React, { ReactElement, useEffect, useRef } from 'react'
import { useAppChain, useIsConnected } from 'src/hooks/useUserStore'
import { addPendingTx, markTxFailed, markTxMined } from 'src/store/transactionsStore'

const log = debug('controllers:StoreInitializer')

const STORE_KEY = 'upStore'

export const prevStore = ((): {
  pendingTx?: string
  chainId: number
} => {
  let sessionStore = null
  try {
    const rawUPStore = sessionStorage.getItem(STORE_KEY)
    sessionStorage.removeItem(STORE_KEY)
    sessionStore = JSON.parse(rawUPStore)
  } catch (err) {
    console.error('Failed to parse sessionStorage.upStore', err)
  }

  return Object.freeze({ ...sessionStore })
})()

log('prevStore:', prevStore)

const useIsSameChainId = () => useAppChain().id === prevStore.chainId

const InitTransactionsStore = (): null => {
  const initializedRef = useRef(false)
  const isConnected = useIsConnected()
  const isSameChainId = useIsSameChainId()
  const appChain = useAppChain()

  useEffect(() => {
    if (initializedRef.current) return // already initialized
    if (!isConnected || !prevStore.pendingTx || !isSameChainId) return

    initializedRef.current = true

    try {
      const { pendingTx: hash } = prevStore
      addPendingTx(hash)
      appChain.ethersProvider
        .getTransactionReceipt(hash)
        .then((res) => {
          if (res.status) {
            markTxMined(hash as any)
          } else {
            markTxFailed('Transaction failed', hash as any)
          }
        })
        .catch(() => {
          log('Failed to get status of previous pending transaction:', hash)
        })
    } catch (err) {
      console.warn('Failed to init transactions store with saved value:', err)
    }
  }, [isConnected, isSameChainId, appChain])

  return null
}

/** This controller is used for initializing store */
export const StoreInitializer = (): ReactElement => {
  return <InitTransactionsStore />
}
