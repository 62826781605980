import React, { useEffect, useMemo, useState } from 'react'
import { useSetModal } from 'src/providers/ModalsProvider'
import { numberInputReg } from 'src/utils/input'

import { ReactComponent as SwapIcon } from '../../../assets/images/icons/ic-swap.svg'
import { useWrapperApproval } from '../../../hooks/useApprovals'
import useSubject from '../../../hooks/useSubject'
import { collateralsStore } from '../../../store/collateralsStore'
import currentCollateralStore from '../../../store/currentCollateralStore'
import { StoreCollaterals } from '../../../types/collaterals'
import { MainBlock, MainBlockActionBtns, MainBlockTitle } from '../../../uiKit/MainBlock'
import { TokenInput } from '../../../uiKit/TokenInput'
import { UIButton } from '../../../uiKit/UiButton/styled'
import $BN from '../../../utils/BigNumber'
import { getWrapUnwrapAction } from '../../../utils/collateralWrapUnwrap'
import { getAtomicAmountFromDisplayAmount } from '../../../utils/utils'
import { SwapButton, WrapUnwrapBody } from './styled'

export const WrapUnwrap: React.FC = () => {
  const [action, setAction] = useState<'Wrap' | 'Unwrap'>('Wrap')
  const [amountToWrap, setAmountToWrap] = useState<string>('0')
  const [amountToUnwrap, setAmountToUnwrap] = useState<string>('0')
  const [balanceNotEnough, setBalanceNotEnough] = useState(false)
  const [oneDirection, setOneDirection] = useState<boolean>(false)
  const setModal = useSetModal()

  const assetAddress = useSubject<string>(currentCollateralStore.address)
  const collaterals = useSubject<StoreCollaterals>(collateralsStore.collaterals)

  const collateral = collaterals[assetAddress]

  const { wrapAction, unwrapAction } = useMemo(() => {
    return getWrapUnwrapAction(collateral, amountToUnwrap, amountToWrap)
  }, [collateral, amountToWrap, amountToUnwrap])

  useEffect(() => {
    if (!wrapAction) {
      setAction('Unwrap')
      setOneDirection(true)
    } else if (!unwrapAction) {
      setAction('Wrap')
      setOneDirection(true)
    } else {
      setOneDirection(false)
    }
  }, [wrapAction, unwrapAction])

  useEffect(() => {
    setAmountToUnwrap(amountToWrap)
  }, [amountToWrap])

  useEffect(() => {
    setAmountToWrap(amountToUnwrap)
  }, [amountToUnwrap])

  function swapDirection() {
    if (oneDirection) return
    if (action === 'Wrap') setAction('Unwrap')
    else setAction('Wrap')
  }

  const remainingApprovals = useWrapperApproval(
    collateral,
    getAtomicAmountFromDisplayAmount(amountToUnwrap, collateral.address),
  )

  async function wrapUnwrapAction() {
    setModal({ key: 'transaction' })
    // eslint-disable-next-line no-restricted-syntax
    for (const a of remainingApprovals) {
      if (!(await a())) {
        setModal(null)
        return
      }
    }
    if (action === 'Wrap') wrapAction()
    else unwrapAction()
    setModal(null)
  }

  useEffect(() => {
    if (action === 'Wrap') {
      const error = $BN(
        getAtomicAmountFromDisplayAmount(amountToWrap, collateral.underlying.address),
      ).gt(collateral.underlying.balance)
      setBalanceNotEnough(error)
    } else {
      const error = $BN(getAtomicAmountFromDisplayAmount(amountToUnwrap, assetAddress)).gt(
        collateral.balance,
      )
    }
  }, [collateral])

  return (
    <MainBlock pad="small">
      <MainBlockTitle>{oneDirection ? action : 'Wrap / Unwrap'}</MainBlockTitle>
      <WrapUnwrapBody reverse={action !== 'Wrap'}>
        <TokenInput
          value={amountToWrap}
          tokenAddress={collateral.underlying.address}
          onInput={(e) => setAmountToWrap(numberInputReg(e.target.value))}
          onMax={(e) => setAmountToWrap(e.toString())}
          maxValue={collateral.underlying.balance}
          skipCheck={action === 'Unwrap'}
          maxBtn={action === 'Wrap'}
        />
        <SwapButton onClick={() => swapDirection()}>
          {!oneDirection && <SwapIcon width="40" fill="rgb(172,12,238)" />}
        </SwapButton>
        <TokenInput
          value={amountToUnwrap}
          tokenAddress={assetAddress}
          onInput={(e) => setAmountToUnwrap(numberInputReg(e.target.value))}
          onMax={(e) => setAmountToUnwrap(e.toString())}
          maxValue={collateral.balance}
          skipCheck={action === 'Wrap'}
          maxBtn={action === 'Unwrap'}
        />
      </WrapUnwrapBody>
      {action === 'Wrap' ? (
        <MainBlockActionBtns>
          <UIButton
            disabled={
              $BN(getAtomicAmountFromDisplayAmount(amountToWrap, collateral.underlying.address)).eq(
                0,
              ) || balanceNotEnough
            }
            onClick={() => wrapUnwrapAction()}
          >
            {remainingApprovals.length ? 'Approve and wrap' : 'Wrap'}
          </UIButton>
        </MainBlockActionBtns>
      ) : (
        <MainBlockActionBtns>
          <UIButton
            disabled={
              $BN(getAtomicAmountFromDisplayAmount(amountToUnwrap, assetAddress)).eq(0) ||
              balanceNotEnough
            }
            onClick={wrapUnwrapAction}
          >
            {action}
          </UIButton>
        </MainBlockActionBtns>
      )}
    </MainBlock>
  )
}
