import { getChainConfig } from 'src/store/userStore'
import abi from 'src/contracts/abis/LiquidationAuction.json'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackLiquidationAuctionBuyout = ({ callback, asset, owner, buyer }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig().contracts.liquidationAuction,
    abi,
    name: 'Buyout',
    params: { asset, owner, buyer },
    topic: '0x4bafc1b25a3e727ead9384f3708c85489bdad9585fb211bbae2f76cf68d97339',
    namespace: 'LiquidationAuction',
  })
