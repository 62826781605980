import styled from 'styled-components'

import { breakpoint, color, fontFamily } from '../../../uiKit/theme'

export const StyledIntroText = styled.div`
  margin-top: 0;
  position: relative;
  @media (min-width: ${breakpoint.fullHd}) {
    width: 180rem;
    margin: 4rem auto 0;
  }
`

export const MainText = styled.span`
  display: block;
  font-size: 11rem;
  color: ${color.pink};
  ${fontFamily.maru}
  margin-left: -1rem;
  letter-spacing: -0.65rem;
  line-height: 0.8em;
  @media screen and (min-width: ${breakpoint.tablet}) {
    font-size: 16.5rem;
    white-space: nowrap;
    letter-spacing: -2rem;
    line-height: initial;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-left: -3rem;
    font-size: 27.8rem;
  }
  @media (min-width: ${breakpoint.fullHd}) {
    text-align: center;
  }
`

export const SubText = styled.span`
  font-size: 2rem;
  display: block;
  color: ${color.pink};
  line-height: 1.5em;
  width: 80%;
  margin-top: 3rem;
  @media (min-width: ${breakpoint.tablet}) {
    width: 60%;
    margin-bottom: 4rem;
  }
  @media (min-width: ${breakpoint.desktop}) {
    margin-top: -0.6em;
    margin-left: 2rem;
    width: 41rem;
  }
  @media (min-width: ${breakpoint.fullHd}) {
    margin-left: 3rem;
    width: 41rem;
  }
`

export const UnitLogo = styled.img`
  display: block;
  width: 64rem;
  margin: -27rem auto 0;
`
