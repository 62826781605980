import React, { createContext, Dispatch, useContext, useState } from 'react'
import { FCC } from 'src/types/fcc'

export type ModalSettings =
  | { key: 'connect_wallet' }
  | { key: 'transaction'; txHash?: string; message?: string; txError?: number }

type ModalState = null | ModalSettings

const initialModalState: ModalState = null

const ModalStx = createContext<[ModalState, Dispatch<React.SetStateAction<ModalState>>]>([
  initialModalState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
])

export const ModalsProvider: FCC = ({ children }) => {
  const value = useState<ModalState>(initialModalState)

  return <ModalStx.Provider value={value}>{children}</ModalStx.Provider>
}

export const useModal = () => useContext(ModalStx)[0]

export const useSetModal = () => useContext(ModalStx)[1]
