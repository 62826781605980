import React, { memo, useEffect } from 'react'
import useSubject from 'src/hooks/useSubject'
import { useAppChain } from 'src/hooks/useUserStore'
import { isTxPending, transactions } from 'src/store/transactionsStore'
import { TransactionsState } from 'src/types/transactions'
import { STORE_KEY } from 'src/utils/prevStore'

const getStorageEmitter = (
  storage: Storage,
): React.FC<{ pendingTx: string | null; chainId: number }> =>
  memo((props) => {
    useEffect(() => {
      storage.setItem(STORE_KEY, JSON.stringify(props))
    }, [Object.values(props)])

    return null
  })

const SessionStorageEmitter = getStorageEmitter(sessionStorage)

const usePendingTx = () => {
  const { lastTxHash } = useSubject<TransactionsState>(transactions)
  return isTxPending(lastTxHash) ? lastTxHash : null
}

export const StorageController: React.FC = () => {
  return <SessionStorageEmitter pendingTx={usePendingTx()} chainId={useAppChain().id} />
}
