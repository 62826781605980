import React, { useEffect, useRef, useState } from 'react'

// css
import './style.css'

export const RiskSlider: React.FC<{
  setPercent: (val: number) => void
  currentPercent: number
  disabled: boolean
}> = ({ setPercent, currentPercent, disabled }) => {
  const [currentValue, setCurrentValue] = useState(0)
  const [clientX, setClientX] = useState(0)
  const bar = useRef(null)
  const point = useRef(null)

  // set initial clientX
  useEffect(() => {
    setClientX(point.current.getBoundingClientRect().left)
  }, [])

  useEffect(() => {
    setCurrentValue(currentPercent)
    setPointerPosition(currentPercent)
  }, [currentPercent])

  const setPointerPosition = (value) => {
    const pointer = point.current
    if (pointer) {
      const sliderWidth = bar.current.clientWidth - pointer.clientWidth
      if (value >= 100) {
        setCurrentValue(100)
        pointer.style.left = `calc(100% - ${pointer.clientWidth}px)`
      } else if (value <= 0) {
        setCurrentValue(0)
        pointer.style.left = '0'
      } else {
        setCurrentValue(value)
        pointer.style.left = `${(sliderWidth * value) / 100}px`
      }
      setClientX(pointer.getBoundingClientRect().left)
    }
  }

  // mouseWellHangler
  const mouseWellHandler = (e) => {
    if (disabled) return
    e.preventDefault()
    const delta = e.deltaY || e.detail || e.wheelDelta
    if (delta > 0) setPercent(currentValue + 1)
    if (delta < 0) setPercent(currentValue - 1)
  }

  useEffect(() => {
    const currentBar = bar.current
    currentBar.addEventListener('mousewheel', mouseWellHandler)
    return () => currentBar.removeEventListener('mousewheel', mouseWellHandler)
  }, [])

  // click handler
  const changePointerPosition = (e) => {
    if (disabled) return
    const pointer = point.current
    const barEl = bar.current
    if (!pointer || !barEl) return
    window.getSelection().removeAllRanges()
    let clickClientX
    if (e.touches) {
      clickClientX = e.touches[0].clientX - pointer.clientWidth / 2
    } else {
      clickClientX = e.clientX - pointer.clientWidth / 2
    }
    const sliderWidth = barEl.clientWidth - pointer.clientWidth
    const cursorMoveDelta = clickClientX - clientX
    if (clickClientX < barEl.getBoundingClientRect().left)
      clickClientX = barEl.getBoundingClientRect().left
    if (clickClientX > barEl.getBoundingClientRect().right - pointer.clientWidth) {
      clickClientX = barEl.getBoundingClientRect().right - pointer.clientWidth
    }
    setClientX(clickClientX)
    const percentDelta = (cursorMoveDelta * 100) / sliderWidth
    setPercent(currentValue + percentDelta)
  }

  //
  const startChangeValue = (e) => {
    if (disabled) return
    if (e.touches) {
      setClientX(e.touches[0].clientX)
      document.addEventListener('touchmove', changePointerPosition)
      document.addEventListener('touchend', endChangePointerPosition)
    } else {
      setClientX(e.clientX)
      document.addEventListener('mousemove', changePointerPosition)
      document.addEventListener('mouseup', endChangePointerPosition)
    }
  }

  const endChangePointerPosition = (e) => {
    changePointerPosition(e)
    document.removeEventListener('mousemove', changePointerPosition)
    document.removeEventListener('touchmove', changePointerPosition)
    document.removeEventListener('mouseup', endChangePointerPosition)
    document.removeEventListener('touchend', endChangePointerPosition)
  }

  return (
    <div className="risk-slider">
      <div
        className="risk-slider__bar"
        ref={bar}
        onMouseDown={startChangeValue}
        onTouchStart={startChangeValue}
      >
        <div className="risk-slider__point" ref={point}>
          {currentPercent.toFixed(0)}%
        </div>
      </div>
    </div>
  )
}
