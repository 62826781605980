export const ORACLE_BY_TYPE = {
  1: {
    1: '0xBFE2e6eCEdFB9CDf0e9dA98AB116D57DdC82D078',
    2: '0x72A2e0D0A201B54DcFB668a46BE99494eFF6D2A8',
    5: '0x54b21C140F5463e1fDa69B934da619eAaa61f1CA',
    7: '0x7562FB711173095Bc2d8100C107e6Da639E0F4B0',
    9: '0x190DB945Ae572Ae72E367b549b78C41E211864AB',
    10: '0x0E08d9e1DC22a400EbcA25E9a8f292910fa8fe08',
    11: '0x220Ea780a484c18fd0Ab252014c58299759a1Fbd',
    12: '0xd88e1F40b6CD9793aa10A6C3ceEA1d01C2a507f9',
    13: '0x769E35030f5cE160b287Bce0462d46Decf29b6DD',
    14: '0x40B743Ca424E3eC7b97f5AD93d2263Ae01DAE1D8',
    15: '0x759EB07A8258BcF5590E9303763803DcF264652d',
    16: '0xd31817a1E1578C4BECE02FbFb235d76f5716f18f',
    20: '0x4c71C265ad0Db6386F36b9f3d33818cc5ed67cb1',
  },
  56: {
    5: '0x8F904b4d41630135fa020E8cE5Dd6DFD92028264',
    7: '0x7562FB711173095Bc2d8100C107e6Da639E0F4B0',
  },
  100: {
    5: '0x850943c274f5d2bab9e643aff7b1c1eeb89d30dd',
    11: '0x6635c1ddef754cff7eeffab060382a8c36e59f65',
    17: '0xe30a50b117ddc1c163dc80115e96a3672eaa8c28',
    20: '0x6c509307495782f2a8b5a841f8b2ea275a84015e',
  },
  250: {},
}
