import React, { useMemo } from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import { protocolStableSymbol } from '../../../../constants/usdp'
import useOracles from '../../../../hooks/useOracles'
import useSubject from '../../../../hooks/useSubject'
import currentCollateralStore from '../../../../store/currentCollateralStore'
import { usdpStore } from '../../../../store/usdpStore'
import { Collateral } from '../../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { TokenName } from '../../../../uiKit/TokenInput/styled'
import { UiTableHead } from '../../../../uiKit/UiTable/UiTableHead'
import { StyledUiTableRow, StyledUiTableRowCell } from '../../../../uiKit/UiTable/UiTableRow/styled'
import { getAssetUsdPriceFormatted } from '../../../../utils/CDP'
import { getDisplayAmountFromAtomicAmount } from '../../../../utils/utils'

const TABLE_COLS = [
  {
    name: 'Asset',
    id: 'asset',
  },
  {
    name: 'Balance',
    id: 'balance',
  },
  {
    name: 'Value',
    id: 'value',
  },
]

export const UserBalances: React.FC<{ collateral: Collateral }> = ({ collateral }) => {
  const usdpBalance = useSubject(usdpStore.balance)
  const prices = useOracles()
  const chainConfig = useAppChain().config
  const useWETH = useSubject(currentCollateralStore.useWeth)

  const pricesList = useMemo<{ symbol: string; balance: string; price: string }[]>(() => {
    const list = []

    if (collateral.address !== chainConfig.wethAddress) {
      list.push({
        symbol: collateral.symbol,
        balance: getDisplayAmountFromAtomicAmount(collateral.balance, collateral.address, true),
        price: getAssetUsdPriceFormatted(collateral.balance, collateral.address),
      })
    } else if (useWETH) {
      list.push({
        symbol: chainConfig.wCurrencySymbol,
        balance: getDisplayAmountFromAtomicAmount(collateral.balance, collateral.address, true),
        price: getAssetUsdPriceFormatted(collateral.balance, collateral.address),
      })
    }

    if (collateral.underlying) {
      list.push({
        symbol: collateral.underlying.symbol,
        balance: getDisplayAmountFromAtomicAmount(
          collateral.underlying.balance,
          collateral.underlying.address,
          true,
        ),
        // TODO: Check this suppose
        // in this case we suppose that wrapped and underlying price are equal
        price: getAssetUsdPriceFormatted(collateral.underlying.balance, collateral.address),
      })
    }

    if (collateral.ethBalance) {
      list.push({
        symbol: chainConfig.nativeCurrency.symbol,
        balance: getDisplayAmountFromAtomicAmount(
          collateral.ethBalance,
          chainConfig.mockAddress,
          true,
        ),
        price: getAssetUsdPriceFormatted(collateral.ethBalance, chainConfig.mockAddress),
      })
    }

    list.push({
      symbol: protocolStableSymbol,
      balance: getDisplayAmountFromAtomicAmount(usdpBalance, chainConfig.usdpAddress, true),
      price: getAssetUsdPriceFormatted(usdpBalance, chainConfig.usdpAddress),
    })

    return list
  }, [usdpBalance, prices, collateral, chainConfig, useWETH])

  return (
    <MainBlock pad="small">
      <MainBlockTitle>Your balances</MainBlockTitle>
      <UiTableHead cols={TABLE_COLS} />
      {pricesList.map((price, i) => (
        <StyledUiTableRow key={i}>
          <StyledUiTableRowCell>
            <TokenName>{price.symbol}</TokenName>
          </StyledUiTableRowCell>
          <StyledUiTableRowCell>{price.balance}</StyledUiTableRowCell>
          <StyledUiTableRowCell>{price.price}</StyledUiTableRowCell>
        </StyledUiTableRow>
      ))}
    </MainBlock>
  )
}
