import chainlinkAggregatorStore from '../store/chainlinkAggregatorStore'
import { EthUsd, EthUsdState } from '../types/chainlinkAggregator'
import useSubject from './useSubject'

function useEthUsdPrice(): EthUsd {
  const pricesState: EthUsdState = useSubject(chainlinkAggregatorStore)
  return pricesState.ethUsd
}

export default useEthUsdPrice
