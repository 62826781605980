import debug from 'debug'
import { ethers } from 'ethers'
import { BehaviorSubject } from 'rxjs'

import { TransactionsState, TxError, TxRecord, TxStatus } from '../types/transactions'

const log = debug('store:transactions')

export const errBeforeTx = new BehaviorSubject<TxError>(null)
export const transactions = new BehaviorSubject<TransactionsState>({
  lastTxHash: null,
  txByHash: {},
})

export const transactionsStore = { errBeforeTx, transactions }

const updateTransactionsSubject = (props: Partial<TransactionsState>) => {
  transactions.next({ ...transactions.getValue(), ...props })
}

export const getTxByHash = (hash: string): TxRecord | undefined =>
  transactions.getValue().txByHash[hash]

export const isTxPending = (hash: string): boolean =>
  Boolean(getTxByHash(hash)?.status === TxStatus.Pending)

const setLastTransaction = (tx: TxRecord) => {
  updateStorage(tx)

  log('new lastTransaction:', tx)
  updateTransactionsSubject({
    txByHash: { ...transactions.getValue().txByHash, [tx.hash]: tx },
    lastTxHash: tx.hash,
  })
  errBeforeTx.next(null)
}

export function addPendingTx(hash: string, from?: string): void {
  setLastTransaction({
    hash,
    from,
    status: TxStatus.Pending,
    error: null,
  })
}

function addError(error: TxError) {
  errBeforeTx.next(error)
  updateTransactionsSubject({ lastTxHash: null })
}

export function markTxFailed(error: any, hash: string): void {
  setLastTransaction({
    ...getTxByHash(hash),
    status: TxStatus.Failed,
    error,
  })
}

export function markTxMined(hash: string, from?: string): void {
  setLastTransaction({
    ...getTxByHash(hash),
    status: TxStatus.Mined,
    from,
  })
}

export async function trackTx(tx: ethers.ContractTransaction): Promise<void> {
  if (isTxPending(tx.hash)) return

  addPendingTx(tx.hash, tx.from)
  await tx.wait()
  markTxMined(tx.hash, tx.from)
}

export async function sendTransaction(
  contract: ethers.Contract,
  fnName: string,
  args: any[],
): Promise<ethers.ContractTransaction | TxError> {
  const getTxOptions = () => args[args.length - 1]
  const updateOptions = (props) => {
    args[args.length - 1] = { ...args[args.length - 1], ...props }
  }

  log('sendTransaction', fnName, '\noptions:', getTxOptions())

  let tx = null

  try {
    // try to estimate gas when gasLimit not set
    if (!getTxOptions().gasLimit) {
      updateOptions({ gasLimit: 3_000_000 })
      const gasLimit = await contract.estimateGas[fnName](...args)
      updateOptions({
        gasLimit: Number(gasLimit) > 100_000 ? Number(gasLimit) + 150_000 : gasLimit,
      })
    }

    log('sendTransaction - result options', getTxOptions())
    tx = await contract[fnName](...args)
    log('sendTransacton - tx:', tx)
    trackTx(tx)
    return tx
  } catch (err) {
    log('sendTransacton - error:', err, '\ntx:', tx)
    if (tx) {
      markTxFailed(err, tx)
    } else {
      addError(err)
    }
    // We save error to the store and handle it in the store subscribers.
    // To be able to ignore the error in further chain
    // instead of writing try-catch blocks everywhere,
    // we simply resolve the wrapped error.
    return { error: err }
  }
}

export const isTxError = (maybeTx: ethers.ContractTransaction | TxError): boolean =>
  Boolean(maybeTx.error)

export const safeWait = async (
  maybeTx: ethers.ContractTransaction | TxError | Promise<ethers.ContractTransaction | TxError>,
): Promise<ethers.ContractReceipt | null> => (await maybeTx).wait?.() || null

export const resetTransactionsStore = (): void => {
  errBeforeTx.next(null)
  updateTransactionsSubject({ lastTxHash: null, txByHash: {} })
}

export const getTransactionsFromStorage = (): {
  [key: string]: { [key: string]: { hash: string; status: number } }
} => {
  let sessionStore = null
  try {
    sessionStore = JSON.parse(sessionStorage.getItem('transactions') || '{}')
  } catch (err) {
    console.error('Failed to parse sessionStorage.transactions', err)
  }
  return Object.freeze({ ...sessionStore })
}

const updateStorage = (tx: TxRecord) => {
  sessionStorage.setItem(
    'transactions',
    JSON.stringify({
      ...getTransactionsFromStorage(),
      [tx.from]: {
        ...getTransactionsFromStorage()[tx.from],
        [tx.hash]: {
          hash: tx.hash,
          status: tx.status,
        },
      },
    }),
  )
}
