import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'
import { MAX_UINT } from 'src/constants/constants'
import { collateralsStore } from 'src/store/collateralsStore'
import { isTxError } from 'src/store/transactionsStore'

import { submitErc20TokenApprove } from '../contracts/erc20token/contractFunctions'
import { allowancesStore } from '../store/allowancesStore'
import { isKeydonixOracle } from '../store/oraclesStore'
import { getChainConfig, isBsc, userStore } from '../store/userStore'
import { CDPAllowances, WrapperAllowances } from '../types/approvals'
import { Collateral } from '../types/collaterals'
import $BN from '../utils/BigNumber'
import useSubject from './useSubject'

export const approveToken = async (
  signer: JsonRpcSigner,
  tokenAddress: string,
  spenderAddress = getChainConfig().contracts.vault,
): Promise<boolean> =>
  !isTxError(await submitErc20TokenApprove(tokenAddress, spenderAddress, MAX_UINT, signer))

export function useApprovals(
  amount: string,
  assetAddress: string,
  isDeposit = false,
): (() => any)[] {
  const signer = useSubject(userStore.signer)
  const remainingApprovals = []
  const { mockAddress, wethAddress, contracts } = getChainConfig()
  const collateralAddress = assetAddress === mockAddress ? wethAddress : assetAddress

  const allowances = useSubject<CDPAllowances>(allowancesStore.cdpAllowances)
  const allowance = allowances[collateralAddress]

  if (!allowance) return []

  if (assetAddress === mockAddress) {
    if (isDeposit) {
      if ($BN(amount).gt(allowance.vaultAllowance || 0)) {
        remainingApprovals.push(() => approveToken(signer, collateralAddress, contracts.vault))
      }

      return remainingApprovals
    }
    if ($BN(amount).gt(allowance.managerAllowance || 0)) {
      remainingApprovals.push(() => approveToken(signer, wethAddress, contracts.cdpManager))
    }

    return remainingApprovals
  }

  if ($BN(amount).gt(allowance.vaultAllowance || 0)) {
    remainingApprovals.push(() => approveToken(signer, collateralAddress, contracts.vault))
  }

  return remainingApprovals
}

export function useUsdpApproval(
  amount: string,
  assetAddress = '',
  isDeposit = false,
): (() => Promise<string>)[] {
  const remainingApprovals = []
  const signer = useSubject(userStore.signer)
  const { usdpAddress, contracts } = getChainConfig()
  const allowances = useSubject<CDPAllowances>(allowancesStore.cdpAllowances)
  const allowance = allowances[usdpAddress]

  if (!allowance) return []

  const vaultAllowance = allowance.vaultAllowance
  const managerAllowance = allowance.managerAllowance
  const managerFallbackAllowance = allowance.managerFallbackAllowance

  const keydonix =
    assetAddress && isKeydonixOracle(collateralsStore.getCollateralProps(assetAddress).oracleType)

  if (isDeposit && keydonix && !isBsc()) {
    if ($BN(amount).gt(managerFallbackAllowance || 0)) {
      remainingApprovals.push(() => approveToken(signer, usdpAddress, contracts.cdpManagerFallback))
    }
  } else if (isDeposit && !isBsc()) {
    if ($BN(amount).gt(managerAllowance || 0)) {
      remainingApprovals.push(() => approveToken(signer, usdpAddress, contracts.cdpManager))
    }
  } else if (!isDeposit && $BN(amount).gt(vaultAllowance || 0)) {
    remainingApprovals.push(() => approveToken(signer, usdpAddress, contracts.vault))
  }

  return remainingApprovals
}

export function useWrapperApproval(collateral: Collateral, amount: string): (() => any)[] {
  const approvals = []

  const signer = useSubject(userStore.signer)
  const allowances = useSubject<WrapperAllowances>(allowancesStore.wrapperAllowances)

  const allowanceForWrap = allowances[collateral?.underlying?.address] || 0

  if ($BN(amount).gt(allowanceForWrap)) {
    approvals.push(() => approveToken(signer, collateral.underlying.address, collateral.address))
  }

  return approvals
}
