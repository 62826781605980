import React from 'react'
import { useAppChain, useIsConnected } from 'src/hooks/useUserStore'

import { CDP } from '../../../../../types/cdp'
import { Collateral } from '../../../../../types/collaterals'
import $BN from '../../../../../utils/BigNumber'
import { formatNumber, getDisplayAmountFromAtomicAmount } from '../../../../../utils/utils'
import {
  CollateralListItemHeader,
  CollateralListItemPointersContainer,
  CollateralListItemUserOnBalance,
  CollateralListItemUserOwnCDP,
  CollateralsListItemName,
  CollateralsListItemParam,
  CollateralsListItemParams,
  StyledCollateralsListItem,
} from './styled'

export const CollateralsListItem: React.FC<{
  collateral: Collateral
  cdp: CDP
}> = ({ collateral, cdp }) => {
  const isConnected = useIsConnected()
  const { usdpAddress } = useAppChain().config

  return (
    <StyledCollateralsListItem to={`asset/${collateral.symbol}`}>
      <CollateralListItemHeader>
        <CollateralsListItemName>{collateral.symbol}</CollateralsListItemName>
        {isConnected && (
          <CollateralListItemPointersContainer>
            {$BN(cdp.cdp_deposit).gt(0) && <CollateralListItemUserOwnCDP />}
            {$BN(collateral.balance)
              .plus(collateral.ethBalance || 0)
              .gt(0) && <CollateralListItemUserOnBalance />}
          </CollateralListItemPointersContainer>
        )}
      </CollateralListItemHeader>
      <CollateralsListItemParams>
        <CollateralsListItemParam>
          <span>Borrowable</span>
          <span>
            {formatNumber(getDisplayAmountFromAtomicAmount(cdp.usdpMintable, usdpAddress))}
          </span>
        </CollateralsListItemParam>
        <CollateralsListItemParam>
          <span>ICR</span>
          <span>{cdp.icr}%</span>
        </CollateralsListItemParam>
      </CollateralsListItemParams>
    </StyledCollateralsListItem>
  )
}
