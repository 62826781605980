import styled from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../../../uiKit/theme'

export const StyledLeftSideBarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2.5rem;
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-bottom: 0;
    width: auto;
    position: sticky;
    top: 2rem;
    padding-right: 2.5rem;
    flex: 1 0 10px;
  }
`

export const StyledLeftSideBarInner = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  @media screen and (min-width: ${breakpoint.desktop}) {
    display: block;
    margin-left: auto;
    width: 41rem;
  }
`

export const LeftSideBarFilter = styled.div`
  display: flex;
  background: ${color.white};
  border-radius: ${borderRadius.normal};
  padding: 2rem 4rem;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  @media screen and (min-width: ${breakpoint.desktop}) {
    align-items: flex-start;
    flex-direction: column;
    margin-right: 0;
    cursor: initial;
  }
`

export const FiltersContainer = styled.div<{ showFilters: boolean }>`
  position: absolute;
  left: 0;
  top: calc(100% + 1rem);
  background: ${color.white};
  opacity: ${(p) => (p.showFilters ? 1 : 0)};
  visibility: ${(p) => (p.showFilters ? 'visible' : 'hidden')};
  padding: 2rem 4rem;
  white-space: nowrap;
  border-radius: ${borderRadius.normal};
  @media screen and (min-width: ${breakpoint.desktop}) {
    position: initial;
    opacity: 1 !important;
    visibility: visible !important;
    padding: 0;
    margin: 0;
  }
`

export const LeftSideBarTtl = styled.span`
  ${fontFamily.maru}
  font-size: 3rem;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${breakpoint.desktop}) {
    margin-bottom: 4rem;
  }
  span {
    display: none;
    @media screen and (min-width: ${breakpoint.desktop}) {
      display: block;
    }
  }
  img {
    width: 3rem;
    @media screen and (min-width: ${breakpoint.desktop}) {
      margin-left: 1.5rem;
    }
  }
`

export const FilterOption = styled.span<{ onlyMobile?: boolean }>`
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  color: #515151;
  cursor: pointer;
  :not(:last-child) {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: ${breakpoint.desktop}) {
    font-size: 2rem;
    display: ${(p) => (p.onlyMobile ? 'none' : 'block')};
  }
`
