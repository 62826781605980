import debug from 'debug'
import { BehaviorSubject } from 'rxjs'

import { ZERO_ADDRESS } from '../constants/constants'
import { trackCdpManagerExit, trackCdpManagerJoin } from '../contracts/cdpmanager/eventListeners'
import {
  trackCdpManagerFallbackExit,
  trackCdpManagerFallbackJoin,
} from '../contracts/cdpmanagerfallback/eventListeners'
import {
  getCdpViewerGetCollateralParameters,
  getCdpViewerGetMultiCollateralParameters,
} from '../contracts/cdpviewer/contractFunctions'
import { CDP, CDPFromChain, CDPList } from '../types/cdp'
import $BN from '../utils/BigNumber'
import { userStore } from './userStore'

const log = debug('store:cdpStore')

export const cdpStore = {
  cdpList: new BehaviorSubject<CDPList>({}),

  async init(collaterals: string[], userAddress: string): Promise<void> {
    log('Init start')

    const cdpsBatches = await getParametersOfCDPs(collaterals, userAddress)
    const cdps = cdpsBatches.reduce((acc, curr) => acc.concat(curr), [])

    log('cdps from chain', cdps)

    const formattedCdps = {}

    cdps.forEach((cdp, i) => {
      formattedCdps[collaterals[i]] = formCdpProps(cdp, collaterals[i])
    })

    cdpStore.cdpList.next(formattedCdps)

    if (userAddress !== ZERO_ADDRESS) initializeCdpsTracking(userAddress)

    log('Formatted cdps', formattedCdps)
    log('Init end')
  },

  getCdpByAddress(address: string): CDP {
    return cdpStore.cdpList.getValue()[address.toLowerCase()]
  },

  clearCdpsStore(): void {
    cdpStore.cdpList.next({})
  },
}

async function getParametersOfCDPs(
  collaterals: string[],
  owner: string,
): Promise<CDPFromChain[][]> {
  const maxParamsCallCount = 70
  const paramsPromises = []
  let paramsFetchedCount = 0

  while (paramsFetchedCount < collaterals.length) {
    const nextBatch = collaterals.slice(
      paramsFetchedCount,
      (paramsFetchedCount += maxParamsCallCount),
    )
    paramsPromises.push(getCdpViewerGetMultiCollateralParameters(nextBatch, owner))
  }

  return Promise.all(paramsPromises)
}

function formCdpProps(cdp: CDPFromChain, collateralAddress: string): CDP {
  const usdpMintable = calculateUSDPMintable(cdp)
  return {
    collateralAddress,
    usdpMintable,
    bf: cdp[9],
    cdpTotalDebt: cdp[10][2].toString(),
    cdp_debt: cdp[10][1].toString(),
    cdp_deposit: cdp[10][0].toString(),
    cdp_lastUpdate: cdp[10][4],
    cdp_lf: cdp[10][5],
    cdp_ot: cdp[10][6],
    cdp_sf: cdp[10][3],
    devaluationPeriod: cdp[4],
    icr: cdp[6],
    liquidationDiscount: cdp[3],
    lf: cdp[7],
    lr: cdp[5],
    ot: cdp[8],
    sf: cdp[2],
    td: cdp[1].toString(),
    tdl: cdp[0].toString(),
  }
}

async function updateCdp(collateralAddress: string) {
  log(`updateCdp ${collateralAddress}`)
  const owner = userStore.address.getValue()

  const cdpParams = await getCdpViewerGetCollateralParameters(collateralAddress, owner)
  const formattedCDPParams = formCdpProps(cdpParams, collateralAddress)

  cdpStore.cdpList.next({
    ...cdpStore.cdpList.getValue(),
    ...{ [formattedCDPParams.collateralAddress]: formattedCDPParams },
  })
}

function initializeCdpsTracking(walletAddress: string) {
  const trackParams = {
    callback: async ([event]) => {
      if (!event) return
      const collateralAddress = `0x${event.topics[1].slice(-40)}`
      await updateCdp(collateralAddress)
    },
    asset: '',
    owner: walletAddress,
  }

  trackCdpManagerFallbackJoin(trackParams)
  trackCdpManagerFallbackExit(trackParams)
  trackCdpManagerJoin(trackParams)
  trackCdpManagerExit(trackParams)
}

function calculateUSDPMintable(cdp: CDPFromChain): string {
  if ($BN(cdp[1].toString()).gt(cdp[0].toString())) return '0'
  return $BN(cdp[0].toString()).minus(cdp[1].toString()).toString()
}
