import React, { useEffect, useState } from 'react'
import { Attention } from 'src/components/Attention'
import { CDPParamsChanges } from 'src/components/CDPActions/CDPParamsChanges'
import { useIsConnected, useIsSameNetworks } from 'src/hooks/useUserStore'
import { useSetModal } from 'src/providers/ModalsProvider'
import { isTxError } from 'src/store/transactionsStore'
import { getChainConfig } from 'src/store/userStore'
import { numberInputReg } from 'src/utils/input'
import { getExecuteBtnLabel } from 'src/utils/ui'

import {
  InputGroupContainer,
  TokenInputContainer,
} from '../../../../components/CDPActions/DepositAndBorrow/styled'
import { protocolStableSymbol } from '../../../../constants/usdp'
import { useApprovals, useUsdpApproval } from '../../../../hooks/useApprovals'
import useEthUsdPrice from '../../../../hooks/useEthUsdPrice'
import useOracles from '../../../../hooks/useOracles'
import useSubject from '../../../../hooks/useSubject'
import { repayAndWithdraw } from '../../../../hooks/useVaultManager'
import currentCollateralStore from '../../../../store/currentCollateralStore'
import { usdpStore } from '../../../../store/usdpStore'
import { CDP } from '../../../../types/cdp'
import { Collateral } from '../../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { NoCDPMessage } from '../../../../uiKit/NoCDPMessage'
import { TokenInput } from '../../../../uiKit/TokenInput'
import { UIButton } from '../../../../uiKit/UiButton/styled'
import $BN from '../../../../utils/BigNumber'
import { calculateFee, getMaxMainWithdrawable } from '../../../../utils/CDP'
import { getAtomicAmountFromDisplayAmount } from '../../../../utils/utils'

export const WithdrawAndRepay: React.FC<{ cdp: CDP; collateral: Collateral }> = ({
  cdp,
  collateral,
}) => {
  const prices = useOracles()
  const ethUsd = useEthUsdPrice()
  const usdpBalance = useSubject<string>(usdpStore.balance)
  const isConnected = useIsConnected()
  const wrongNetwork = !useIsSameNetworks()
  const assetAddress = cdp.collateralAddress
  const useWeth = useSubject(currentCollateralStore.useWeth)
  const setModal = useSetModal()

  const [assetAmount, setAssetAmount] = useState('0')
  const [usdpAmount, setUsdpAmount] = useState('0')
  const [repayAllValue, setRepayAllValue] = useState('0')
  const [maxRepayValue, setMaxRepayValue] = useState('0')
  const [maxWithdrawalValue, setMaxWithdrawalValue] = useState('0')
  const [isRepayAll, setIsReapyAll] = useState(false)
  const [showSFAttention, setShowSFAttention] = useState(false)
  const [showLFAttention, setShowLFAttention] = useState(false)
  const underlyingAddress = collateral.type === 'shiba' ? collateral.underlying.address : null

  const { wethAddress, usdpAddress } = getChainConfig()

  useEffect(() => {
    if ($BN(cdp.sf).eq(cdp.cdp_sf)) setShowSFAttention(false)
    else if ($BN(cdp.cdp_sf).eq(0)) setShowSFAttention(false)
    else setShowSFAttention(true)
  }, [cdp])

  useEffect(() => {
    if ($BN(cdp.lf).eq(cdp.cdp_lf)) setShowLFAttention(false)
    else if ($BN(cdp.cdp_lf).eq(0)) setShowLFAttention(false)
    else setShowLFAttention(true)
  }, [cdp])

  const isEth = assetAddress === wethAddress && !useWeth

  useEffect(() => {
    setIsReapyAll(getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress) === repayAllValue)
  }, [usdpAmount, repayAllValue])

  const feeValue = calculateFee(cdp, getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress))

  useEffect(() => {
    const totalDebt = cdp.cdpTotalDebt
    const cdp_deposit = cdp.cdp_deposit
    const usdpBalanceAtomic = usdpBalance
    const usdpAmountAtomic = getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress)

    const isRepayAllPossible = $BN(usdpBalanceAtomic).gt(totalDebt)

    setRepayAllValue(totalDebt)

    const usdpMax = isRepayAllPossible ? totalDebt : usdpBalanceAtomic

    setMaxRepayValue(usdpMax.toString())

    setMaxWithdrawalValue(
      getMaxMainWithdrawable(
        cdp,
        ethUsd,
        prices,
        assetAddress,
        cdp_deposit.toString(),
        totalDebt,
        $BN(usdpAmountAtomic).gte(totalDebt)
          ? $BN(usdpAmountAtomic).toString()
          : $BN(usdpAmountAtomic).eq(0)
          ? $BN(0).minus($BN(totalDebt).div(10000)).toString()
          : $BN(usdpAmountAtomic).minus($BN(usdpAmountAtomic).div(10000)).toString(),
      ),
    )
  }, [cdp, ethUsd, prices, usdpAmount, usdpBalance, assetAddress, feeValue])

  const usdpApprovals = useUsdpApproval(getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress))

  const remainingApprovalsCollateral = useApprovals(
    getAtomicAmountFromDisplayAmount(assetAmount, assetAddress),
    getChainConfig().mockAddress,
  )

  const remainingApprovals = usdpApprovals.concat(isEth ? remainingApprovalsCollateral : [])

  const fullPrice = prices[assetAddress] && prices[assetAddress].full

  function clearValues() {
    setAssetAmount('0')
    setUsdpAmount('0')
  }

  const footer = (
    <>
      <CDPParamsChanges assetAmount={`-${assetAmount}`} usdpAmount={`-${usdpAmount}`} />
      <div style={{ textAlign: 'center' }}>
        <UIButton
          disabled={!isConnected || !fullPrice || wrongNetwork}
          onClick={async () => {
            setModal({ key: 'transaction' })
            // eslint-disable-next-line no-restricted-syntax
            for (const a of remainingApprovals) {
              if (!(await a())) {
                setModal(null)
                return
              }
            }
            const tx = await repayAndWithdraw(
              assetAddress,
              assetAmount,
              usdpAmount,
              useWeth,
              feeValue,
              isRepayAll,
              !!remainingApprovals.length,
              !!underlyingAddress,
            )
            if (isTxError(tx)) {
              clearValues()
            }
            setModal(null)
          }}
        >
          {getExecuteBtnLabel(isConnected, wrongNetwork, fullPrice, remainingApprovals.length > 0)}
        </UIButton>
      </div>
    </>
  )

  return (
    <>
      {isConnected && (
        <MainBlock pad="small">
          <div>
            {showSFAttention && (
              <Attention>
                Withdrawing or borrowing will change the stability fee from{' '}
                {$BN(cdp.cdp_sf).div(1000).toString()}% to {$BN(cdp.sf).div(1000).toString()}%
              </Attention>
            )}
            {showLFAttention && (
              <Attention>
                Withdrawing or borrowing will change the liquidation fee from{' '}
                {$BN(cdp.cdp_lf).toString()}% to {$BN(cdp.lf).toString()}%
              </Attention>
            )}
          </div>
          <MainBlockTitle>Repay {protocolStableSymbol} & Withdraw collateral</MainBlockTitle>
          {$BN(cdp.cdp_debt).lte(0) && $BN(cdp.cdp_deposit).lte(0) ? (
            <NoCDPMessage />
          ) : (
            <>
              <InputGroupContainer>
                <TokenInputContainer>
                  <TokenInput
                    value={usdpAmount}
                    tokenAddress={usdpAddress}
                    onInput={(e) => setUsdpAmount(numberInputReg(e.target.value))}
                    onMax={(e) => setUsdpAmount(e.toString())}
                    maxValue={maxRepayValue || '0'}
                    errorMessage="Repayable limit reached"
                  />
                </TokenInputContainer>
                <TokenInputContainer>
                  <TokenInput
                    value={assetAmount}
                    tokenAddress={currentCollateralStore.address.getValue()}
                    onInput={(e) => setAssetAmount(numberInputReg(e.target.value))}
                    onMax={(e) => setAssetAmount(e.toString())}
                    maxValue={maxWithdrawalValue}
                    errorMessage="Withdrawal limit reached"
                  />
                </TokenInputContainer>
              </InputGroupContainer>
              {footer}
            </>
          )}
        </MainBlock>
      )}
    </>
  )
}
