import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/Vault/old.json'

export function getVaultContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.vault, abi, provider)
}

export function getVaultDENOMINATOR_1E2() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.DENOMINATOR_1E2().catch((e) => {
    console.error('Failed to call DENOMINATOR_1E2 in Vault:', e)
  })
}

export function getVaultDENOMINATOR_1E5() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.DENOMINATOR_1E5().catch((e) => {
    console.error('Failed to call DENOMINATOR_1E5 in Vault:', e)
  })
}

export function submitVaultBorrow(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'borrow', [asset, user, amount, { ...options }])
}

export function getVaultCalculateFee(asset, user, amount) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.calculateFee(asset, user, amount).catch((e) => {
    console.error('Failed to call calculateFee in Vault:', e)
  })
}

export function submitVaultChangeOracleType(asset, user, newOracleType, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'changeOracleType', [asset, user, newOracleType, { ...options }])
}

export function submitVaultChargeFee(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'chargeFee', [asset, user, amount, { ...options }])
}

export function getVaultCol() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.col().catch((e) => {
    console.error('Failed to call col in Vault:', e)
  })
}

export function getVaultColToken(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.colToken(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call colToken in Vault:', e)
  })
}

export function getVaultCollaterals(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.collaterals(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call collaterals in Vault:', e)
  })
}

export function getVaultDebts(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.debts(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call debts in Vault:', e)
  })
}

export function submitVaultDepositCol(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'depositCol', [asset, user, amount, { ...options }])
}

export function submitVaultDepositEth(ethAmount, user, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'depositEth', [user, { ...options, value: ethAmount }])
}

export function submitVaultDepositMain(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'depositMain', [asset, user, amount, { ...options }])
}

export function submitVaultDestroy(asset, user, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'destroy', [asset, user, { ...options }])
}

export function getVaultGetTotalDebt(asset, user) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.getTotalDebt(asset, user).catch((e) => {
    console.error('Failed to call getTotalDebt in Vault:', e)
  })
}

export function getVaultLastUpdate(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.lastUpdate(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call lastUpdate in Vault:', e)
  })
}

export function submitVaultLiquidate(
  asset,
  positionOwner,
  mainAssetToLiquidator,
  colToLiquidator,
  mainAssetToPositionOwner,
  colToPositionOwner,
  repayment,
  penalty,
  liquidator,
  signer,
  options = {},
) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'liquidate', [
    asset,
    positionOwner,
    mainAssetToLiquidator,
    colToLiquidator,
    mainAssetToPositionOwner,
    colToPositionOwner,
    repayment,
    penalty,
    liquidator,
    { ...options },
  ])
}

export function getVaultLiquidationBlock(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.liquidationBlock(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call liquidationBlock in Vault:', e)
  })
}

export function getVaultLiquidationFee(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.liquidationFee(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call liquidationFee in Vault:', e)
  })
}

export function getVaultLiquidationPrice(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.liquidationPrice(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call liquidationPrice in Vault:', e)
  })
}

export function getVaultOracleType(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.oracleType(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call oracleType in Vault:', e)
  })
}

export function submitVaultRepay(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'repay', [asset, user, amount, { ...options }])
}

export function submitVaultSpawn(asset, user, _oracleType, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'spawn', [asset, user, _oracleType, { ...options }])
}

export function getVaultStabilityFee(addressInput1, addressInput2) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.stabilityFee(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call stabilityFee in Vault:', e)
  })
}

export function getVaultTokenDebts(addressInput1) {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.tokenDebts(addressInput1).catch((e) => {
    console.error('Failed to call tokenDebts in Vault:', e)
  })
}

export function submitVaultTriggerLiquidation(
  asset,
  positionOwner,
  initialPrice,
  signer,
  options = {},
) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'triggerLiquidation', [
    asset,
    positionOwner,
    initialPrice,
    { ...options },
  ])
}

export function submitVaultUpdate(asset, user, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'update', [asset, user, { ...options }])
}

export function getVaultUsdp() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.usdp().catch((e) => {
    console.error('Failed to call usdp in Vault:', e)
  })
}

export function getVaultVaultParameters() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in Vault:', e)
  })
}

export function getVaultWeth() {
  const contract = getVaultContract(getChain().ethersProvider)
  return contract.weth().catch((e) => {
    console.error('Failed to call weth in Vault:', e)
  })
}

export function submitVaultWithdrawCol(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'withdrawCol', [asset, user, amount, { ...options }])
}

export function submitVaultWithdrawEth(user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'withdrawEth', [user, amount, { ...options }])
}

export function submitVaultWithdrawMain(asset, user, amount, signer, options = {}) {
  const contract = getVaultContract(signer)
  return sendTransaction(contract, 'withdrawMain', [asset, user, amount, { ...options }])
}
