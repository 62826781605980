import oraclesStore from '../store/oraclesStore'
import { Price, PricesState } from '../types/oracles'
import useSubject from './useSubject'

function useOracles(): { [address: string]: Price } {
  const pricesState: PricesState = useSubject(oraclesStore)
  return pricesState.prices
}

export default useOracles
