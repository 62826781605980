import styled from 'styled-components'

import { breakpoint } from '../../../uiKit/theme'
import { UIButton } from '../../../uiKit/UiButton/styled'

export const ClaimBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${breakpoint.tablet}) {
    width: 45%;
  }
  ${UIButton} {
    margin-top: 0;
    margin-left: 0;
    @media (min-width: ${breakpoint.tablet}) {
      margin-top: 4rem;
    }
  }
`

export const ClaimBlocksInfo = styled.div`
  font-size: 2rem;
  margin-bottom: auto;
  opacity: 0.6;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.6em;
  }
  span {
    font-weight: bold;
  }
`

export const ClaimableToken = styled.div`
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ClaimBlocksGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: stretch;
  }
  ${ClaimBlock}:not(:last-child) {
    margin-bottom: 6rem;
    @media (min-width: ${breakpoint.tablet}) {
      margin-bottom: 0;
    }
  }
`
