import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import abi from 'src/contracts/abis/CDPViewer.json'

export function getCdpViewerContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.cdpViewer, abi, provider)
}

export function getCdpViewerGetCollateralParameters(asset, owner) {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.getCollateralParameters(asset, owner).catch((e) => {
    console.error('Failed to call getCollateralParameters in cdpViewer:', e)
  })
}

export function getCdpViewerGetMultiCollateralParameters(assets, owner) {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.getMultiCollateralParameters(assets, owner).catch((e) => {
    console.error('Failed to call getMultiCollateralParameters in cdpViewer:', e)
  })
}

export function getCdpViewerGetMultiTokenDetails(assets, owner) {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.getMultiTokenDetails(assets, owner).catch((e) => {
    console.error('Failed to call getMultiTokenDetails in cdpViewer:', e)
  })
}

export function getCdpViewerGetTokenDetails(asset, owner) {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.getTokenDetails(asset, owner).catch((e) => {
    console.error('Failed to call getTokenDetails in cdpViewer:', e)
  })
}

export function getCdpViewerOracleRegistry() {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.oracleRegistry().catch((e) => {
    console.error('Failed to call oracleRegistry in cdpViewer:', e)
  })
}

export function getCdpViewerVault() {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.vault().catch((e) => {
    console.error('Failed to call vault in cdpViewer:', e)
  })
}

export function getCdpViewerVaultManagerBorrowFeeParameters() {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.vaultManagerBorrowFeeParameters().catch((e) => {
    console.error('Failed to call vaultManagerBorrowFeeParameters in cdpViewer:', e)
  })
}

export function getCdpViewerVaultManagerParameters() {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.vaultManagerParameters().catch((e) => {
    console.error('Failed to call vaultManagerParameters in cdpViewer:', e)
  })
}

export function getCdpViewerVaultParameters() {
  const contract = getCdpViewerContract(getChain().ethersProvider)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in cdpViewer:', e)
  })
}
