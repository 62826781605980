import { BehaviorSubject } from 'rxjs'
import blockStore from 'src/store/blockStore'
import chainlinkAggregatorStore from 'src/store/chainlinkAggregatorStore'
import currentCollateralStore from 'src/store/currentCollateralStore'
import infoModalStore from 'src/store/infoModalStore'
import { liquidationsStore } from 'src/store/liquidationsStore'
import { lockingStore } from 'src/store/lockingStore'
import oraclesStore from 'src/store/oraclesStore'
import { stakeStore } from 'src/store/stakeStore'
import { stakeUSDPStore } from 'src/store/stakeUSDPStore'
import { transactionsStore } from 'src/store/transactionsStore'
import { usdpStore } from 'src/store/usdpStore'
import { userStore } from 'src/store/userStore'

import { collateralsStore } from '../store/collateralsStore'

/**
 * - returns store as simple object
 * - useful for debug purposes
 */
const getStore = () => {
  const makeStorePrintable = (store) => {
    if (store instanceof BehaviorSubject) return store.getValue()
    return store && typeof store === 'object' ? extractValues(store) : store
  }

  const { entries, fromEntries } = Object

  const filterOutFunctions = (x) =>
    x && typeof x === 'object'
      ? fromEntries(entries(x).filter(([, val]) => typeof val !== 'function'))
      : x

  const extractValues = (obj: Record<string, BehaviorSubject<any> | any>) =>
    fromEntries(
      entries(obj)
        .map(([name, store]) => [name, makeStorePrintable(store)])
        .map(([name, store]) => [name, filterOutFunctions(store)]),
    )

  return extractValues({
    blockStore,
    chainlinkAggregatorStore,
    collateralsStore,
    currentCollateralStore,
    infoModalStore,
    liquidationsStore,
    lockingStore,
    oraclesStore,
    stakeStore,
    stakeUSDPStore,
    transactionsStore,
    usdpStore: usdpStore.balance,
    userStore,
  })
}

Object.defineProperty(window, 'upStore', { get: getStore })
