import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../../../../../uiKit/theme'

export const CollateralsListItemName = styled.span`
  ${fontFamily.maru}
  font-size: 3rem;
`

export const CollateralsListItemParams = styled.div`
  width: 100%;
  font-size: 2rem;
  display: flex;
  margin-top: 3rem;
  flex: 1 100%;
  @media screen and (min-width: ${breakpoint.tablet}) {
    margin-top: 5rem;
  }
`

export const CollateralsListItemParam = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  span:first-child {
    color: ${color.gray};
    margin-bottom: 0.6rem;
    display: block;
  }
`

export const StyledCollateralsListItem = styled(Link)`
  background: #fff;
  width: 100%;
  margin-bottom: 1%;
  border-radius: ${borderRadius.normal};
  padding: 2rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: block;
  align-items: center;
  text-decoration: none;
  color: inherit;
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: calc(50% - 0.5rem);
  }
  :hover {
    transform: scale(1.02);
    background: ${color.purple};
    color: ${color.liteGray};
    ${CollateralsListItemParam} {
      span:first-child {
        color: ${color.liteGray};
      }
    }
    ${CollateralsListItemName} {
      color: ${color.liteGray};
    }
  }
`

export const CollateralListItemHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 1 100%;
`

export const CollateralListItemPointersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const PointerCss = css`
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 0.5rem;
`

export const CollateralListItemUserOnBalance = styled.div`
  background: ${color.CDPDots.withBalance};
  ${PointerCss}
`

export const CollateralListItemUserOwnCDP = styled.div`
  background: ${color.CDPDots.opened};
  ${PointerCss}
`
