import React, { useEffect } from 'react'

import { GAUGED_3CRV } from '../../../constants/constants'
import useSubject from '../../../hooks/useSubject'
import { claimStore } from '../../../store/claimStore'
import { ClaimStore } from '../../../types/claim'
import { Collateral } from '../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../uiKit/MainBlock'
import { TokenName } from '../../../uiKit/TokenInput/styled'
import { UIButton } from '../../../uiKit/UiButton/styled'
import $BN from '../../../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount } from '../../../utils/utils'
import { ClaimableToken, ClaimBlock, ClaimBlocksGroup, ClaimBlocksInfo } from './styled'

export const ClaimRewards: React.FC<{ collateral: Collateral }> = ({ collateral }) => {
  const claims = useSubject<ClaimStore>(claimStore.claims)
  const tokenClaims = claims[collateral.address]

  useEffect(() => {
    claimStore.fetchAndStoreClaims(collateral)
  }, [])

  if (
    $BN(tokenClaims?.claimable).gt(0) ||
    $BN(tokenClaims?.pending).gt(0) ||
    $BN(tokenClaims?.totalPendingInfo?.totalPendingRewards).gt(0)
  ) {
    return (
      <MainBlock pad="small">
        <MainBlockTitle>Claim your rewards</MainBlockTitle>
        <ClaimBlocksGroup>
          <ClaimBlock>
            <ClaimableToken>
              <TokenName>{tokenClaims.claimableToken.symbol}</TokenName>
              <span>
                {getDisplayAmountFromAtomicAmount(
                  tokenClaims.claimable,
                  tokenClaims.claimableToken.address,
                  true,
                )}
              </span>
            </ClaimableToken>
            <ClaimBlocksInfo />
            <UIButton onClick={tokenClaims.claimAction}>Claim</UIButton>
          </ClaimBlock>
          {collateral.address !== GAUGED_3CRV && (
            <ClaimBlock>
              <ClaimableToken>
                <TokenName>Pending {tokenClaims.claimableToken.symbol}</TokenName>
                <span>
                  {getDisplayAmountFromAtomicAmount(
                    tokenClaims.pending,
                    tokenClaims.claimableToken.address,
                    true,
                  )}
                </span>
              </ClaimableToken>
              {tokenClaims.totalPendingInfo?.totalPendingRewards !== '0' && (
                <ClaimBlocksInfo>
                  <div>
                    Next claim date: <span>{tokenClaims.totalPendingInfo.nextUnlockDate}</span>
                  </div>
                  <div>
                    Next claimable:{' '}
                    <span>
                      {getDisplayAmountFromAtomicAmount(
                        tokenClaims.totalPendingInfo?.nextUnlockValue,
                        tokenClaims.claimableToken.address,
                        true,
                      )}{' '}
                      {tokenClaims.claimableToken.symbol}
                    </span>
                  </div>
                  <div>
                    Total pending:{' '}
                    <span>
                      {getDisplayAmountFromAtomicAmount(
                        tokenClaims.totalPendingInfo?.totalPendingRewards,
                        tokenClaims.claimableToken.address,
                        true,
                      )}{' '}
                      {tokenClaims.claimableToken.symbol}
                    </span>
                  </div>
                </ClaimBlocksInfo>
              )}
              <UIButton onClick={tokenClaims.claimPendingAction}>Claim</UIButton>
            </ClaimBlock>
          )}
        </ClaimBlocksGroup>
      </MainBlock>
    )
  }
  return null
}
