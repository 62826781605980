import { useMemo } from 'react'
import { collateralsStore } from 'src/store/collateralsStore'

import { TOKENS_WITH_UNIQUE_PRECISION } from '../constants/identifiers'
import { cdpStore } from '../store/cdpStore'
import currentCollateralStore from '../store/currentCollateralStore'
import { CDPList } from '../types/cdp'
import { StoreCollaterals } from '../types/collaterals'
import $BN from '../utils/BigNumber'
import { calculateFee } from '../utils/CDP'
import { getAtomicAmountFromDisplayAmount } from '../utils/utils'
import useSubject from './useSubject'
import { useAppChain } from './useUserStore'

export default (assetAmount = '0', usdpAmount = '0'): string | undefined => {
  const collateralAddress = useSubject(currentCollateralStore.address)
  const collaterals = useSubject<StoreCollaterals>(collateralsStore.collaterals)
  const cdps = useSubject<CDPList>(cdpStore.cdpList)
  const chainConfig = useAppChain().config

  const collateral = collaterals[collateralAddress]
  const cdp = cdps[collateralAddress]

  return useMemo(() => {
    const newDebt = $BN(cdp.cdp_debt)
      .plus(getAtomicAmountFromDisplayAmount(usdpAmount, chainConfig.usdpAddress))
      .toString()
    const newDeposit = $BN(cdp.cdp_deposit).plus(
      getAtomicAmountFromDisplayAmount(assetAmount, collateralAddress),
    )
    const hasLiquidationPrice = $BN(newDebt).gt(0)

    if (hasLiquidationPrice) {
      const totalDebt = $BN(newDebt).plus(calculateFee(cdp, cdp.cdp_debt)).toString()
      const liquidationPriceTotal = $BN(totalDebt).times(100).div(cdp.lr).toString()
      const denom = $BN(newDeposit)
        .times($BN(10).pow(18 - collateral.decimals))
        .toString()

      if ($BN(denom).eq(0)) return undefined

      const precision = TOKENS_WITH_UNIQUE_PRECISION[collateralAddress]
        ? TOKENS_WITH_UNIQUE_PRECISION[collateralAddress].precision
        : 2

      return $BN(liquidationPriceTotal.toString()).div(denom.toString()).toFixed(precision)
    }
    return undefined
  }, [collaterals, cdps, usdpAmount, assetAmount, collateralAddress, chainConfig])
}
