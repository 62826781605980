import React, { useEffect, useState } from 'react'

import FilterIcon from '../../../../assets/images/icons/ic-filters.svg'
import { collateralsFilterStore } from '../../../../store/collateralsFilterStore'
import { isMainnet } from '../../../../store/userStore'
import { CollateralsListSearch } from '../CollateralsList/CollateralsListSearch'
import {
  FilterOption,
  FiltersContainer,
  LeftSideBarFilter,
  LeftSideBarTtl,
  StyledLeftSideBarContainer,
  StyledLeftSideBarInner,
} from './styled'

const getFilters = (mainnet: boolean) =>
  [
    {
      label: 'All',
      type: 'All',
    },
    {
      label: 'Your portfolio',
      type: 'With balance',
      onlyMobile: true,
    },
    {
      label: 'Open positions',
      type: 'Opened',
      onlyMobile: true,
    },
    {
      label: 'Tokens',
      type: 'Token',
    },
    {
      label: 'Stablecoins',
      type: 'Stable',
    },
    {
      label: 'Uni V2 LP',
      type: 'Uni V2 LP',
      disabled: !mainnet,
    },
    {
      label: 'Sushi LP',
      type: 'Sushi LP',
      disabled: !mainnet,
    },
    {
      label: 'Shiba LP',
      type: 'Shiba LP',
      disabled: !mainnet,
    },
    {
      label: 'Compound',
      type: 'Compound',
      disabled: !mainnet,
    },
    {
      label: 'Yearn vaults',
      type: 'Yearn Vaults',
      disabled: !mainnet,
    },
  ].filter((el) => !el.disabled)

export const LeftSideBar: React.FC = () => {
  const [showFilters, setShowFilters] = useState(false)

  function onFilterClick(filter) {
    collateralsFilterStore.setFilter(filter.type)
  }

  function documentClickListener() {
    setShowFilters(false)
  }

  useEffect(() => {
    document.addEventListener('click', documentClickListener)
    return () => document.removeEventListener('click', documentClickListener)
  }, [])

  return (
    <StyledLeftSideBarContainer>
      <StyledLeftSideBarInner>
        <CollateralsListSearch />
        <LeftSideBarFilter
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation()
            setShowFilters(!showFilters)
          }}
        >
          <LeftSideBarTtl>
            <span>Filters</span>
            <img src={FilterIcon} alt="filter icon" />
          </LeftSideBarTtl>
          <FiltersContainer showFilters={showFilters}>
            {getFilters(isMainnet()).map((filter) => (
              <FilterOption
                key={filter.type}
                onlyMobile={filter.onlyMobile}
                onClick={() => onFilterClick(filter)}
              >
                {filter.label}
              </FilterOption>
            ))}
          </FiltersContainer>
        </LeftSideBarFilter>
      </StyledLeftSideBarInner>
    </StyledLeftSideBarContainer>
  )
}
