import styled from 'styled-components'

import { borderRadius, color, fontFamily } from '../theme'

export const StyledTokenInput = styled.input<{
  onInput: any
  error: any
}>`
  width: 100%;
  border: ${(p) => (p.error ? '0.1rem solid red' : `0.1rem solid ${color.border.gray}`)};
  box-shadow: none;
  font-size: 2.5rem;
  padding: 1.4em 1.75em;
  border-radius: ${borderRadius.large};
`

export const TokenInputContainer = styled.div<{ footerMessage?: boolean }>`
  position: relative;
  padding: 9rem 0 3rem;
  width: 100%;
  margin-bottom: 2rem;
  @media screen and (max-width: 600px) {
    margin: 2rem 0 3rem;
  }
`

export const TokenName = styled.span`
  ${fontFamily.maru};
  font-size: 3rem;
  color: ${color.black};
`

export const FooterMessage = styled.span`
  color: ${color.gray};
  font-size: 1.8rem;
  text-align: right;
`

export const TokenInputHeader = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  left: 4rem;
  top: 0;
  width: calc(100% - 8rem);
`

export const TokenBalance = styled.div`
  margin-left: auto;
  cursor: pointer;
  font-size: 1.8rem;
  margin-top: 0.5rem;
  :hover {
    text-decoration: underline;
  }
`

export const TokenInputFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 1.5rem;
  text-align: right;
`

export const TokenInputErrorMessage = styled.div`
  color: red;
  margin-left: auto;
`
