import React, { useState } from 'react'
import { Attention } from 'src/components/Attention'
import { DUCK_ADDRESS, QDUCK_ADDRESS } from 'src/constants/identifiers'
import { submitStakeQuack, submitStakeUnquack } from 'src/contracts/stake/contractFunctions'
import { approveToken } from 'src/hooks/useApprovals'
import useSubject from 'src/hooks/useSubject'
import { useAppChain } from 'src/hooks/useUserStore'
import { stakeStore, StakeStoreTokens } from 'src/store/stakeStore'
import { userStore } from 'src/store/userStore'
import { MainBlockActionBtns, MainBlockTitle } from 'src/uiKit/MainBlock'
import { TokenInput } from 'src/uiKit/TokenInput'
import { UIButton } from 'src/uiKit/UiButton/styled'
import { UiTabs } from 'src/uiKit/UiTabs'
import $BN from 'src/utils/BigNumber'
import { numberInputReg } from 'src/utils/input'

import {
  getAtomicAmountFromDisplayAmount,
  getDisplayAmountFromAtomicAmount,
} from '../../../../utils/utils'
import { useWeb3Controller } from '../../../../providers/Web3CtxProvider'

const TABS = [
  {
    name: 'Stake',
    id: 'stake',
  },
  {
    name: 'Unstake',
    id: 'unstake',
  },
]

export const StakeBlock: React.FC<{ tokens: StakeStoreTokens }> = ({ tokens }) => {
  const exchangeRate = useSubject(stakeStore.exchangeRate)
  const userAddress = useSubject(userStore.address)
  const { connect } = useWeb3Controller()

  const [currentTab, setCurrentTab] = useState({
    name: 'Stake',
    id: 'stake',
  })
  const [value, setValue] = useState('0')
  const { contracts } = useAppChain().config

  async function mainAction() {
    if (!userAddress) return connect()

    const signer = userStore.signer.getValue()

    if (currentTab.id === 'stake') {
      if (
        $BN(tokens[DUCK_ADDRESS].allowance).gt(
          getAtomicAmountFromDisplayAmount(value, DUCK_ADDRESS),
        )
      ) {
        submitStakeQuack(getAtomicAmountFromDisplayAmount(value, DUCK_ADDRESS), signer)
      } else {
        approveToken(signer, DUCK_ADDRESS, contracts.stake)
      }
    } else {
      submitStakeUnquack(getAtomicAmountFromDisplayAmount(value, QDUCK_ADDRESS), signer)
    }
  }

  return (
    <>
      <UiTabs tabs={TABS} currentTab={currentTab} onTabClick={setCurrentTab} />
      <Attention>
        {getDisplayAmountFromAtomicAmount(exchangeRate, DUCK_ADDRESS)} DUCK = 1 qDUCK
      </Attention>
      <MainBlockTitle>{currentTab.name}</MainBlockTitle>
      <TokenInput
        value={value}
        tokenAddress={currentTab.id === 'stake' ? DUCK_ADDRESS : QDUCK_ADDRESS}
        onInput={(e) => setValue(numberInputReg(e.target.value))}
        onMax={(e) => setValue(e)}
        maxValue={
          currentTab.id === 'stake' ? tokens[DUCK_ADDRESS].balance : tokens[QDUCK_ADDRESS].balance
        }
      />
      <MainBlockActionBtns>
        <UIButton onClick={mainAction}>
          {!userAddress
            ? 'Connect'
            : !$BN(tokens[DUCK_ADDRESS].allowance).gt(value) && currentTab.id === 'stake'
            ? 'Approve'
            : currentTab.id === 'stake'
            ? 'Stake'
            : 'Unstake'}
        </UIButton>
      </MainBlockActionBtns>
    </>
  )
}
