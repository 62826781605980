import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/CollateralRegistry.json'

export function getCollateralRegistryContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.collateralRegistry, abi, provider)
}

export function submitCollateralRegistryAddCollateral(asset, signer, options = {}) {
  const contract = getCollateralRegistryContract(signer)
  return sendTransaction(contract, 'addCollateral', [asset, { ...options }])
}

export function getCollateralRegistryCollateralId(addressInput1) {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.collateralId(addressInput1).catch((e) => {
    console.error('Failed to call collateralId in collateralRegistry:', e)
  })
}

export function getCollateralRegistryCollateralList(uint256Input1) {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.collateralList(uint256Input1).catch((e) => {
    console.error('Failed to call collateralList in collateralRegistry:', e)
  })
}

export function getCollateralRegistryCollaterals() {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.collaterals().catch((e) => {
    console.error('Failed to call collaterals in collateralRegistry:', e)
  })
}

export function getCollateralRegistryCollateralsCount() {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.collateralsCount().catch((e) => {
    console.error('Failed to call collateralsCount in collateralRegistry:', e)
  })
}

export function getCollateralRegistryIsCollateral(asset) {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.isCollateral(asset).catch((e) => {
    console.error('Failed to call isCollateral in collateralRegistry:', e)
  })
}

export function submitCollateralRegistryRemoveCollateral(asset, signer, options = {}) {
  const contract = getCollateralRegistryContract(signer)
  return sendTransaction(contract, 'removeCollateral', [asset, { ...options }])
}

export function getCollateralRegistryVaultParameters() {
  const contract = getCollateralRegistryContract(getChain().ethersProvider)
  return contract.vaultParameters().catch((e) => {
    console.error('Failed to call vaultParameters in collateralRegistry:', e)
  })
}
