import React, { useEffect, useRef } from 'react'
import { toast, Toaster } from 'react-hot-toast'
// TODO #34: react-toast-notifications is deprecated
import { filter, map } from 'rxjs/operators'
import { getChain } from 'src/store/userStore'
import { shortenHash } from 'src/utils/ui'

import { errBeforeTx, getTxByHash, transactions } from '../store/transactionsStore'
import { TxRecord, TxStatus } from '../types/transactions'

const renderTxExplorerLink = (hash: string) => (
  <a target="_blank" rel="noopener noreferrer" href={getChain().getLinkToTxExplorer(hash)}>
    {shortenHash(hash)}
  </a>
)

const EXECUTION_REVERTED_RE = /execution reverted:\s+(.+)/

const renderTxMining = (hash: string) => (
  <div>Mining transaction... {renderTxExplorerLink(hash)}</div>
)
const renderTxMined = (hash: string) => <div>Transaction mined! {renderTxExplorerLink(hash)}</div>

const FormWithToasts = () => {
  const pendingTxToastsRef = useRef({})

  useEffect(() => {
    const maybeClearPendingTx = (hash) => {
      const pendingToastId = pendingTxToastsRef.current[hash]
      if (pendingToastId) {
        if (pendingToastId.then) {
          toast.remove()
        } else {
          toast.remove(pendingToastId)
        }
        delete pendingTxToastsRef.current[hash]
      }
    }

    const lastTxSub = transactions
      .pipe(
        map(({ lastTxHash }) => getTxByHash(lastTxHash)),
        filter((x?: TxRecord): x is TxRecord => Boolean(x)),
      )
      .subscribe((txRecord) => {
        const { hash, status, error } = txRecord

        if (status === TxStatus.Pending) {
          if (!hash || hash in pendingTxToastsRef.current) return

          pendingTxToastsRef.current[hash] = toast.promise(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            new Promise((resolve, reject) => {}),
            {
              loading: renderTxMining(hash),
              success: null,
              error: null,
            },
          )
          return
        }

        if (status === TxStatus.Mined) {
          maybeClearPendingTx(hash)
          toast.success(renderTxMined(hash))
          return
        }

        if (!error) return

        maybeClearPendingTx(hash)
      })

    const errBeforeTxSub = errBeforeTx.subscribe((error) => {
      if (!error) return

      if (error && EXECUTION_REVERTED_RE.test(error.message)) {
        const [, message] = error.message.match(EXECUTION_REVERTED_RE)
        toast.error(message)
      }

      toast.error(error.message || error.reason)
    })

    return () => {
      lastTxSub.unsubscribe()
      errBeforeTxSub.unsubscribe()
    }
  }, [])

  return null
}

const TransactionsToasts = () => (
  <>
    <Toaster
      position="top-right"
      toastOptions={{
        style: {
          fontSize: '1.8rem',
          padding: '1rem',
          color: '#713200',
          overflow: 'hidden',
          maxWidth: '50rem',
        },
      }}
    />
    <FormWithToasts />
  </>
)

export { TransactionsToasts }
