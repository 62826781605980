import React from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import MoreIcon from '../../../../../assets/images/icons/ic-more.svg'
import { protocolStableSymbol } from '../../../../../constants/usdp'
import infoModalStore from '../../../../../store/infoModalStore'
import { CDP } from '../../../../../types/cdp'
import { Collateral } from '../../../../../types/collaterals'
import $BN from '../../../../../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount } from '../../../../../utils/utils'
import {
  Param,
  ParamInfo,
  ParamInfoContainer,
  ParamInfoIcon,
  ParamName,
  ParamValue,
} from '../../CollateralParameters/styled'
import { StyledCurrentCDPParams } from './styled'

export const CurrentCDPParams: React.FC<{
  collateral: Collateral
  cdp: CDP
  utilization: string
}> = ({ collateral, cdp, utilization }) => {
  const hasLiquidationPrice = $BN(cdp.cdp_debt).gt(0)
  const { usdpAddress } = useAppChain().config

  const INFO = [
    `Utilization - % of borrowed ${protocolStableSymbol} compare to maximum ${protocolStableSymbol} which is possible to borrow for the CDP.`,
    `Borrowed ${protocolStableSymbol} - the amount of borrowed ${protocolStableSymbol} for this main collateral CDP.`,
    `Stability fee - represents the cost of ${protocolStableSymbol} debt per year. It capitalizes during every action, which reduces debt/collateral ratio like withdrawing collateral and borrowing more ${protocolStableSymbol}.`,
    'Liquidation fee - fee in % from the loan, which will be deducted from collateral if liquidation will occur.',
    'Below the collateral price the position can be liquidated.',
  ]

  let liquidationPrice

  const totalDebt = hasLiquidationPrice ? cdp.cdpTotalDebt : '0'

  const totalDebtFormatted = getDisplayAmountFromAtomicAmount(totalDebt, usdpAddress)

  if (hasLiquidationPrice) {
    liquidationPrice = $BN(totalDebt)
      .times(10000)
      .div(cdp.lr)
      .div(cdp.cdp_deposit)
      .div($BN(10).pow(18 - collateral.decimals))
      .div(100)
      .toFixed(2)
  }

  function showInfoModal(index) {
    infoModalStore.setInfo(INFO[index])
    infoModalStore.setShowState(true)
  }

  const sf = cdp.cdp_sf
  const sfAdjustable = $BN(sf).gt(cdp.sf)
  const lf = cdp.cdp_lf

  const adjustSF = sfAdjustable ? (
    <>
      <div onClick={showInfoModal.bind(null, 6)}>
        {$BN(sf).div(1000).toString()}% ({$BN(cdp.sf).div(1000).toString()}%)
        <i />
      </div>
    </>
  ) : (
    <span>{$BN(sf).div(1000).toString()}%</span>
  )

  const depositFormatted = getDisplayAmountFromAtomicAmount(
    cdp.cdp_deposit.toString(),
    collateral.address,
  )

  const CDPParamsArr = [
    [
      <div onClick={showInfoModal.bind(null, 1)}>
        Utilization
        <i />
      </div>,
      utilization ? `${$BN(utilization).div(100).toString()}%` : '-',
    ],
    [
      <div onClick={showInfoModal.bind(null, 0)}>
        Borrowed USDP
        <i />
      </div>,
      totalDebtFormatted,
    ],
    [
      <div onClick={showInfoModal.bind(null, 3)}>
        Stability fee
        <i />
      </div>,
      adjustSF,
    ],
    [
      <div onClick={showInfoModal.bind(null, 4)}>
        Liquidation Fee
        <i />
      </div>,
      `${lf}%`,
    ],
    ['Liquidation Price', liquidationPrice || '-'],
    ['Collateral Amount', depositFormatted],
  ]

  return (
    <StyledCurrentCDPParams>
      {CDPParamsArr.map((param, i) => (
        <Param key={i}>
          <ParamName>
            {param[0]}
            {INFO[i] && (
              <ParamInfoContainer>
                <ParamInfoIcon src={MoreIcon} alt="more-icon" />
                <ParamInfo>{INFO[i]}</ParamInfo>
              </ParamInfoContainer>
            )}
          </ParamName>
          <ParamValue>{param[1]}</ParamValue>
        </Param>
      ))}
    </StyledCurrentCDPParams>
  )
}
