import { getChainConfig } from 'src/store/userStore'
import abi from 'src/contracts/abis/CDPManager.json'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackCdpManagerExit = ({ callback, asset, owner }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig().contracts.cdpManager,
    abi,
    name: 'Exit',
    params: { asset, owner },
    topic: '0xa702ae6024e8b4c2b0eb162b1453dacb93cf6922f5a2d7236faed898c21148f8',
    namespace: 'cdpManager',
  })

export const trackCdpManagerJoin = ({ callback, asset, owner }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig().contracts.cdpManager,
    abi,
    name: 'Join',
    params: { asset, owner },
    topic: '0x6423b6056e64b3b29cb2d342362b47c26fa8d6877da27ea152354060df19b9bc',
    namespace: 'cdpManager',
  })

export const trackCdpManagerLiquidationTriggered = ({ callback, asset, owner }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig().contracts.cdpManager,
    abi,
    name: 'LiquidationTriggered',
    params: { asset, owner },
    topic: '0x5b79a897d30813a62a1f95ba180d3320d3701d96605708b81105e00719a069e4',
    namespace: 'cdpManager',
  })
