import { pick } from 'lodash'
import { INFURA_API_KEY, IS_DEV } from 'src/appConfig'
import { HARDHAT_COLLATERALS, IS_GNO } from 'src/constants/constants'
import { CollateralProps } from 'src/types/collaterals'
import Web3 from 'web3'

import { bscCollaterals } from './collaterals/bscCollaterals'
import { ethCollaterals } from './collaterals/ethCollaterals'
import { ftmCollaterals } from './collaterals/ftmCollaterals'
import { gnoCollaterals } from './collaterals/gnoCollaterals'

// TODO: replace with env variable
const HARDHAT_WS_URL = 'ws://localhost:8545'

export const CHAIN_ID = {
  ETH: 1,
  HARDHAT: 31337,
  BSC: 56,
  GNO: 100,
  FTM: 250,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  AVALANCHE: 43114,
}

export type ChainId = (typeof CHAIN_ID)[keyof typeof CHAIN_ID]

export const CHAIN_URL_REPRESENTATION = {
  ETH: 'eth',
  ETH_RINKEBY: 'rinkeby',
  BSC: 'bsc',
  FTM: 'ftm',
  GNO: 'gnosis',
  AVALANCHE: 'avalanche',
  ARBITRUM: 'arbitrum',
  OPTIMISM: 'optimism',
  POLYGON: 'polygon',
  AURORA: 'aurora',
  BOBA: 'boba',
  MOONBEAM: 'moonbeam',
  HARDHAT: 'hardhat',
}

export const urlRepresentationsList = Object.values(CHAIN_URL_REPRESENTATION) as string[]

export type UrlRepresentation =
  (typeof CHAIN_URL_REPRESENTATION)[keyof typeof CHAIN_URL_REPRESENTATION]

// can be used to easily disable some networks without modifying the config
const SUPPORTED_NETWORKS = IS_GNO
  ? [CHAIN_ID.GNO].filter(Boolean)
  : [
      IS_DEV && CHAIN_ID.HARDHAT,
      CHAIN_ID.ETH,
      CHAIN_ID.BSC,
      CHAIN_ID.FTM,
      CHAIN_ID.OPTIMISM,
      CHAIN_ID.ARBITRUM,
      CHAIN_ID.AVALANCHE,
      CHAIN_ID.GNO,
    ].filter(Boolean)

export type NetworkConfig = {
  id: ChainId
  hexId: string
  name: string
  shortName: string
  link?: string
  contracts: { [key: string]: string }
  mockAddress: string
  urlRepresentation: UrlRepresentation
  ethUsdChainlinkAggregator: string
  wethAddress: string
  stablecoins: string[]
  usdpAddress: string
  ethWebsocketUrl: string
  tokenListUrl: string
  trustWalletPath: string
  collaterals: { [key: string]: CollateralProps }
  unitProtocolLink?: string
  blockStoreBufferCount: number
  blockExplorerUrls: string[]
  rpcUrls: string[]
  wCurrencySymbol: string
  chainLinkOracleUrl: string
  nativeCurrency?: {
    name: string
    symbol: string
    decimals: number
  }
}

type RawNetworkConfig = Omit<NetworkConfig, 'hexId' | 'tokenListUrl'>

const NETWORKS = [
  {
    id: CHAIN_ID.ETH,
    name: 'Ethereum',
    shortName: 'ETH',
    wCurrencySymbol: 'WETH',
    nativeCurrency: {
      name: 'Ethereum Token',
      symbol: 'ETH',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.ETH,
    link: 'https://duck.limo',
    contracts: {
      uniswapFactory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      uniswapV3Oracle: '0xd31817a1E1578C4BECE02FbFb235d76f5716f18f',
      sushiSwapFactory: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
      shibaSwapFactory: '0x115934131916c8b277dd010ee02de363c09d037c',
      chainlinkAggregator: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
      parameters: '0xB46F8CF42e504Efe8BEf895f848741daA55e9f1D',
      vaultManagerParameters: '0x203153522B9EAef4aE17c6e99851EE7b2F7D312E',
      collateralRegistry: '0x3DB39B538Db1123389c77F888a213F1A6dd22EF3',
      cdpViewer: '0x68af7bd6f3e2fb480b251cb1b508bbb406e8e21d',
      vault: '0xb1cff81b9305166ff1efc49a129ad2afcd7bcf19',
      bearingAssetOracle: '0x190DB945Ae572Ae72E367b549b78C41E211864AB',
      oracleRegistry: '0x75fBFe26B21fd3EA008af0C764949f8214150C8f',
      cdpManager: '0x3b088b680ff7253e662bc29e5a7b696ba0100869',
      cdpManagerFallback: '0x6a99d3840998a6a4612ff4e3735cc061bea75e1f',
      chainlinkOracleMainAsset: '0x54b21C140F5463e1fDa69B934da619eAaa61f1CA',
      keep3rV1: '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
      keep3rV1Oracle: '0x73353801921417F465377c8d898c6f4C0270282C',
      liquidationAuction: '0x9cCbb2F03184720Eef5f8fA768425AF06604Daf4',
      liquidationTriggerKeep3rMainAsset: '0x989AC9c8353C3F01412705d7E50B4Ab9E804c227',
      liquidationTriggerKeep3rPoolToken: '0xfeaCFc01e122Bb6b17dc4Aef4F0E3be20FCa888D',
      liquidationTriggerKeep3rSushiSwapMainAsset: '0x20c854cdd322d3a501da91374027b4b257de57dd',
      liquidationTriggerKeep3rSushiSwapPoolToken: '0x88F2Ec209b58076db31E4221a737313941be2ef8',
      keep3rSushiswapV1Oracle: '0xf67Ab1c914deE06Ba0F264031885Ea7B276a7cDa',
      curveLPOracle: '0x0e08d9e1dc22a400ebca25e9a8f292910fa8fe08',
      wrappedToUnderlyingOracle: '0x220ea780a484c18fd0ab252014c58299759a1fbd',
      gauge3crv: '0x4bfb2fa13097e5312b19585042fdbf3562dc8676',
      cyTokenOracle: '0x40B743Ca424E3eC7b97f5AD93d2263Ae01DAE1D8',
      yvTokenOracle: '0x759EB07A8258BcF5590E9303763803DcF264652d',
      stake: '0xE85d5FE256F5f5c9E446502aE994fDA12fd6700a',
      stakeUSDP: '0x8ac08b12b9417dbecf7f6ab2dd96e22441adf3a4',
      veDuck: '0x48ddd27a4d54cd3e8c34f34f7e66e998442dbce3',
      veDUCKDistribution: '0x9f2138ccb930f0654B2C40E7e29FF8291452Eed8',
    },
    // TODO: not needed?
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419',
    wethAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    stablecoins: [
      '0x6b175474e89094c44da98b954eedeac495271d0f',
      '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
      '0xdac17f958d2ee523a2206206994597c13d831ec7',
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
      '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
      '0x4bfb2fa13097e5312b19585042fdbf3562dc8676',
      '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
      '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
      '0x84e13785b5a27879921d6f685f041421c7f482da',
      '0x7da96a3891add058ada2e826306d812c638d87a7',
      '0x19d3364a399d251e894ac732651be8b0e4e85001',
      '0x5f18c75abdae578b483e5f43f12a39cf75b973a9',
      '0xa5ca62d95d24a4a350983d5b8ac4eb8638887396',
      '0xb4ada607b9d6b2c9ee07a275e9616b84ac560139',
      '0x6ede7f19df5df6ef23bd5b9cedb651580bdf56ca',
      '0x1c6a9783f812b3af3abbf7de64c3cd7cc7d1af44',
      '0x054af22e1519b020516d72d749221c24756385c9',
      '0x3b96d491f067912d18563d56858ba7d6ec67a6fa',
      '0xf8768814b88281de4f532a3beefa5b85b69b9324',
      '0xdb25f211ab05b1c97d595516f45794528a807ad8',
      '0x955ac1ddbf18d9cd793d0c964d24e4bb16ba3996',
      '0x0770e27f92f0d0e716dc531037b8b87fefebe561',
    ],
    usdpAddress: '0x1456688345527be1f37e9e627da0837d6f08c925',
    ethWebsocketUrl: `wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}`,
    trustWalletPath: 'ethereum',
    collaterals: ethCollaterals,
    blockStoreBufferCount: 5,
    rpcUrls: [`https://mainnet.infura.io/v3/${INFURA_API_KEY}`],
    blockExplorerUrls: ['https://etherscan.io'],
    chainLinkOracleUrl: 'https://data.chain.link/',
  },
  {
    id: CHAIN_ID.BSC,
    name: 'Binance Chain',
    shortName: 'BSC',
    wCurrencySymbol: 'WBNB',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.BSC,
    link: 'https://bsc.duck.limo',
    contracts: {
      sushiSwapFactory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
      chainlinkAggregator: '0x944B319cB0326887c39a851813186273846dAe7b',
      parameters: '0x56c7CA666d192332F72a5842E72eED5f59F0fb48',
      vaultManagerParameters: '0x99f2B13C28A4183a5d5e0fe02B1B5aeEe85FAF5A',
      vault: '0xDAcfeEd000e12C356fb72aB5089e7DD80FF4dd93',
      vaultManagerKeep3rSushiSwapMainAsset: '0x7Cdefc60Aa5eF145f905b99C999d3ED2883f6d10',
      vaultManagerStandard: '0x7E920256041F77613885A018Fce194409A734bBe',
      liquidationAuction: '0x852de08f3cD5b92dD8b3B92b321363D04EeEc39E',
      liquidationTriggerKeep3rSushiSwapMainAsset: '0xfAa71d14458a197DeC85a767B23dA27E33363b9b',
      pancakeTwap: '0x11b1bd923f4D0669958e16A511567f540Bc21d2e',
      collateralRegistry: '0xA1ad3602697c15113E089C2723c15eBF3038465C',
      cdpViewer: '0xf4ce5576bbc0e1291808049989d8dad0e51929fb',
      cdpManager: '0x1337daC01Fc21Fa21D17914f96725f7a7b73868f',
      oracleRegistry: '0xbea721ACe12e881cb44Dbe9361ffEd9141CE547F',
    },
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
    wethAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    stablecoins: [
      '0x55d398326f99059ff775485246999027b3197955',
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    ],
    usdpAddress: '0xdacd011a71f8c9619642bf482f1d4ceb338cffcf',
    ethWebsocketUrl: IS_DEV
      ? 'wss://bsc.getblock.io/mainnet?api_key=35ea05e1-26a7-40e6-a910-d7afcdcae684'
      : 'wss://methodical-bitter-dream.bsc.quiknode.pro/775e50e7b4f613454fb879ecc57dfe55b2093d4a/',
    trustWalletPath: 'binance',
    collaterals: bscCollaterals,
    unitProtocolLink: '',
    blockStoreBufferCount: 10,
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com/'],
    chainLinkOracleUrl: 'https://data.chain.link/bsc/mainnet',
  },
  {
    id: CHAIN_ID.FTM,
    name: 'Fantom',
    shortName: 'FTM',
    wCurrencySymbol: 'WFTM',
    nativeCurrency: {
      name: 'Fantom Token',
      symbol: 'FTM',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.FTM,
    link: 'https://ftm.duck.limo',
    unitProtocolLink: '',
    contracts: {
      parameters: '0xa8F0b5758041158Cf0375b7AdC8AC175ff031B6C',
      vault: '0xD7A9b0D75e51bfB91c843b23FB2C19aa3B8D958e',
      collateralRegistry: '0x5BEf93a96DCc2cAEC92e8610bb2f5bf5EB4D89f4',
      oracleRegistry: '0x0058aB54d4405D8084e8D71B8AB36B3091b21c7D',
      vaultManagerParameters: '0x1c7aEA8B6498F0854D1fCE542a27ed6a10D71d2f',
      cdpManager: '0xD12d6082811709287AE8b6d899Ab841659075FC3',
      liquidationAuction: '0x1F18FAc6A422cF4a8D18369F017a100C77b49DeF',
      wrappedToUnderlyingOracle: '0xf2dA959a37a05685f08CacB2733a19BB008849E1',
      chainlinkOracleMainAsset: '0xEac49454A156AbFF249E2C1A2aEF4E4f192D8Cb9',
      chainlinkAggregator: '0xf4766552D15AE4d256Ad41B6cf2933482B0680dc',
      cdpViewer: '0xdbf1a7fad2c4280fb8b93b00e88de3592d905305',
    },
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0xf4766552D15AE4d256Ad41B6cf2933482B0680dc',
    wethAddress: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    stablecoins: [],
    usdpAddress: '0x3129ac70c738d398d1d74c87eab9483fd56d16f8',
    ethWebsocketUrl: IS_DEV
      ? 'wss://wsapi.fantom.network/'
      : 'wss://stylish-attentive-mansion.fantom.quiknode.pro/c2aac7fc18b29c79e6a17e9752f51ca709c35c34/',
    trustWalletPath: 'fantom',
    collaterals: ftmCollaterals,
    blockStoreBufferCount: 10,
    rpcUrls: ['https://rpc.ankr.com/fantom/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
    chainLinkOracleUrl: 'https://data.chain.link/',
  },
  {
    id: CHAIN_ID.GNO,
    name: 'Gnosis Chain',
    shortName: 'xDai',
    wCurrencySymbol: 'WXDAI',
    nativeCurrency: {
      name: 'XDAI',
      symbol: 'XDAI',
      decimals: 18,
    },
    link: '',
    unitProtocolLink: '',
    urlRepresentation: CHAIN_URL_REPRESENTATION.GNO,
    contracts: {
      uniswapFactory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      uniswapV3Oracle: '0xd31817a1E1578C4BECE02FbFb235d76f5716f18f',
      sushiSwapFactory: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
      shibaSwapFactory: '0x115934131916c8b277dd010ee02de363c09d037c',
      chainlinkAggregator: '0x678df3415fc31947da4324ec63212874be5a82f8',
      parameters: '0x1Be5E82dbF2aa477CbFf48e75a19B096eBcdDDb0',
      vaultManagerParameters: '0x9096c43f1E11d64bad829f962377663097F28346',
      collateralRegistry: '0xBF1B434f82D084954689eadAeF781a1ED031A0e6',
      cdpViewer: '0x0A87FeA68fA21C507F2d24612D31334e2cb3424D',
      vault: '0x2EBb09eC5ECdc20800031f9d6Cee98f90127A822',
      bearingAssetOracle: '0x190DB945Ae572Ae72E367b549b78C41E211864AB',
      oracleRegistry: '0x7670225e8c72dC627EAe09640c2Ba9a088b837b8',
      cdpManager: '0xCa5d2E0961fe43eAE4bf07FA961B3CA8Cc0f50f6',
      chainlinkOracleMainAsset: '0x850943c274f5d2bAB9e643AfF7b1c1eEB89d30DD',
      keep3rV1: '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
      keep3rV1Oracle: '0x73353801921417F465377c8d898c6f4C0270282C',
      liquidationAuction: '0x9095557b53E7701bB0AC685d33efE116231B2b19',
      keep3rSushiswapV1Oracle: '0xf67Ab1c914deE06Ba0F264031885Ea7B276a7cDa',
      curveLPOracle: '0x0e08d9e1dc22a400ebca25e9a8f292910fa8fe08',
      wrappedToUnderlyingOracle: '0x6635C1ddEf754CFF7eEffAb060382A8C36e59F65',
      gauge3crv: '0x4bfb2fa13097e5312b19585042fdbf3562dc8676',
      cyTokenOracle: '0x40B743Ca424E3eC7b97f5AD93d2263Ae01DAE1D8',
      yvTokenOracle: '0x759EB07A8258BcF5590E9303763803DcF264652d',
      stake: '0xE85d5FE256F5f5c9E446502aE994fDA12fd6700a',
      stakeUSDP: '0x8ac08b12b9417dbecf7f6ab2dd96e22441adf3a4',
      veDuck: '0x48ddd27a4d54cd3e8c34f34f7e66e998442dbce3',
      veDUCKDistribution: '0x9f2138ccb930f0654B2C40E7e29FF8291452Eed8',
    },
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0x678df3415fc31947da4324ec63212874be5a82f8',
    wethAddress: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    stablecoins: ['0xfe7ed09c4956f7cdb54ec4ffcb9818db2d7025b8'],
    usdpAddress: '0x068e56eBB63e5f98532bAF94fA1f9b9AE19Ba761',
    ethWebsocketUrl: IS_DEV
      ? 'wss://rpc.gnosischain.com/wss'
      : 'wss://intensive-polished-breeze.xdai.quiknode.pro/569655f4723a24b2d375b4230bb8fc3c32afac4c/',
    trustWalletPath: 'xdai',
    collaterals: gnoCollaterals,
    blockStoreBufferCount: 10,
    rpcUrls: ['https://rpc.gnosischain.com', 'https://xdai-rpc.gateway.pokt.network'],
    blockExplorerUrls: ['https://blockscout.com/xdai/mainnet/'],
    chainLinkOracleUrl: 'https://data.chain.link/xdai/mainnet',
  },
  {
    id: CHAIN_ID.AVALANCHE,
    name: 'Avalanche',
    shortName: 'Avalanche',
    wCurrencySymbol: 'WAVAX',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.AVALANCHE,
    contracts: {
      chainlinkAggregator: '0x0a77230d17318075983913bc2145db16c7366156',
      parameters: '0x1Be5E82dbF2aa477CbFf48e75a19B096eBcdDDb0',
      vaultManagerParameters: '0x7dEC7B7Ae8f5aEa3f031fD55d180D939D8c462E5',
      collateralRegistry: '0xC598dD36ff65Cd9e6aD8B2A29485e2C4f519Adf8',
      cdpViewer: '0x451213c572c434964110b8f8612D79504F0a84bf',
      vault: '0xBF389addC2A319fAf111fbe1bBBc8BF4A562549C',
      bearingAssetOracle: '0x190DB945Ae572Ae72E367b549b78C41E211864AB',
      oracleRegistry: '0x91B3e6dc1506702702Cc6814461ceE1Fe059D246',
      cdpManager: '0xA7549fe82F3dE73b270880A817FE05d6c40094E0',
      chainlinkOracleMainAsset: '0xcf7713729965334EFEF28Ad2a69DcC358957ADB0',
      liquidationAuction: '0xC744c62BEbED8Db3eCB2BdB226E864eB0AA60A6D',
      curveLPOracle: '0x0e08d9e1dc22a400ebca25e9a8f292910fa8fe08',
      wrappedToUnderlyingOracle: '0xB9f7347cdb678c5A25a5aCCaDA74738498D6CD7c',
    },
    mockAddress: 'FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z',
    ethUsdChainlinkAggregator: '0x0a77230d17318075983913bc2145db16c7366156',
    wethAddress: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    stablecoins: [],
    usdpAddress: '0x0378210a6f770Fb70BEe091467A1ef2dE351003B',
    ethWebsocketUrl:
      'wss://avax.getblock.io/mainnet/ext/bc/C/ws?api_key=35ea05e1-26a7-40e6-a910-d7afcdcae684',
    trustWalletPath: 'avalanchec',
    collaterals: {},
    blockStoreBufferCount: 10,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
    chainLinkOracleUrl: 'https://data.chain.link/avalanche/mainnet',
  },
  {
    id: CHAIN_ID.ARBITRUM,
    name: 'Arbitrum',
    shortName: 'Arbitrum',
    wCurrencySymbol: 'WETH',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.ARBITRUM,
    contracts: {
      chainlinkAggregator: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
      parameters: '0x1Be5E82dbF2aa477CbFf48e75a19B096eBcdDDb0',
      vaultManagerParameters: '0x778a50734d8787C6b7603D7bC0636a62203aeB9F',
      collateralRegistry: '0x58FfE5460005CCF9e1Bb8981dEC89D59f7b69F5a',
      cdpViewer: '0x641040A292d1044383eBcA481d05D6f5aa84b747',
      vault: '0x1278615c4f0b09F32A73eFF2d24A1FC3652C2903',
      bearingAssetOracle: '0x190DB945Ae572Ae72E367b549b78C41E211864AB',
      oracleRegistry: '0xc7952a86896Bc00E268F696D7789e27aeE1FFF25',
      cdpManager: '0x85a19Ac3e47B0c16f4C7FE733e64a7D61a853D98',
      chainlinkOracleMainAsset: '0x94A4F09F7c1B2b4330585FAa5d3062d2f3d1412F',
      liquidationAuction: '0x29FD96C94c9e120de16a1C6bBdf39fDD0C468aFe',
      curveLPOracle: '0x0e08d9e1dc22a400ebca25e9a8f292910fa8fe08',
      wrappedToUnderlyingOracle: '0x89a5F4EFB5A96D74cC27990cd87438B5f2C7C317',
    },
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0x639fe6ab55c921f74e7fac1ee960c0b6293ba612',
    wethAddress: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    stablecoins: [],
    usdpAddress: '0x47dBAFd10ba52B88148bC6a20999cbB098AFa593',
    ethWebsocketUrl: IS_DEV
      ? 'wss://arbitrum.getblock.io/mainnet/?api_key=35ea05e1-26a7-40e6-a910-d7afcdcae684'
      : 'wss://nameless-divine-star.arbitrum-mainnet.quiknode.pro/08a17331bd7e75aac05cba26a10771b84ecd4765/',
    trustWalletPath: 'arbitrum',
    collaterals: {},
    blockStoreBufferCount: 10,
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io/'],
    chainLinkOracleUrl: 'https://data.chain.link/avalanche/mainnet',
  },
  {
    id: CHAIN_ID.OPTIMISM,
    name: 'Optimism',
    shortName: 'Optimism',
    wCurrencySymbol: 'WETH',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    urlRepresentation: CHAIN_URL_REPRESENTATION.OPTIMISM,
    contracts: {
      chainlinkAggregator: '0x13e3ee699d1909e989722e753853ae30b17e08c5',
      parameters: '0x77b15B5ad8e770Da753CAa20Da727769C71EA23E',
      vaultManagerParameters: '0x26cB83583711eB90E65e78EcAed8510Ed0AD970f',
      collateralRegistry: '0x67927472A0dA80F40B364D9E763B4435CB0a23ED',
      cdpViewer: '0x9813bfA01097E039a51cb7a4b3c0C8e4290282E7',
      vault: '0x112331e98e25BB1f4C8Eb0659674E40Fc1fD3BC4',
      oracleRegistry: '0xBBD408eb2dc6b34C2D18893286aC0210557F5a94',
      cdpManager: '0xBb85794cd26516E4dB8E6a004236Ba06d34bfC60',
      chainlinkOracleMainAsset: '0xA49F136566eF6475c279d30EfFE25E116720D81d',
      liquidationAuction: '0x5E7902A7d4c3ad0317b9C84B59348836E94158a2',
      wrappedToUnderlyingOracle: '0x7Df10530Ac7188EBaCA88A0FF18c0d06eb77edf1',
    },
    mockAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ethUsdChainlinkAggregator: '0x13e3ee699d1909e989722e753853ae30b17e08c5',
    wethAddress: '0x4200000000000000000000000000000000000006',
    stablecoins: [],
    usdpAddress: '0x51D08eB1012D1c67400a0Abac40EA1987Fdd07eC',
    ethWebsocketUrl: IS_DEV
      ? 'wss://omniscient-late-snow.optimism.discover.quiknode.pro/bee99d110a0f29833d84dc6671c6e6bf00be054e/'
      : 'wss://fluent-flashy-card.optimism.quiknode.pro/f6b70c62f2dd2b1e0c303e569e2b82ee7cb868ec/',
    trustWalletPath: 'optimism',
    collaterals: {},
    blockStoreBufferCount: 10,
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
    chainLinkOracleUrl: 'https://data.chain.link/optimism/mainnet',
  },
] as RawNetworkConfig[]

const withHardhat = (nets: RawNetworkConfig[]) =>
  SUPPORTED_NETWORKS.includes(CHAIN_ID.HARDHAT)
    ? [
        ...nets,
        {
          ...nets.find((n) => n.id === CHAIN_ID.OPTIMISM), // copy config
          id: CHAIN_ID.HARDHAT,
          urlRepresentation: CHAIN_URL_REPRESENTATION.HARDHAT,
          name: 'Hardhat',
          shortName: 'HARDHAT',
          link: 'https://hardhat.org',
          ethWebsocketUrl: HARDHAT_WS_URL,
          rpcUrls: ['https://localhost:8545'],
          collaterals: pick(ethCollaterals, HARDHAT_COLLATERALS),
        },
      ]
    : nets

const onlySupported = (nets: NetworkConfig[]) =>
  nets.filter((net) => SUPPORTED_NETWORKS.includes(net.id))

const getEnhancedNetworks = (nets: RawNetworkConfig[]): NetworkConfig[] =>
  onlySupported(
    withHardhat(nets).map((n) => ({
      ...n,
      hexId: Web3.utils.toHex(n.id),
      tokenListUrl: `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${n.trustWalletPath}/tokenlist.json`,
    })),
  )

export const NETWORK_LIST = Object.freeze(getEnhancedNetworks(NETWORKS))
