import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/IUniswapV2Factory.json'

export function getUniswapFactoryContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.uniswapFactory, abi, provider)
}

export function getUniswapFactoryAllPairs(uint256Input1) {
  const contract = getUniswapFactoryContract(getChain().ethersProvider)
  return contract.allPairs(uint256Input1).catch((e) => {
    console.error('Failed to call allPairs in uniswapFactory:', e)
  })
}

export function getUniswapFactoryAllPairsLength() {
  const contract = getUniswapFactoryContract(getChain().ethersProvider)
  return contract.allPairsLength().catch((e) => {
    console.error('Failed to call allPairsLength in uniswapFactory:', e)
  })
}

export function submitUniswapFactoryCreatePair(tokenA, tokenB, signer, options = {}) {
  const contract = getUniswapFactoryContract(signer)
  return sendTransaction(contract, 'createPair', [tokenA, tokenB, { ...options }])
}

export function getUniswapFactoryFeeTo() {
  const contract = getUniswapFactoryContract(getChain().ethersProvider)
  return contract.feeTo().catch((e) => {
    console.error('Failed to call feeTo in uniswapFactory:', e)
  })
}

export function getUniswapFactoryFeeToSetter() {
  const contract = getUniswapFactoryContract(getChain().ethersProvider)
  return contract.feeToSetter().catch((e) => {
    console.error('Failed to call feeToSetter in uniswapFactory:', e)
  })
}

export function getUniswapFactoryGetPair(tokenA, tokenB) {
  const contract = getUniswapFactoryContract(getChain().ethersProvider)
  return contract.getPair(tokenA, tokenB).catch((e) => {
    console.error('Failed to call getPair in uniswapFactory:', e)
  })
}
