import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/ERC20.json'

export function getErc20TokenContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function getErc20TokenName(contractAddress) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.name().catch((e) => {
    console.error('Failed to call name in erc20Token:', e)
  })
}

export function submitErc20TokenApprove(contractAddress, guy, wad, signer, options = {}) {
  const contract = getErc20TokenContract(signer, contractAddress)
  return sendTransaction(contract, 'approve', [guy, wad, { ...options }])
}

export function getErc20TokenTotalSupply(contractAddress) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in erc20Token:', e)
  })
}

export function submitErc20TokenTransferFrom(contractAddress, src, dst, wad, signer, options = {}) {
  const contract = getErc20TokenContract(signer, contractAddress)
  return sendTransaction(contract, 'transferFrom', [src, dst, wad, { ...options }])
}

export function submitErc20TokenWithdraw(contractAddress, wad, signer, options = {}) {
  const contract = getErc20TokenContract(signer, contractAddress)
  return sendTransaction(contract, 'withdraw', [wad, { ...options }])
}

export function getErc20TokenDecimals(contractAddress) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in erc20Token:', e)
  })
}

export function getErc20TokenBalanceOf(contractAddress, addressInput1) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.balanceOf(addressInput1).catch((e) => {
    console.error('Failed to call balanceOf in erc20Token:', e)
  })
}

export function getErc20TokenSymbol(contractAddress) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in erc20Token:', e)
  })
}

export function submitErc20TokenTransfer(contractAddress, dst, wad, signer, options = {}) {
  const contract = getErc20TokenContract(signer, contractAddress)
  return sendTransaction(contract, 'transfer', [dst, wad, { ...options }])
}

export function submitErc20TokenDeposit(contractAddress, ethAmount, signer, options = {}) {
  const contract = getErc20TokenContract(signer, contractAddress)
  return sendTransaction(contract, 'deposit', [{ ...options, value: ethAmount }])
}

export function getErc20TokenAllowance(contractAddress, addressInput1, addressInput2) {
  const contract = getErc20TokenContract(getChain().ethersProvider, contractAddress)
  return contract.allowance(addressInput1, addressInput2).catch((e) => {
    console.error('Failed to call allowance in erc20Token:', e)
  })
}
