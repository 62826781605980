import BigNumber from 'bignumber.js'
import React, { useMemo } from 'react'
import { IS_DEV } from 'src/appConfig'

import useSubject from '../../../../hooks/useSubject'
import { cdpStore } from '../../../../store/cdpStore'
import { collateralsFilterStore } from '../../../../store/collateralsFilterStore'
import { collateralsStore } from '../../../../store/collateralsStore'
import { CDPList } from '../../../../types/cdp'
import { Collateral, StoreCollaterals } from '../../../../types/collaterals'
import $BN from '../../../../utils/BigNumber'
import { CollateralsListItem } from './CollateralsListItem'
import { EmptyListMessage, StyledCollateralsList } from './styled'

function getCollateralBalance(collateral: Collateral): BigNumber {
  return $BN(collateral.balance).plus(collateral.ethBalance || 0)
}

export const CollateralsList: React.FC = () => {
  const collaterals = useSubject<StoreCollaterals>(collateralsStore.collaterals)
  const cdps = useSubject<CDPList>(cdpStore.cdpList)
  const searchValue = useSubject(collateralsFilterStore.currentFilterBySearch)
  const currentFilterByType = useSubject(collateralsFilterStore.currentFilterByType)

  const sortedCollaterals = useMemo(() => {
    if (!Object.values(collaterals).length || !Object.values(cdps).length) return []
    return Object.values(collaterals).sort((a, b) => {
      if (a.isNew && !b.isNew) return -1
      if (b.isNew && !a.isNew) return 1
      const aCDP = cdps[a.address]
      const bCDP = cdps[b.address]
      if ($BN(aCDP.tdl).minus(aCDP.td).gt($BN(bCDP.tdl).minus(bCDP.td))) return -1
      if ($BN(aCDP.tdl).minus(aCDP.td).lt($BN(bCDP.tdl).minus(bCDP.td))) return 1
      return 0
    })
  }, [collaterals, cdps])

  const filteredCollaterals = useMemo(() => {
    let _collaterals = sortedCollaterals
    if (searchValue) {
      _collaterals = sortedCollaterals.filter((collateral) => {
        return collateral.symbol.toLowerCase().includes(searchValue.toLowerCase())
      })
    }

    _collaterals = _collaterals.filter((collateral) => {
      if (IS_DEV) return true
      return collateral.symbol !== 'N/A'
    })

    if (!currentFilterByType) return _collaterals

    switch (currentFilterByType) {
      case 'New':
        return _collaterals.filter((collateral) => collateral.isNew)
      case 'Opened':
        return _collaterals.filter((collateral) => $BN(cdps[collateral.address].cdp_deposit).gt(0))
      case 'All':
        return _collaterals
      case 'Token':
        return _collaterals.filter((collateral) => collateral.type === 'token')
      case 'Stable':
        return _collaterals.filter((collateral) => collateral.type === 'stable')
      case 'With balance':
        return _collaterals.filter((collateral) => getCollateralBalance(collateral).gt(0))
      case 'Uni V2 LP':
        return _collaterals.filter((collateral) => collateral.type === 'uni')
      case 'Sushi LP':
        return _collaterals.filter((collateral) => collateral.type === 'sushi')
      case 'Shiba LP':
        return _collaterals.filter((collateral) => collateral.type === 'shiba')
      case 'Yearn Vaults':
        return _collaterals.filter((collateral) => collateral.type === 'yearn')
      case 'Compound':
        return _collaterals.filter((collateral) => collateral.type === 'compound')
      default:
        return _collaterals.filter((collateral) => collateral.type === currentFilterByType)
    }
  }, [sortedCollaterals, currentFilterByType, searchValue])

  return (
    <StyledCollateralsList>
      <>
        {filteredCollaterals.map((collateral) => (
          <CollateralsListItem
            key={collateral.address}
            collateral={collateral}
            cdp={cdps[collateral.address]}
          />
        ))}
      </>
      {!filteredCollaterals.length && <EmptyListMessage>Filtered list is empty</EmptyListMessage>}
    </StyledCollateralsList>
  )
}
