import React, { useEffect, useState } from 'react'
import { Attention } from 'src/components/Attention'
import { CDPParamsChanges } from 'src/components/CDPActions/CDPParamsChanges'
import { useApprovals, useUsdpApproval, useWrapperApproval } from 'src/hooks/useApprovals'
import { useAppChain, useIsConnected, useIsSameNetworks } from 'src/hooks/useUserStore'
import { useSetModal } from 'src/providers/ModalsProvider'
import { isTxError } from 'src/store/transactionsStore'
import { numberInputReg } from 'src/utils/input'
import { getExecuteBtnLabel } from 'src/utils/ui'

import {
  InputGroupContainer,
  TokenInputContainer,
} from '../../../../components/CDPActions/DepositAndBorrow/styled'
import { RiskSlider } from '../../../../components/controls/RiskSlider'
import { TOKENS_WITHOUT_LIQUIDATION } from '../../../../constants/identifiers'
import { protocolStableSymbol } from '../../../../constants/usdp'
import useEthUsdPrice from '../../../../hooks/useEthUsdPrice'
import useOracles from '../../../../hooks/useOracles'
import useSubject from '../../../../hooks/useSubject'
import { depositAndMint } from '../../../../hooks/useVaultManager'
import currentCollateralStore from '../../../../store/currentCollateralStore'
import { isMainnet, userStore } from '../../../../store/userStore'
import { CDP } from '../../../../types/cdp'
import { Collateral } from '../../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { TokenInput } from '../../../../uiKit/TokenInput'
import { UIButton } from '../../../../uiKit/UiButton/styled'
import $BN from '../../../../utils/BigNumber'
import { getMaxUSDPMintable } from '../../../../utils/CDP'
import {
  cutSomeEthToCoverFee,
  getAtomicAmountFromDisplayAmount,
  getDisplayAmountFromAtomicAmount,
} from '../../../../utils/utils'

export const DepositAndBorrow: React.FC<{ collateral: Collateral; cdp: CDP }> = ({
  collateral,
  cdp,
}) => {
  const prices = useOracles()
  const ethUsd = useEthUsdPrice()
  const signer = useSubject(userStore.signer)
  const wrongNetwork = !useIsSameNetworks()
  const isAccountConnected = useIsConnected()
  const assetAddress = collateral.address
  const underlyingAddress = collateral.type === 'shiba' ? collateral.underlying.address : null
  const useWeth = useSubject<boolean>(currentCollateralStore.useWeth)
  const { mockAddress, usdpAddress, wethAddress } = useAppChain().config
  const appChain = useAppChain()
  const setModal = useSetModal()

  const [assetAmount, setAssetAmount] = useState('0')
  const [usdpAmount, setUsdpAmount] = useState('0')
  const [maxDeposit, setMaxDeposit] = useState('0')
  const [maxBorrow, setMaxBorrow] = useState('0')
  const [isEth, setIsEth] = useState(false)
  const [isFullPrice, setIsFullPrice] = useState(false)
  const [showSFAttention, setShowSFAttention] = useState(false)
  const [showLFAttention, setShowLFAttention] = useState(false)

  useEffect(() => {
    if ($BN(cdp.sf).eq(cdp.cdp_sf)) setShowSFAttention(false)
    else if ($BN(cdp.cdp_sf).eq(0)) setShowSFAttention(false)
    else setShowSFAttention(true)

    if ($BN(cdp.lf).eq(cdp.cdp_lf)) setShowLFAttention(false)
    else if ($BN(cdp.cdp_lf).eq(0)) setShowLFAttention(false)
    else setShowLFAttention(true)
  }, [cdp])

  const usdpRemainingApprovals = useUsdpApproval(
    getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress),
    assetAddress,
    true,
  )

  const remainingApprovalsCollateral = useApprovals(
    getAtomicAmountFromDisplayAmount(assetAmount, assetAddress),
    isEth ? mockAddress : assetAddress,
    true,
  )

  const remainingWrapperApprovals = useWrapperApproval(
    collateral,
    getAtomicAmountFromDisplayAmount(assetAmount, underlyingAddress),
  )

  const remainingApprovals = usdpRemainingApprovals.concat(
    remainingApprovalsCollateral,
    remainingWrapperApprovals,
  )

  useEffect(() => clearValues(), [assetAddress])

  useEffect(() => {
    setIsFullPrice(prices[assetAddress] && prices[assetAddress].full)
  }, [prices, assetAddress])

  useEffect(() => {
    setIsEth(assetAddress === wethAddress && !useWeth)
  }, [assetAddress, useWeth])

  useEffect(() => {
    const mainDepositAtomic = cdp.cdp_deposit

    let maxMainDeposit = '0'

    if (assetAddress === wethAddress && !useWeth) {
      maxMainDeposit = collateral.ethBalance
    } else if (underlyingAddress) {
      maxMainDeposit = collateral.underlying.balance
    } else {
      maxMainDeposit = collateral.balance
    }

    if (isEth && isMainnet()) {
      maxMainDeposit = cutSomeEthToCoverFee(maxMainDeposit.toString())
    }

    setMaxDeposit(maxMainDeposit.toString())

    setMaxBorrow(
      isFullPrice
        ? getAtomicAmountFromDisplayAmount(
            $BN(
              getMaxUSDPMintable(
                collateral,
                cdp,
                ethUsd,
                prices,
                assetAddress,
                mainDepositAtomic.toString(),
                getAtomicAmountFromDisplayAmount(assetAmount, assetAddress),
                cdp.cdp_debt,
              ),
            ).toString(),
            usdpAddress,
          )
        : '0',
    )
  }, [
    collateral,
    useWeth,
    isEth,
    prices,
    usdpAmount,
    assetAddress,
    assetAmount,
    ethUsd,
    isFullPrice,
  ])

  function clearValues() {
    setAssetAmount('0')
    setUsdpAmount('0')
  }

  function getUSDPValueByPercent(percent: number) {
    return getDisplayAmountFromAtomicAmount(
      $BN(maxBorrow).times(percent).div(100).toString(),
      usdpAddress,
      false,
    )
  }

  const initAdditionalFooterMessage = (
    <div>
      Issuance fee:
      <br />
      {$BN(usdpAmount || 0)
        .times(cdp.bf)
        .div(10000 - cdp.bf)
        .toFixed(2)}{' '}
      {protocolStableSymbol}
    </div>
  )

  const footer = (
    <>
      <CDPParamsChanges assetAmount={assetAmount} usdpAmount={usdpAmount} />
      <div style={{ textAlign: 'center' }}>
        <UIButton
          onClick={async () => {
            setModal({ key: 'transaction' })
            // eslint-disable-next-line no-restricted-syntax
            for (const a of remainingApprovals) {
              if (!(await a())) {
                setModal(null)
                return
              }
            }
            const tx = await depositAndMint(
              { signer },
              assetAddress,
              assetAmount,
              $BN(usdpAmount)
                .div(1 - cdp.bf / 10000)
                .toString(),
              !!remainingApprovals.length,
              useWeth,
              !!underlyingAddress,
            )
            if (isTxError(tx)) {
              clearValues()
            }
            setModal(null)
          }}
          disabled={!isAccountConnected || !isFullPrice || wrongNetwork}
        >
          {getExecuteBtnLabel(
            isAccountConnected,
            wrongNetwork,
            isFullPrice,
            remainingApprovals.length > 0,
          )}
        </UIButton>
      </div>
    </>
  )

  return (
    <>
      <MainBlock pad="small">
        <div>
          {showSFAttention && (
            <Attention>
              Withdrawing or borrowing will change the stability fee from{' '}
              {$BN(cdp.cdp_sf).div(1000).toString()}% to {$BN(cdp.sf).div(1000).toString()}%
            </Attention>
          )}
          {showLFAttention && (
            <Attention>
              Withdrawing or borrowing will change the liquidation fee from{' '}
              {$BN(cdp.cdp_lf).toString()}% to {$BN(cdp.lf).toString()}%
            </Attention>
          )}
        </div>
        <MainBlockTitle>Deposit collateral & Borrow {protocolStableSymbol}</MainBlockTitle>
        <InputGroupContainer>
          <TokenInputContainer>
            <TokenInput
              value={assetAmount}
              tokenAddress={underlyingAddress || collateral.address}
              onInput={(e) => setAssetAmount(numberInputReg(e.target.value))}
              onMax={(e) => setAssetAmount(e.toString())}
              maxValue={maxDeposit || '0'}
            />
          </TokenInputContainer>
          <TokenInputContainer>
            <TokenInput
              value={usdpAmount}
              tokenAddress={usdpAddress}
              onInput={(e) => setUsdpAmount(numberInputReg(e.target.value))}
              onMax={(e) => setUsdpAmount(e.toString())}
              maxValue={maxBorrow || '0'}
              footerMessage={initAdditionalFooterMessage}
              errorMessage="Borrowing limit reached"
            />
          </TokenInputContainer>
        </InputGroupContainer>
        {!TOKENS_WITHOUT_LIQUIDATION[appChain.id].includes(collateral.address) && (
          <RiskSlider
            currentPercent={
              usdpAmount === maxBorrow
                ? maxBorrow === '0'
                  ? 0
                  : 100
                : $BN(getAtomicAmountFromDisplayAmount(usdpAmount, usdpAddress))
                    .times(100)
                    .div(maxBorrow)
                    .toNumber()
            }
            setPercent={(val) => {
              if ($BN(val).gt(100)) {
                setUsdpAmount(getUSDPValueByPercent(100))
              } else if ($BN(val).lt(0)) {
                setUsdpAmount('0')
              } else {
                setUsdpAmount(getUSDPValueByPercent(val))
              }
            }}
            disabled={maxBorrow === '0'}
          />
        )}
        {footer}
      </MainBlock>
    </>
  )
}
