import * as OracleSdk from '@keydonix/uniswap-oracle-sdk'
import { getChain } from 'src/store/userStore'

function stringToBigint(hex: string): bigint {
  const match = /^(?:0x)?([a-fA-F0-9]*)$/.exec(hex)
  if (match === null)
    throw new Error(
      `Expected a hex string encoded number with an optional '0x' prefix but received ${hex}`,
    )
  const normalized = match[1]
  return BigInt(`0x${normalized}`)
}

function stringToByteArray(hex: string): Uint8Array {
  const match = /^(?:0x)?([a-fA-F0-9]*)$/.exec(hex)
  if (match === null)
    throw new Error(
      `Expected a hex string encoded byte array with an optional '0x' prefix but received ${hex}`,
    )
  const normalized = match[1]
  if (normalized.length % 2)
    throw new Error(`Hex string encoded byte array must be an even number of charcaters long.`)
  const bytes: Array<number> = []
  for (let i = 0; i < normalized.length; i += 2) {
    const n = parseInt(`${normalized[i]}${normalized[i + 1]}`, 16)
    bytes.push(n)
  }
  return new Uint8Array(bytes)
}

function bigintToHexAddress(value): string {
  if (typeof value === 'string') return value
  return `0x${value.toString(16).padStart(40, '0')}`
}

function bigintToHexQuantity(value: bigint): string {
  return `0x${value.toString(16)}`
}

export function getBlockByNumberFactory(): OracleSdk.EthGetBlockByNumber {
  return async (blockNumber: bigint | string) => {
    const block: any = await getChain().wsProvider.eth.getBlock(blockNumber.toString())

    return {
      parentHash: stringToBigint(block.parentHash),
      sha3Uncles: stringToBigint(block.sha3Uncles),
      miner: stringToBigint(block.miner),
      stateRoot: stringToBigint(block.stateRoot),
      transactionsRoot: stringToBigint(block.transactionsRoot),
      receiptsRoot: stringToBigint(block.receiptsRoot),
      logsBloom: stringToBigint(block.logsBloom),
      difficulty: BigInt(block.difficulty),
      number: BigInt(block.number),
      gasLimit: BigInt(block.gasLimit),
      gasUsed: BigInt(block.gasUsed),
      timestamp: BigInt(block.timestamp),
      extraData: stringToByteArray(block.extraData),
      mixHash: stringToBigint(block.mixHash),
      nonce: stringToBigint(block.nonce),
      baseFeePerGas: block.baseFeePerGas ? BigInt(block.baseFeePerGas) : null,
    }
  }
}

export function getStorageAtFactory(): OracleSdk.EthGetStorageAt {
  return async (address: bigint, position: bigint, block: bigint | 'latest') => {
    const encodedAddress = bigintToHexAddress(address)
    const encodedPosition = bigintToHexQuantity(position)
    const encodedBlockTag = block === 'latest' ? 'latest' : bigintToHexQuantity(block)

    const result = await getChain().wsProvider.eth.getStorageAt(
      encodedAddress,
      encodedPosition,
      encodedBlockTag,
    )
    if (typeof result !== 'string') {
      throw new Error(
        `Expected eth_getStorageAt to return a string but instead returned a ${typeof result}`,
      )
    }
    return BigInt(result)
  }
}

export function getProofFactory(): OracleSdk.EthGetProof {
  return async (address: bigint, positions: readonly bigint[], block: bigint) => {
    const encodedAddress = bigintToHexAddress(address)
    const encodedPositions = positions.map(bigintToHexQuantity)
    const encodedBlockTag = bigintToHexQuantity(block)

    const result: any = await getChain().wsProvider.eth.getProof(
      encodedAddress,
      encodedPositions,
      encodedBlockTag,
    )
    const accountProof = result.accountProof.map((entry) => {
      return stringToByteArray(entry)
    })

    const storageProof = result.storageProof.map((entry) => {
      return {
        key: BigInt(entry.key),
        value: BigInt(entry.key),
        proof: entry.proof.map((proofEntry) => {
          return stringToByteArray(proofEntry)
        }),
      }
    })
    return { accountProof, storageProof }
  }
}

export class JsonRpcError extends Error {
  constructor(public readonly code: number, message: string, public readonly data?: unknown) {
    super(message)
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, JsonRpcError.prototype)
  }
}
