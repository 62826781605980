import styled from 'styled-components'

import { SpinnerContainer } from '../../../uiKit/Spinner'
import { borderRadius, breakpoint, color, fontFamily } from '../../../uiKit/theme'

export const ChainSelectorContainer = styled.div`
  position: relative;
  z-index: 10;
  margin-right: 0.8rem;
  color: ${color.black};
  display: none;
  @media screen and (min-width: ${breakpoint.tablet}) {
    display: block;
  }
`

export const ChainSelectorCurrent = styled.div<{ showOptions: boolean }>`
  min-width: 14.5rem;
  text-align: center;
  position: relative;
  display: block;
  background: ${color.white};
  padding: 1.9rem 4rem 1.9rem 2rem;
  box-shadow: none;
  border: none;
  border-radius: ${borderRadius.normal};
  ${fontFamily.inter}
  font-weight: 500;
  font-size: 2rem;
  cursor: pointer;
  overflow: hidden;

  :after {
    content: '';
    display: block;
    position: absolute;
    right: 1.7rem;
    top: 45%;
    width: 1rem;
    height: 1rem;
    border-left: 0.2rem solid ${color.black};
    border-bottom: 0.2rem solid ${color.black};
    transform: translateY(-50%) rotate(-45deg);
  }

  ${SpinnerContainer} {
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

export const ChainSelectorOptions = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 1rem);
  background: ${color.white};
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius.normal};
  min-width: 100%;
  text-align: center;
  padding: 0 1rem 2rem;
  margin-top: -0.2rem;
`

export const ChainSelectorOption = styled.a`
  font-size: 2rem;
  line-height: 2em;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
  padding-left: 1rem;
  white-space: nowrap;

  :first-child {
    padding-top: 0.5rem;
  }
`
