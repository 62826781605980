import { Subscription } from 'rxjs'
import { bufferCount, skip } from 'rxjs/operators'

import { allowancesStore } from '../store/allowancesStore'
import blockStore from '../store/blockStore'
import chainlinkAggregatorStore from '../store/chainlinkAggregatorStore'
import { collateralsStore } from '../store/collateralsStore'
import currentCollateralStore from '../store/currentCollateralStore'
import { collateralExist, ensureFreshPrice, updateOraclesStore } from '../store/oraclesStore'
import { getChainConfig, userStore } from '../store/userStore'

export function subscriptionsForCollateral(): Subscription[] {
  const { blockStoreBufferCount } = getChainConfig()

  return [
    currentCollateralStore.address.subscribe(async (address) => {
      const collateral = collateralsStore.collaterals.getValue()[address]
      const userAddress = userStore.address.getValue()
      if (address) {
        allowancesStore.initCdpApprovalsForToken(userAddress, address)
        // fetch allowances if underlying
        if (collateral.underlying) {
          allowancesStore.initWrapperAllowancesForToken(
            userAddress,
            collateral.underlying.address,
            address,
          )
        }
      }
      if (address && chainlinkAggregatorStore.getValue().ethUsd) {
        console.log('currentCollateralStore.address fired', address)
        await ensureFreshPrice(address, chainlinkAggregatorStore.getValue().ethUsd.price)
      }
    }),
    userStore.address.pipe(skip(1)).subscribe(async () => {
      console.log('userStore.address fired')
      if (collateralExist()) {
        await ensureFreshPrice(
          currentCollateralStore.address.getValue(),
          chainlinkAggregatorStore.getValue().ethUsd.price,
        )
      }
    }),
    blockStore.pipe(bufferCount(blockStoreBufferCount)).subscribe(async () => {
      const asset = currentCollateralStore.address.getValue()
      const user = userStore.address.getValue()
      const ethUsd =
        chainlinkAggregatorStore.getValue().ethUsd &&
        chainlinkAggregatorStore.getValue().ethUsd.price
      if (asset && user && ethUsd) {
        await ensureFreshPrice(asset, ethUsd)
      }
    }),
    chainlinkAggregatorStore.pipe(skip(1)).subscribe(async () => {
      console.log('chainlinkAggregatorSubject fired')
      if (collateralExist()) {
        await updateOraclesStore()
      }
    }),
  ]
}
