import React from 'react'

import useLiquidations from '../../hooks/useLiquidations'
import { MainBlock } from '../../uiKit/MainBlock'
import LiquidationListItem from './LiquidationListItem'

const LiquidationList: React.FC = () => {
  const { loading, cdpMap } = useLiquidations()

  const showLoading = loading

  const cdps = Array.from(cdpMap.values()).sort((a, b) => {
    if (a.liquidationBlock > b.liquidationBlock) return 1
    if (a.liquidationBlock < b.liquidationBlock) return -1
    if (a.debt > b.debt) return -1
    if (a.debt < b.debt) return 1
    return 0
  })

  return (
    <>
      {cdps.length ? (
        cdps.map((item) => <LiquidationListItem cdp={item} key={`${item.owner}_${item.asset}`} />)
      ) : showLoading ? (
        <MainBlock>
          <p style={{ fontSize: '3rem' }}>Loading liquidations...</p>
        </MainBlock>
      ) : (
        <MainBlock>
          <p style={{ fontSize: '3rem' }}>There is nothing to liquidate</p>
        </MainBlock>
      )}
    </>
  )
}

export default LiquidationList
