import React from 'react'
import { useIsConnected, useIsSameNetworks, useIsSupportedNetwork } from 'src/hooks/useUserStore'

import { getChainConfig } from '../../store/userStore'
import { StyledNetworkAttention } from './styled'

export const NetworkAttention: React.FC = () => {
  const isSameNetworks = useIsSameNetworks()
  const isSupportedNetwork = useIsSupportedNetwork()
  const isConnected = useIsConnected()

  async function switchNetwork() {
    if (window.ethereum && window.ethereum.isMetaMask) {
      const { hexId, nativeCurrency, name, rpcUrls, blockExplorerUrls } = getChainConfig()
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexId }],
        })
      } catch (switchError) {
        console.error(switchError)
        if (switchError['code'] === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: hexId,
                  chainName: name,
                  rpcUrls,
                  nativeCurrency,
                  blockExplorerUrls,
                },
              ],
            })
          } catch (addError) {
            console.error(addError)
          }
        }
      }
    }
  }

  if (!isSupportedNetwork) {
    return (
      <StyledNetworkAttention onClick={switchNetwork}>
        Click to switch network to {getChainConfig().name}
      </StyledNetworkAttention>
    )
  }

  if (isConnected && !isSameNetworks)
    return (
      <StyledNetworkAttention onClick={switchNetwork}>
        Click to switch network to {getChainConfig().name}
      </StyledNetworkAttention>
    )

  return null
}
