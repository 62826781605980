import { CHAIN_ID } from './network'

export const TOKENS_WITH_UNIQUE_PRECISION = {
  '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce': {
    address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    precision: 7,
  },
  '0x8e7ab03ca7d17996b097d5866bfaa1e251c35c6a': {
    address: '0x8e7ab03ca7d17996b097d5866bfaa1e251c35c6a',
    precision: 4,
  },
  '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5': {
    address: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5',
    precision: 4,
  },
}

export const TOKENS_WITHOUT_LIQUIDATION = {
  [CHAIN_ID.ETH]: [
    '0x955ac1ddbf18d9cd793d0c964d24e4bb16ba3996',
    '0x0770e27f92f0d0e716dc531037b8b87fefebe561',
  ],
  [CHAIN_ID.BSC]: [],
  [CHAIN_ID.GNO]: ['0xfe7ed09c4956f7cdb54ec4ffcb9818db2d7025b8'],
  [CHAIN_ID.FTM]: [],
  [CHAIN_ID.AVALANCHE]: [],
  [CHAIN_ID.ARBITRUM]: [],
  [CHAIN_ID.OPTIMISM]: [],
  [CHAIN_ID.HARDHAT]: [],
}

export const SHIBA_WITHOUT_REWARDS = ['0x4c8ddbb27ba928167f1a06bb30ea8efc1b5b3d61']

export const DUCK_ADDRESS = '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5'
export const QDUCK_ADDRESS = '0xe85d5fe256f5f5c9e446502ae994fda12fd6700a'
export const SUSDP_ADDRESS = '0x8ac08b12b9417dbecf7f6ab2dd96e22441adf3a4'
