import { Calendar } from 'grommet-icons'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { useAppChain } from 'src/hooks/useUserStore'
import { numberInputReg } from 'src/utils/input'
import { ClaimBlock } from 'src/views/stakingV2/components/ClaimBlock'

import { DUCK_ADDRESS } from '../../constants/identifiers'
import {
  submitVeDuckCreate_lock,
  submitVeDuckIncrease_amount,
  submitVeDuckIncrease_unlock_time,
  submitVeDuckWithdraw,
} from '../../contracts/veduck/contractFunctions'
import { approveToken } from '../../hooks/useApprovals'
import useSubject from '../../hooks/useSubject'
import { APRStats, lockingStore, LockStats } from '../../store/lockingStore'
import { StakeStoreTokens } from '../../store/stakeStore'
import { userStore } from '../../store/userStore'
import { MainBlock, MainBlockActionBtns, MainBlockTitle } from '../../uiKit/MainBlock'
import { TokenInput } from '../../uiKit/TokenInput'
import { UIButton } from '../../uiKit/UiButton/styled'
import { UiTabs } from '../../uiKit/UiTabs'
import $BN from '../../utils/BigNumber'
import {
  formatDateToHuman,
  getAtomicAmountFromDisplayAmount,
  getDisplayAmountFromAtomicAmount,
} from '../../utils/utils'
import {
  Param,
  ParamName,
  ParamValue,
} from '../../views/asset/components/CollateralParameters/styled'
import { Attention } from '../Attention'
import './index.css'
import { PeriodLink, PeriodLinkContainer } from './styled'

import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { useWeb3Controller, useWeb3State } from '../../providers/Web3CtxProvider'

const TABS = [
  {
    name: 'Lock',
    id: 'lock',
  },
  {
    name: 'Withdraw',
    id: 'withdraw',
  },
]

const LOCK_TABS = [
  {
    name: 'Increase amount',
    id: 'amount',
  },
  {
    name: 'Increase time',
    id: 'time',
  },
]

const LOCK_PERIODS = [7, 31, 93, 186, 730, 1460]

export const LockingBlock: React.FC<{ tokens: StakeStoreTokens; lockStats: LockStats }> = ({
  tokens,
  lockStats,
}) => {
  const userAddress = useSubject<string>(userStore.address)
  const signer = useSubject(userStore.signer)
  const claimableUSDP = useSubject<string>(lockingStore.claimableUSDP)
  const aprStats = useSubject<APRStats>(lockingStore.aprStats)
  const { connect } = useWeb3Controller()

  const [currentTab, setCurrentTab] = useState(TABS[0])
  const [currentLockTab, setCurrentLockTab] = useState(LOCK_TABS[0])

  const [value, setValue] = useState('0')
  const [lockTime, setLockTime] = useState(0)
  const [increaseLock, setIncreaseLock] = useState<Date>(null)
  const [disableDates, setDisableDates] = useState({
    from: new Date((Date.now() + 86400 * 365 * 4) * 1000),
    to: new Date((lockTime + 86400 * 7) * 1000),
  })
  const { contracts } = useAppChain().config

  let startTime = $BN(Date.now()).toString()

  if (lockStats.lockEnd) {
    startTime = $BN(lockStats.lockEnd)
      .minus(
        $BN(tokens[contracts.veDuck].balance)
          .times(86400)
          .times(365)
          .times(4)
          .div(lockStats.locked),
      )
      .times(1000)
      .toFixed(0)
  }

  let newVDUCKMinted = '0'

  if (increaseLock) {
    newVDUCKMinted = $BN($BN(value || 0).plus($BN(lockStats.locked).div($BN(10).pow(18))))
      .times(
        $BN(
          !!lockStats.lockEnd && currentLockTab.id === 'amount'
            ? $BN(lockTime).times(1000)
            : increaseLock.getTime(),
        ).minus(startTime),
      )
      .div(1000)
      .div(86400)
      .div(365)
      .div(4)
      .toFixed(2, 4)
  }

  const hasEndedLock = lockStats.lockEnd > 0 && Date.now() / 1000 > lockStats.lockEnd

  useEffect(() => {
    setValue('0')
  }, [currentLockTab])

  useEffect(() => {
    setLockTime(lockStats.lockEnd)
  }, [lockStats])

  useEffect(() => {
    if (lockTime === 0) {
      setLockTime(Date.now() / 1000)
      setIncreaseLock(new Date(Date.now() + 86400 * 7 * 1000))
    } else {
      setIncreaseLock(new Date((lockTime + 86400 * 7) * 1000))
    }
    setDisableDates({
      from: new Date(Date.now() + 86400 * 365 * 4 * 1000),
      to: new Date((lockTime + 86400 * 7) * 1000),
    })
  }, [lockTime])

  function setNewLockingPeriod(days) {
    setIncreaseLock(new Date((lockTime + 86400 * days) * 1000))
  }

  function showIncreaseLockButton(days) {
    const time1 = $BN(lockTime).plus(86400 * days)
    const time2 = $BN(Date.now())
      .div(1000)
      .plus(86400 * 365 * 4)
    return time1.lt(time2)
  }

  async function mainAction() {
    if (!userAddress) return connect()

    if (currentTab.id === 'lock') {
      if (
        $BN(tokens[DUCK_ADDRESS].allowance).gt(
          getAtomicAmountFromDisplayAmount(value, DUCK_ADDRESS),
        )
      ) {
        submitVeDuckCreate_lock(
          getAtomicAmountFromDisplayAmount(value, DUCK_ADDRESS),
          $BN(increaseLock.getTime() / 1000)
            .toFixed(0, 1)
            .toString(),
          signer,
        )
      } else {
        approveToken(signer, DUCK_ADDRESS, contracts.veDuck)
      }
    }
  }

  function addToLock() {
    submitVeDuckIncrease_amount(getAtomicAmountFromDisplayAmount(value, DUCK_ADDRESS), signer)
  }

  function increaseLockTime() {
    submitVeDuckIncrease_unlock_time(
      $BN(increaseLock.getTime() / 1000)
        .toFixed(0, 1)
        .toString(),
      signer,
    )
  }

  return (
    <>
      <MainBlock pad="small">
        <UiTabs tabs={TABS} currentTab={currentTab} onTabClick={(tab) => setCurrentTab(tab)} />
        <MainBlockTitle style={{ margin: '4rem 0 4.5rem' }}>{currentTab.name}</MainBlockTitle>
        <Param>
          <ParamName>Staking APR (based on the last 4 weeks):</ParamName>
          <ParamValue>
            {aprStats.feeAPYAverage === 'Loading...'
              ? aprStats.feeAPYAverage
              : aprStats.feeAPYAverage === '0'
              ? 'TBA'
              : `${aprStats.feeAPYAverage}%`}
          </ParamValue>
        </Param>
        <Param>
          <ParamName>Next distribution:</ParamName>
          <ParamValue>
            {aprStats.distroTime === 'Loading...'
              ? aprStats.distroTime
              : aprStats.distroTime === '0'
              ? 'Waiting for fees collected'
              : aprStats.distroTime}
          </ParamValue>
        </Param>
        {!!lockStats.lockEnd && (
          <div>
            <Param>
              <ParamName>Your lock amount:</ParamName>
              <ParamValue>
                {getDisplayAmountFromAtomicAmount(lockStats.locked, DUCK_ADDRESS)} DUCK
              </ParamValue>
            </Param>
            <Param>
              <ParamName>Locked until:</ParamName>
              <ParamValue>{formatDateToHuman(lockStats.lockEnd)}</ParamValue>
            </Param>
          </div>
        )}
        {currentTab.id === 'lock' ? (
          <>
            {!!lockStats.lockEnd && (
              <UiTabs
                tabs={LOCK_TABS}
                currentTab={currentLockTab}
                onTabClick={(tab) => setCurrentLockTab(tab)}
              />
            )}
            {(!lockStats.lockEnd || currentLockTab.id === 'amount') && (
              <TokenInput
                value={value}
                tokenAddress={currentTab.id === 'lock' ? DUCK_ADDRESS : contracts.veDuck}
                onInput={(e) => setValue(numberInputReg(e.target.value))}
                onMax={(e) => setValue(e)}
                maxValue={
                  currentTab.id === 'lock'
                    ? tokens[DUCK_ADDRESS].balance
                    : tokens[contracts.veDuck].balance
                }
              />
            )}
            {(!lockStats.lockEnd || currentLockTab.id === 'time') && (
              <div>
                <MainBlockTitle style={{ margin: '4rem 0 4.5rem' }}>Set unlock time</MainBlockTitle>
                <DatePicker
                  value={increaseLock}
                  onChange={(e: any) => {
                    setIncreaseLock(e)
                  }}
                  clearIcon={null}
                  calendarIcon={<Calendar />}
                  format="dd.M.y"
                  minDate={disableDates.to}
                  maxDate={disableDates.from}
                  minDetail="decade"
                />
                <PeriodLinkContainer>
                  {LOCK_PERIODS.map((period) => {
                    if (showIncreaseLockButton(period)) {
                      return (
                        <PeriodLink key={period} onClick={() => setNewLockingPeriod(period)}>
                          {period === 7
                            ? '1 week'
                            : period === 31
                            ? '1 month'
                            : period === 93
                            ? '3 months'
                            : period === 186
                            ? '6 months'
                            : period === 730
                            ? '2 year'
                            : '4 years'}
                        </PeriodLink>
                      )
                    }
                    return null
                  })}
                </PeriodLinkContainer>
              </div>
            )}
            <Attention style={{ marginTop: '4rem' }}>
              Your veDUCK balance will be: {newVDUCKMinted} veDUCK
            </Attention>
            {!!lockStats.lockEnd && (
              <MainBlockActionBtns>
                {currentLockTab.id === 'amount' ? (
                  <UIButton disabled={$BN(value).lte(0)} onClick={addToLock}>
                    Increase lock amount
                  </UIButton>
                ) : (
                  <UIButton onClick={increaseLockTime}>Increase lock time</UIButton>
                )}
              </MainBlockActionBtns>
            )}
          </>
        ) : hasEndedLock ? (
          <>
            <div>Your lock ended, you can withdraw your veDUCK</div>
            <UIButton onClick={() => submitVeDuckWithdraw(signer)}>Withdraw</UIButton>
          </>
        ) : lockStats.lockEnd ? (
          // eslint-disable-next-line react/no-unescaped-entities
          <Attention>You can withdraw your DUCK after the locking period ends.</Attention>
        ) : (
          <Attention>You have no locked DUCK yet</Attention>
        )}
        {currentTab.id === 'lock' && !lockStats.lockEnd && (
          <MainBlockActionBtns>
            <UIButton
              disabled={
                userAddress && $BN(tokens[DUCK_ADDRESS].allowance).gt(value) && $BN(value).lte(0)
              }
              onClick={mainAction}
            >
              {!userAddress
                ? 'Connect'
                : !$BN(tokens[DUCK_ADDRESS].allowance).gt(value)
                ? 'Approve'
                : 'Lock'}
            </UIButton>
          </MainBlockActionBtns>
        )}
      </MainBlock>
      {!!lockStats.lockEnd && (
        <MainBlock pad="small">
          <ClaimBlock claimableUSDP={claimableUSDP} />
        </MainBlock>
      )}
    </>
  )
}
