import React from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import { submitStakeUSDPClaim } from '../../../../contracts/stakeusdp/contractFunctions'
import useSubject from '../../../../hooks/useSubject'
import { StakeStoreTokens } from '../../../../store/stakeStore'
import { userStore } from '../../../../store/userStore'
import { MainBlockActionBtns } from '../../../../uiKit/MainBlock'
import { UIButton } from '../../../../uiKit/UiButton/styled'
import $BN from '../../../../utils/BigNumber'
import { getDisplayAmountFromAtomicAmount } from '../../../../utils/utils'
import { Param, ParamName, ParamValue } from '../../../asset/components/CollateralParameters/styled'

export const ClaimBlock: React.FC<{ tokens: StakeStoreTokens }> = ({ tokens }) => {
  const signer = useSubject(userStore.signer)
  const { usdpAddress } = useAppChain().config

  return (
    <>
      <Param>
        <ParamName>Earned:</ParamName>
        <ParamValue>
          {getDisplayAmountFromAtomicAmount(tokens[usdpAddress].claimable, usdpAddress, true)} USDP
        </ParamValue>
      </Param>
      <MainBlockActionBtns>
        <UIButton
          disabled={$BN(tokens[usdpAddress].claimable).lte(0)}
          onClick={() => submitStakeUSDPClaim(signer)}
        >
          Claim
        </UIButton>
      </MainBlockActionBtns>
    </>
  )
}
