import { useEffect, useState } from 'react'

import { detectWebGLContext } from '../utils/detectWebGLContext'

export function useDetectWebGL(): boolean {
  const [supportWebGL, setSupportWebGL] = useState(false)

  useEffect(() => {
    setSupportWebGL(detectWebGLContext())
  }, [])

  return supportWebGL
}
