import debug from 'debug'
import { BehaviorSubject, Subscription } from 'rxjs'
import { bufferCount } from 'rxjs/operators'

import { trackBlockHeader } from '../contracts/block/eventListeners'
import { BlockState } from '../types/block'
import { getChain, getChainConfig } from './userStore'

const log = debug('store:blockStore')

const blockStore = new BehaviorSubject<BlockState>({
  number: null,
  hash: null,
  timestamp: null,
  promise: null,
})

export const blockStoreSubscriptions = []

export function blockStoreAddSubscription(callback: () => void): void {
  const { blockStoreBufferCount } = getChainConfig()
  blockStoreSubscriptions.push(
    blockStore.pipe(bufferCount(blockStoreBufferCount)).subscribe(callback),
  )
}

export function blockStoreClearAllSubscriptions(): void {
  blockStoreSubscriptions.forEach((sub: Subscription) => sub.unsubscribe())
}

const updateSubject = (obj) => {
  blockStore.next({
    ...blockStore.getValue(),
    ...obj,
  })
}

export const initializeBlockStore = async (): Promise<void> => {
  log('initializeBlockStore')
  const currentPromise = blockStore.getValue().promise
  if (currentPromise) {
    log('initializeBlockStore currentPromise exist')
    await currentPromise
    log('initializeBlockStore currentPromise exist exit')
    return
  }
  const blockPromise = getChain().ethersProvider.getBlock('latest')
  updateSubject({ promise: blockPromise })
  const currentBlock = await blockPromise
  log('initializeBlockStore currentBlock: ', currentBlock)
  updateSubject({
    number: currentBlock.number,
    hash: currentBlock.hash,
    timestamp: currentBlock.timestamp,
    promise: null,
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trackBlockHeader((data) => {
    updateSubject({
      number: data.number,
      hash: data.hash,
      timestamp: data.timestamp,
      promise: null,
    })
  })
}

initializeBlockStore()

export async function getLatestBlockNumber(): Promise<number> {
  const blockState = blockStore.getValue()
  if (blockState.number) return blockStore.getValue().number
  return (await blockState.promise).number
}

export async function getLastestBlockTimestamp(): Promise<number> {
  const blockState = blockStore.getValue()
  if (blockState.timestamp) return blockStore.getValue().timestamp
  return (await blockState.promise).timestamp
}

export default blockStore
