import BigNumber from 'bignumber.js'
import debug from 'debug'
import { BehaviorSubject } from 'rxjs'
import { skip } from 'rxjs/operators'
import { CollateralProps } from 'src/types/collaterals'
import { ChainVendor } from 'src/utils/chainVendor'

import {
  COLLATERAL_ADDED_TOPICS,
  GAUGED_3CRV,
  HARDHAT_COLLATERALS,
  SHIBA_FACTORY,
  SUSHI_FACTORY,
  TRI_POOL_TOKEN,
  UNI_FACTORY,
  ZERO_ADDRESS,
} from '../constants/constants'
import { CHAIN_ID } from '../constants/network'
import { getCdpViewerGetMultiTokenDetails } from '../contracts/cdpviewer/contractFunctions'
import { getCollateralRegistryCollaterals } from '../contracts/collateralregistry/contractFunctions'
import WebsocketEventTracker from '../contracts/websocketEventTracker'
import { CDP } from '../types/cdp'
import {
  Collateral,
  CollateralDetails,
  CollateralDetailsFromChain,
  CollateralType,
  StoreCollaterals,
} from '../types/collaterals'
import { fetchEthBalance, getBalance, initializeBalanceTrackingForToken } from '../utils/balances'
import $BN from '../utils/BigNumber'
import { getTokenSymbolByAddress } from '../utils/token'
import { allowancesStore } from './allowancesStore'
import {
  blockStoreAddSubscription,
  blockStoreClearAllSubscriptions,
  getLatestBlockNumber,
  initializeBlockStore,
} from './blockStore'
import { cdpStore } from './cdpStore'
import { initializeAggregatorSubject } from './chainlinkAggregatorStore'
import { tokensStore } from './tokensStore'
import { usdpStore } from './usdpStore'
import { getChain, getChainConfig, userStore } from './userStore'

const log = debug('store:collateralsStore')

export const collateralsStore = {
  collaterals: new BehaviorSubject<StoreCollaterals>({}),
  loading: new BehaviorSubject(true),
  firstInit: true,

  // calls only when user selected new chain or when firs loading the app
  async init(): Promise<void> {
    const appChain = getChain()
    const { usdpAddress, wethAddress } = appChain.config
    log('init store START')
    // clear current
    // get collaterals list from trust github
    await tokensStore.initTokensFromTrustWallet()
    const userAddress = userStore.address.getValue()

    const [collaterals, recentCollaterals] = await Promise.all([
      (await getCollateralsAddressesList()).map((collateral) => collateral.toLowerCase()),
      getRecentCollateralsAddressesList(appChain),
    ])

    log(`collaterals:`, collaterals)

    // initialize cdpStore for access to collateral oracle type
    await cdpStore.init(collaterals, userAddress)
    const cdps = cdpStore.cdpList.getValue()
    log(`recentCollaterals:`, recentCollaterals)

    const detailsOfCollateral = await getCollateralsDetails(collaterals, userAddress)

    const formattedCollaterals = {} as StoreCollaterals

    await Promise.all(
      detailsOfCollateral.map(async (collateral, i): Promise<void> => {
        const cdp = cdps[collaterals[i]]
        if (!cdp) {
          console.warn('CDP NOT FOUND!: ', collaterals[i])
          return
        }
        formattedCollaterals[collaterals[i]] = await formCollateralProps(
          userAddress,
          collaterals[i].toLowerCase(),
          collateral,
          cdp,
          recentCollaterals,
        )
      }),
    )

    collateralsStore.collaterals.next(formattedCollaterals)

    if (userAddress !== ZERO_ADDRESS) {
      // init USDP
      usdpStore.init(userAddress)
      allowancesStore.initCdpApprovalsForToken(userAddress, usdpAddress)
      // initialize balances tracking
      Object.keys(formattedCollaterals).forEach((collateralAddress) => {
        trackCollateralBalance(collateralAddress, userAddress)
        const underlying = formattedCollaterals[collateralAddress].underlying
        if (underlying) {
          trackCollateralUnderlyingBalance(collateralAddress, underlying.address, userAddress)
        }
        if (collateralAddress === wethAddress) {
          trackEthBalance(userAddress)
        }
      })
    }

    // loading is a type of first init flag
    if (collateralsStore.firstInit) initStoresSubscriptions()
    collateralsStore.firstInit = false

    collateralsStore.loading.next(false)

    log('detailsOfTokens:', formattedCollaterals)
    log('init store END')
  },

  getTokenAddressBySymbol(symbol: string): string {
    let address = ''
    Object.values(collateralsStore.collaterals.getValue()).forEach((collateral) => {
      if (collateral.symbol.toLowerCase() === symbol.toLowerCase()) {
        address = collateral.address
      }
    })
    return address
  },

  getCollateralProps(address: string): Collateral | undefined {
    return (
      collateralsStore.collaterals.getValue()[address.toLowerCase()] ||
      (collateralsStore.getCollateralUnderlyingTokenProps(address) as Collateral)
    )
  },

  getCollateralByUnderlying(underlyingAddress: string): Collateral | undefined {
    return Object.values(collateralsStore.collaterals.getValue()).find(
      (collateral) => collateral.underlying?.address === underlyingAddress,
    )
  },

  getCollateralUnderlyingTokenProps(underlyingAddress: string): CollateralProps | undefined {
    return collateralsStore.getCollateralByUnderlying(underlyingAddress)?.underlying
  },

  getCollateralSymbolByAddress(address: string): string {
    return collateralsStore.getCollateralProps(address)?.symbol
  },
}

async function getCollateralsAddressesList(): Promise<string[]> {
  return getChain().id === CHAIN_ID.HARDHAT
    ? HARDHAT_COLLATERALS || getCollateralRegistryCollaterals()
    : getCollateralRegistryCollaterals()
}

async function getRecentCollateralsAddressesList(appChain: ChainVendor): Promise<string[]> {
  const { id, config, wsProvider } = appChain
  if (id === CHAIN_ID.HARDHAT) return []

  log('getRecentCollateralsAddressesList START')

  const recentPeriodInBlocks = 9_900

  log('getRecentCollateralsAddressesList recentPeriodInBlocks: ', recentPeriodInBlocks)
  const fromBlock = (await getLatestBlockNumber()) - recentPeriodInBlocks
  log('getRecentCollateralsAddressesList fromBlock: ', fromBlock)
  const logsPromise = await wsProvider.eth.getPastLogs({
    fromBlock,
    address: config.contracts.collateralRegistry,
    topics: COLLATERAL_ADDED_TOPICS,
  })

  log('getRecentCollateralsAddressesList END')

  return logsPromise.map((logData) => `0x${logData.topics[1].slice(26)}`)
}

async function getCollateralsDetails(
  collaterals: string[],
  owner: string,
): Promise<CollateralDetails[]> {
  const maxDetailsCallCount = getChain().id === CHAIN_ID.HARDHAT ? 1 : 8
  let balancesAndLPTokensFetchedCount = 0
  const detailsPromises = [] as Promise<CollateralDetailsFromChain[]>[]

  while (balancesAndLPTokensFetchedCount < collaterals.length) {
    const nextBatch = collaterals.slice(
      balancesAndLPTokensFetchedCount,
      (balancesAndLPTokensFetchedCount += maxDetailsCallCount),
    )

    log('getCollateralsDetails nextBatch: ', nextBatch)

    detailsPromises.push(getCdpViewerGetMultiTokenDetails(nextBatch, owner))
  }

  const detailsArray = await Promise.all(detailsPromises)

  log('detailsArray', detailsArray)

  // reduce, replace zero addresses with null, all addresses to lower case
  return detailsArray
    .reduce((acc, curr) => acc.concat(curr), [])
    .map((detail) => {
      return detail.map((el) => {
        if (BigNumber.isBigNumber(el)) return el
        if ((Array.isArray(el) || typeof el === 'string') && el.includes(ZERO_ADDRESS)) return null
        if (Array.isArray(el)) return el.map((address) => address.toLowerCase())
        if (typeof el === 'string') return el.toLowerCase()
        return el
      }) as unknown as CollateralDetails
    })
}

async function formCollateralProps(
  owner: string,
  collateralAddress: string,
  details: CollateralDetails,
  cdp: CDP,
  recentCollaterals: string[],
): Promise<Collateral> {
  const { collaterals, wethAddress } = getChainConfig()
  const tokenProps =
    tokensStore.tokensFromTrustWallet[collateralAddress] || collaterals[collateralAddress]
  const oracleType = cdp.ot || getCollateralOracleType(collateralAddress)
  const type = getCollateralType(collateralAddress, details, oracleType)
  const symbol = getCollateralSymbol(collateralAddress, type, details)
  const isNew = recentCollaterals.map((el) => el.toLowerCase()).includes(collateralAddress)
  const is3CrvGauge = collateralAddress === GAUGED_3CRV
  const isWETH = collateralAddress === wethAddress
  const name = getChain().config.collaterals[collateralAddress]?.name

  let ethBalance = null

  if (isWETH) {
    ethBalance =
      owner === ZERO_ADDRESS ? '0' : $BN((await fetchEthBalance(owner)).toString()).toString()
  }

  if (!tokenProps?.decimals)
    console.warn(`TOKEN DECIMALS NOT PRESENTED, SET 18: ${symbol} - ${collateralAddress}`)

  const props = {
    address: collateralAddress,
    symbol,
    type,
    name,
    lpUnderlyings: details[0],
    balance: owner === ZERO_ADDRESS ? '0' : details[1].toString(),
    totalSupply: details[2].toString(),
    decimals: details[3] || tokenProps?.decimals || 18,
    uniswapV2Factory: details[4],
    oracleType,
    isNew,
    ethBalance,
    underlying: null,
  } as Collateral

  if (details[5]) {
    const underlyingDetails = [
      details[10],
      details[6],
      details[7],
      details[8],
      details[9],
      null,
      null,
      null,
      null,
      null,
      null,
    ] as CollateralDetails

    props.underlying = {
      address: details[5],
      lpUnderlyings: details[10],
      balance: owner === ZERO_ADDRESS ? '0' : details[6].toString(),
      totalSupply: details[7].toString(),
      decimals: details[8],
      uniswapV2Factory: details[9],
      symbol: getCollateralSymbol(details[5], type, underlyingDetails),
    }
  }

  if (is3CrvGauge) {
    const address = TRI_POOL_TOKEN
    // TODO: get rid of that ($BN / toString etc.) after problem with bigint will be resolved
    const balance =
      owner === ZERO_ADDRESS ? '0' : $BN((await getBalance(owner, address)).toString()).toString()
    const underlyingSymbol = getCollateralSymbol(address, 'token', [])
    props.underlying = {
      ...props.underlying,
      address,
      balance,
      symbol: underlyingSymbol,
      decimals: 18,
    }
  }

  return props
}

function getCollateralType(
  address: string,
  details: CollateralDetails,
  oracleType: number,
): CollateralType {
  const factory = details[4] || details[9]
  if (factory === UNI_FACTORY) return 'uni'
  if (factory === SUSHI_FACTORY) return 'sushi'
  if (factory === SHIBA_FACTORY) return 'shiba'
  if (getChainConfig().stablecoins.includes(address)) return 'stable'
  if (oracleType === 15) return 'yearn'
  if (oracleType === 14) return 'compound'
  return 'token'
}

function getCollateralSymbol(
  collateralAddress: string,
  type: CollateralType,
  details: CollateralDetails | [],
): string {
  const { wethAddress, nativeCurrency } = getChainConfig()
  let symbol = ''
  if (type === 'shiba') symbol = 'SSLP'
  else if (type === 'uni') symbol = 'UNI-V2'
  else if (type === 'sushi') symbol = 'SLP'
  if (details[5]) {
    const token0Symbol = getTokenSymbolByAddress(details[10][0])
    const token1Symbol = getTokenSymbolByAddress(details[10][1])
    symbol = `w${symbol}-${token0Symbol}-${token1Symbol}`
  } else if (details[0]) {
    const token0Symbol = getTokenSymbolByAddress(details[0][0])
    const token1Symbol = getTokenSymbolByAddress(details[0][1])
    symbol = `${symbol}-${token0Symbol}-${token1Symbol}`
  } else if (collateralAddress === wethAddress) {
    symbol = nativeCurrency.symbol
  } else {
    symbol = getTokenSymbolByAddress(collateralAddress)
  }
  return symbol
}

function getCollateralOracleType(collateralAddress: string): number {
  const tokenProps = getChainConfig().collaterals[collateralAddress]

  if (!tokenProps || !tokenProps.oracleType) {
    console.warn(`getCollateralOracleType, oracle not presented: ${collateralAddress}`)
    return 0
  }

  return tokenProps.oracleType
}

async function trackCollateralBalance(collateralAddress: string, owner: string): Promise<void> {
  initializeBalanceTrackingForToken(owner, collateralAddress, async (): Promise<void> => {
    // TODO: get rid of that ($BN / toString etc.) after problem with bigint will be resolved
    const balance = $BN((await getBalance(owner, collateralAddress)).toString()).toString()
    const collateral = collateralsStore.collaterals.getValue()[collateralAddress]

    collateralsStore.collaterals.next({
      ...collateralsStore.collaterals.getValue(),
      [collateralAddress]: {
        ...collateral,
        balance,
      },
    })
  })
}

function trackCollateralUnderlyingBalance(
  collateralAddress: string,
  underlyingAddress: string,
  owner: string,
): void {
  initializeBalanceTrackingForToken(owner, underlyingAddress, async (): Promise<void> => {
    // TODO: get rid of that ($BN / toString etc.) after problem with bigint will be resolved
    const balance = $BN((await getBalance(owner, underlyingAddress)).toString()).toString()
    const collateral = collateralsStore.collaterals.getValue()[collateralAddress]

    collateralsStore.collaterals.next({
      ...collateralsStore.collaterals.getValue(),
      [collateralAddress]: {
        ...collateral,
        underlying: {
          ...collateral.underlying,
          balance,
        },
      },
    })
  })
}

function trackEthBalance(owner: string): void {
  const { wethAddress } = getChainConfig()

  blockStoreAddSubscription(async (): Promise<void> => {
    log('trackEthBalance fired')
    const ethBalance = $BN((await fetchEthBalance(owner)).toString()).toString()
    const wethCollateral = collateralsStore.collaterals.getValue()[wethAddress]
    log('trackEthBalance set params', { owner, ethBalance, wethCollateral })

    collateralsStore.collaterals.next({
      ...collateralsStore.collaterals.getValue(),
      [wethAddress]: {
        ...wethCollateral,
        ethBalance,
      },
    })
  })
}

function clearCollateralsStore(): void {
  log('clearCollateralsStore')
  collateralsStore.collaterals.next({})
}

// TODO: don't forget about clearing subscriptions

function initStoresSubscriptions(): void {
  // use skip to skip ( o_0 ) initial state
  userStore.address.pipe(skip(1)).subscribe(async () => {
    log('userStore.address.pipe(skip(1)).subscribe fired')
    const { prevAddress } = userStore
    if (prevAddress !== ZERO_ADDRESS)
      await WebsocketEventTracker.clearSubscriptionsOfAddress(prevAddress, getChain().id)
    blockStoreClearAllSubscriptions()
    collateralsStore.init()
  })
  userStore.appChain.pipe(skip(1)).subscribe(async (val) => {
    collateralsStore.loading.next(true)
    const prevChain = userStore.prevAppChainId
    log('userStore.appChain.subscribe fired', { val, prevChain })
    if (prevChain) {
      await WebsocketEventTracker.clearSubscriptionsOfChain(prevChain)
      await Promise.all([initializeAggregatorSubject(), initializeBlockStore()])
    }
    blockStoreClearAllSubscriptions()
    clearCollateralsStore()
    cdpStore.clearCdpsStore()
    collateralsStore.init()
  })
}
