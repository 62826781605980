import debug from 'debug'
import { BehaviorSubject } from 'rxjs'
import { getChainConfig } from 'src/store/userStore'

import { getErc20TokenAllowance } from '../contracts/erc20token/contractFunctions'
import { trackErc20TokenApproval } from '../contracts/erc20token/eventListeners'
import { CDPAllowances, WrapperAllowances } from '../types/approvals'

const log = debug('store:allowancesStore')
// TODO: needs small refactor afterwards
export const allowancesStore = {
  cdpAllowances: new BehaviorSubject<CDPAllowances>({}),
  wrapperAllowances: new BehaviorSubject<WrapperAllowances>({}),

  async initWrapperAllowancesForToken(
    walletAddress: string,
    tokenAddress: string,
    wrapperAddress: string,
  ): Promise<void> {
    log('init wrapper allowances START', { walletAddress, tokenAddress, wrapperAddress })

    // get allowance for wrap
    if (!allowancesStore.wrapperAllowances.getValue()[tokenAddress]) {
      const allowance = await fetchAllowanceAndTrackApprovals(
        walletAddress,
        tokenAddress,
        wrapperAddress,
        wrapperAllowanceCallback,
      )

      log('allowance for wrap', allowance)
      log('tokenAddress', tokenAddress)

      setWrapperAllowance(allowance, tokenAddress)
    }

    log('init wrapper allowances END', {
      walletAddress,
      tokenAddress,
      wrapperAddress,
      allowances: allowancesStore.wrapperAllowances.getValue(),
    })
  },

  async initCdpApprovalsForToken(walletAddress: string, tokenAddress: string): Promise<void> {
    const { wethAddress, usdpAddress, contracts } = getChainConfig()
    log('init cdp allowances START', { walletAddress, tokenAddress })

    if (!allowancesStore.cdpAllowances.getValue()[tokenAddress]) {
      const allowancesPromises = []
      allowancesPromises.push(
        fetchAllowanceAndTrackApprovals(
          walletAddress,
          tokenAddress,
          contracts.vault,
          vaultAllowanceCallback,
        ),
      )
      if (tokenAddress === wethAddress || tokenAddress === usdpAddress) {
        allowancesPromises.push(
          fetchAllowanceAndTrackApprovals(
            walletAddress,
            tokenAddress,
            contracts.cdpManager,
            managerAllowanceCallback,
          ),
        )
      }
      if (tokenAddress === usdpAddress && contracts.cdpManagerFallback) {
        allowancesPromises.push(
          fetchAllowanceAndTrackApprovals(
            walletAddress,
            tokenAddress,
            contracts.cdpManagerFallback,
            managerFallbackAllowanceCallback,
          ),
        )
      }
      const [vaultAllowance, managerAllowance, managerFallbackAllowance] = await Promise.all(
        allowancesPromises,
      )

      allowancesStore.cdpAllowances.next({
        ...allowancesStore.cdpAllowances.getValue(),
        [tokenAddress]: {
          managerAllowance,
          vaultAllowance,
          managerFallbackAllowance,
        },
      })

      log(`${tokenAddress} cdpAllowances: `, {
        vaultAllowance,
        managerAllowance,
        managerFallbackAllowance,
      })
    }

    log('cdp allowances: ', allowancesStore.cdpAllowances.getValue())
    log('init cdp allowances END')
  },
}

async function fetchAllowanceAndTrackApprovals(
  walletAddress: string,
  tokenAddress: string,
  spenderAddress: string,
  trackApprovalsCallback: (event: any, tokenAddress: string, spenderAddress: string) => void,
): Promise<string> {
  trackApprovals(walletAddress, tokenAddress, spenderAddress, trackApprovalsCallback)
  return (await getErc20TokenAllowance(tokenAddress, walletAddress, spenderAddress)).toString()
}

function trackApprovals(
  wallet: string,
  tokenAddress: string,
  spenderAddress: string,
  trackApprovalsCallback: (event: any, tokenAddress: string, spenderAddress: string) => void,
) {
  trackErc20TokenApproval(
    {
      callback: ([event]) => trackApprovalsCallback(event, tokenAddress, spenderAddress),
      contract: tokenAddress,
      src: wallet,
      guy: spenderAddress,
    },
    wallet,
  )
}

function vaultAllowanceCallback(event, tokenAddress: string) {
  if (!event) return
  const vaultAllowance = event.data.toString()
  allowancesStore.cdpAllowances.next({
    ...allowancesStore.cdpAllowances.getValue(),
    [tokenAddress]: {
      ...allowancesStore.cdpAllowances.getValue()[tokenAddress],
      vaultAllowance,
    },
  })
}

function managerAllowanceCallback(event: any, tokenAddress: string) {
  if (!event) return
  const managerAllowance = event.data.toString()
  allowancesStore.cdpAllowances.next({
    ...allowancesStore.cdpAllowances.getValue(),
    [tokenAddress]: {
      ...allowancesStore.cdpAllowances.getValue()[tokenAddress],
      managerAllowance,
    },
  })
}

function managerFallbackAllowanceCallback(event: any, tokenAddress: string) {
  if (!event) return
  const managerFallbackAllowance = event.data.toString()
  allowancesStore.cdpAllowances.next({
    ...allowancesStore.cdpAllowances.getValue(),
    [tokenAddress]: {
      ...allowancesStore.cdpAllowances.getValue()[tokenAddress],
      managerFallbackAllowance,
    },
  })
}

// spenderAddress this is a wrapper address eq wrapped collateral
function wrapperAllowanceCallback(event: any, tokenAddress: string) {
  if (!event) return
  const allowance = event.data.toString()
  setWrapperAllowance(allowance, tokenAddress)
}

function setWrapperAllowance(allowance: string, tokenAddress: string) {
  allowancesStore.wrapperAllowances.next({
    ...allowancesStore.wrapperAllowances.getValue(),
    [tokenAddress]: allowance,
  })
}
