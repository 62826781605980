import { BehaviorSubject } from 'rxjs'

import { getChainlinkAggregatorLatestAnswer } from '../contracts/chainlinkaggregator/contractFunctions'
import { trackChainlinkAggregatorAnswerUpdated } from '../contracts/chainlinkaggregator/eventListeners'
import { EthUsdState } from '../types/chainlinkAggregator'
import { getChainConfig } from './userStore'

const chainlinkAggregatorStore = new BehaviorSubject<EthUsdState>({
  loading: true,
  ethUsd: null,
})

const updateSubject = (obj) => {
  chainlinkAggregatorStore.next({
    ...chainlinkAggregatorStore.getValue(),
    ...obj,
  })
}

async function fetchLatestAnswer() {
  const price = await getEthUsdPrice()
  const priceFormatted = (Number(price) / 10 ** 8).toFixed(4)

  return {
    price,
    priceFormatted,
  }
}

async function getEthUsdPrice(): Promise<string> {
  const latestAnswer = await getChainlinkAggregatorLatestAnswer(
    getChainConfig().ethUsdChainlinkAggregator,
  )
  return latestAnswer.toString()
}

export async function initializeAggregatorSubject(): Promise<void> {
  const ethUsd = await fetchLatestAnswer()
  updateSubject({ loading: false, ethUsd })

  trackChainlinkAggregatorAnswerUpdated({
    callback: (data) => {
      if (!data.pop()) return
      const event = data.pop()
      const price = BigInt(event.topics[1]).toString()
      const priceFormatted = (Number(price) / 10 ** 8).toFixed(4)
      updateSubject({ ethUsd: { price, priceFormatted } })
    },
    current: undefined,
    roundId: undefined,
    contract: getChainConfig().ethUsdChainlinkAggregator,
  })
}

initializeAggregatorSubject()

export default chainlinkAggregatorStore
