import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/Stake.json'

export function getStakeContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.stake, abi, provider)
}

export function getStakeAllowance(owner, spender) {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.allowance(owner, spender).catch((e) => {
    console.error('Failed to call allowance in stake:', e)
  })
}

export function submitStakeApprove(spender, amount, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'approve', [spender, amount, { ...options }])
}

export function getStakeBalanceOf(account) {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.balanceOf(account).catch((e) => {
    console.error('Failed to call balanceOf in stake:', e)
  })
}

export function getStakeDecimals() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in stake:', e)
  })
}

export function submitStakeDecreaseAllowance(spender, subtractedValue, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'decreaseAllowance', [spender, subtractedValue, { ...options }])
}

export function getStakeDuck() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.duck().catch((e) => {
    console.error('Failed to call duck in stake:', e)
  })
}

export function getStakeGetExchangeRate() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.getExchangeRate().catch((e) => {
    console.error('Failed to call getExchangeRate in stake:', e)
  })
}

export function submitStakeIncreaseAllowance(spender, addedValue, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'increaseAllowance', [spender, addedValue, { ...options }])
}

export function getStakeName() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.name().catch((e) => {
    console.error('Failed to call name in stake:', e)
  })
}

export function submitStakeQuack(_amount, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'quack', [_amount, { ...options }])
}

export function getStakeSymbol() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in stake:', e)
  })
}

export function getStakeToDUCK(qDuckAmount) {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.toDUCK(qDuckAmount).catch((e) => {
    console.error('Failed to call toDUCK in stake:', e)
  })
}

export function getStakeToQDUCK(duckAmount) {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.toQDUCK(duckAmount).catch((e) => {
    console.error('Failed to call toQDUCK in stake:', e)
  })
}

export function getStakeTotalSupply() {
  const contract = getStakeContract(getChain().ethersProvider)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in stake:', e)
  })
}

export function submitStakeTransfer(recipient, amount, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'transfer', [recipient, amount, { ...options }])
}

export function submitStakeTransferFrom(sender, recipient, amount, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'transferFrom', [sender, recipient, amount, { ...options }])
}

export function submitStakeUnquack(qDuckAmount, signer, options = {}) {
  const contract = getStakeContract(signer)
  return sendTransaction(contract, 'unquack', [qDuckAmount, { ...options }])
}
