import React, { useEffect, useState } from 'react'
import useSubject from 'src/hooks/useSubject'
import {
  getTransactionsFromStorage,
  resetTransactionsStore,
  trackTx,
  transactionsStore,
} from 'src/store/transactionsStore'
import { getChain, userStore } from 'src/store/userStore'
import { TxStatus } from 'src/types/transactions'
import { Spinner } from 'src/uiKit/Spinner'
import { color } from 'src/uiKit/theme'

import { StatusContainer } from './styled'

export const TransactionStatus: React.FC = React.memo(() => {
  const userAddress = useSubject(userStore.address)
  const transactions = useSubject(transactionsStore.transactions)
  const [amount, setAmount] = useState(0)

  const getPendingTx = () => {
    return userAddress
      ? Object.values(getTransactionsFromStorage()[userAddress] || {}).filter(
          (tx) => tx.status === TxStatus.Pending,
        )
      : []
  }

  useEffect(() => {
    resetTransactionsStore()
    if (!userAddress) {
      setAmount(0)
      return
    }

    const promises = getPendingTx().map((tx) => getChain().ethersProvider.getTransaction(tx.hash))
    Promise.all(promises)
      .then((trx) => {
        trx.forEach((res) => {
          if (res) {
            trackTx(res)
          }
        })
      })
      .catch((e: any) => {
        console.error('Failed to get Transaction:', e)
      })
  }, [userAddress])

  useEffect(() => {
    setAmount(getPendingTx().length)
  }, [transactions])

  return amount ? (
    <StatusContainer>
      {amount} pending
      <Spinner style={{ width: '3rem', color: color.white }} />
    </StatusContainer>
  ) : null
})
