import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/TopDog.json'

export function getTopDogContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function getTopDogBONUS_MULTIPLIER(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.BONUS_MULTIPLIER().catch((e) => {
    console.error('Failed to call BONUS_MULTIPLIER in topDog:', e)
  })
}

export function submitTopDogAdd(
  contractAddress,
  _allocPoint,
  _lpToken,
  _withUpdate,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'add', [_allocPoint, _lpToken, _withUpdate, { ...options }])
}

export function getTopDogBone(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.bone().catch((e) => {
    console.error('Failed to call bone in topDog:', e)
  })
}

export function getTopDogBoneLocker(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.boneLocker().catch((e) => {
    console.error('Failed to call boneLocker in topDog:', e)
  })
}

export function submitTopDogBoneLockerUpdate(contractAddress, _boneLocker, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'boneLockerUpdate', [_boneLocker, { ...options }])
}

export function getTopDogBonePerBlock(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.bonePerBlock().catch((e) => {
    console.error('Failed to call bonePerBlock in topDog:', e)
  })
}

export function getTopDogBonusEndBlock(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.bonusEndBlock().catch((e) => {
    console.error('Failed to call bonusEndBlock in topDog:', e)
  })
}

export function submitTopDogCallEmergencyWithdraw(contractAddress, _to, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'callEmergencyWithdraw', [_to, { ...options }])
}

export function submitTopDogDeposit(contractAddress, _pid, _amount, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'deposit', [_pid, _amount, { ...options }])
}

export function getTopDogDevBoneDistributor(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.devBoneDistributor().catch((e) => {
    console.error('Failed to call devBoneDistributor in topDog:', e)
  })
}

export function submitTopDogDevBoneDistributorUpdate(
  contractAddress,
  _devBoneDistributor,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'devBoneDistributorUpdate', [
    _devBoneDistributor,
    { ...options },
  ])
}

export function getTopDogDevPercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.devPercent().catch((e) => {
    console.error('Failed to call devPercent in topDog:', e)
  })
}

export function submitTopDogDevPercentUpdate(contractAddress, _devPercent, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'devPercentUpdate', [_devPercent, { ...options }])
}

export function getTopDogDevRewardMintPercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.devRewardMintPercent().catch((e) => {
    console.error('Failed to call devRewardMintPercent in topDog:', e)
  })
}

export function submitTopDogEmergencyWithdraw(contractAddress, _pid, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'emergencyWithdraw', [_pid, { ...options }])
}

export function getTopDogGetMultiplier(contractAddress, _from, _to) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.getMultiplier(_from, _to).catch((e) => {
    console.error('Failed to call getMultiplier in topDog:', e)
  })
}

export function submitTopDogMassUpdatePools(contractAddress, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'massUpdatePools', [{ ...options }])
}

export function submitTopDogMigrate(contractAddress, _pid, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'migrate', [_pid, { ...options }])
}

export function getTopDogMigrator(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.migrator().catch((e) => {
    console.error('Failed to call migrator in topDog:', e)
  })
}

export function getTopDogOwner(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.owner().catch((e) => {
    console.error('Failed to call owner in topDog:', e)
  })
}

export function getTopDogPendingBone(contractAddress, _pid, _user) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.pendingBone(_pid, _user).catch((e) => {
    console.error('Failed to call pendingBone in topDog:', e)
  })
}

export function getTopDogPoolExistence(contractAddress, addressInput1) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.poolExistence(addressInput1).catch((e) => {
    console.error('Failed to call poolExistence in topDog:', e)
  })
}

export function getTopDogPoolInfo(contractAddress, uint256Input1) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.poolInfo(uint256Input1).catch((e) => {
    console.error('Failed to call poolInfo in topDog:', e)
  })
}

export function getTopDogPoolLength(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.poolLength().catch((e) => {
    console.error('Failed to call poolLength in topDog:', e)
  })
}

export function submitTopDogRenounceOwnership(contractAddress, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'renounceOwnership', [{ ...options }])
}

export function getTopDogRewardMintPercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.rewardMintPercent().catch((e) => {
    console.error('Failed to call rewardMintPercent in topDog:', e)
  })
}

export function submitTopDogSet(
  contractAddress,
  _pid,
  _allocPoint,
  _withUpdate,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'set', [_pid, _allocPoint, _withUpdate, { ...options }])
}

export function submitTopDogSetDevRewardMintPercent(
  contractAddress,
  _newPercent,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'setDevRewardMintPercent', [_newPercent, { ...options }])
}

export function submitTopDogSetLockingPeriod(
  contractAddress,
  _newLockingPeriod,
  _newDevLockingPeriod,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'setLockingPeriod', [
    _newLockingPeriod,
    _newDevLockingPeriod,
    { ...options },
  ])
}

export function submitTopDogSetMigrator(contractAddress, _migrator, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'setMigrator', [_migrator, { ...options }])
}

export function submitTopDogSetRewardMintPercent(
  contractAddress,
  _newPercent,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'setRewardMintPercent', [_newPercent, { ...options }])
}

export function getTopDogStartBlock(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.startBlock().catch((e) => {
    console.error('Failed to call startBlock in topDog:', e)
  })
}

export function getTopDogTBoneBoneDistributor(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.tBoneBoneDistributor().catch((e) => {
    console.error('Failed to call tBoneBoneDistributor in topDog:', e)
  })
}

export function submitTopDogTBoneBoneDistributorUpdate(
  contractAddress,
  _tBoneBoneDistributor,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'tBoneBoneDistributorUpdate', [
    _tBoneBoneDistributor,
    { ...options },
  ])
}

export function getTopDogTBonePercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.tBonePercent().catch((e) => {
    console.error('Failed to call tBonePercent in topDog:', e)
  })
}

export function submitTopDogTBonePercentUpdate(
  contractAddress,
  _tBonePercent,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'tBonePercentUpdate', [_tBonePercent, { ...options }])
}

export function getTopDogTotalAllocPoint(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.totalAllocPoint().catch((e) => {
    console.error('Failed to call totalAllocPoint in topDog:', e)
  })
}

export function submitTopDogTransferOwnership(contractAddress, newOwner, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'transferOwnership', [newOwner, { ...options }])
}

export function submitTopDogUpdatePool(contractAddress, _pid, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'updatePool', [_pid, { ...options }])
}

export function submitTopDogUpdateRewardPerBlock(contractAddress, _perBlock, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'updateRewardPerBlock', [_perBlock, { ...options }])
}

export function getTopDogUserInfo(contractAddress, uint256Input1, addressInput2) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.userInfo(uint256Input1, addressInput2).catch((e) => {
    console.error('Failed to call userInfo in topDog:', e)
  })
}

export function submitTopDogWithdraw(contractAddress, _pid, _amount, signer, options = {}) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'withdraw', [_pid, _amount, { ...options }])
}

export function getTopDogXLeashBoneDistributor(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.xLeashBoneDistributor().catch((e) => {
    console.error('Failed to call xLeashBoneDistributor in topDog:', e)
  })
}

export function submitTopDogXLeashBoneDistributorUpdate(
  contractAddress,
  _xLeashBoneDistributor,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'xLeashBoneDistributorUpdate', [
    _xLeashBoneDistributor,
    { ...options },
  ])
}

export function getTopDogXLeashPercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.xLeashPercent().catch((e) => {
    console.error('Failed to call xLeashPercent in topDog:', e)
  })
}

export function submitTopDogXLeashPercentUpdate(
  contractAddress,
  _xLeashPercent,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'xLeashPercentUpdate', [_xLeashPercent, { ...options }])
}

export function getTopDogXShibBoneDistributor(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.xShibBoneDistributor().catch((e) => {
    console.error('Failed to call xShibBoneDistributor in topDog:', e)
  })
}

export function submitTopDogXShibBoneDistributorUpdate(
  contractAddress,
  _xShibBoneDistributor,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'xShibBoneDistributorUpdate', [
    _xShibBoneDistributor,
    { ...options },
  ])
}

export function getTopDogXShibPercent(contractAddress) {
  const contract = getTopDogContract(getChain().ethersProvider, contractAddress)
  return contract.xShibPercent().catch((e) => {
    console.error('Failed to call xShibPercent in topDog:', e)
  })
}

export function submitTopDogXShibPercentUpdate(
  contractAddress,
  _xShibPercent,
  signer,
  options = {},
) {
  const contract = getTopDogContract(signer, contractAddress)
  return sendTransaction(contract, 'xShibPercentUpdate', [_xShibPercent, { ...options }])
}
