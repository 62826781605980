import React, { useEffect } from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import LiquidationList from '../../components/LiquidationList/LiquidationList'
import useSubject from '../../hooks/useSubject'
import { collateralsStore } from '../../store/collateralsStore'
import { initializeLiquidations } from '../../store/liquidationsStore'
import { MainWrap } from '../../uiKit/MainBlock'

export const Liquidations: React.FC = () => {
  const selectedChain = useAppChain().id
  const collateralsLoading = useSubject<boolean>(collateralsStore.loading)

  useEffect(() => {
    if (!collateralsLoading) {
      initializeLiquidations(selectedChain)
    }
  }, [selectedChain, collateralsLoading])

  return (
    <MainWrap>
      <LiquidationList />
    </MainWrap>
  )
}
