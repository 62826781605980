import axios, { AxiosPromise } from 'axios'

interface CoingeckoDuckPrice {
  'unit-protocol-duck': {
    usd: number
  }
}

const getDuckPrice = (): AxiosPromise<CoingeckoDuckPrice> =>
  axios.get(
    'https://api.coingecko.com/api/v3/simple/price?ids=unit-protocol-duck&vs_currencies=usd',
  )

export const coingecko = {
  getDuckPrice,
}
