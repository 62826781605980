import styled from 'styled-components'

import { borderRadius, breakpoint, color, fontFamily } from '../theme'

export const UIButton = styled.button<{ small?: boolean }>`
  flex-shrink: 0;
  background: ${color.purple};
  font-size: 2.5rem;
  ${fontFamily.maru}
  color: #fff;
  border: none;
  margin: 5rem auto 0;
  cursor: pointer;
  border-radius: ${borderRadius.normal};
  padding: 2rem 3.5rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    padding: 3.5rem 15rem;
    margin: 10rem auto 0;
  }

  &:not(:last-child) {
    margin-right: 20px;
    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &:hover {
    border: none;
    box-shadow: none;
  }

  &:active {
    box-shadow: inset 2px 2px 5px -2px rgba(0, 0, 0, 0.2);
  }

  &[disabled] {
    box-shadow: none;
    opacity: 0.6;

    &:hover {
      box-shadow: none;
    }
  }
`
