import { getChainConfig } from 'src/store/userStore'
import abi from 'src/contracts/abis/Vault/new.json'
import eventTracker from 'src/contracts/websocketEventTracker'

export const trackVaultOracleTypeChanged = ({ callback, asset, user }) =>
  eventTracker.trackEvent({
    callback,
    contract: getChainConfig().contracts.vault,
    abi,
    name: 'OracleTypeChanged',
    params: { asset, user },
    topic: '0xd2b4b670c72f252f1f9c7e2d559c3edf225d23864d6945e168bcf22840450fa5',
    namespace: 'Vault',
  })
