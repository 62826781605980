import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'

function useSubject<T = any>(subject: BehaviorSubject<T>, initValue: T | undefined = undefined): T {
  // if useSubject provided with initValue use it, else use subject value
  const [value, setValue] = useState<T>(initValue || subject.getValue())

  useEffect(() => {
    const subscription = subject.subscribe((newValue) => {
      setValue(newValue)
    })
    return () => subscription.unsubscribe()
  }, [])

  return value
}

export default useSubject
