import { getChainConfig } from 'src/store/userStore'
import { CollateralProps } from 'src/types/collaterals'

import { protocolStable, protocolStableSymbol } from '../constants/usdp'
import { collateralsStore } from '../store/collateralsStore'
import { tokensStore } from '../store/tokensStore'
import { isAddressesEq } from './compareAddresses'

export function getTokenSymbolByAddress(tokenAddress: string): string {
  let symbol = ''
  const tokenProps =
    tokensStore.tokensFromTrustWallet[tokenAddress] || getChainConfig().collaterals[tokenAddress]

  if (tokenProps) symbol = tokenProps.symbol
  else if (isAddressesEq(tokenAddress, getChainConfig().usdpAddress)) return protocolStableSymbol
  else symbol = 'N/A'

  if (symbol === 'N/A') {
    console.warn(`NOT LISTED: ${tokenAddress}`)
  }

  return symbol
}

export function searchTokenPropsInAllStores(tokenAddress: string): CollateralProps {
  const tokenProps =
    (isAddressesEq(tokenAddress, getChainConfig().usdpAddress) && protocolStable()) ||
    collateralsStore.getCollateralProps(tokenAddress) ||
    collateralsStore.getCollateralUnderlyingTokenProps(tokenAddress) ||
    tokensStore.tokensFromTrustWallet[tokenAddress] ||
    getChainConfig().collaterals[tokenAddress]

  if (!tokenProps) {
    console.warn(`searchTokenPropsInAllStores fault on token ${tokenAddress} - not presented`)
    return {
      symbol: 'N/A',
      decimals: 18,
    }
  }

  return tokenProps
}
