import React from 'react'

import useEthUsdPrice from '../../../../hooks/useEthUsdPrice'
import useOracles from '../../../../hooks/useOracles'
import useSubject from '../../../../hooks/useSubject'
import currentCollateralStore from '../../../../store/currentCollateralStore'
import { CDP } from '../../../../types/cdp'
import { Collateral } from '../../../../types/collaterals'
import { MainBlock, MainBlockTitle } from '../../../../uiKit/MainBlock'
import { NoCDPMessage } from '../../../../uiKit/NoCDPMessage'
import $BN from '../../../../utils/BigNumber'
import { getMaxUSDPMintableForPosition } from '../../../../utils/CDP'
import { CurrentCDPParams } from './CurrentCDPParams'

export interface ExtendedCollateralState extends Collateral, CDP {
  address: string
  utilization: string
}

export const CurrentCDP: React.FC<{ collateral: Collateral; cdp: CDP }> = ({ collateral, cdp }) => {
  const noCDP = $BN(cdp.cdp_debt).eq(0) && $BN(cdp.cdp_deposit).eq(0)
  const collateralAddress = useSubject<string>(currentCollateralStore.address)

  const ethUsd = useEthUsdPrice()
  const prices = useOracles()

  const maxUsdpMintable = getMaxUSDPMintableForPosition(
    collateral,
    cdp,
    ethUsd,
    prices,
    collateralAddress,
    cdp.cdp_deposit,
    '0',
    '0',
    true,
  )

  const utilization = maxUsdpMintable
    ? $BN(cdp.cdp_debt).times(10000).div(maxUsdpMintable).toFixed(0)
    : ''

  return (
    <MainBlock pad="small">
      <MainBlockTitle>Your CDP ({collateral.symbol})</MainBlockTitle>
      {noCDP ? (
        <NoCDPMessage />
      ) : (
        <CurrentCDPParams collateral={collateral} cdp={cdp} utilization={utilization} />
      )}
    </MainBlock>
  )
}
