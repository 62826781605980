import { IS_GNO } from '../constants/constants'

export const color = {
  pink: IS_GNO ? 'rgba(61, 137, 116, 1)' : 'rgba(235, 82, 161, 1)',
  purple: IS_GNO ? 'rgba(62, 138, 116, 1)' : '#D88BF9',
  gray: 'rgba(191, 191, 191, 1)',
  liteGray: '#f7f7f7',
  grayBg: '#f0f0f0',
  darkGray: '#d6d6d6',
  black: 'rgba(37, 37, 37, 1)',
  white: '#fff',
  border: {
    gray: 'rgba(174, 174, 174, 1)',
  },
  green: 'rgba(18, 227, 73, 1)',
  CDPDots: {
    opened: IS_GNO ? '#e8663d' : 'rgba(235, 82, 161, 1)',
    withBalance: IS_GNO ? 'rgb(0, 156, 180)' : '#D88BF9',
  },
}

export const borderRadius = {
  normal: '1.9rem',
  middle: '2.5rem',
  large: '6.2rem',
  round: '50%',
}

export const breakpoint = {
  phone: '500px',
  tablet: '768px',
  desktop: '1170px',
  hd: '1440px',
  fullHd: '1920px',
}

export const fontFamily = {
  inter: `font-family: 'Circe Regular';`,
  maru: `font-family: 'GT Maru Black';`,
}
