import { CollateralProps } from '../../types/collaterals'

export const ethCollaterals: Record<string, CollateralProps> = {
  '0x48ddd27a4d54cd3e8c34f34f7e66e998442dbce3': {
    symbol: 'veDUCK',
    decimals: 18,
  },
  '0xe85d5fe256f5f5c9e446502ae994fda12fd6700a': {
    symbol: 'qDUCK',
    decimals: 18,
  },
  '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5': {
    symbol: 'DUCK',
    decimals: 18,
  },
  '0x892a6f9df0147e5f079b0993f486f9aca3c87881': {
    symbol: 'xFUND',
    decimals: 9,
  },
  '0x0770e27f92f0d0e716dc531037b8b87fefebe561': {
    symbol: 'USG',
    name: 'USG stablecoin on Gnosis Chain (OmniBridge)',
    decimals: 18,
  },
  '0x955ac1ddbf18d9cd793d0c964d24e4bb16ba3996': {
    symbol: 'USG(old)',
    name: 'USG(old) stablecoin on Gnosis Chain (OmniBridge)',
    decimals: 18,
  },
  '0x9813037ee2218799597d83d4a5b6f3b6778218d9': {
    symbol: 'BONE',
    decimals: 18,
  },
  '0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31': {
    symbol: 'VXL',
    decimals: 18,
  },
  '0x111111111117dc0aa78b770fa6a738034120c302': {
    symbol: '1INCH',
  },
  '0x8290333cef9e6d528dd5618fb97a76f268f3edd4': {
    symbol: 'ANKR',
  },
  '0x0abdace70d3790235af448c88547603b945604ea': {
    symbol: 'DNT',
  },
  '0x0d8775f648430679a709e98d2b0cb6250d2887ef': {
    symbol: 'BAT',
  },
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c': {
    symbol: 'ENJ',
  },
  '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d': {
    symbol: 'CEL',
    decimals: 4,
  },
  '0xdd974d5c2e2928dea5f71b9825b8b646686bd200': {
    symbol: 'KNC',
  },
  '0xa117000000f279d81a1d3cc75430faa017fa5a2e': {
    symbol: 'ANT',
  },
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd': {
    symbol: 'LRC',
  },
  '0x4691937a7508860f876c9c0a2a617e7d9e945d4b': {
    symbol: 'WOO',
  },
  '0x4fe83213d56308330ec302a8bd641f1d0113a4cc': {
    symbol: 'NU',
  },
  '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7': {
    symbol: 'AKRO',
  },
  '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784': {
    symbol: 'TRU',
  },
  '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9': {
    symbol: 'SXP',
  },
  '0x6810e776880c02933d47db1b9fc05908e5386b96': {
    symbol: 'GNO',
  },
  '0x956f47f50a910163d8bf957cf5846d573e7f87ca': {
    symbol: 'FEI',
  },
  '0x4bfb2fa13097e5312b19585042fdbf3562dc8676': {
    symbol: '3CRV-Gauge-Unit',
  },
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32': {
    symbol: 'LDO',
  },
  '0xe28b3b32b6c345a34ff64674606124dd5aceca30': {
    symbol: 'INJ',
  },
  '0x4575f41308ec1483f3d399aa9a2826d74da13deb': {
    symbol: 'OXT',
  },
  '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a': {
    symbol: 'ORN',
  },
  '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26': {
    symbol: 'OGN',
  },
  '0x221657776846890989a759ba2973e427dff5c9bb': {
    symbol: 'REPv2',
  },
  '0xa1faa113cbe53436df28ff0aee54275c13b40975': {
    symbol: 'ALPHA',
  },
  '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b': {
    symbol: 'CVX',
  },
  '0x57b946008913b82e4df85f501cbaed910e58d26c': {
    symbol: 'POND',
    oracleType: 13,
  },
  '0x0391d2021f89dc339f60fff84546ea23e337750f': {
    symbol: 'BOND',
  },
  '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490': {
    symbol: '3Crv',
    decimals: 18,
  },
  '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9': {
    symbol: 'FTT',
  },
  '0xade00c28244d5ce17d72e40330b1c318cd12b7c3': {
    symbol: 'ADX',
  },
  '0x6f259637dcd74c767781e37bc6133cd6a68aa161': {
    symbol: 'HT',
  },
  '0x75231f58b43240c9718dd58b4967c5114342a86c': {
    symbol: 'OKB',
  },
  '0x94b0a3d511b6ecdb17ebf877278ab030acb0a878': {
    symbol: 'UNISWAP LP FEI-WETH',
  },
  '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304': {
    oracleType: 13,
    symbol: 'MM',
  },
  '0x9461173740d27311b176476fa27e94c681b1ea6b': {
    symbol: 'UNISWAP LP yvBOOST-WETH',
    oracleType: 2,
  },
  '0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a': {
    oracleType: 13,
    symbol: 'yvBOOST',
  },
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
    symbol: 'ETH',
  },
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': {
    symbol: 'WBTC',
    decimals: 8,
  },
  '0xe41d2489571d322189246dafa5ebde1f4699f498': {
    symbol: 'ZRX',
  },
  '0x0ae055097c6d159879521c384f1d2123d1f195e6': {
    symbol: 'STAKE',
  },
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e': {
    symbol: 'YFI',
  },
  '0xd0660cd418a64a1d44e9214ad8e459324d8157f1': {
    symbol: 'WOOFY',
    decimals: 12,
  },
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2': {
    symbol: 'MKR',
  },
  '0x0f5d2fb29fb7d3cfee444a200298f468908cc942': {
    symbol: 'MANA',
  },
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0': {
    symbol: 'MATIC',
  },
  '0xfca59cd816ab1ead66534d82bc21e7515ce441cf': {
    symbol: 'RARI',
  },
  '0x1b40183efb4dd766f11bda7a7c3ad8982e998421': {
    symbol: 'VSP',
  },
  '0xd533a949740bb3306d119cc777fa900ba034cd52': {
    symbol: 'CRV',
  },
  '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44': {
    symbol: 'KP3R',
  },
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984': {
    symbol: 'UNI',
  },
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9': {
    symbol: 'AAVE',
  },
  '0xc00e94cb662c3520282e6f5717214004a7f26888': {
    symbol: 'COMP',
  },
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f': {
    symbol: 'SNX',
  },
  '0x514910771af9ca656af840dff83e8264ecf986ca': {
    symbol: 'LINK',
  },
  '0x04fa0d235c4abf4bcf4787af4cf447de572ef828': {
    symbol: 'UMA',
  },
  '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d': {
    symbol: 'renBTC',
    decimals: 8,
  },
  '0x584bc13c7d411c00c01a62e8019472de68768430': {
    symbol: 'HEGIC',
  },
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
    symbol: 'USDC',
    decimals: 6,
  },
  '0xdac17f958d2ee523a2206206994597c13d831ec7': {
    symbol: 'USDT',
    decimals: 6,
  },
  '0x6b175474e89094c44da98b954eedeac495271d0f': {
    symbol: 'DAI',
  },
  '0x57ab1ec28d129707052df4df418d58a2d46d5f51': {
    symbol: 'sUSD',
  },
  '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b': {
    symbol: 'DPI',
  },
  '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55': {
    symbol: 'BAND',
  },
  '0x408e41876cccdc0f92210600ef50372656052a38': {
    symbol: 'REN',
  },
  '0x3472a5a71965499acd81997a54bba8d852c6e53d': {
    symbol: 'BADGER',
  },
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': {
    symbol: 'SUSHI',
  },
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7': {
    symbol: 'GRT',
  },
  '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2': {
    symbol: 'MTA',
  },
  '0x429881672b9ae42b8eba0e26cd9c73711b891ca5': {
    symbol: 'PICKLE',
    oracleType: 1,
  },
  '0xbc396689893d065f41bc2c6ecbee5e0085233447': {
    symbol: 'PERP',
  },
  '0x967da4048cd07ab37855c090aaf366e4ce1b9f48': {
    symbol: 'OCEAN',
  },
  '0xba100000625a3754423978a60c9317c58a424e3d': {
    symbol: 'BAL',
  },
  '0x2ba592f78db6436527729929aaf6c908497cb200': {
    symbol: 'CREAM',
  },
  '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831': {
    symbol: 'DUSD',
    oracleType: 1,
  },
  '0xe2f2a5c287993345a840db3b0845fbc70f5935a5': {
    symbol: 'mUSD',
    oracleType: 1,
  },
  '0xd26114cd6ee289accf82350c8d8487fedb8a0c07': {
    symbol: 'OMG',
  },
  '0x4e15361fd6b4bb609fa63c81a2be19d873717870': {
    symbol: 'FTM',
  },
  '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713': {
    symbol: 'COVER',
  },
  '0x0aacfbec6a24756c20d41914f2caba817c0d8521': {
    symbol: 'YAM',
    oracleType: 13,
  },
  '0xc5bddf9843308380375a611c18b50fb9341f502a': {
    symbol: 'yveCRV-DAO',
    oracleType: 13,
  },
  '0x8798249c2e607446efb7ad49ec89dd1865ff4272': {
    symbol: 'XSUSHI',
  },
  '0xf99d58e463a2e07e5692127302c20a191861b4d6': {
    symbol: 'ANY',
    oracleType: 13,
  },
  '0x1337def16f9b486faed0293eb623dc8395dfe46a': {
    symbol: 'ARMOR',
    oracleType: 1,
  },
  '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9': {
    symbol: 'BOR',
    oracleType: 13,
  },
  '0xb753428af26e81097e7fd17f40c88aaa3e04902c': {
    symbol: 'SFI',
  },
  '0x23b608675a2b2fb1890d3abbd85c5775c51691d5': {
    symbol: 'SOCKS',
    oracleType: 1,
  },
  '0xd291e7a03283640fdc51b121ac401383a46cc623': {
    symbol: 'RGT',
  },
  '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2': {
    symbol: 'renZEC',
    oracleType: 1,
    decimals: 8,
  },
  '0xd5147bc8e386d91cc5dbe72099dac6c9b99276f5': {
    symbol: 'renFIL',
  },
  '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b': {
    symbol: 'CRO',
    decimals: 8,
  },
  '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671': {
    symbol: 'NMR',
  },
  '0x56d811088235f11c8920698a204a5010a788f4b3': {
    symbol: 'BZRX',
  },
  '0x3832d2f059e55934220881f831be501d180671a7': {
    symbol: 'renDOGE',
    oracleType: 13,
    decimals: 8,
  },
  '0xdbdb4d16eda451d0503b854cf79d55697f90c8df': {
    symbol: 'ALCX',
    oracleType: 13,
  },
  '0x8888801af4d980682e47f1a9036e589479e835c5': {
    symbol: 'MPH',
    oracleType: 1,
  },
  '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9': {
    symbol: 'cUSDT',
    decimals: 8,
  },
  '0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4': {
    symbol: 'cCOMP',
    decimals: 8,
  },
  '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643': {
    symbol: 'cDAI',
    decimals: 8,
  },
  '0xface851a4921ce59e912d19329929ce6da6eb0c7': {
    symbol: 'cLINK',
    decimals: 8,
  },
  '0xe14d13d8b3b85af791b2aadd661cdbd5e6097db1': {
    symbol: 'yvYFI',
  },
  '0xb8c3b7a2a618c552c23b1e4701109a9e756bab67': {
    symbol: 'yv1INCH',
  },
  '0xa258c4606ca8206d8aa700ce2143d7db854d168c': {
    symbol: 'yvWETH',
  },
  '0xa696a63cc78dffa1a63e9e50587c197387ff6c7e': {
    symbol: 'yvWBTC',
    decimals: 8,
  },
  '0xf29ae508698bdef169b89834f76704c3b205aedf': {
    symbol: 'yvSNX',
  },
  '0x671a912c10bba0cfa74cfc2d6fba9ba1ed9530b2': {
    symbol: 'yvLINK',
  },
  '0xfbeb78a723b8087fd2ea7ef1afec93d35e8bed42': {
    symbol: 'yvUNI',
  },
  '0x84e13785b5a27879921d6f685f041421c7f482da': {
    symbol: 'yv3CRV',
  },
  '0x7da96a3891add058ada2e826306d812c638d87a7': {
    symbol: 'yvUSDT',
    decimals: 6,
  },
  '0x19d3364a399d251e894ac732651be8b0e4e85001': {
    symbol: 'yvDAI',
  },
  '0x5f18c75abdae578b483e5f43f12a39cf75b973a9': {
    symbol: 'yvUSDC',
    decimals: 6,
  },
  '0xa5ca62d95d24a4a350983d5b8ac4eb8638887396': {
    symbol: 'yvsUSD',
  },
  '0xb4ada607b9d6b2c9ee07a275e9616b84ac560139': {
    symbol: 'yvcrvFRAX',
  },
  '0x6ede7f19df5df6ef23bd5b9cedb651580bdf56ca': {
    symbol: 'yvcrvBUSD',
  },
  '0x1c6a9783f812b3af3abbf7de64c3cd7cc7d1af44': {
    symbol: 'yvcrvUST',
  },
  '0x054af22e1519b020516d72d749221c24756385c9': {
    symbol: 'yvcrvHUSD',
  },
  '0x3b96d491f067912d18563d56858ba7d6ec67a6fa': {
    symbol: 'yvcrvUSDN',
  },
  '0xf8768814b88281de4f532a3beefa5b85b69b9324': {
    symbol: 'yvcrvTUSD',
  },
  '0x35a18000230da775cac24873d00ff85bccded550': {
    symbol: 'cUNI',
    decimals: 8,
  },
  '0xccf4429db6322d5c611ee964527d42e5d685dd6a': {
    symbol: 'cWBTC',
    decimals: 8,
  },
  '0xe6c804ff4ec692e6808c0d032cdbc03772fc4d42': {
    symbol: 'UNISWAP LP renZEC-ETH',
    oracleType: 2,
  },
  '0xf1f85b2c54a2bd284b1cf4141d64fd171bd85539': {},
  '0x26aad2da94c59524ac0d93f6d6cbf9071d7086f2': {},
  '0x86fef14c27c78deaeb4349fd959caa11fc5b5d75': {},
  '0xc5be99a02c6857f9eac67bbce58df5572498f40c': {},
  '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206': { decimals: 18 },
  '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f': {
    symbol: 'GTC',
  },
  '0x6c6ee5e31d828de241282b9606c8e98ea48526e2': {},
  '0x467bccd9d29f223bce8043b84e8c8b282827790f': {
    decimals: 2,
    symbol: 'TEL',
  },
  '0x5b7533812759b45c2b44c19e320ba2cd2681b542': {
    decimals: 8,
    symbol: 'AGIX',
  },
  '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b': {
    symbol: 'AXS',
  },
  '0xba9d4199fab4f26efe3551d490e3821486f135ba': {
    decimals: 8,
    symbol: 'CHSB',
  },
  '0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25': {
    decimals: 0,
    symbol: 'SLP',
  },
  '0x18aaa7115705e8be94bffebde57af9bfc265b998': {},
  '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107': {
    symbol: 'GOVI',
  },
  '0x77777feddddffc19ff86db637967013e6c6a116c': {
    symbol: 'TORN',
  },
  '0x16c52ceece2ed57dad87319d91b5e3637d50afa4': {
    symbol: 'TCAP',
  },
  '0x8ac08b12b9417dbecf7f6ab2dd96e22441adf3a4': {
    symbol: 'SUSDP',
  },
  '0xaaca86b876ca011844b5798eca7a67591a9743c8': {
    symbol: 'BIOS',
  },
  '0x2f109021afe75b949429fe30523ee7c0d5b27207': {
    symbol: 'OCC',
  },
  '0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559': {
    symbol: 'EDEN',
  },
  '0x0000000000095413afc295d19edeb1ad7b71c952': {
    symbol: 'LON',
  },
  '0x090185f2135308bad17527004364ebcc2d37e5f6': {
    symbol: 'SPELL',
  },
  '0x8daebade922df735c38c80c7ebd708af50815faa': {
    symbol: 'tBTC',
  },
  '0x020edc614187f9937a1efeee007656c6356fb13a': {
    symbol: 'ibUSDTv2',
    decimals: 8,
  },
  '0x4423be2173401e96065953a3e962ba7b8fdba68a': {
    symbol: 'UNISWAP LP renFIL-ETH',
  },
  '0xc3601f3e1c26d1a47571c559348e4156786d1fec': {
    symbol: 'UNISWAP LP DUCK-ETH',
  },
  '0xbb2b8038a1640196fbe3e38816f3e67cba72d940': {
    symbol: 'UNISWAP LP WBTC-ETH',
    decimals: 18,
  },
  '0x87febfb3ac5791034fd5ef1a615e9d9627c2665d': {
    symbol: 'UNISWAP LP KP3R-ETH',
    decimals: 18,
  },
  '0x3da1313ae46132a397d90d95b1424a9a7e3e0fce': {
    symbol: 'UNISWAP LP CRV-ETH',
  },
  '0x3b3d4eefdc603b232907a7f3d0ed1eea5c62b5f7': {
    symbol: 'UNISWAP LP STAKE-ETH',
  },
  '0x2fdbadf3c4d5a8666bc06645b8358ab803996e28': {
    symbol: 'UNISWAP LP YFI-ETH',
  },
  '0xc2adda861f89bbb333c90c492cb837741916a225': {
    symbol: 'UNISWAP LP MKR-ETH',
  },
  '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852': {
    symbol: 'UNISWAP LP USDT-ETH',
  },
  '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc': {
    symbol: 'UNISWAP LP USDC-ETH',
  },
  '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11': {
    symbol: 'UNISWAP LP DAI-ETH',
  },
  '0xd3d2e2692501a5c9ca623199d38826e513033a17': {
    symbol: 'UNISWAP LP UNI-ETH',
  },
  '0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974': {
    symbol: 'UNISWAP LP LINK-ETH',
  },
  '0xdfc14d2af169b0d36c4eff567ada9b2e0cae044f': {
    symbol: 'UNISWAP LP AAVE-ETH',
  },
  '0x81fbef4704776cc5bba0a5df3a90056d2c6900b3': {
    symbol: 'UNISWAP LP renBTC-ETH',
  },
  '0x43ae24960e5534731fc831386c07755a2dc33d47': {
    symbol: 'UNISWAP LP SNX-ETH',
  },
  '0xcffdded873554f362ac02f8fb1f02e5ada10516f': {
    symbol: 'UNISWAP LP COMP-ETH',
  },
  '0x88d97d199b9ed37c29d846d00d443de980832a22': {
    symbol: 'UNISWAP LP UMA-ETH',
  },
  '0x1273ad5d8f3596a7a39efdb5a4b8f82e8f003fc3': {
    symbol: 'UNISWAP LP HEGIC-ETH',
  },
  '0xb46736888247c68c995b156ca86426ff32e27cc9': {
    symbol: 'SUSHISWAP LP renDOGE-ETH',
    oracleType: 2,
  },
  '0x10b47177e92ef9d5c6059055d92ddf6290848991': {
    symbol: 'SUSHISWAP LP yveCRV-DAO-ETH',
    oracleType: 2,
  },
  '0x795065dcc9f64b5614c407a6efdc400da6221fb0': {
    symbol: 'SUSHISWAP LP SUSHI-ETH',
  },
  '0x001b6450083e531a5a7bf310bd2c1af4247e23d4': {
    symbol: 'SUSHISWAP LP UMA-ETH',
  },
  '0x06da0fd433c1a5d7a4faa01111c044910a184553': {
    symbol: 'SUSHISWAP LP USDT-ETH',
  },
  '0x088ee5007c98a9677165d78dd2109ae4a3d04d0c': {
    symbol: 'SUSHISWAP LP YFI-ETH',
  },
  '0x31503dcb60119a812fee820bb7042752019f2355': {
    symbol: 'SUSHISWAP LP COMP-ETH',
  },
  '0x397ff1542f962076d0bfe58ea045ffa2d347aca0': {
    symbol: 'SUSHISWAP LP USDC-ETH',
  },
  '0x58dc5a51fe44589beb22e8ce67720b5bc5378009': {
    symbol: 'SUSHISWAP LP CRV-ETH',
  },
  '0x611cde65dea90918c0078ac0400a72b0d25b9bb1': {
    symbol: 'SUSHISWAP LP REN-ETH',
  },
  '0xa1d7b2d891e3a1f9ef4bbc5be20630c2feb1c470': {
    symbol: 'SUSHISWAP LP SNX-ETH',
  },
  '0xa75f7c2f025f470355515482bde9efa8153536a8': {
    symbol: 'SUSHISWAP LP BAND-ETH',
  },
  '0xc3d03e4f041fd4cd388c549ee2a29a9e5075882f': {
    symbol: 'SUSHISWAP LP DAI-ETH',
  },
  '0xc40d16476380e4037e6b1a2594caf6a6cc8da967': {
    symbol: 'SUSHISWAP LP LINK-ETH',
  },
  '0xceff51756c56ceffca006cd410b03ffc46dd3a58': {
    symbol: 'SUSHISWAP LP WBTC-ETH',
  },
  '0xd75ea151a61d06868e31f8988d28dfe5e9df57b4': {
    symbol: 'SUSHISWAP LP AAVE-ETH',
  },
  '0xdafd66636e2561b0284edde37e42d192f2844d40': {
    symbol: 'SUSHISWAP LP UNI-ETH',
  },
  '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec': {
    symbol: 'KEEP',
    oracleType: 1,
  },
  '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce': {
    symbol: 'SHIB',
  },
  '0xdb25f211ab05b1c97d595516f45794528a807ad8': {
    symbol: 'EURS',
    decimals: 2,
  },
}
