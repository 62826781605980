import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import React from 'react'

import { InfoBlockStyled } from './styled'

export const InfoBlock: React.FC<{ content: string }> = ({ content }) => {
  const cleanedHTML = DOMPurify.sanitize(content, {
    USE_PROFILES: { html: true },
    ADD_ATTR: ['target'],
  })

  return <InfoBlockStyled pad="small">{parse(cleanedHTML)}</InfoBlockStyled>
}
