import styled from 'styled-components'

import { borderRadius, breakpoint, color } from '../../../../uiKit/theme'

export const StyledCollateralsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  flex-grow: 0;
  @media screen and (min-width: ${breakpoint.desktop}) {
    width: 88rem;
  }
`

export const EmptyListMessage = styled.div`
  font-size: 2.5rem;
  color: ${color.black};
  text-align: center;
  width: 100%;
  border-radius: ${borderRadius.normal};
  padding: 2rem;
  background: #fff;
`
