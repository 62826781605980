import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/AggregatorInterface.json'

export function getChainlinkAggregatorContract(provider, contractAddress) {
  return new ethers.Contract(contractAddress, abi, provider)
}

export function submitChainlinkAggregatorAcceptOwnership(contractAddress, signer, options = {}) {
  const contract = getChainlinkAggregatorContract(signer, contractAddress)
  return sendTransaction(contract, 'acceptOwnership', [{ ...options }])
}

export function getChainlinkAggregatorAccessController(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.accessController().catch((e) => {
    console.error('Failed to call accessController in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorAggregator(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.aggregator().catch((e) => {
    console.error('Failed to call aggregator in chainlinkAggregator:', e)
  })
}

export function submitChainlinkAggregatorConfirmAggregator(
  contractAddress,
  _aggregator,
  signer,
  options = {},
) {
  const contract = getChainlinkAggregatorContract(signer, contractAddress)
  return sendTransaction(contract, 'confirmAggregator', [_aggregator, { ...options }])
}

export function getChainlinkAggregatorDecimals(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorDescription(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.description().catch((e) => {
    console.error('Failed to call description in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorGetAnswer(contractAddress, _roundId) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.getAnswer(_roundId).catch((e) => {
    console.error('Failed to call getAnswer in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorGetRoundData(contractAddress, _roundId) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.getRoundData(_roundId).catch((e) => {
    console.error('Failed to call getRoundData in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorGetTimestamp(contractAddress, _roundId) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.getTimestamp(_roundId).catch((e) => {
    console.error('Failed to call getTimestamp in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorLatestAnswer(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.latestAnswer().catch((e) => {
    console.error('Failed to call latestAnswer in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorLatestRound(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.latestRound().catch((e) => {
    console.error('Failed to call latestRound in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorLatestRoundData(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.latestRoundData().catch((e) => {
    console.error('Failed to call latestRoundData in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorLatestTimestamp(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.latestTimestamp().catch((e) => {
    console.error('Failed to call latestTimestamp in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorOwner(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.owner().catch((e) => {
    console.error('Failed to call owner in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorPhaseAggregators(contractAddress, uint16Input1) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.phaseAggregators(uint16Input1).catch((e) => {
    console.error('Failed to call phaseAggregators in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorPhaseId(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.phaseId().catch((e) => {
    console.error('Failed to call phaseId in chainlinkAggregator:', e)
  })
}

export function submitChainlinkAggregatorProposeAggregator(
  contractAddress,
  _aggregator,
  signer,
  options = {},
) {
  const contract = getChainlinkAggregatorContract(signer, contractAddress)
  return sendTransaction(contract, 'proposeAggregator', [_aggregator, { ...options }])
}

export function getChainlinkAggregatorProposedAggregator(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.proposedAggregator().catch((e) => {
    console.error('Failed to call proposedAggregator in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorProposedGetRoundData(contractAddress, _roundId) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.proposedGetRoundData(_roundId).catch((e) => {
    console.error('Failed to call proposedGetRoundData in chainlinkAggregator:', e)
  })
}

export function getChainlinkAggregatorProposedLatestRoundData(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.proposedLatestRoundData().catch((e) => {
    console.error('Failed to call proposedLatestRoundData in chainlinkAggregator:', e)
  })
}

export function submitChainlinkAggregatorSetController(
  contractAddress,
  _accessController,
  signer,
  options = {},
) {
  const contract = getChainlinkAggregatorContract(signer, contractAddress)
  return sendTransaction(contract, 'setController', [_accessController, { ...options }])
}

export function submitChainlinkAggregatorTransferOwnership(
  contractAddress,
  _to,
  signer,
  options = {},
) {
  const contract = getChainlinkAggregatorContract(signer, contractAddress)
  return sendTransaction(contract, 'transferOwnership', [_to, { ...options }])
}

export function getChainlinkAggregatorVersion(contractAddress) {
  const contract = getChainlinkAggregatorContract(getChain().ethersProvider, contractAddress)
  return contract.version().catch((e) => {
    console.error('Failed to call version in chainlinkAggregator:', e)
  })
}
