import { FormNextLink } from 'grommet-icons'
import _ from 'lodash'
import React from 'react'
import { ORACLE_BY_TYPE } from 'src/constants/oracles'
import { getChainConfig } from 'src/store/userStore'

import { ReactComponent as LinkIcon } from '../../../assets/images/icons/ic-link.svg'
import { TOKENS_WITHOUT_LIQUIDATION } from '../../../constants/identifiers'
import useLiquidationPrice from '../../../hooks/useLiquidationPrice'
import useOracles from '../../../hooks/useOracles'
import useSubject from '../../../hooks/useSubject'
import { useAppChain } from '../../../hooks/useUserStore'
import { collateralsStore } from '../../../store/collateralsStore'
import currentCollateralStore from '../../../store/currentCollateralStore'
import { isChainlinkOracle } from '../../../store/oraclesStore'
import Spinner from '../../../uiKit/Spinner'
import { LiquidationPrice } from '../../LiquidationPrice'
import {
  StyledCdpParam,
  StyledCdpParamName,
  StyledCdpParams,
  StyledCdpParamValue,
} from '../DepositAndBorrow/styled'

export const CDPParamsChanges: React.FC<{ assetAmount: string; usdpAmount: string }> = ({
  assetAmount,
  usdpAmount,
}) => {
  const prices = useOracles()
  const collateralAddress = useSubject(currentCollateralStore.address)
  const oracleType = collateralsStore.getCollateralProps(collateralAddress).oracleType
  const liquidationPrice = useLiquidationPrice()
  const newLiquidationPrice = useLiquidationPrice(assetAmount, usdpAmount)
  const appChain = useAppChain()
  const { blockExplorerUrls, chainLinkOracleUrl } = getChainConfig()

  const oraclePrices = _.values(prices).filter((price) => collateralAddress === price.address)

  const chainlink = isChainlinkOracle(oracleType)
  const oracleName = chainlink ? 'Chainlink' : 'Oracle contract'
  const oracleLink = chainlink
    ? chainLinkOracleUrl
    : `${blockExplorerUrls[0]}/address/${ORACLE_BY_TYPE[getChainConfig().id][oracleType]}#code`

  const oracleNameFragment = (
    <>
      <span>{oracleName}</span>
      <LinkIcon width="15" height="15" style={{ marginLeft: '8px' }} />
    </>
  )

  return (
    <StyledCdpParams>
      <StyledCdpParam>
        <StyledCdpParamName style={{ fontWeight: 600 }}>
          Oracle price&nbsp;
          {oracleLink ? (
            <span>
              ({' '}
              <a target="_blank" rel="noopener noreferrer" href={oracleLink}>
                {oracleNameFragment}
              </a>{' '}
              )
            </span>
          ) : (
            oracleNameFragment
          )}
        </StyledCdpParamName>
        <StyledCdpParamValue>
          {!oraclePrices[0] ? (
            <Spinner />
          ) : (
            <>
              <div>
                {Number.isNaN(+oraclePrices[0].priceFormatted)
                  ? oraclePrices[0].priceFormatted
                  : `$${oraclePrices[0].priceFormatted}`}
              </div>
            </>
          )}
        </StyledCdpParamValue>
      </StyledCdpParam>
      {!TOKENS_WITHOUT_LIQUIDATION[appChain.id].includes(collateralAddress) && (
        <StyledCdpParam>
          <StyledCdpParamName>Liquidation price</StyledCdpParamName>
          <StyledCdpParamValue>
            {!oraclePrices[0] ? (
              <Spinner />
            ) : (
              <>
                <LiquidationPrice liquidationPrice={liquidationPrice || '0'} />
                <FormNextLink style={{ margin: '0 10px' }} />
                <LiquidationPrice liquidationPrice={newLiquidationPrice || '0'} />
              </>
            )}
          </StyledCdpParamValue>
        </StyledCdpParam>
      )}
    </StyledCdpParams>
  )
}
