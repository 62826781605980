import React from 'react'

import { useDetectWebGL } from '../../../hooks/useDetectWebGL'
import { isGnosis } from '../../../store/userStore'
import { MainText, StyledIntroText, SubText } from './styled'

export const Intro: React.FC = () => {
  const supportWebGL = useDetectWebGL()

  return (
    <StyledIntroText>
      <MainText>Unit protocol</MainText>
      <SubText>
        Unit Protocol lets you use a huge selection of tokens as collateral to mint{' '}
        {isGnosis() ? 'USG' : 'USDP'} stable
      </SubText>
    </StyledIntroText>
  )
}
