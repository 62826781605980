import { BehaviorSubject } from 'rxjs'

const currentCollateralStore = {
  address: new BehaviorSubject(null),
  useWeth: new BehaviorSubject(false),
  useWrapped: new BehaviorSubject(false),

  setCurrentAsset(asset: string): void {
    currentCollateralStore.address.next(asset)
  },

  setUseWeth(enable: boolean): void {
    currentCollateralStore.useWeth.next(enable)
  },

  setUseWrapped(enable: boolean): void {
    currentCollateralStore.useWrapped.next(enable)
  },
}

export default currentCollateralStore
