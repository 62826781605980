import { BehaviorSubject } from 'rxjs'

import { SUSDP_ADDRESS } from '../constants/identifiers'
import {
  getErc20TokenAllowance,
  getErc20TokenTotalSupply,
} from '../contracts/erc20token/contractFunctions'
import {
  trackErc20TokenApproval,
  trackErc20TokenTransfer,
} from '../contracts/erc20token/eventListeners'
import {
  getStakeUSDPEarned,
  getStakeUSDPRewardRate,
} from '../contracts/stakeusdp/contractFunctions'
import { getBalance } from '../utils/balances'
import $BN from '../utils/BigNumber'
import { StakeStoreTokens } from './stakeStore'
import { usdpStore } from './usdpStore'
import { getChainConfig, userStore } from './userStore'

export const stakeUSDPStore = {
  tokens: new BehaviorSubject<StakeStoreTokens>({
    [getChainConfig().usdpAddress]: {
      symbol: 'USDP',
      decimals: 18,
      balance: '0',
      claimable: '0',
    },
    [SUSDP_ADDRESS]: {
      symbol: 'SUSDP',
      decimals: 18,
      balance: '0',
    },
  }),
  storeSubscriptions: [],
  chainSubscriptions: [],
  apr: new BehaviorSubject<string>(''),
  initialized: false,
  chainConfig: getChainConfig(),

  async init(): Promise<void> {
    const newChainConfig = getChainConfig()
    stakeUSDPStore.chainConfig = newChainConfig

    stakeUSDPStore.storeSubscriptions.push(
      userStore.address.subscribe(stakeUSDPStore.fetchTokensParams),
    )
    stakeUSDPStore.storeSubscriptions.push(
      usdpStore.balance.subscribe((value) => {
        stakeUSDPStore._setTokenParam(newChainConfig.usdpAddress, value.toString(), 'balance')
      }),
    )

    stakeUSDPStore.initializeAPR()
  },

  async fetchTokensParams(wallet: string): Promise<void> {
    if (!wallet) {
      stakeUSDPStore._resetTokensParams()
    } else {
      await stakeUSDPStore._getSUSDPBalance(wallet)
      await stakeUSDPStore._getTokensAllowance(wallet)
    }
  },

  async _getSUSDPBalance(wallet: string): Promise<void> {
    const tokenAddress = SUSDP_ADDRESS
    const { contracts, usdpAddress } = stakeUSDPStore.chainConfig
    const totalStake = (await getBalance(contracts.stakeUSDP, usdpAddress)).toString()
    const userStake = (await getBalance(wallet, tokenAddress)).toString()
    const claimable = (await getStakeUSDPEarned(wallet)).toString()

    stakeUSDPStore.tokens.next({
      [usdpAddress]: {
        ...stakeUSDPStore.tokens.getValue()[usdpAddress],
        contractBalance: totalStake,
        claimable,
      },
      [tokenAddress]: {
        ...stakeUSDPStore.tokens.getValue()[tokenAddress],
        balance: userStake,
      },
    })

    stakeUSDPStore.chainSubscriptions.push(
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const contractBalance = (await getBalance(contracts.stakeUSDP, usdpAddress)).toString()
            const userClaimable = (await getStakeUSDPEarned(wallet)).toString()

            stakeUSDPStore._setTokenParam(usdpAddress, contractBalance, 'contractBalance')
            stakeUSDPStore._setTokenParam(usdpAddress, userClaimable, 'claimable')
          },
          contract: usdpAddress,
          src: SUSDP_ADDRESS,
          dst: '',
        },
        wallet,
      ),
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const contractBalance = (await getBalance(contracts.stakeUSDP, usdpAddress)).toString()
            const userClaimable = (await getStakeUSDPEarned(wallet)).toString()

            stakeUSDPStore._setTokenParam(usdpAddress, contractBalance, 'contractBalance')
            stakeUSDPStore._setTokenParam(usdpAddress, userClaimable, 'claimable')
          },
          contract: usdpAddress,
          src: '',
          dst: SUSDP_ADDRESS,
        },
        wallet,
      ),
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const balance = (await getBalance(wallet, tokenAddress)).toString()
            stakeUSDPStore._setTokenParam(tokenAddress, balance, 'balance')
          },
          contract: tokenAddress,
          src: wallet,
          dst: '',
        },
        wallet,
      ),
      await trackErc20TokenTransfer(
        {
          callback: async () => {
            const balance = (await getBalance(wallet, tokenAddress)).toString()
            stakeUSDPStore._setTokenParam(tokenAddress, balance, 'balance')
          },
          contract: tokenAddress,
          src: '',
          dst: wallet,
        },
        wallet,
      ),
    )
  },

  async initializeAPR(): Promise<void> {
    const { contracts } = stakeUSDPStore.chainConfig
    const duration = 604800
    const rewardRate = await getStakeUSDPRewardRate()
    const totalLocked = await getErc20TokenTotalSupply(contracts.stakeUSDP)
    const apr = $BN(duration)
      .times(rewardRate.toString())
      .times(365)
      .times(100)
      .div(7)
      .div(totalLocked.toString())
      .toFixed(2)
    stakeUSDPStore.apr.next(`${apr.toString()}%`)
  },

  async _getTokensAllowance(wallet: string): Promise<void> {
    const { usdpAddress } = stakeUSDPStore.chainConfig
    const allowances = await Promise.all(
      Object.keys(stakeUSDPStore.tokens.getValue()).map(async (tokenAddress) => {
        stakeUSDPStore.chainSubscriptions.push(
          await trackErc20TokenApproval(
            {
              callback: async ([event]) => {
                if (!event) return
                const tokenAllowance = BigInt(event.data).toString()
                stakeUSDPStore._setTokenParam(tokenAddress, tokenAllowance, 'allowance')
              },
              src: wallet,
              contract: tokenAddress,
              guy: SUSDP_ADDRESS,
            },
            wallet,
          ),
        )
        return getErc20TokenAllowance(tokenAddress, wallet, SUSDP_ADDRESS)
      }),
    )
    stakeUSDPStore.tokens.next({
      [usdpAddress]: {
        ...stakeUSDPStore.tokens.getValue()[usdpAddress],
        allowance: allowances[0].toString(),
      },
      [SUSDP_ADDRESS]: {
        ...stakeUSDPStore.tokens.getValue()[SUSDP_ADDRESS],
        allowance: allowances[1].toString(),
      },
    })
  },

  _setTokenParam(
    tokenAddress: string,
    value: string,
    param: 'balance' | 'allowance' | 'contractBalance' | 'claimable',
  ): void {
    stakeUSDPStore.tokens.next({
      ...stakeUSDPStore.tokens.getValue(),
      [tokenAddress.toLowerCase()]: {
        ...stakeUSDPStore.tokens.getValue()[tokenAddress.toLowerCase()],
        [param]: value,
      },
    })
  },

  async _resetTokensParams(): Promise<void> {
    const { contracts, usdpAddress } = stakeUSDPStore.chainConfig
    // TODO: add try-catch block? Token params should be reset in case of error too?
    const totalStake = (await getBalance(contracts.stakeUSDP, usdpAddress)).toString()
    stakeUSDPStore.tokens.next({
      [usdpAddress]: {
        ...stakeUSDPStore.tokens.getValue()[usdpAddress],
        balance: '0',
        allowance: '0',
        contractBalance: totalStake,
      },
      [SUSDP_ADDRESS]: {
        ...stakeUSDPStore.tokens.getValue()[SUSDP_ADDRESS],
        balance: '0',
        allowance: '0',
      },
    })
  },

  destroy(): void {
    stakeUSDPStore._resetTokensParams()
    stakeUSDPStore.storeSubscriptions.forEach((subscription): void => {
      subscription.unsubscribe()
    })
    stakeUSDPStore.chainSubscriptions.forEach((sub): void => {
      sub.unsubscribe()
    })
  },
}
