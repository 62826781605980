import debug from 'debug'

const log = debug('utils:prevStore')

export const STORE_KEY = 'upStore'

export const prevStore = ((): {
  pendingTx?: string
  chainId?: number
} => {
  let sessionStore = null
  try {
    const rawUPStore = sessionStorage.getItem(STORE_KEY)
    sessionStorage.removeItem(STORE_KEY)
    sessionStore = JSON.parse(rawUPStore)
  } catch (err) {
    console.error('Failed to parse sessionStorage.upStore', err)
  }

  return Object.freeze({ ...sessionStore })
})()

log(prevStore)
