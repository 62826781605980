import styled from 'styled-components'

import { borderRadius } from '../uiKit/theme'

export const Attention = styled.div`
  width: 100%;
  color: rgba(37, 37, 37, 0.8);
  font-weight: 400;
  text-align: center;
  background: #f6f6f6;
  padding: 2rem;
  border-radius: ${borderRadius.normal};
  margin-bottom: 2rem;
`
