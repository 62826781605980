import React from 'react'
import { useIsConnected } from 'src/hooks/useUserStore'
import { collateralsStore } from 'src/store/collateralsStore'

import { Intro } from '../../components/Header/IntroText'
import { IS_GNO } from '../../constants/constants'
import useSubject from '../../hooks/useSubject'
import { CollateralsList } from './components/CollateralsList'
import { EmptyListMessage, StyledCollateralsList } from './components/CollateralsList/styled'
import { FastFilters } from './components/FastFilters'
import { LeftSideBar } from './components/LeftSideBar'
import { HomePageLayout, HomePageMiddle, RightSideBarStub } from './styled'

export const Home: React.FC = () => {
  const isConnected = useIsConnected()
  const collateralsStoreLoading = useSubject(collateralsStore.loading)

  return (
    <>
      {!IS_GNO && <Intro />}
      <HomePageLayout>
        {collateralsStoreLoading ? (
          <StyledCollateralsList>
            <EmptyListMessage>Loading collaterals...</EmptyListMessage>
          </StyledCollateralsList>
        ) : (
          <>
            <LeftSideBar />
            <HomePageMiddle>
              {isConnected && <FastFilters />}
              <CollateralsList />
            </HomePageMiddle>
            <RightSideBarStub />
          </>
        )}
      </HomePageLayout>
    </>
  )
}
