import React from 'react'

import { UIButton } from '../UiButton/styled'
import { TabsContainer } from './styled'

interface TabProps {
  name: string
  id: string
}

interface TabsProps {
  tabs: TabProps[]
  currentTab: TabProps
  onTabClick: (tab: TabProps) => void
}

export const UiTabs: React.FC<TabsProps> = ({ tabs, currentTab, onTabClick }) => (
  <TabsContainer>
    {tabs.map((tab) => (
      <UIButton key={tab.id} disabled={tab.id === currentTab.id} onClick={() => onTabClick(tab)}>
        {tab.name}
      </UIButton>
    ))}
  </TabsContainer>
)
