import axios from 'axios'
import debug from 'debug'

import { TrustWalletApi } from '../types/api'
import { getChainConfig, isBsc, isMainnet } from './userStore'

const log = debug('store:tokensStore')

export const tokensStore = {
  tokensFromTrustWallet: {} as { [tokenAddress: string]: TrustWalletApi['data']['tokens'][0] },

  initTokensFromTrustWallet: fetchAndFormatTokensFromTrustWallet,
}

async function fetchAndFormatTokensFromTrustWallet(): Promise<void> {
  const formattedTokens = {}
  if (!isMainnet() && !isBsc()) {
    tokensStore.tokensFromTrustWallet = formattedTokens
    return
  }

  const resp = (await axios.get(getChainConfig().tokenListUrl)) as TrustWalletApi

  resp.data.tokens.forEach((token) => {
    formattedTokens[token.address.toLowerCase()] = token
  })

  log('tokens from trust: ', formattedTokens)

  tokensStore.tokensFromTrustWallet = formattedTokens
}
