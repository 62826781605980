import styled from 'styled-components'

import { borderRadius, color } from '../theme'
import { UIButton } from '../UiButton/styled'

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${color.liteGray};
  border-radius: ${borderRadius.normal};
  margin-bottom: 4rem;
  ${UIButton} {
    flex: 1 10px;
    padding: 2rem;
    margin: 1rem;
    :first-child:not(:last-child) {
      margin-right: 0;
    }
  }
`
