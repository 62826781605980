const { getChain } = require('src/store/userStore')

const prefixesMap = JSON.parse('{"1":"old","100":"old","250":"old","default":"new"}')

export const getVaultContractFunctions = () =>
  require(`./${
    prefixesMap[getChain().id] || prefixesMap.default || prefixesMap[1]
  }/contractFunctions`)

export const getEventListeners = () =>
  require(`./${prefixesMap[getChain().id] || prefixesMap.default || prefixesMap[1]}/eventListeners`)
