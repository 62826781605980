import styled from 'styled-components'

import { breakpoint, color } from '../../../uiKit/theme'

export const CDPManipulationContainer = styled.div`
  flex-grow: 1;
  max-width: 880px;
  width: 100%;
`

export const InputGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 2rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
  }
`

export const TokenInputContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: 48%;
  }
`

export const StyledCdpParam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 5rem;
  }
  @media screen and (min-width: ${breakpoint.tablet}) {
    width: 50%;
    :not(:last-child) {
      margin-bottom: 0;
    }
  }
  a {
    font-weight: normal;
    color: ${color.gray};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`

export const StyledCdpParams = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-top: 4rem;
  @media screen and (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    padding-top: 5rem;
  }
`

export const StyledCdpParamName = styled.span`
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 0.7em;
`

export const StyledCdpParamValue = styled.span`
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
`
