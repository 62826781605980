import React, { useState } from 'react'
import { useSetModal } from 'src/providers/ModalsProvider'

import MetaMaskIconDisabled from '../../../assets/images/icons/ic-metamask-disabled.svg'
import MetaMaskIcon from '../../../assets/images/icons/ic-metamask.svg'
import WalletConnectIconDisabled from '../../../assets/images/icons/ic-walletconnect-disabled.svg'
import WalletConnectIcon from '../../../assets/images/icons/ic-walletconnect.svg'
import { Modal } from '../../../uiKit/Modal'
import { color } from '../../../uiKit/theme'
import { UiCheckbox } from '../../../uiKit/UiCheckbox'
import { isMobile } from '../../../utils/isMobile'
import {
  ConnectStep,
  ConnectStepIcon,
  ConnectStepNum,
  ConnectStepTtl,
  ModalConnectContainer,
} from './styled'
import { useWeb3Controller } from '../../../providers/Web3CtxProvider'

const termsAcceptedFromLS = JSON.parse(localStorage.getItem('termsAccepted'))
const citizenConfirmFromLS = JSON.parse(localStorage.getItem('citizenConfirm') || 'false')

export const ModalConnect: React.FC = () => {
  const { connect } = useWeb3Controller()

  const [termsAccepted, setTermAccepted] = useState(termsAcceptedFromLS)
  const [citizenConfirm, setCitizenConfirm] = useState(citizenConfirmFromLS)
  const setModal = useSetModal()

  function termOfUseClickAction() {
    localStorage.setItem('termsAccepted', (!termsAccepted).toString())
    setTermAccepted(!termsAccepted)
  }

  function citizenConfirmClickAction() {
    localStorage.setItem('citizenConfirm', (!citizenConfirm).toString())
    setCitizenConfirm(!citizenConfirm)
  }

  async function handleConnect(type: string) {
    if (termsAccepted) {
      if (type === 'walletconnect') setModal(null)
      await connect(type)
      setModal(null)
    }
  }

  return (
    <Modal onCloseModal={() => setModal(null)} title="Connect Wallet" forConnect>
      <ModalConnectContainer termsAccepted={termsAccepted}>
        <ConnectStep>
          <ConnectStepNum>1</ConnectStepNum>
          <div>
            <ConnectStepTtl>
              Accept{' '}
              <a
                href="https://gateway.ipfs.io/ipfs/QmUDosDLFs5rM3yAyU4GQK4Z81kMbMue8X298hbSyzgrPn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </ConnectStepTtl>
            <UiCheckbox
              label="I have read and accept the terms."
              value={termsAccepted}
              onChange={termOfUseClickAction}
              style={{ color: color.gray }}
            />
          </div>
        </ConnectStep>
        <ConnectStep>
          <ConnectStepNum>2</ConnectStepNum>
          <UiCheckbox
            label="I hereby confirm that I am not a citizen or resident of the following countries: Afghanistan, American Samoa, U.S. Virgin Islands, Guam, Iran, Iraq, Yemen, Libya, Pakistan, State of Palestine, Puerto Rico, Somalia, North Korea, The Northern Mariana Islands, The United States of America, Syria, Russian Federation, Republic of Belarus, Transnistria, Turkish Republic of Northern Cyprus, Western Sahara, Federal Republic of Ambazonia, Kosovo, Ukraine, Sudan, Venezuela, Democratic Republic of the Congo, Singapore, Cuba, Canada, China, Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden."
            value={citizenConfirm}
            onChange={citizenConfirmClickAction}
            style={{ color: color.gray }}
          />
        </ConnectStep>
        <ConnectStep>
          <ConnectStepNum>3</ConnectStepNum>
          <div>
            <ConnectStepTtl>Choose Wallet</ConnectStepTtl>
            <div>
              {!isMobile() && (
                <ConnectStepIcon
                  onClick={() => handleConnect('injected')}
                  disabled={!termsAccepted && !citizenConfirm}
                  src={termsAccepted && citizenConfirm ? MetaMaskIcon : MetaMaskIconDisabled}
                />
              )}
              <ConnectStepIcon
                onClick={() => handleConnect('walletconnect')}
                disabled={!termsAccepted && !citizenConfirm}
                src={
                  termsAccepted && citizenConfirm ? WalletConnectIcon : WalletConnectIconDisabled
                }
              />
            </div>
          </div>
        </ConnectStep>
      </ModalConnectContainer>
    </Modal>
  )
}
