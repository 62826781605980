import { JsonRpcSigner } from '@ethersproject/providers/src.ts/json-rpc-provider'
import debug from 'debug'
import { BehaviorSubject } from 'rxjs'

import { GAUGED_3CRV, ZERO_ADDRESS } from '../constants/constants'
import {
  getBoneLockerGetLeftRightCounters,
  getBoneLockerLockInfoByUser,
  getBoneLockerLockingPeriod,
} from '../contracts/bonelocker/contractFunctions'
import {
  getGauge3crvClaimable_tokens,
  submitGauge3crvClaim_tokens,
} from '../contracts/gauge3crv/contractFunctions'
import { getTopDogBoneLocker } from '../contracts/topdog/contractFunctions'
import {
  getWrappedSSLPGetClaimableRewardFromBoneLocker,
  getWrappedSSLPPendingReward,
  getWrappedSSLPTopDog,
  getWrappedSSLPUsersProxies,
  submitWrappedSSLPClaimReward,
  submitWrappedSSLPClaimRewardFromBoneLocker,
} from '../contracts/wrappedsslp/contractFunctions'
import { ClaimStore, TokenClaimOptions } from '../types/claim'
import { Collateral } from '../types/collaterals'
import $BN from '../utils/BigNumber'
import { formatDateToHuman } from '../utils/utils'
import { userStore } from './userStore'

const log = debug('store:claimStore')

export const claimStore = {
  claims: new BehaviorSubject<ClaimStore>({}),

  fetchAndStoreClaims(collateral: Collateral): void {
    const tokenAddress = collateral.address
    log('Fetch and set claims for token: ', tokenAddress)
    const owner = userStore.address.getValue()
    const signer = userStore.signer.getValue()

    if (claimStore.claims.getValue()[tokenAddress]) {
      log('Token exist in store: ', claimStore.claims.getValue()[tokenAddress])
      return
    }

    if (tokenAddress === GAUGED_3CRV) {
      fetch3crvGaugeClaims(tokenAddress, owner, signer)
      return
    }

    if (collateral.type === 'shiba') {
      fetchShibaClaims(collateral.address, owner, signer)
    }

    console.warn(`fetchAndStoreClaims unresolved token: ${tokenAddress}`)
  },
}

function fetch3crvGaugeClaims(tokenAddress: string, owner: string, signer: JsonRpcSigner): void {
  getGauge3crvClaimable_tokens(owner).then((res) => {
    setTokenClaims(tokenAddress, {
      claimable: res.toString(),
      pending: '0',
      claimableToken: {
        address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
        symbol: 'CRV',
        decimals: 18,
      },
      claimAction: () => submitGauge3crvClaim_tokens(signer),
      claimPendingAction: () => null,
    })
  })
}

async function fetchShibaClaims(
  tokenAddress: string,
  owner: string,
  signer: JsonRpcSigner,
): Promise<void> {
  const boneLocker = ZERO_ADDRESS
  const [claimable, pending, totalPendingInfo] = await Promise.all([
    getWrappedSSLPPendingReward(tokenAddress, owner),
    getWrappedSSLPGetClaimableRewardFromBoneLocker(tokenAddress, owner, boneLocker),
    fetchShibaNextClaimPeriod(tokenAddress, owner),
  ])

  setTokenClaims(tokenAddress, {
    claimable: claimable.toString(),
    pending: pending.toString(),
    totalPendingInfo,
    claimableToken: {
      address: '',
      symbol: 'BONE',
      decimals: 18,
    },
    claimAction: () => submitWrappedSSLPClaimReward(tokenAddress, owner, signer),
    claimPendingAction: () =>
      submitWrappedSSLPClaimRewardFromBoneLocker(tokenAddress, boneLocker, 0, signer),
  })

  log('fetchShibaClaims: ', { claimable, pending })
}

async function fetchShibaNextClaimPeriod(
  tokenAddress: string,
  owner: string,
): Promise<TokenClaimOptions['totalPendingInfo']> {
  const userProxy = await getWrappedSSLPUsersProxies(tokenAddress, owner)
  const topDog = await getWrappedSSLPTopDog(tokenAddress)
  const boneLocker = await getTopDogBoneLocker(topDog)
  const [leftCounter, rightCounter] = (
    await getBoneLockerGetLeftRightCounters(boneLocker, userProxy)
  ).map(String)
  const countersLockInfoPromises = []

  for (let i = leftCounter; i < rightCounter; i++) {
    countersLockInfoPromises.push(getBoneLockerLockInfoByUser(boneLocker, userProxy, i))
  }

  let totalPendingRewards = $BN('0')
  let nextUnlockPeriod = ''
  let nextUnlockValue = ''
  const lockingPeriod = (await getBoneLockerLockingPeriod(boneLocker)).toString()

  ;(await Promise.all(countersLockInfoPromises)).forEach((info) => {
    totalPendingRewards = totalPendingRewards.plus(info[0].toString())
    if (!nextUnlockPeriod) nextUnlockPeriod = info[1].toString()
    if (!nextUnlockValue) nextUnlockValue = info[0].toString()
  })

  return {
    nextUnlockDate: formatDateToHuman($BN(nextUnlockPeriod).plus(lockingPeriod).toNumber()),
    totalPendingRewards: totalPendingRewards.toString(),
    nextUnlockValue,
  }
}

function setTokenClaims(tokenAddress, claims: TokenClaimOptions): void {
  claimStore.claims.next({
    ...claimStore.claims.getValue(),
    [tokenAddress]: claims,
  })

  log('Set token claims: ', claims)
}
