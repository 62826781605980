import { Pair, Token } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import { protocolStable } from 'src/constants/usdp'
import { getChain, getChainConfig } from 'src/store/userStore'

import { collateralsStore } from '../store/collateralsStore'
import $BN from './BigNumber'
import { isAddressesEq } from './compareAddresses'
import { getShibaSwapAddress, getSushiSwapAddress } from './oracle'
import { searchTokenPropsInAllStores } from './token'

export function getAtomicAmountFromDisplayAmount(
  amount: BigNumber.Value,
  tokenAddress: string,
): string {
  if (!tokenAddress) return '0'
  if (Number.isNaN(Number(amount))) return '0'
  if (amount === '0') return '0'

  const token =
    collateralsStore.getCollateralProps(tokenAddress) ||
    getChainConfig().collaterals[tokenAddress.toLowerCase()] ||
    (isAddressesEq(tokenAddress, getChainConfig().usdpAddress) && protocolStable())
  if (!token) {
    return '0'
  }
  const { decimals } = token
  const num = $BN(amount || 0)
    .times(10 ** decimals)
    .toString()
  if (num.includes('.')) return num.substr(0, num.indexOf('.'))
  return num
}

/** Cuts decimal with the given precision without rounding */
const cutToFixed = (val: string, digitsAfterPoint: number) =>
  digitsAfterPoint > 0 ? val.slice(0, val.indexOf('.') + digitsAfterPoint + 1) : val

export function getDisplayAmountFromAtomicAmount(
  amount: string,
  tokenAddress: string,
  cut = true,
): string {
  if (amount === '0') return '0'
  tokenAddress = getChain().getAddress(tokenAddress)
  const isUsdp = getChainConfig().usdpAddress === tokenAddress
  const token = searchTokenPropsInAllStores(tokenAddress)
  if (!token) {
    return '0'
  }
  const { decimals } = token

  const res = $BN(amount || 0)
    .div($BN(10).pow(decimals))
    .toString()
  if (cut) {
    if (isUsdp) {
      if (res.includes('.')) return cutToFixed(res, Number(res) < 0.01 ? 4 : 2)
      return Number(res).toFixed(2)
    }
    const getFirstTwoNonZeroValues = /^-?\d*\.?0*\d{0,2}/
    if ($BN(res).lt(0.0001)) return res.match(getFirstTwoNonZeroValues)[0]
    if (res.includes('.')) return cutToFixed(res, 4)
  }
  return res
}

export function cutSomeEthToCoverFee(amount: string): string {
  const fee = $BN(10).pow(17)
  const amnt = $BN(amount)
  if (amnt.comparedTo(fee) === 1) {
    return amnt.minus(fee).toString()
  }
  return '0'
}

export function getDisplayUSDAmountFromAtomicUSDAmount(amount: string): string {
  return $BN(amount || 0)
    .dividedBy(10 ** 18)
    .toFixed(4)
}

export function sqrt(value: bigint): bigint {
  if (value < 0n) {
    throw new Error('square root of negative numbers is not supported')
  }

  if (value < 2n) {
    return value
  }

  function newtonIteration(n, x0): bigint {
    // eslint-disable-next-line no-bitwise
    const x1 = (n / x0 + x0) >> 1n
    if (x0 === x1 || x0 === x1 - 1n) {
      return x0
    }
    return newtonIteration(n, x1)
  }

  return newtonIteration(value, 1n)
}

const makeGetAddress =
  (getter) =>
  (asset1: string, asset2: string): string => {
    const { id, wsProvider } = getChain()
    return getter(
      new Token(id, wsProvider.utils.toChecksumAddress(asset1), 18),
      new Token(id, wsProvider.utils.toChecksumAddress(asset2), 18),
    ).toLowerCase()
  }

export const uniLPAddress = makeGetAddress(Pair.getAddress.bind(Pair))
export const sushiLPAddress = makeGetAddress(getSushiSwapAddress)
export const shibaLPAddress = makeGetAddress(getShibaSwapAddress)

export function formatNumber(x: BigNumber.Value, precision = 2): string {
  if ($BN(x).gte(1_000_000)) {
    return `${$BN(x).div(1_000_000).toFixed(precision)}M`
  }
  if ($BN(x).gte(1_000)) {
    return `${$BN(x).div(1_000).toFixed(precision)}K`
  }
  return $BN(x).toFixed(precision)
}

export function formatDateToHuman(timestamp: number): string {
  // convert to UTC
  const d = new Date(timestamp * 1000 + new Date().getTimezoneOffset() * 60 * 1000)
  return `${[
    d.getDate().toString().padStart(2, '0'),
    `${(d.getMonth() + 1).toString().padStart(2, '0')}`,
    d.getFullYear(),
  ].join('.')} ${[
    `${d.getHours()}`.padStart(2, '0'),
    `${d.getMinutes()}`.padStart(2, '0'),
    `${d.getSeconds()}`.padStart(2, '0'),
  ].join(':')}`
}

export function getThursdayUTCTimestamp(): number {
  const ts = new Date().getTime()
  const week = 604800

  return Math.floor(ts / 1000 / week) * week
}
