import React, { useEffect } from 'react'
import { useAppChain } from 'src/hooks/useUserStore'

import { Attention } from '../../components/Attention'
import { LockingBlock } from '../../components/LockingBlock'
import { DUCK_ADDRESS } from '../../constants/identifiers'
import useSubject from '../../hooks/useSubject'
import { lockingStore, LockStats } from '../../store/lockingStore'
import { MainBlock, MainWrap } from '../../uiKit/MainBlock'
import $BN from '../../utils/BigNumber'
import { formatNumber, getDisplayAmountFromAtomicAmount } from '../../utils/utils'
import { Param, ParamName, ParamValue } from '../asset/components/CollateralParameters/styled'
import { StyledCurrentCDPParams } from '../asset/components/CurrentCDP/CurrentCDPParams/styled'

export const StakingV2: React.FC = () => {
  const tokens = useSubject(lockingStore.tokens)
  const lockStats = useSubject(lockingStore.stats) as LockStats
  const { contracts } = useAppChain().config

  useEffect(() => {
    lockingStore.init()
    return lockingStore.destroy
  }, [])

  const averageLockTime = $BN(lockStats.totalMinted).times(4).div(lockStats.totalLocked).toFixed(2)

  return (
    <MainWrap>
      <MainBlock pad="small">
        <StyledCurrentCDPParams>
          <Param>
            <ParamName>Total DUCK locked</ParamName>
            <ParamValue>
              {formatNumber(getDisplayAmountFromAtomicAmount(lockStats.totalLocked, DUCK_ADDRESS))}
            </ParamValue>
          </Param>
          <Param>
            <ParamName>Total veDUCK</ParamName>
            <ParamValue>
              {formatNumber(
                getDisplayAmountFromAtomicAmount(lockStats.totalMinted, contracts.veDuck),
              )}
            </ParamValue>
          </Param>
          <Param>
            <ParamName>Average lock time</ParamName>
            <ParamValue>{averageLockTime}</ParamValue>
          </Param>
          <Attention style={{ marginBottom: 0 }}>
            Note: you can only withdraw after the lock expires
          </Attention>
        </StyledCurrentCDPParams>
      </MainBlock>
      <LockingBlock tokens={tokens} lockStats={lockStats} />
    </MainWrap>
  )
}
