import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { TransactionsToasts } from 'src/components/TransactionsToasts'
import { CHAIN_ID } from 'src/constants/network'
import { StorageController } from 'src/controllers/StorageController'
import { StoreInitializer } from 'src/controllers/StoreInitializer'
import { useAppChain } from 'src/hooks/useUserStore'
import { GlobalStyles } from 'src/uiKit/GlobalStyles'
import { AssetPage } from 'src/views/asset/AssetPage'
import { Home } from 'src/views/home'
import { StakePage } from 'src/views/stake'
import { StakeUSDP } from 'src/views/stakeUSDP'
import { StakingV2 } from 'src/views/stakingV2'

import { Modals } from './components/Modals/Modals'
import { IS_GNO } from './constants/constants'
import { color } from './uiKit/theme'
import { ChainUrlRepresentation } from './views/chain-url-representation'
import { Liquidations } from './views/liquidations'
import { Web3CtxProvider } from './providers/Web3CtxProvider'

export const App: React.FC = () => {
  const isMainnet = useAppChain().id === CHAIN_ID.ETH
  const { pathname } = useLocation()
  const navigate = useNavigate()

  console.log('test')

  const subDomain = window.location.hostname.replace('www.', '').split('.').slice(0, -2).join('.')

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.background = color.grayBg
    if (pathname === `/`) {
      navigate(IS_GNO ? `/gnosis` : `/eth`, { replace: true })
    }
  }, [pathname])

  return (
    <Web3CtxProvider>
      <Routes>
        <Route path="/:chainUrlRepresentation" element={<ChainUrlRepresentation />}>
          <Route index element={<Home />} />
          <Route path="asset">
            <Route index element={<Navigate to="/" replace />} />
            <Route path=":assetName" element={<AssetPage />} />
          </Route>
          <Route path="liquidations" element={<Liquidations />} />
          {isMainnet && (
            <>
              <Route path="stakingv2" element={<StakingV2 />} />
              <Route path="staking" element={<StakePage />} />
              <Route path="staking-usdp" element={<StakeUSDP />} />
            </>
          )}
        </Route>
      </Routes>

      <GlobalStyles />
      <Modals />
      <TransactionsToasts />
      <StoreInitializer />
      <StorageController />
    </Web3CtxProvider>
  )
}
