import * as ethers from 'ethers'

import { getChain, getChainConfig } from 'src/store/userStore'
import { sendTransaction } from 'src/store/transactionsStore'
import abi from 'src/contracts/abis/VeDuck.json'

export function getVeDuckContract(provider) {
  return new ethers.Contract(getChainConfig().contracts.veDuck, abi, provider)
}

export function submitVeDuckCommit_transfer_ownership(addr, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'commit_transfer_ownership', [addr, { ...options }])
}

export function submitVeDuckApply_transfer_ownership(signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'apply_transfer_ownership', [{ ...options }])
}

export function submitVeDuckCommit_smart_wallet_checker(addr, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'commit_smart_wallet_checker', [addr, { ...options }])
}

export function submitVeDuckApply_smart_wallet_checker(signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'apply_smart_wallet_checker', [{ ...options }])
}

export function getVeDuckGet_last_user_slope(addr) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.get_last_user_slope(addr).catch((e) => {
    console.error('Failed to call get_last_user_slope in veDuck:', e)
  })
}

export function getVeDuckUser_point_history__ts(_addr, _idx) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.user_point_history__ts(_addr, _idx).catch((e) => {
    console.error('Failed to call user_point_history__ts in veDuck:', e)
  })
}

export function getVeDuckLocked__end(_addr) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.locked__end(_addr).catch((e) => {
    console.error('Failed to call locked__end in veDuck:', e)
  })
}

export function submitVeDuckCheckpoint(signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'checkpoint', [{ ...options }])
}

export function submitVeDuckDeposit_for(_addr, _value, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'deposit_for', [_addr, _value, { ...options }])
}

export function submitVeDuckCreate_lock(_value, _unlock_time, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'create_lock', [_value, _unlock_time, { ...options }])
}

export function submitVeDuckIncrease_amount(_value, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'increase_amount', [_value, { ...options }])
}

export function submitVeDuckIncrease_unlock_time(_unlock_time, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'increase_unlock_time', [_unlock_time, { ...options }])
}

export function submitVeDuckWithdraw(signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'withdraw', [{ ...options }])
}

export function getVeDuckBalanceOf(addr) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.balanceOf(addr).catch((e) => {
    console.error('Failed to call balanceOf in veDuck:', e)
  })
}

export function getVeDuckBalanceOfAt(addr, _block) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.balanceOfAt(addr, _block).catch((e) => {
    console.error('Failed to call balanceOfAt in veDuck:', e)
  })
}

export function getVeDuckTotalSupply() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.totalSupply().catch((e) => {
    console.error('Failed to call totalSupply in veDuck:', e)
  })
}

export function getVeDuckTotalSupplyAt(_block) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.totalSupplyAt(_block).catch((e) => {
    console.error('Failed to call totalSupplyAt in veDuck:', e)
  })
}

export function submitVeDuckChangeController(_newController, signer, options = {}) {
  const contract = getVeDuckContract(signer)
  return sendTransaction(contract, 'changeController', [_newController, { ...options }])
}

export function getVeDuckToken() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.token().catch((e) => {
    console.error('Failed to call token in veDuck:', e)
  })
}

export function getVeDuckSupply() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.supply().catch((e) => {
    console.error('Failed to call supply in veDuck:', e)
  })
}

export function getVeDuckLocked(arg0) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.locked(arg0).catch((e) => {
    console.error('Failed to call locked in veDuck:', e)
  })
}

export function getVeDuckEpoch() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.epoch().catch((e) => {
    console.error('Failed to call epoch in veDuck:', e)
  })
}

export function getVeDuckPoint_history(arg0) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.point_history(arg0).catch((e) => {
    console.error('Failed to call point_history in veDuck:', e)
  })
}

export function getVeDuckUser_point_history(arg0, arg1) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.user_point_history(arg0, arg1).catch((e) => {
    console.error('Failed to call user_point_history in veDuck:', e)
  })
}

export function getVeDuckUser_point_epoch(arg0) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.user_point_epoch(arg0).catch((e) => {
    console.error('Failed to call user_point_epoch in veDuck:', e)
  })
}

export function getVeDuckSlope_changes(arg0) {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.slope_changes(arg0).catch((e) => {
    console.error('Failed to call slope_changes in veDuck:', e)
  })
}

export function getVeDuckController() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.controller().catch((e) => {
    console.error('Failed to call controller in veDuck:', e)
  })
}

export function getVeDuckTransfersEnabled() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.transfersEnabled().catch((e) => {
    console.error('Failed to call transfersEnabled in veDuck:', e)
  })
}

export function getVeDuckName() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.name().catch((e) => {
    console.error('Failed to call name in veDuck:', e)
  })
}

export function getVeDuckSymbol() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.symbol().catch((e) => {
    console.error('Failed to call symbol in veDuck:', e)
  })
}

export function getVeDuckVersion() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.version().catch((e) => {
    console.error('Failed to call version in veDuck:', e)
  })
}

export function getVeDuckDecimals() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.decimals().catch((e) => {
    console.error('Failed to call decimals in veDuck:', e)
  })
}

export function getVeDuckFuture_smart_wallet_checker() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.future_smart_wallet_checker().catch((e) => {
    console.error('Failed to call future_smart_wallet_checker in veDuck:', e)
  })
}

export function getVeDuckSmart_wallet_checker() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.smart_wallet_checker().catch((e) => {
    console.error('Failed to call smart_wallet_checker in veDuck:', e)
  })
}

export function getVeDuckAdmin() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.admin().catch((e) => {
    console.error('Failed to call admin in veDuck:', e)
  })
}

export function getVeDuckFuture_admin() {
  const contract = getVeDuckContract(getChain().ethersProvider)
  return contract.future_admin().catch((e) => {
    console.error('Failed to call future_admin in veDuck:', e)
  })
}
