import { getCreate2Address } from '@ethersproject/address'
import { keccak256, pack } from '@ethersproject/solidity'
import { Token } from '@uniswap/sdk'
import BigNumber from 'bignumber.js'
import { MAX_UINT, Q112 } from 'src/constants/constants'
import { collateralsStore } from 'src/store/collateralsStore'
import { getChainConfig, isMainnet } from 'src/store/userStore'

import {
  getErc20TokenBalanceOf,
  getErc20TokenTotalSupply,
} from '../contracts/erc20token/contractFunctions'
import {
  getKeep3rV1OracleObservationLength,
  getKeep3rV1OracleObservations,
} from '../contracts/keep3rv1oracle/contractFunctions'
import { getOracleRegistryOracleTypeByAsset } from '../contracts/oracleregistry/contractFunctions'
import { getShibaSwapFactoryGetPair } from '../contracts/shibaswapfactory/contractFunctions'
import { getSushiSwapFactoryGetPair } from '../contracts/sushiswapfactory/contractFunctions'
import { getUniswapFactoryGetPair } from '../contracts/uniswapfactory/contractFunctions'
import {
  getUniswapPairGetReserves,
  getUniswapPairPrice0CumulativeLast,
  getUniswapPairPrice1CumulativeLast,
} from '../contracts/uniswappair/contractFunctions'
import { getLastestBlockTimestamp, getLatestBlockNumber } from '../store/blockStore'
import $BN from './BigNumber'
import * as OracleSdkAdapter from './oracleSDK'
import { shibaLPAddress, sushiLPAddress, uniLPAddress } from './utils'

const blockLookbackAverage = 120

const minObservationTimeBack = 5400
const maxObservationTimeBack = 9000

function bufferToHex(buffer: Uint8Array) {
  return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, '0')).join('')
}

async function getAssetPriceProof(assetAddress: string): Promise<[string[], bigint]> {
  const { wethAddress } = getChainConfig()
  if (wethAddress === assetAddress) throw new Error('no proof for WETH')

  const oracleType =
    (
      collateralsStore.getCollateralProps(assetAddress) ||
      getChainConfig().collaterals[assetAddress]
    )?.oracleType || Number(await getOracleRegistryOracleTypeByAsset(assetAddress))

  const [uniswapPoolAddress, currentBlockNumber] = await Promise.all([
    oracleType === 1
      ? getUniswapFactoryGetPair(wethAddress, assetAddress)
      : oracleType === 18
      ? getShibaSwapFactoryGetPair(wethAddress, assetAddress)
      : getSushiSwapFactoryGetPair(wethAddress, assetAddress),
    getLatestBlockNumber(),
  ])

  const blockNumber = currentBlockNumber - blockLookbackAverage
  // create the getters the SDK needs from an Ethereum instance off the window.  you could use `window.web3.currentProvider` instead of `window.ethereum` if that is what is available
  const getStorageAt = OracleSdkAdapter.getStorageAtFactory()
  const getProofMethod = OracleSdkAdapter.getProofFactory()
  const getBlockByNumber = OracleSdkAdapter.getBlockByNumberFactory()

  const proof = await getProof(
    getStorageAt,
    getProofMethod,
    getBlockByNumber,
    BigInt(uniswapPoolAddress),
    BigInt(wethAddress),
    BigInt(blockNumber),
  )

  const proofFormatted = [
    `0x${bufferToHex(proof.block)}`,
    `0x${bufferToHex(proof.accountProofNodesRlp)}`,
    `0x${bufferToHex(proof.reserveAndTimestampProofNodesRlp)}`,
    `0x${bufferToHex(proof.priceAccumulatorProofNodesRlp)}`,
  ]

  return [proofFormatted, BigInt(blockNumber)]
}

async function getProof(
  eth_getStorageAt,
  eth_getProof,
  eth_getBlockByNumber,
  exchangeAddress: bigint,
  denominationToken: bigint,
  blockNumber: bigint,
) {
  const [token0Address, token1Address, block] = await Promise.all([
    eth_getStorageAt(exchangeAddress, 6n, 'latest'),
    eth_getStorageAt(exchangeAddress, 7n, 'latest'),
    eth_getBlockByNumber(blockNumber),
  ])
  if (denominationToken !== token0Address && denominationToken !== token1Address)
    throw new Error(
      `Denomination token ${addressToString(
        denominationToken,
      )} is not one of the two tokens for the Uniswap exchange at ${addressToString(
        exchangeAddress,
      )}`,
    )
  if (block === null) throw new Error(`Received null for block ${Number(blockNumber)}`)
  const priceAccumulatorSlot = denominationToken === token0Address ? 10n : 9n
  const proof = await eth_getProof(exchangeAddress, [8n, priceAccumulatorSlot], blockNumber)
  const blockRlp = rlpEncodeBlock(block)
  const accountProofNodesRlp = rlpEncode(proof.accountProof.map(rlpDecode))
  const reserveAndTimestampProofNodesRlp = rlpEncode(proof.storageProof[0].proof.map(rlpDecode))
  const priceAccumulatorProofNodesRlp = rlpEncode(proof.storageProof[1].proof.map(rlpDecode))

  return {
    block: blockRlp,
    accountProofNodesRlp,
    reserveAndTimestampProofNodesRlp,
    priceAccumulatorProofNodesRlp,
  }
}

async function getAssetAvgPriceInEth_Keydonix(
  assetAddress: string,
  oracleType: number,
): Promise<bigint> {
  const { wethAddress } = getChainConfig()
  let uniswapPoolAddress

  if (oracleType === 1) {
    uniswapPoolAddress = BigInt(uniLPAddress(wethAddress, assetAddress))
  } else if (oracleType === 13) {
    uniswapPoolAddress = BigInt(sushiLPAddress(wethAddress, assetAddress))
  } else if (oracleType === 18) {
    uniswapPoolAddress = BigInt(shibaLPAddress(wethAddress, assetAddress))
  } else {
    throw new Error(`Wrong oracle for asset ${assetAddress} ${oracleType}. KEYDONIX`)
  }

  const blockNumber = (await getLatestBlockNumber()) - blockLookbackAverage
  const getStorageAt = OracleSdkAdapter.getStorageAtFactory()
  const getBlockByNumber = OracleSdkAdapter.getBlockByNumberFactory()

  return getPrice(
    getStorageAt,
    getBlockByNumber,
    uniswapPoolAddress,
    BigInt(wethAddress),
    BigInt(blockNumber),
  )
}

async function getAssetAvgPriceInEth_Keep3r(assetAddress: string): Promise<bigint> {
  const blockTime = BigInt(await getLastestBlockTimestamp())
  const { wethAddress } = getChainConfig()

  const uniswapPoolAddress = uniLPAddress(wethAddress, assetAddress)

  const token0 = BigInt(assetAddress) < BigInt(wethAddress) ? assetAddress : wethAddress
  const observationLength = await getKeep3rV1OracleObservationLength(uniswapPoolAddress)
  if (observationLength <= 1) throw new Error('Unit Protocol: NOT_ENOUGH_OBSERVATIONS')
  const lastObservationIndex = BigInt(observationLength) - BigInt(1)
  let promises = [
    getUniswapPairPrice0CumulativeLast(uniswapPoolAddress),
    getUniswapPairPrice1CumulativeLast(uniswapPoolAddress),
    getUniswapPairGetReserves(uniswapPoolAddress),
    getKeep3rV1OracleObservations(uniswapPoolAddress, lastObservationIndex),
  ]

  for (let i = 0; i < 5; i++) {
    promises.push(
      getKeep3rV1OracleObservations(uniswapPoolAddress, lastObservationIndex - BigInt(i)),
    )
  }
  promises = await Promise.all(promises)

  let timestampObs
  let price0CumulativeObs
  let price1CumulativeObs
  let timestampObsNext
  let price0CumulativeObsNext
  let price1CumulativeObsNext
  let price0Cumulative = BigInt(promises[0])
  let price1Cumulative = BigInt(promises[1])
  const [reserve0, reserve1, blockTimestampLast] = promises[2]
  let promiseIndex = 3
  ;[timestampObs, price0CumulativeObs, price1CumulativeObs] = promises[promiseIndex]

  if (blockTime !== BigInt(blockTimestampLast)) {
    // subtraction overflow is desired
    const elapsed = blockTime - BigInt(blockTimestampLast)
    // addition overflow is desired
    // counterfactual
    // eslint-disable-next-line no-bitwise
    price0Cumulative += ((BigInt(reserve1) << BigInt(112)) / BigInt(reserve0)) * elapsed
    // counterfactual
    // eslint-disable-next-line no-bitwise
    price1Cumulative += ((BigInt(reserve0) << BigInt(112)) / BigInt(reserve1)) * elapsed
  }

  while (blockTime - BigInt(timestampObs) < BigInt(minObservationTimeBack)) {
    timestampObsNext = timestampObs
    price0CumulativeObsNext = price0CumulativeObs
    price1CumulativeObsNext = price1CumulativeObs

    promiseIndex++
    ;[timestampObs, price0CumulativeObs, price1CumulativeObs] = promises[promiseIndex]
    if (blockTime - BigInt(timestampObs) >= maxObservationTimeBack) {
      console.error('Unit Protocol: STALE_PRICES')
      return BigInt(0)
    }
  }

  const timeElapsed = blockTime - BigInt(timestampObs)

  if (!timestampObsNext) {
    return BigInt(0)
  }

  const timeElapsedNext = blockTime - BigInt(timestampObsNext)
  let current
  let next
  if (token0 === assetAddress) {
    current = computeAmountOut(price0CumulativeObs, price0Cumulative, timeElapsed, BigInt(1))
    next = computeAmountOut(price0CumulativeObsNext, price0Cumulative, timeElapsedNext, BigInt(1))
  } else {
    current = computeAmountOut(price1CumulativeObs, price1Cumulative, timeElapsed, BigInt(1))
    next = computeAmountOut(price1CumulativeObsNext, price1Cumulative, timeElapsedNext, BigInt(1))
  }

  return current < next ? current : next
}

function computeAmountOut(
  priceCumulativeStart: string,
  priceCumulativeEnd: bigint,
  timeElapsed: bigint,
  amountIn: bigint,
): bigint {
  let avgPrice: bigint
  if (priceCumulativeEnd > BigInt(priceCumulativeStart)) {
    avgPrice = (priceCumulativeEnd - BigInt(priceCumulativeStart)) / timeElapsed
  } else {
    avgPrice = (BigInt(MAX_UINT) - BigInt(priceCumulativeStart) + priceCumulativeEnd) / timeElapsed
  }
  return avgPrice * amountIn
}

export function sdkPriceInEthToUsdQ112(
  amount: BigNumber.Value,
  priceInEth: BigNumber.Value,
  ethUsdPrice: BigNumber.Value,
): string {
  return $BN(amount).times(priceInEth).times(ethUsdPrice).div(1e8).toString()
}

async function getMainAssetPriceFastQ112(assetAddress: string): Promise<bigint> {
  const { wethAddress } = getChainConfig()
  const uniswapPoolAddress = await getUniswapFactoryGetPair(wethAddress, assetAddress)
  const ethReserve = BigInt(await getErc20TokenBalanceOf(wethAddress, uniswapPoolAddress))
  const tokenReserve = BigInt(await getErc20TokenBalanceOf(assetAddress, uniswapPoolAddress))
  return (ethReserve * Q112) / tokenReserve
}

async function getPoolTokenPriceFastQ112(assetAddress: string): Promise<bigint> {
  const promises = []
  const { wethAddress } = getChainConfig()
  promises.push(getErc20TokenBalanceOf(wethAddress, assetAddress))
  promises.push(getErc20TokenTotalSupply(assetAddress))
  const [ethReserve, poolTokenSupply] = await Promise.all(promises)
  return (BigInt(ethReserve) * BigInt(2) * Q112) / BigInt(poolTokenSupply)
}

function getSushiSwapAddress(tokenA: Token, tokenB: Token): string {
  const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

  return getCreate2Address(
    getChainConfig().contracts.sushiSwapFactory,
    keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
    isMainnet()
      ? '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303'
      : '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
  )
}

function getShibaSwapAddress(tokenA: Token, tokenB: Token): string {
  const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

  return getCreate2Address(
    getChainConfig().contracts.shibaSwapFactory,
    keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
    '0x65d1a3b1e46c6e4f1be1ad5f99ef14dc488ae0549dc97db9b30afe2241ce1c7a',
  )
}

export {
  getAssetPriceProof,
  getAssetAvgPriceInEth_Keydonix,
  getMainAssetPriceFastQ112,
  getPoolTokenPriceFastQ112,
  getAssetAvgPriceInEth_Keep3r,
  getSushiSwapAddress,
  getShibaSwapAddress,
}

function rlpEncodeBlock(block) {
  return rlpEncode([
    unsignedIntegerToUint8Array(block.parentHash, 32),
    unsignedIntegerToUint8Array(block.sha3Uncles, 32),
    unsignedIntegerToUint8Array(block.miner, 20),
    unsignedIntegerToUint8Array(block.stateRoot, 32),
    unsignedIntegerToUint8Array(block.transactionsRoot, 32),
    unsignedIntegerToUint8Array(block.receiptsRoot, 32),
    unsignedIntegerToUint8Array(block.logsBloom, 256),
    stripLeadingZeros(unsignedIntegerToUint8Array(block.difficulty, 32)),
    stripLeadingZeros(unsignedIntegerToUint8Array(block.number, 32)),
    stripLeadingZeros(unsignedIntegerToUint8Array(block.gasLimit, 32)),
    stripLeadingZeros(unsignedIntegerToUint8Array(block.gasUsed, 32)),
    stripLeadingZeros(unsignedIntegerToUint8Array(block.timestamp, 32)),
    stripLeadingZeros(block.extraData),
    ...(block.mixHash !== undefined ? [unsignedIntegerToUint8Array(block.mixHash, 32)] : []),
    ...(block.nonce !== null && block.nonce !== undefined
      ? [unsignedIntegerToUint8Array(block.nonce, 8)]
      : []),
    ...(block.baseFeePerGas
      ? [stripLeadingZeros(unsignedIntegerToUint8Array(block.baseFeePerGas, 32))]
      : []),
  ])
}

function addressToString(value: bigint) {
  return `0x${value.toString(16).padStart(40, '0')}`
}

export function stripLeadingZeros(byteArray: Uint8Array): Uint8Array {
  let i = 0
  for (; i < byteArray.length; ++i) {
    if (byteArray[i] !== 0) break
  }
  const result = new Uint8Array(byteArray.length - i)
  for (let j = 0; j < result.length; ++j) {
    result[j] = byteArray[i + j]
  }
  return result
}

function unsignedIntegerToUint8Array(value: bigint | number, widthInBytes: 8 | 20 | 32 | 256 = 32) {
  if (typeof value === 'number') {
    if (!Number.isSafeInteger(value))
      throw new Error(`${value} is not able to safely be cast into a bigint.`)
    value = BigInt(value)
  }
  if (value >= BigInt(`0x1${'00'.repeat(widthInBytes)}`) || value < 0n)
    throw new Error(`Cannot fit ${value} into a ${widthInBytes * 8}-bit unsigned integer.`)
  const result = new Uint8Array(widthInBytes)
  if (result.length !== widthInBytes)
    throw new Error(`Cannot a ${widthInBytes} value into a ${result.length} byte array.`)
  for (let i = 0; i < result.length; ++i) {
    // eslint-disable-next-line no-bitwise
    result[i] = Number((value >> BigInt((widthInBytes - i) * 8 - 8)) & 0xffn)
  }
  return result
}

function rlpDecode(data: Uint8Array) {
  return rlpDecodeItem(data).decoded
}

function rlpDecodeItem(data: Uint8Array): { decoded; consumed: number } {
  if (data.length === 0) throw new Error(`Cannot RLP decode a 0-length byte array.`)
  if (data[0] <= 0x7f) {
    const consumed = 1
    const decoded = data.slice(0, consumed)
    return { decoded, consumed }
  }
  if (data[0] <= 0xb7) {
    const byteLength = data[0] - 0x80
    if (byteLength > data.length - 1)
      throw new Error(
        `Encoded data length (${byteLength}) is larger than remaining data (${data.length - 1}).`,
      )
    const consumed = 1 + byteLength
    const decoded = data.slice(1, consumed)
    if (byteLength === 1 && decoded[0] <= 0x7f)
      throw new Error(
        `A tiny value (${decoded[0].toString(16)}) was found encoded as a small value (> 0x7f).`,
      )
    return { decoded, consumed }
  }
  if (data[0] <= 0xbf) {
    const lengthBytesLength = data[0] - 0xb7
    if (lengthBytesLength > data.length - 1)
      throw new Error(
        `Encoded length of data length (${lengthBytesLength}) is larger than the remaining data (${
          data.length - 1
        })`,
      )
    // the conversion to Number here is lossy, but we throw on the following line in that case so "meh"
    const length = decodeLength(data, 1, lengthBytesLength)
    if (length > data.length - 1 - lengthBytesLength)
      throw new Error(
        `Encoded data length (${length}) is larger than the remaining data (${
          data.length - 1 - lengthBytesLength
        })`,
      )
    const consumed = 1 + lengthBytesLength + length
    const decoded = data.slice(1 + lengthBytesLength, consumed)
    if (length <= 0x37)
      throw new Error(`A small value (<= 55 bytes) was found encoded in a large value (> 55 bytes)`)
    return { decoded, consumed }
  }
  if (data[0] <= 0xf7) {
    const length = data[0] - 0xc0
    if (length > data.length - 1)
      throw new Error(
        `Encoded array length (${length}) is larger than remaining data (${data.length - 1}).`,
      )
    let offset = 1
    const results = []
    while (offset !== length + 1) {
      const { decoded, consumed } = rlpDecodeItem(data.slice(offset))
      results.push(decoded)
      offset += consumed
      if (offset > length + 1)
        throw new Error(
          `Encoded array length (${length}) doesn't align with the sum of the lengths of the encoded elements (${offset})`,
        )
    }
    return { decoded: results, consumed: offset }
  }
  const lengthBytesLength = data[0] - 0xf7
  // the conversion to Number here is lossy, but we throw on the following line in that case so "meh"
  const length = decodeLength(data, 1, lengthBytesLength)
  if (length > data.length - 1 - lengthBytesLength)
    throw new Error(
      `Encoded array length (${length}) is larger than the remaining data (${
        data.length - 1 - lengthBytesLength
      })`,
    )
  let offset = 1 + lengthBytesLength
  const results = []
  while (offset !== length + 1 + lengthBytesLength) {
    const { decoded, consumed } = rlpDecodeItem(data.slice(offset))
    results.push(decoded)
    offset += consumed
    if (offset > length + 1 + lengthBytesLength)
      throw new Error(
        `Encoded array length (${length}) doesn't align with the sum of the lengths of the encoded elements (${offset})`,
      )
  }
  return { decoded: results, consumed: offset }
}

function decodeLength(data: Uint8Array, offset: number, lengthBytesLength: number): number {
  const lengthBytes = data.slice(offset, offset + lengthBytesLength)
  let length = 0
  if (lengthBytes.length >= 1) length = lengthBytes[0]
  // eslint-disable-next-line no-bitwise
  if (lengthBytes.length >= 2) length = (length << 8) | lengthBytes[1]
  // eslint-disable-next-line no-bitwise
  if (lengthBytes.length >= 3) length = (length << 8) | lengthBytes[2]
  // eslint-disable-next-line no-bitwise
  if (lengthBytes.length >= 4) length = (length << 8) | lengthBytes[3]
  if (lengthBytes.length >= 5)
    throw new Error(`Unable to decode RLP item or array with a length larger than 2**32`)
  return length
}

function rlpEncode(item): Uint8Array {
  if (item instanceof Uint8Array) {
    return rlpEncodeItem(item)
  }
  if (Array.isArray(item)) {
    return rlpEncodeList(item)
  }
  throw new Error(
    `Can only RLP encode Uint8Arrays (items) and arrays (lists).  Please encode your item into a Uint8Array first.\nType: ${typeof item}\n${item}`,
  )
}

function rlpEncodeItem(data: Uint8Array): Uint8Array {
  if (data.length === 1 && data[0] < 0x80) return rlpEncodeTiny(data)
  if (data.length <= 55) return rlpEncodeSmall(data)
  return rlpEncodeLarge(data)
}

function rlpEncodeList(items): Uint8Array {
  const encodedItems = items.map(rlpEncode)
  const encodedItemsLength = encodedItems.reduce((total, item) => total + item.length, 0)
  if (encodedItemsLength <= 55) {
    const result = new Uint8Array(encodedItemsLength + 1)
    result[0] = 0xc0 + encodedItemsLength
    let offset = 1
    // eslint-disable-next-line no-restricted-syntax
    for (const encodedItem of encodedItems) {
      result.set(encodedItem, offset)
      offset += encodedItem.length
    }
    return result
  }
  const lengthBytes = hexStringToUint8Array(encodedItemsLength.toString(16))
  const result = new Uint8Array(1 + lengthBytes.length + encodedItemsLength)
  result[0] = 0xf7 + lengthBytes.length
  result.set(lengthBytes, 1)
  let offset = 1 + lengthBytes.length
  // eslint-disable-next-line no-restricted-syntax
  for (const encodedItem of encodedItems) {
    result.set(encodedItem, offset)
    offset += encodedItem.length
  }
  return result
}

function rlpEncodeTiny(data: Uint8Array): Uint8Array {
  if (data.length > 1) throw new Error(`rlpEncodeTiny can only encode single byte values.`)
  if (data[0] > 0x80) throw new Error(`rlpEncodeTiny can only encode values less than 0x80`)
  return data
}

function rlpEncodeSmall(data: Uint8Array): Uint8Array {
  if (data.length === 1 && data[0] < 0x80)
    throw new Error(`rlpEncodeSmall can only encode a value > 0x7f`)
  if (data.length > 55)
    throw new Error(`rlpEncodeSmall can only encode data that is <= 55 bytes long`)
  const result = new Uint8Array(data.length + 1)
  result[0] = 0x80 + data.length
  result.set(data, 1)
  return result
}

function rlpEncodeLarge(data: Uint8Array): Uint8Array {
  if (data.length <= 55)
    throw new Error(`rlpEncodeLarge can only encode data that is > 55 bytes long`)
  const lengthBytes = hexStringToUint8Array(data.length.toString(16))
  const result = new Uint8Array(data.length + lengthBytes.length + 1)
  result[0] = 0xb7 + lengthBytes.length
  result.set(lengthBytes, 1)
  result.set(data, 1 + lengthBytes.length)
  return result
}

function hexStringToUint8Array(hex: string): Uint8Array {
  const match = new RegExp(`^(?:0x)?([a-fA-F0-9]*)$`).exec(hex)
  if (match === null)
    throw new Error(
      `Expected a hex string encoded byte array with an optional '0x' prefix but received ${hex}`,
    )
  const maybeLeadingZero = match[1].length % 2 ? '0' : ''
  const normalized = `${maybeLeadingZero}${match[1]}`
  const byteLength = normalized.length / 2
  const bytes = new Uint8Array(byteLength)
  for (let i = 0; i < byteLength; ++i) {
    bytes[i] = Number.parseInt(`${normalized[i * 2]}${normalized[i * 2 + 1]}`, 16)
  }
  return bytes
}

async function getPrice(
  eth_getStorageAt,
  eth_getBlockByNumber,
  exchangeAddress: bigint,
  denominationToken: bigint,
  blockNumber: bigint,
): Promise<bigint> {
  async function getAccumulatorValue(innerBlockNumber: bigint, timestamp: bigint): Promise<bigint> {
    const [token0, token1, reservesAndTimestamp, accumulator0, accumulator1] = await Promise.all([
      eth_getStorageAt(exchangeAddress, 6n, innerBlockNumber),
      eth_getStorageAt(exchangeAddress, 7n, innerBlockNumber),
      eth_getStorageAt(exchangeAddress, 8n, innerBlockNumber),
      eth_getStorageAt(exchangeAddress, 9n, innerBlockNumber),
      eth_getStorageAt(exchangeAddress, 10n, innerBlockNumber),
    ])
    // eslint-disable-next-line no-bitwise
    const blockTimestampLast = reservesAndTimestamp >> (112n + 112n)
    const reserve1 =
      // eslint-disable-next-line no-bitwise
      (reservesAndTimestamp >> 112n) & (BigInt('0x10000000000000000000000000000') - 1n)
    // eslint-disable-next-line no-bitwise
    const reserve0 = reservesAndTimestamp & (BigInt('0x10000000000000000000000000000') - 1n)
    if (token0 !== denominationToken && token1 !== denominationToken)
      throw new Error(
        `Denomination token ${addressToString(
          denominationToken,
        )} is not one of the tokens for exchange ${exchangeAddress}`,
      )
    if (reserve0 === 0n)
      throw new Error(
        `Exchange ${addressToString(exchangeAddress)} does not have any reserves for token0.`,
      )
    if (reserve1 === 0n)
      throw new Error(
        `Exchange ${addressToString(exchangeAddress)} does not have any reserves for token1.`,
      )
    if (blockTimestampLast === 0n)
      throw new Error(
        `Exchange ${addressToString(
          exchangeAddress,
        )} has not had its first accumulator update (or it is year 2106).`,
      )
    if (accumulator0 === 0n)
      throw new Error(
        `Exchange ${addressToString(
          exchangeAddress,
        )} has not had its first accumulator update (or it is 136 years since launch).`,
      )
    if (accumulator1 === 0n)
      throw new Error(
        `Exchange ${addressToString(
          exchangeAddress,
        )} has not had its first accumulator update (or it is 136 years since launch).`,
      )
    const numeratorReserve = token0 === denominationToken ? reserve0 : reserve1
    const denominatorReserve = token0 === denominationToken ? reserve1 : reserve0
    const accumulator = token0 === denominationToken ? accumulator1 : accumulator0
    const timeElapsedSinceLastAccumulatorUpdate = timestamp - blockTimestampLast
    const priceNow =
      (numeratorReserve * BigInt('0x10000000000000000000000000000')) / denominatorReserve
    return accumulator + timeElapsedSinceLastAccumulatorUpdate * priceNow
  }

  const latestBlock = await eth_getBlockByNumber('latest')
  if (latestBlock === null) throw new Error(`Block 'latest' does not exist.`)
  const historicBlock = await eth_getBlockByNumber(blockNumber)
  if (historicBlock === null) throw new Error(`Block ${blockNumber} does not exist.`)
  const [latestAccumulator, historicAccumulator] = await Promise.all([
    getAccumulatorValue(latestBlock.number, latestBlock.timestamp),
    getAccumulatorValue(blockNumber, historicBlock.timestamp),
  ])
  const accumulatorDelta = latestAccumulator - historicAccumulator
  const timeDelta = BigInt(latestBlock.timestamp) - BigInt(historicBlock.timestamp)
  return accumulatorDelta / timeDelta
}
