import styled from 'styled-components'

import { breakpoint } from '../../../../../uiKit/theme'
import { Param } from '../../CollateralParameters/styled'

export const StyledCurrentCDPParams = styled.div<{ inRow?: number }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  ${Param} {
    width: 100%;
    @media screen and (min-width: ${breakpoint.tablet}) {
      width: ${(p) => (p.inRow ? `${(100 / (p.inRow + 0.2)).toFixed(1)}%` : '26%')};
    }
  }
`
