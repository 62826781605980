import styled from 'styled-components'

import { breakpoint } from '../../uiKit/theme'

export const HomePageLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 4rem 0 0;
  flex-wrap: wrap;
  @media screen and (min-width: ${breakpoint.desktop}) {
    flex-wrap: nowrap;
  }
`

export const RightSideBarStub = styled.div`
  display: flex;
  flex: 1 0 10px;
`

export const HomePageMiddle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${breakpoint.desktop}) {
    width: auto;
  }
`
