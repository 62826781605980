import { isGnosis } from '../store/userStore'
import { IS_GNO } from './constants'

export const PROTOCOL_STABLE = {
  100: {
    symbol: 'USG',
    decimals: 18,
    roundTo: 2,
  },
  default: {
    symbol: 'USDP',
    decimals: 18,
    roundTo: 2,
  },
}

export const protocolStableSymbol = IS_GNO
  ? PROTOCOL_STABLE[100].symbol
  : PROTOCOL_STABLE.default.symbol

export const protocolStable = (): (typeof PROTOCOL_STABLE)[keyof typeof PROTOCOL_STABLE] =>
  isGnosis() ? PROTOCOL_STABLE[100] : PROTOCOL_STABLE.default
